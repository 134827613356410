import { useEffect, useRef, useState, forwardRef } from 'react';
import DropDownBox from 'devextreme-react/drop-down-box';
import DataGrid, { Toolbar, Item as ToolbarItem,} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react';
import baseapi from '../../api/baseapi';
import utils from '../../utils/common';

export default forwardRef(function CustomizedGridLookup(props, ref){
    
    const [selectedKey, setSelectedKey] = useState(props.data.value);
    const [tempSelection, setTempSelection] = useState(props.data.value);
    const gridRef = useRef(null);
    const searchBoxRef = useRef(null);
    const dropdownRef = useRef(null);
    const currentFocusIndex = useRef(null);
    const previousDataSource = useRef([]);
    const pagingIndex = useRef({
        loading: false,
        page: 1
    });
    const defaultValueExpr = props.valueExpr !== undefined ? props.valueExpr : "id";
    const defaultDisplayExpr = props.displayExpr !== undefined ? props.displayExpr : "code";
    const [gridDataSource, setGridDataSource] = useState(props.sourceList ?? []);

    useEffect(() => {
        if(searchBoxRef.current !== null){
            searchBoxRef.current.instance.focus();
        }
    }, []);

    useEffect(() => {
        if(searchBoxRef.current !== null && selectedKey === null){
            searchBoxRef.current.instance.focus();
        }
        else{
            dropdownRef.current.instance.focus();
        }
    }, [selectedKey]);

    useEffect(() => {
        if(props.opened === true && props.disabled !== true){
            openDropDown();
        }
        else if(props.opened === false){
            closeDropDown();
        }
    }, [props.opened]);

    useEffect(() => {
        if(pagingIndex.current["loading"] === true && Array.isArray(gridDataSource)){
            pagingIndex.current["page"] += 1;
            pagingIndex.current["loading"] = false;
        }

        if(Array.isArray(gridDataSource)){
            previousDataSource.current = [...gridDataSource];
        }

        if(Array.isArray(gridDataSource) && gridDataSource.length === 0 && !utils.isNullOrEmpty(props.dataSourceURL)){
            baseapi.httpget(props.dataSourceURL, utils.mergeObject({ q: selectedKey, singleReturn: false }, props.mergeParams))
            .then(response => {
                const data = response.data;
                updateDataSource(data);
            })
            .catch(() => { throw 'Network error' });
        }

        // console.log("grid data source", gridDataSource)
    }, [gridDataSource]);

    const openDropDown = () => {
        if(dropdownRef.current !== null){
            dropdownRef.current.instance.open();
        }
    }

    const closeDropDown = () => {
        if(dropdownRef.current !== null){
            dropdownRef.current.instance.close();
        }
    }

    const onSelectionChanged = (changedValue) => {
        const returnValue = utils.isObject(changedValue) ? changedValue[defaultValueExpr] : changedValue;

        if(!utils.isNullOrEmpty(props.onSelectionChanged)){
            props.onSelectionChanged({value: returnValue, selectedItem: changedValue, dataSource: gridDataSource});
        }

        props.data.setValue(returnValue);
        setSelectedKey(returnValue);
    }

    const clearValue = () => {
        onSelectionChanged(null);
        if(searchBoxRef.current !== null) searchBoxRef.current.instance.reset();
    }

    const updateDataSource = (givenSource) => {
        const currentSource = previousDataSource.current;
        if(Array.isArray(givenSource) && Array.isArray(currentSource)){
            // Combine arrays
            const combinedArray = [...currentSource, ...givenSource];

            // Filter out duplicates based on the 'valueExpr' property
            const uniqueArray = combinedArray.filter((item, index, self) =>
                index === self.findIndex((t) => t[defaultValueExpr] === item[defaultValueExpr])
            );

            if(!utils.arrayEqual(currentSource, uniqueArray)){
                const sortedArray = utils.sortArrayByProp(uniqueArray, utils.isNullOrEmpty(props.sortByColumn) ? defaultDisplayExpr : props.sortByColumn);
                setGridDataSource(sortedArray);

                //Trigger onDataSourceChanged
                if(!utils.isNullOrEmpty(props.onDataSourceChanged)){
                    props.onDataSourceChanged(sortedArray);
                }
            }
        }
    };

    const foundInDataSource = (value) => {
        const copiedArr = [...gridDataSource];
        const foundInOriginal = copiedArr.find(c => c[defaultValueExpr] === value);

        if(foundInOriginal === undefined){
            return false;
        }
        else{
            return true;
        }
    }

    const DataGridRender = () =>{
        return <div className='customized-lookup-container'>
                <div className="customized-lookup-search-container">
                    <div>
                        <TextBox 
                            ref={searchBoxRef}
                            placeholder="Search..." 
                            width={160}
                            valueChangeEvent="keyup"
                            onValueChanged={(e) => {
                                gridRef.current.instance.searchByText(e.value);

                                if(props.dataSourceURL !== undefined){
                                    if(!utils.isNullOrEmpty(props.dataSourceURL)){
                                        baseapi.httpget(props.dataSourceURL, utils.mergeObject({ q: e.value, singleReturn: true }, props.mergeParams))
                                        .then(response => {
                                            const data = response.data;
                                            updateDataSource(data);
                                        })
                                        .catch(() => { throw 'Network error' });
                                    }
                                }
                            }}
                            onKeyDown={(e) => {
                                // console.log("key down", e.event)
                                if(e.event.key === "ArrowDown"){
                                    gridRef.current.instance.focus();
                                }
                            }}
                        />
                    </div>

                    <div className="customized-lookup-btn-section">
                        <Button text="Clear" onClick={(e) => clearValue()}/>
                    </div>
                </div>

                <DataGrid
                    ref={gridRef}
                    className={"lookup-datagrid ".concat(props.className !== undefined ? props.className : "")}
                    height={props.gridHeight !== undefined ? props.height : "200px"}
                    showBorders={true}
                    dataSource={gridDataSource}
                    columnChooser={{enabled: false}}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                    hoverStateEnabled={true}
                    columnAutoWidth={props.gridColumnAutoWidth !== undefined ? props.gridColumnAutoWidth : true}
                    paging={{enabled: false}}
                    keyExpr="id"
                    scrolling={{columnRenderingMode: "standard", showScrollbar: "onHover"}}
                    focusedRowEnabled={true}
                    // focusedRowKey={tempSelection}
                    onFocusedRowChanging={(e) => {
                        if(e.event === null){
                            e.cancel = true;
                        }
                        else if(e.event.key === undefined){
                            e.cancel = true;
                        }
                    }}
                    onFocusedRowChanged={(e) => {
                        const data = e.row.data;
                        currentFocusIndex.current = e.rowIndex;
                        setTempSelection(data);
                    }}
                    onKeyDown={(e) => {
                        if(e.event.key === "Enter"){
                            onSelectionChanged(tempSelection);
                            closeDropDown();
                        }
                        if(e.event.key === "ArrowUp"){
                            // If focus is one the first row then brings focus back to the search box
                            if(currentFocusIndex.current === 0){
                                setTimeout(() => {
                                    searchBoxRef.current.instance.focus();
                                }, 50);
                            }
                        }
                    }}
                    onCellClick={(e) =>{
                        if(e.rowType === "data"){
                            const data = e.data;
                            onSelectionChanged(data);
                            closeDropDown();
                        }
                    }}
                    onRowPrepared={(e) => {
                        const key = e.key;

                        if(key === selectedKey){
                            e.rowElement.classList.add("lookup-selected-highlight");
                        }
                    }}
                    onContentReady={(element) => {
                        const scrollable = element.component.getScrollable();
                        scrollable.on("scroll", function(e) {
                            if(e.reachedBottom){
                                if(pagingIndex.current["loading"] === false){
                                    pagingIndex.current["loading"] = true;
                                    baseapi.httpget(props.dataSourceURL, utils.mergeObject({ q: selectedKey, singleReturn: false, page: pagingIndex.current["page"] }, props.mergeParams))
                                    .then(response => {
                                        const data = response.data;
                                        updateDataSource(data);
                                    })
                                    .catch(() => { throw 'Network error' });
                                }              
                            }
                        })
                    }}
                >
                    {props.children}
                </DataGrid>
                
            </div>;
    };

    return <DropDownBox
            ref={dropdownRef}
            onKeyDown={(e) =>{
                const keydown = e.event.key;
                if(keydown !== "Tab"){
                    openDropDown();
                }
            }}
            disabled={props.disabled}
            dropDownOptions={{width: "auto"}}
            dataSource={gridDataSource}
            value={selectedKey}
            displayExpr={defaultDisplayExpr}
            valueExpr={defaultValueExpr}
            contentRender={DataGridRender}
        >
        </DropDownBox>;
})