import React from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';
import { Column, Editing, Popup, Form, Item, RequiredRule, Button as ButtonColumn, AsyncRule } from 'devextreme-react/data-grid';
import utils from '../../utils/common';

class Forex extends React.Component {
    constructor(props) {
        super();
        this.listingRef = React.createRef();
        this.ButtonColumnWidth = utils.getColumnWidth("buttons", "SettingForexListing") === null ? 80 : utils.getColumnWidth("buttons", "SettingForexListing");
        this.CodeColumnWidth = utils.getColumnWidth("Code", "SettingForexListing") === null ? undefined : utils.getColumnWidth("Code", "SettingForexListing");
        this.NameColumnWidth = utils.getColumnWidth("Name", "SettingForexListing") === null ? undefined : utils.getColumnWidth("Name", "SettingForexListing");
        this.ForexRateColumnWidth = utils.getColumnWidth("ForexRate", "SettingForexListing") === null ? undefined : utils.getColumnWidth("ForexRate", "SettingForexListing");
        this.LocalRateColumnWidth = utils.getColumnWidth("LocalRate", "SettingForexListing") === null ? undefined : utils.getColumnWidth("LocalRate", "SettingForexListing");
        this.CreatedDateColumnWidth = utils.getColumnWidth("CreatedDate", "SettingForexListing") === null ? undefined : utils.getColumnWidth("CreatedDate", "SettingForexListing");

        this.ButtonVisibleIndex = utils.getColumnVisibleIndex("buttons", "SettingForexListing") === null ? 0 : utils.getColumnVisibleIndex("buttons", "SettingForexListing");
        this.CodeVisibleIndex = utils.getColumnVisibleIndex("Code", "SettingForexListing") === null ? 1 : utils.getColumnVisibleIndex("Code", "SettingForexListing");
        this.NameVisibleIndex = utils.getColumnVisibleIndex("Name", "SettingForexListing") === null ? 2 : utils.getColumnVisibleIndex("Name", "SettingForexListing");
        this.ForexRateVisibleIndex = utils.getColumnVisibleIndex("ForexRate", "SettingForexListing") === null ? 3 : utils.getColumnVisibleIndex("ForexRate", "SettingForexListing");
        this.LocalRateVisibleIndex = utils.getColumnVisibleIndex("LocalRate", "SettingForexListing") === null ? 4 : utils.getColumnVisibleIndex("LocalRate", "SettingForexListing");
        this.CreatedDateVisibleIndex = utils.getColumnVisibleIndex("CreatedDate", "SettingForexListing") === null ? 5 : utils.getColumnVisibleIndex("CreatedDate", "SettingForexListing");
    }

    onInitNewRow(e) {
        e.data.Active = true;
    }

    render() {
        return <Container>
            <div id='normal-listing'>
                <SettingListing
                    ref={this.listingRef}
                    listingTitle="Forex"
                    apiURL="/api/Forex"
                    listingURL="/api/Forex/list"
                    sortColumn={"Code"}
                    dateFilter={false}
                    storageName={"SettingForexListing"}
                    customizedPopup={true}
                    onInitNewRow={this.onInitNewRow}
                >
                    <Column type="buttons" width={this.ButtonColumnWidth} visibleIndex={this.ButtonVisibleIndex} fixed={true}>
                        <ButtonColumn name="edit" />
                        <ButtonColumn name="delete" />
                    </Column>
                    <Column dataField="Code" dataType="string" setCellValue={utils.GridCellValueToUpper} width={this.CodeColumnWidth} visibleIndex={this.CodeVisibleIndex} />
                    <Column dataField="Name" dataType="string" width={this.NameColumnWidth} visibleIndex={this.NameVisibleIndex} />
                    <Column dataField="ForexRate" dataType="string" width={this.ForexRateColumnWidth} visibleIndex={this.ForexRateVisibleIndex} />
                    <Column dataField="LocalRate" dataType="string" width={this.LocalRateColumnWidth} visibleIndex={this.LocalRateVisibleIndex} />
                    <Column dataField="CreatedDate" format="dd/MM/yyyy" dataType={"date"} width={this.CreatedDateColumnWidth} visibleIndex={this.CreatedDateVisibleIndex} />
                    <Column dataField="Active" visible={false} />
                    <Editing useIcons={true}
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        mode="popup"
                    >
                        <Popup title="Forex" showTitle={true} width="auto" height="auto" dragEnabled={false} />
                        <Form>
                            <Item dataField="Code">
                                <RequiredRule message={"Code is required!"} />
                                <AsyncRule
                                    message={"This code is already taken!"}
                                    validationCallback={(e) => utils.codeAsyncPropsValidation("/api/Forex", { code: e.value, id: this.listingRef.current?.getEditingID() })}
                                />
                            </Item>
                            <Item dataField="Name" >
                                <RequiredRule message={"Name is required!"} />
                            </Item>
                            <Item dataField="ForexRate" >
                                <RequiredRule message={"Forex Rate is required!"} />
                            </Item>
                            <Item dataField="LocalRate" >
                                <RequiredRule message={"Local Rate is required!"} />
                            </Item>
                            <Item dataField="Active" editorType="dxCheckBox" defaultValue={true} enableThreeStateBehavior={false} />
                        </Form>
                    </Editing>
                </SettingListing>
            </div>
        </Container>
    }
}

export default Forex;