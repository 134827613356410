import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import baseapi from '../../api/baseapi';
import utils, { done, processing, notfound } from '../../utils/common';
// import Form, { Item, GroupItem, Label, ButtonItem, ButtonOptions, ColCountByScreen } from 'devextreme-react/form';
import Container from '../../components/container';
import { stateOptions, countryOptions } from '../../utils/lookupstore';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import { SelectBox } from 'devextreme-react/select-box';
import FileUploader from 'devextreme-react/file-uploader';
import { CheckBox, CheckBoxTypes } from 'devextreme-react/check-box';
import { loading, closeLoading } from "../../utils/common";
import {
    Validator,
    RequiredRule as ValidatorRequired, PatternRule
} from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import CustomizedHtmlEditor from '../../components/html-editor/CustomizedHtmlEditor';
import ErrorPopUpForm from "../../components/popup-form/ErrorPopupForm";
export default function Profile() {
    const navigate = useNavigate();
    const tempForm = useRef(null);
    const validatorRef = useRef(null);
    const imageRef = useRef(null);
    const htmlEditorRef = useRef(null);

    const [dropDownList, setDropDownList] = useState({
        stateList: [], countryList: []
    });
    const [formValue, setFormValue] = useState({});
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [textVisible, setTextVisible] = useState(true);
    const [isDropZoneActive, setIsDropZoneActive] = useState(false);
    const allowedFileExtensions = ['.jpg', '.jpeg', '.gif', '.png'];
    const userID = JSON.parse(localStorage.getItem('data'));
    const [showPasswordFields, setShowPasswordFields] = useState(false);
    const formRef = useRef();
    const popupMessageRef = useRef(null);
    const initialFormValues = useRef({});
    useEffect(() => {
        onInitialized();
    }, [])
    const [oldPasswordMode, setOldPasswordMode] = useState('password');
    const [authPasswordMode, setAuthPasswordMode] = useState('password');
    const [passwordMode, setPasswordMode] = useState('password');
    const [newPasswordRequired, setNewPasswordRequired] = useState(false);
    const oldPasswordButton = useMemo(
        () => ({
            icon: 'images/eye-regular.svg',
            onClick: () => {
                setOldPasswordMode((prevPasswordMode) => (prevPasswordMode === 'text' ? 'password' : 'text'));
            },
        }),
        [setOldPasswordMode],
    );
    const passwordButton = useMemo(
        () => ({
            icon: 'images/eye-regular.svg',
            onClick: () => {
                setPasswordMode((prevPasswordMode) => (prevPasswordMode === 'text' ? 'password' : 'text'));
            },
        }),
        [setPasswordMode],
    );
    const authPasswordButton = useMemo(
        () => ({
            icon: 'images/eye-regular.svg',
            onClick: () => {
                setAuthPasswordMode((prevPasswordMode) => (prevPasswordMode === 'text' ? 'password' : 'text'));
            },
        }),
        [setAuthPasswordMode],
    );

    function onInitialized(e) {
        loading("Loading User Profile...");

        baseapi.httpget('/api/UserProfile/GetUserProfile', { id: userID.userID || "new" })
            .then(response => {
                const { data } = response;
                tempForm.current = data.model;

                tempForm.current = {
                    Username: data.model.Username || "",
                    Phone: data.model.Phone || "",
                    Email: data.model.Email || "",
                    UserRole: data.model.UserRole || "",
                    Image: data.model.Image || null,
                    OldPassword: "",
                    NewPassword: "",
                    AuthorizedPassword: "",
                    AuthorizedActive: data.model.AuthorizedActive || false,
                    ...data.model
                };

                setFormValue(tempForm.current);
                initialFormValues.current = { ...data.model };

                if (data.model.Image) {
                    setTextVisible(false);
                }
                closeLoading();
            }).catch(error => {
                closeLoading();

            });


    }

    function handleSubmit(e) {

        const hasChanged = Object.keys(formValue).some(key => formValue[key] !== initialFormValues.current[key]);

        if (!hasChanged) {
            return;
        }
        const res = validatorRef.current.instance.validate();
        const imageres = imageRef.current.instance;
        if (res.status === "valid" && res.isValid && (imageres._files.length < 1 || imageres._files[0].isValid())) {
            let tid = formValue.id;

            var formData = new FormData();
            for (var i in formValue)
                if (i != 'id') formData.append(i, formValue[i]);

            if (selectedFiles !== null) formData.append('ImageFile', selectedFiles);
            // processing();
            baseapi.upload(utils.extendUrlVar('/api/UserProfile/save?', { id: (userID.userID || ''), del: false }), formData)
                .then((response) => {

                    const { data } = response;
                    utils.displayPopupMessage(popupMessageRef, {
                        visible: true,
                        message: data.message,
                        type: data.status ? "Success" : "Warning",
                    });

                    if (data.status) {

                        onInitialized()

                    }
                });
        }
    }


    useEffect(() => {
        setNewPasswordRequired(!!formValue.OldPassword); // Set newPasswordRequired based on OldPassword
    }, [formValue.OldPassword]);

    function valueOnChange(e) {
        try {
            const dataField = e.element.getAttribute("dataField");
            const copied = { ...tempForm.current };
            copied[dataField] = e.value;
            tempForm.current = copied;
            setFormValue(tempForm.current);
        }
        catch (err) {
            console.log(err)
        }
    }

    function onSelectedFilesChanged(e) {
        if (e.value.length > 0) {
            setIsDropZoneActive(false);
            setTextVisible(false);
            setSelectedFiles(e.value[0]);
            var reader = new FileReader();
            reader.onload = (function (theFile) {
                return function (e) {
                    setFormValue({ ...formValue, ...{ "Image": e.target.result } })
                };
            })(e.value[0]);

            reader.readAsDataURL(e.value[0]);
        }
    }

    return <Container>
        <ValidationGroup ref={validatorRef}>


            <div className='profile-form-title-bg popup-form-title'>User Profile</div>

            <div className='profile-form-sub-subtitle-bg popup-form-title' style={{ marginTop: '32px' }}>Account Setting</div>
            <hr className='profile-popup-form-hr' />
            <div className='profile-parent-section-bg-white  custom-form-grid-section5'>
                <div>
                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>Username: </div>

                        <div className="popup-group-form-input">
                            <TextBox
                                value={formValue["Username"]}
                                elementAttr={{ dataField: "Username" }}
                                onValueChanged={(e) => { valueOnChange(e) }}
                                alignment="left"
                                width={"auto"}
                                readOnly={true}
                            >

                            </TextBox>
                        </div>
                    </div>
                    <br />
                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>Phone Number:</div>

                        <div className="popup-group-form-input">
                            <TextBox
                                value={formValue["Phone"]}
                                elementAttr={{ dataField: "Phone" }}
                                onValueChanged={(e) => { valueOnChange(e) }}
                                alignment="left"
                                width={"auto"}
                                readOnly={true}
                            >
                            </TextBox>
                        </div>
                    </div>
                    <br />
                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>Email:</div>

                        <div className="popup-group-form-input">
                            <TextBox
                                value={formValue["Email"]}
                                elementAttr={{ dataField: "Email" }}
                                onValueChanged={(e) => { valueOnChange(e) }}
                                alignment="left"
                                width={"auto"}
                                readOnly={true}
                            >
                            </TextBox>
                        </div>
                    </div>
                    <br />
                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>User Role:</div>

                        <div className="popup-group-form-input">
                            <TextBox
                                value={formValue["UserRole"]}
                                elementAttr={{ dataField: "UserRole" }}
                                onValueChanged={(e) => { valueOnChange(e) }}
                                alignment="left"
                                width={"auto"}
                                readOnly={true}
                            >
                            </TextBox>
                        </div>
                    </div>
                </div>


                <div className='custom-form-grid-section1' style={{ marginLeft: '50px' }}>
                    <div className="popup-group-form-item">
                        <div className="widget-container drag-and-drop-image-flex-box">
                            <div
                                id="userprofile-dropzone-external"
                                className={`drag-and-drop-image-flex-box ${isDropZoneActive
                                    ? 'dx-theme-accent-as-border-color dropzone-active'
                                    : 'dx-theme-border-color'
                                    }`}
                            >

                                {textVisible && (
                                    <div
                                        id="dropzone-text"
                                        className="drag-and-drop-image-flex-box"
                                    >
                                        <span>Drag & Drop the desired file …</span>
                                        <span>or click to browse for a file instead.</span>
                                    </div>
                                )}
                                {formValue['Image'] ? <img src={formValue['Image']} className='img-thumbnail' alt="user Image"></img> : null}


                            </div>
                            <div className="center-content">
                                <FileUploader
                                    id="file-uploader1"
                                    dialogTrigger="#userprofile-dropzone-external"
                                    dropZone="#userprofile-dropzone-external"
                                    multiple={false}
                                    allowedFileExtensions={allowedFileExtensions}
                                    ref={imageRef}
                                    maxFileSize={10000000}
                                    selectButtonText="Update Profile Picture "
                                    labelText=""
                                    accept="image/*"
                                    buttonOptions={{
                                        stylingMode: 'contained',
                                        type: 'success',
                                    }}
                                    uploadMode="useButtons"
                                    onValueChanged={onSelectedFilesChanged}
                                    className="user-profile-custom-file-uploader"
                                />
                            </div>
                        </div>
                    </div>

                </div>


            </div>
            <div className='profile-form-sub-subtitle-bg popup-form-title'>Security Setting</div>
            <hr className='profile-popup-form-hr' />
            <div className='profile-parent-section-bg-white custom-form-grid-section5'>
                <div>
                    <div className="popup-group-form-item">
                        <div className='popup-group-form-label'>Password <i class="dx-icon-lock" style={{ fontSize: '15px' }}></i></div>

                        <div className="popup-group-form-input">
                            <div className='popup-group-form-label' style={{ color: '#4482FF', cursor: 'pointer' }} onClick={() => setShowPasswordFields(!showPasswordFields)}>&nbsp; Change Password </div>
                        </div>
                    </div>
                    <br />
                </div>
                <div></div>
                {showPasswordFields && (
                    <>
                        <div>
                            <div className="popup-group-form-item">
                                <div className='popup-group-form-label'>Old Password :</div>
                                <div className="popup-group-form-input">
                                    <TextBox
                                        mode={oldPasswordMode}
                                        value={formValue["OldPassword"]}
                                        elementAttr={{ dataField: "OldPassword" }}
                                        onValueChanged={(e) => { valueOnChange(e) }}
                                        alignment="left"
                                        width={"auto"}

                                    >
                                        <TextBoxButton
                                            name="password"
                                            location="after"
                                            options={oldPasswordButton}
                                        />
                                    </TextBox>
                                </div>
                            </div>
                            <br />
                        </div>

                        <div>
                            <div className="popup-group-form-item">
                                <div className='popup-group-form-label'>New Password :</div>
                                <div className="popup-group-form-input">
                                    <TextBox
                                        mode={passwordMode}
                                        value={formValue["NewPassword"]}
                                        elementAttr={{ dataField: "NewPassword" }}
                                        onValueChanged={valueOnChange}
                                        alignment="left"
                                        width={"auto"}
                                    >
                                        <TextBoxButton
                                            name="password"
                                            location="after"
                                            options={passwordButton}
                                        />
                                        <Validator>
                                        {newPasswordRequired && (
                                            <ValidatorRequired message="New Password is required when changing the Old Password." />
                                        )}
                                            <PatternRule
                                                message="Must contain at least 8 characters, one number, and one letter"
                                                pattern={/^(?=.*[a-zA-Z])(?=.*\d).{8,}$/}
                                            />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>
                            <br />
                        </div>
                    </>
                )}

                {formValue["UserRole"] === "ADMIN" &&
                    <>
                        <div className="popup-group-form-item">
                            <div className='popup-group-form-label'>Authorized Personal Password :</div>

                            <div className="popup-group-form-input">
                                <TextBox
                                    mode={authPasswordMode}
                                    value={formValue["AuthorizedPassword"]}
                                    elementAttr={{ dataField: "AuthorizedPassword" }}
                                    onValueChanged={(e) => { valueOnChange(e) }}
                                    alignment="left"
                                    width={"auto"}
                                >

                                    <TextBoxButton
                                        name="password"
                                        location="after"
                                        options={authPasswordButton}
                                    />
                                    <Validator>
                                        <PatternRule
                                            message="Must contain at least 8 characters, one number, and one letter"
                                            pattern={/^(?=.*[a-zA-Z])(?=.*\d).{8,}$/}
                                        />
                                    </Validator>
                                </TextBox>

                            </div>
                        </div>


                        <div></div>


                        <div className="popup-group-form-item">
                            <div className='popup-group-form-label'></div>

                            <div className="popup-group-form-input">
                                <CheckBox
                                    value={formValue["AuthorizedActive"]}
                                    text="Active"
                                    style={{ marginTop: '9px' }}
                                    elementAttr={{ dataField: "AuthorizedActive" }}
                                    onValueChanged={(e) => { valueOnChange(e) }}
                                />
                            </div>
                        </div>
                    </>
                }


            </div>

            <div className="modal-footer" style={{ textAlign: 'start', marginTop: '30px' }}>
                <Button
                    width={"auto"}
                    text="Save Changes"
                    type="success"
                    stylingMode="contained"
                    useSubmitBehavior={false}
                    className="popup-form-submit-btn"
                    onClick={(e) => {
                        handleSubmit();
                    }}
                />
            </div>
        </ValidationGroup>
        <ErrorPopUpForm ref={popupMessageRef} />

    </Container >;
}