import Listing from "../../../components/listing/newListing";
import Container from "../../../components/container";
import { Column } from 'devextreme-react/data-grid';
import TypeOfServiceForm from './form';
import React, { useState, useEffect, useRef, useCallback } from "react";
export default function TypeOfServiceListing() {
    const apiURL = `/api/WKTypeOfService`;
    const [currentEditID, setCurrentEditID] = useState(null);
    function handleEdit(e) {
        const id = e.row.key;
        setCurrentEditID(id);
    }
    return <Container>
        <div id="normal-listing">
            <Listing
                apiURL={apiURL}
                listingURL="/api/WKTypeOfService/list"
                sortColumn={"Code"}
                className={"workshop-listing-datagrid"}
                listingTitle={"Type Of Service"}
                storageName={"WKTypeOfServiceListingColumnWidth"}
                onAddClick={setCurrentEditID}
                dateFilter={false}
                handleEdit={handleEdit}
            >
                <Column dataField={"Code"} />
                <Column dataField={"Name"} />
                <Column dataField={"Active"} />
            </Listing>
        </div>

        <TypeOfServiceForm
            formID={currentEditID}
            closePopup={setCurrentEditID}
        />
    </Container>
}