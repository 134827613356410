import { Popup } from "devextreme-react/popup";
import { Button } from "devextreme-react/button";
import { DataGrid, Column } from "devextreme-react/data-grid";
import { useRef, useEffect, useState,forwardRef, useImperativeHandle } from "react";
import utils from "../../../utils/common";
import baseapi from "../../../api/baseapi";
import './GainAndLossForm.scss';

export default forwardRef(function GainAndLossForm(props, ref){
    const popupRef = useRef(null);
    const [dataSource, setDataSource] = useState([]);

    useImperativeHandle(ref, () => ({
		openForm(offsetDetails){
			openForm(offsetDetails);
		}
	}));

    const openForm = (offsetDetails) => {
        if(!utils.isNullOrEmpty(popupRef.current)){
            if(!utils.isNullOrEmpty(offsetDetails) && utils.isObject(offsetDetails)){
                const parent = offsetDetails.Parent;

                if(!utils.isNullOrEmpty(props.apiPath) && !utils.isNullOrEmpty(parent) && parent["ForexID"] !== utils.getDefaultForexRM()){
                    baseapi.httppost(props.apiPath, offsetDetails).then((response) => {
                        const { data } = response;
                        const result = data.gainAndLoss;

                        if(Array.isArray(result) && result.length > 0){
                            setDataSource(result);
                            popupRef.current.instance.show();
                        }
                        else{
                            //Submit form if there is no gain and loss
                            saveButtonAction();
                        }
                       
                    });
                    return;
                }
            }
        }

        //Submit form if the forex is not RM
        saveButtonAction();
    };

    const closeForm = () => {
        if(!utils.isNullOrEmpty(popupRef.current)){
            popupRef.current.instance.hide();
        }
    };

    const cancelButtonAction = () => {
        closeForm();
        setDataSource([]);
    };

    const saveButtonAction = () => {
        if(!utils.isNullOrEmpty(props.onSaving)){
            props.onSaving({ stay: null });
        }
        closeForm();
    };

    useEffect(() => {
        if(!utils.isNullOrEmpty(props.dataSource)){
            setDataSource(props.dataSource);
        }
    }, [props.dataSource]);

    return <Popup 
        ref={popupRef}
        height={"auto"}
        shading={true}
        showTitle={false}
    >
        <div className="gain-and-loss-title">Set Foreign Exchange Gain/Loss Posting</div>
        <DataGrid
            dataSource={dataSource}
            keyExpr={"OffsetDocumentNo"}
            showBorders={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
        >
            <Column dataField="OffsetTypeID" caption={"Type"}/>
            <Column dataField="DocumentDate" caption={"Date"} format='dd/MM/yyyy' dataType={"date"}/>
            <Column dataField="OffsetDocumentNo" caption={"No."}/>
            <Column dataField="MainForexAmount" caption={"T.Amt."}/>
            <Column dataField="OutstandingAmount" caption={"Outstanding"}/>
            <Column dataField="OffsetAmount" caption={"Paid Amount"}/>
            <Column dataField="GainLoss" caption={"Gain/Loss Amt."}/>
        </DataGrid>

        <div className='customized-popup-form-footer'>
            <Button 
                width={"auto"} 
                text='OK' 
                type='success' 
                stylingMode='contained' 
                className='popup-form-submit-btn' 
                useSubmitBehavior={false} 
                onClick={saveButtonAction} 
            />

            <Button 
                width={"auto"} 
                text='Cancel' 
                type='normal' 
                stylingMode='outlined' 
                className='gain-and-loss-cancel' 
                useSubmitBehavior={false} 
                onClick={cancelButtonAction} 
            />
        </div>
    </Popup>
})