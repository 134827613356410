import React, { useState, useRef, useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import './LoginForm.scss';
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import Validator from 'devextreme-react/validator';
import CheckBox from 'devextreme-react/check-box';
import ResetPasswordForm from '../reset-password-form/ResetPasswordForm';
import baseapi from "../../api/baseapi";
export default function LoginForm() {
  const navigate = useNavigate();
  const { signIn, showCompanySelection } = useAuth();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ email: '', password: '', companyCode: 'Greenplus' });
  const [focus, setFocus] = useState({ email: false, password: false });
  const emailValidatorRef = useRef(null);
  const passwordValidatorRef = useRef(null);
  const [passwordMode, setPasswordMode] = useState('password');
  const [popupVisible, setPopupVisible] = useState(false);

  const togglePasswordMode = useCallback(() => {
    setPasswordMode((prevMode) => (prevMode === 'password' ? 'text' : 'password'));
  }, []);

  const onFocus = useCallback((field) => {
    setFocus((prevFocus) => ({ ...prevFocus, [field]: true }));
  }, []);

  const onBlur = useCallback((field) => {
    if (!formData.current[field]) {
      setFocus((prevFocus) => ({ ...prevFocus, [field]: false }));
    }
  }, []);

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();

    const emailValidation = emailValidatorRef.current.instance.validate();
    const passwordValidation = passwordValidatorRef.current.instance.validate();

    if (!emailValidation.isValid || !passwordValidation.isValid) {
      return;
    }

    const { email, password, companyCode } = formData.current;
    setLoading(true);

    const result = await signIn(companyCode, email, password);
    setLoading(false);

    if (!result.isOk) {
      notify(result.message, 'error', 2000);
    } else {

      showCompanySelection(true);
    }
  }, [signIn]);

  const onCreateAccountClick = useCallback(() => {
    navigate('/create-account');
  }, [navigate]);

  const passwordButton = useMemo(
    () => ({
      icon: 'images/eye-regular.svg',
      onClick: () => {
        setPasswordMode((prevPasswordMode) => (prevPasswordMode === 'text' ? 'password' : 'text'));
      },
    }),
    [setPasswordMode],
  );

  const showPopup = useCallback(() => {
    setPopupVisible(true);
  }, []);

  const hidePopup = useCallback(() => {
    setPopupVisible(false);
  }, []);

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className={`auth-input-div one ${focus.email ? 'focus' : ''}`}>
          <div className="auth-i">
            <i className="fas fa-user"></i>
          </div>
          <div className="auth-div">
            <TextBox
              value={formData.current.email}
              onValueChanged={(e) => {
                formData.current.email = e.value;
                setFocus((prevFocus) => ({ ...prevFocus, email: !!e.value }));
              }}
              onFocusIn={() => onFocus('email')}
              onFocusOut={() => onBlur('email')}
              className="auth-input"
              stylingMode="underlined"
              label="Email"
              labelMode="outside"
            >
              <Validator ref={emailValidatorRef}>
                <RequiredRule message="Email is required" />
                <EmailRule message="Email is invalid" />
              </Validator>
            </TextBox>
          </div>
        </div>

        <div className={`auth-input-div pass ${focus.password ? 'focus' : ''}`}>
          <div className="auth-i">
            <i className="fas fa-lock"></i>
          </div>
          <div className="auth-div">
            <TextBox
              value={formData.current.password}
              onValueChanged={(e) => {
                formData.current.password = e.value;
                setFocus((prevFocus) => ({ ...prevFocus, password: !!e.value }));
              }}
              onFocusIn={() => onFocus('password')}
              onFocusOut={() => onBlur('password')}
              className="auth-input"
              stylingMode="underlined"
              label="Password"
              labelMode="outside"
              mode={passwordMode}
            >
              <TextBoxButton
                name="password"
                location="after"
                options={passwordButton}
              />
              <Validator ref={passwordValidatorRef}>
                <RequiredRule message="Password is required" />
              </Validator>
            </TextBox>
          </div>
        </div>

        {!loading && <input type="submit" className="auth-button" value="Login" />}
        {loading && (
          <div className="loading-indicator-container">
            <div class="loader"></div>
          </div>
        )}
        <div className="auth-flex-container">
          <div className="auth-checkbox-div">
            <CheckBox
              id="rememberMe"
              text="Remember Me"
            />
          </div>
          <a className="auth-forgot-password" href="#" onClick={showPopup}>Forgot Password?</a>
        </div>
      </form>
      <ResetPasswordForm visible={popupVisible} onClose={hidePopup} setPopupVisible={setPopupVisible} />
    </>
  );
}

const companyCodeEditorOptions = { stylingMode: 'filled', placeholder: 'Company Code', mode: 'text' };
const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
const rememberMeEditorOptions = { text: 'Remember me', elementAttr: { class: 'form-text' } };
