import React, { useState } from 'react';
import { Column } from 'devextreme-react/data-grid';
import Container from '../../../components/container';
import RegistrationForm from './form';
import Listing from '../../../components/listing/newListing';

export default function RegistrationListing() {

    const apiURL = `/api/WKRegistration`;
    const [currentEditID, setCurrentEditID] = useState(null);

    function handleEdit(e){
        const id = e.row.key;
        setCurrentEditID(id);
    }

    return <Container>
        <div id='normal-listing'>
            <Listing
                apiURL={apiURL}
                listingURL="/api/WKRegistration/list"
                className={"workshop-listing-datagrid"}
                sortColumn={"Code"}
                listingTitle={"Registration"}
                storageName={"WorkshopRegistrationListing"}
                onAddClick={setCurrentEditID}
                handleEdit={handleEdit}
            >
                <Column dataField="Code" caption={"Registration No"} />
                <Column dataField="CustomerCode" />
                <Column dataField="OwnerName" caption={'Customer Name'} />
                <Column dataField="ChasisNo" />
                <Column dataField="ModelCode" caption={"Model"} />
                <Column dataField="Colour"/>
                <Column dataField="RegDate"  format="dd/MM/yyyy" dataType={"date"}/>
        
            </Listing>
        </div>

        <RegistrationForm 
            formID={currentEditID}
            closePopup={setCurrentEditID}
        />
    </Container>;
}