import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import baseapi from '../../api/baseapi';
import Listing from '../../components/listing';
import utils, { decision, done, notfound, processing } from '../../utils/common';
import { Column } from 'devextreme-react/data-grid';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { Button as ButtonColumn, Editing, Toolbar, Item } from 'devextreme-react/data-grid';
import Button from 'devextreme-react/button';

export default function GSTTaxListing() {
    const filterStorage = JSON.parse(localStorage.getItem('gsttaxcode_filter') || '{}');
    const [filter, setFilter] = useState({
        q: '',
        fromDate: '',
        toDate: '',
        ...filterStorage
    });
    const [table, setTable] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        refresh()
    }, [filter])

    function handleEdit(e) {
        const id = e ? e.row.data.id : 0;
        // console.log('/arcreditnote/edit/' + encodeURIComponent(id));
        navigate('/gsttaxcode/edit/' + encodeURIComponent(id));
    }
    function renderSearch() {
        const { q } = filter;
        return <div className="dx-field">
            <div className="dx-field-value searchbox">
                <TextBox
                    placeholder="Search Code, Name..."
                    stylingMode="filled"
                    name="q"
                    onValueChanged={handleSearchChanged}
                    valueChangeEvent="keyup"
                    value={q}
                >
                </TextBox>
            </div>
        </div>;
    }
    function handleSearchChanged(e) {
        const { name, value } = e.event.target;
        setFilter({ ...filter, [name]: value })
    }
    function refresh() {
        localStorage.setItem('gsttaxcode_filter', JSON.stringify(filter));
        if (table) table.refresh(filter);
    }
    function handleAdd() {
        navigate('/gsttaxcode/edit/new');
    }

    return <div className='content-block dx-card responsive-paddings'>
        <h3>GST Tax Code</h3>
        {renderSearch()}
        <Listing ref={table => setTable(table)}
            api={baseapi}
            url="/api/GSTTaxCode"
            listingURL="/api/GSTTaxCode/list"
            filter={filter}>
            <Toolbar>
                <Item location="after">
                    <Button icon='plus' onClick={handleAdd} />
                </Item>
            </Toolbar>
            <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true} />
            <Column type="buttons" width={80}>
                <ButtonColumn name="edit" onClick={handleEdit} />
                <ButtonColumn name="delete" />
            </Column>
            <Column width="auto" dataField="Code" />
            <Column width={900} dataField="Name" caption="Description" />
            <Column dataField="TaxRate" caption="GST Rate" />
            <Column dataField="ModifiedDate" allowFiltering={false} />
        </Listing>
    </div>;
}