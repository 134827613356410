import { useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import Popup from 'devextreme-react/popup';
import Button from 'devextreme-react/button';
import CustomizedHtmlEditor from '../html-editor/CustomizedHtmlEditor';
import utils from '../../utils/common';

export default forwardRef(function CustomizedGridHtmlEditor(props, ref){

    const htmlEditorRef = useRef(null);
    const popupRef = useRef(null);

    useImperativeHandle(ref, () => ({
            setValue(value){
                if(!utils.isNullOrEmpty(htmlEditorRef.current)){
                    htmlEditorRef.current.setValue(value);
                }
            }
        })
    );

    const openPopup = () => {
        if(!utils.isNullOrEmpty(popupRef.current)){
            popupRef.current.instance.show();
        }
    };

    const closeButtonAction = () => {
        if(!utils.isNullOrEmpty(popupRef.current)){
            popupRef.current.instance.hide();
        }

        if(!utils.isNullOrEmpty(htmlEditorRef.current)){
            htmlEditorRef.current.setValue(null);
        }

        if(!utils.isNullOrEmpty(props.onClosing)){
            props.onClosing();
        }
    }

    const saveAction = () => {
        if(!utils.isNullOrEmpty(props.onSaving)){
            var editorValue = null;

            if(!utils.isNullOrEmpty(htmlEditorRef.current)){
                editorValue = htmlEditorRef.current.getValue();
            }

            props.onSaving(editorValue);
        }

        closeButtonAction();
    };

    useEffect(() => {
        if(!utils.isNullOrEmpty(props.open)){
            if(props.open !== 0){
                openPopup();
            }
        }
    }, [props.open]);

    return <Popup
        ref={popupRef}
        container='.dx-viewport'
        shading={true}
        width={"50%"}
        height={"auto"}
        showCloseButton={false}
        showTitle={false}
    >
        <div className={"popup-form-title ".concat(props.headerClassName !== undefined ? props.headerClassName : "")}>
            <div className='popup-form-title-grid grid-html-editor-form'>
                <div>Edit Description</div>

                <div className='popup-form-close-container'>
                    {props.cancelDocumentCustomization === undefined ? null : props.cancelDocumentCustomization}
                    <Button icon='close' stylingMode='text' className='' onClick={closeButtonAction} />
                </div>
            </div>
            {props.headerCustomization === undefined ? "" : props.headerCustomization}
        </div>

        <CustomizedHtmlEditor 
            ref={htmlEditorRef}
        />

        <div className='customized-popup-form-footer'>
            <Button 
                width={"auto"} 
                text='Save' 
                type='success' 
                stylingMode='contained' 
                className='popup-form-submit-btn' 
                useSubmitBehavior={false} 
                onClick={saveAction} 
            />

            <Button 
                width={"auto"} 
                text='Close' 
                type='danger' 
                stylingMode='contained' 
                className='popup-form-submit-btn' 
                useSubmitBehavior={false} 
                onClick={closeButtonAction} 
            />
        </div>
    </Popup>
})