import React, { useState, useEffect, useRef, useCallback } from "react";
import PopupForm from "../../../components/popup-form/PopupFormV2";
import baseapi from "../../../api/baseapi";
import utils from "../../../utils/common";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { TextBox } from "devextreme-react/text-box";
import { loading, closeLoading } from "../../../utils/common";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import AsyncTextBox from "../../../components/async-text-box/AsyncTextBox";


export default function MechanicForm(props) {
	const tempForm = useRef(null);
	const formID = useRef(null);
	const formRead = useRef(false);
	const formRef = useRef(null);
	const [startValidation, setStartValidation] = useState(0);
	const [isEditing, setIsEditing] = useState(false);
	const [formValue, setFormValue] = useState({});
	const itemTypeLookupRef = useRef(null);
	const [displayError, setDisplayError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [messageType, setMessageType] = useState("");



	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}
	}, [props.formID]);



	function valueOnChange(e, receivedDataField = null) {
        try {
            if (e.value !== undefined) {
                const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
                const copied = { ...tempForm.current };

                // Convert to uppercase if the field is "Name"
                if (dataField === "Name") {
                    copied[dataField] = e.value.toUpperCase();
                } else {
                    copied[dataField] = e.value;
                }

                tempForm.current = copied;
                setFormValue(tempForm.current);
            }
        } catch (err) {
            console.log(err);
        }
    }

	function handleSubmit({ stay }) {

		loading("Saving Service Advisor...");
		const submittedForm = formValue;
		console.log(submittedForm);
		baseapi
			.httppost(
				utils.extendUrlVar("/api/Mechanic/save", {
					id: formID.current || "",
					del: false,
				}),
				submittedForm
			)
			.then((response) => {
				const { data } = response;
				utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Warning",
					action: data.action
				});

				if (data.status) {
					if (props.onRefresh) {
						props.onRefresh();
					}
				} 
			

			});
	}
	function onInitialized(e) {
		if (formID.current === "new") loading("Loading New Mechanic...");
		else loading("Loading Existing Mechanic...");

		baseapi.httpget("/api/Mechanic/Get?id=" + (formID.current || "new")).then((response) => {
			const { data } = response;
			console.log("data", data.model)

			if (formID.current === "new") {
				tempForm.current = data.model;
				setFormValue(tempForm.current);
			} else {
				setFormValue(data.model);
				tempForm.current = data.model;
			}

			closeLoading();
			formRead.current = true;
		});
	}



	const clearForm = () => {
		if (props.closePopup) {
			props.closePopup(null);
		}
		formRead.current = false;
		tempForm.current = {};
		setFormValue(tempForm.current);
	};



	return (
		<div>
			<PopupForm
				ref={formRef}
				onClosing={clearForm}
				fullScreen={false}
				width={"50%"}
				headerClassName={"maintainance-module-form-title-bg popup-form-title"}
				creditChecking={false}
				title={"Mechanic"}
				directSaveAndClose={props.directSaveAndClose}
				onShown={onInitialized}
				onSuccessfulSubmit={({ stay }) => { if (stay) onInitialized() }}
				onPasteForm={(e) => setFormValue(e.Parent)}
				formData={formValue}
				copyStorage={"MechanicCopiedData"}
				onValidating={setStartValidation}
				startValidation={startValidation}
				isEditing={isEditing}
				onSaving={handleSubmit}
				actionButtonEnabled={false}
				cancelDocumentCustomization={
					<span className='popup-form-header-cancel'>
						<CheckBox
							value={formValue["Active"]}
							elementAttr={{ dataField: "Active" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"25px"}
							height={"50px"}
							iconSize={"18px"}
							disabled={props.lockedForm === true}
							className='popup-header-cancel-input'
						/>

						<span className='popup-header-cancel-text' style={{ color: '#808080' }}>Active</span>

					</span>
				}>	<hr className='maintenance-popup-form-hr' />
				<div className='popup-form-main-content'>
					<div className='maintenance-module-border-bottom custom-form-grid-section2'>
						<div className=''>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'> Mechanic Code : </div>

								<div className='popup-group-form-input'>

									<AsyncTextBox
										value={formValue["Code"]}
										elementAttr={{ dataField: "Code" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={formID.current !== "new" || props.lockedForm === true}
										startValidation={startValidation !== 0}
										asyncURL={"api/Mechanic/CheckDuplicateCode"}
										requiredMessage={"Code is required!"}
										asyncMessage={"This Code is already taken!"}
									/>
								</div>
							</div>


						</div>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Name : </div>

						<div className='popup-group-form-input'>
							<TextBox
								value={formValue["Name"]}
								elementAttr={{ dataField: "Name" }}
								onValueChanged={(e) => {
									valueOnChange(e);
								}}
								alignment='left'
								width={"auto"}
								disabled={props.lockedForm === true}>
								<Validator>{startValidation !== 0 && <ValidatorRequired message={"Description is required!"} />}</Validator>
							</TextBox>
						</div>
					</div>
					<div className='maintenance-module-border-bottom custom-form-grid-section2'>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Contact No : </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["ContactNo"]}
									elementAttr={{ dataField: "ContactNo" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									disabled={props.lockedForm === true}>
								
								</TextBox>
							</div>
						</div>

					</div>
					<div className='maintenance-module-border-bottom custom-form-grid-section2' style={{ padding: 0 }}>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Commission (%) : </div>
							<div className='popup-group-form-input'>
								<NumberBox
									value={formValue["Commission"]}
									elementAttr={{ dataField: "Commission" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									format="#,##0.00" 
									disabled={props.lockedForm === true}>
									<Validator>{startValidation !== 0 && <ValidatorRequired message={"Commission is required!"} />}</Validator>
								</NumberBox>
							</div>
						</div>

					</div>
				</div>
			</PopupForm>
		</div>



	);
}
