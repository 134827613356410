import React from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';

class Brands extends React.Component {
    // render() {
    //     return <CommonTable title="Brands" url="/api/Brand" hasCode sidx={"Code"} sord={true}/>;
    // }

    render() {
        return <Container>
            <div id='normal-listing'>
                <SettingListing
                    listingTitle="Brands"
                    apiURL="/api/Brand"
                    listingURL="/api/Brand/list"
                    sortColumn={"Code"}
                    defaultSettingTemplate={true}
                    dateFilter={false}
                    storageName={"SettingBrandsListing"}
                />
            </div>
        </Container>
    }
}

export default Brands;