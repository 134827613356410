import React, { useRef, useCallback, useEffect, useState, useMemo } from 'react';
import { Popup } from 'devextreme-react/popup';
import { useNavigate } from 'react-router-dom';
const ResetPasswordSuccess = ({ onClose, visible }) => {

    const navigate = useNavigate();
    const popupWidth = useRef('45%'); // Default width
    const checkWindowSize = useCallback(() => {
        if (window.innerWidth < 870) {
            popupWidth.current = '100%';
        } else {
            popupWidth.current = '45%';
        }
    }, []);
    useEffect(() => {
        // Check window size initially
        checkWindowSize();

        // Add resize listener to check window size dynamically
        window.addEventListener('resize', checkWindowSize);

        return () => {
            // Clean up resize listener on component unmount
            window.removeEventListener('resize', checkWindowSize);
        };
    }, [checkWindowSize]);

    const handleOnclick = () =>{
        navigate('/login');
    }

    return (
        <>
            <Popup
                width={popupWidth.current}
                height={'50%'}
                onHiding={onClose}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={false}
                visible={visible}
            >

                <div className="auth-popup-content">
                <div className="icon-flex">
                    <div className="icon-container">
                        <i className="fas fa-thumbs-up"></i>
                    </div>
                    </div>
                    <p className="auth-popup-title">Account Successfully Created!</p>
                    <p className="auth-popup-subtitle">You can now login with your new password.</p>

                    <div className="auth-buttons">
                        <button className="auth-button" onClick={handleOnclick}>login</button>
                    </div>


                </div>
            </Popup>



        </>
    );
};

export default ResetPasswordSuccess;