import React, { useEffect, useState, useRef } from "react";
import baseapi from "../../../api/baseapi";
import { TextBox } from "devextreme-react/text-box";
import utils from "../../../utils/common";
import { workshopModel, customerOptions } from "../../../utils/lookupstore";
import { DateBox } from "devextreme-react/date-box";
import TextArea from "devextreme-react/text-area";
import { loading, closeLoading } from "../../../utils/common";
import CustomizedLookup from "../../../components/lookup/Lookup";
import { Column } from "devextreme-react/data-grid";
import AsyncTextBox from "../../../components/async-text-box/AsyncTextBox";
import { getFromLS } from "../../../utils/localstorage";
import PopupForm from "../../../components/popup-form/PopupFormV2";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import ErrorPopUpForm from "../../../components/popup-form/ErrorPopupForm";
import { NumberBox } from "devextreme-react/number-box";
import ModelForm from "../../settings/ModelForm";
import CustomerForm from "../../customer/form";
export default function RegistrationForm(props) {
	const formID = useRef(null);
	const modelLookupRef = useRef(null);
	const customerLookupRef = useRef(null);
	const formRef = useRef(null);

	const [startValidation, setStartValidation] = useState(0);
	const [isEditing, setIsEditing] = useState(false);
	const [modelList, setModelList] = useState([]);
	const [customerList, setCustomerList] = useState([]);
	const formRead = useRef(false);
	const tempForm = useRef(null);

	const [formValue, setFormValue] = useState({});

	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}
	}, [props.formID]);

	function valueOnChange(e, receivedDataField = null) {
		try {
			const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
			const copied = { ...tempForm.current };
			copied[dataField] = e.value;

			// Update the state immediately with the new value for the current field
			tempForm.current = copied;
			setFormValue(tempForm.current);

			if (dataField === "CustomerID" && formRead.current) {
				// Fetch customer details from the API
				baseapi.httpget(`api/Customer/Get`, { id: e.value }).then((response) => {
					const { data } = response;
					const copied = { ...tempForm.current };
					const customerFound = data.model; // Assuming `data.model` contains customer details
					const address1 = customerFound.Address1 ?? "";
					const address2 = customerFound.Address2 ?? "";
					const address3 = customerFound.Address3 ?? "";
					copied["CustomerAddress"] = address1 + "\n" + address2 + "\n" + address3;
					copied["CustomerName"] = customerFound.Name;
					copied["OwnerName"] = customerFound.Name;
					copied["Address1"] = address1;
					copied["Address2"] = address2;
					copied["Address3"] = address3;
					copied["ContactPerson"] = customerFound.Person;
					copied["Fax"] = customerFound.Fax;
					copied["Telephone"] = customerFound.Telephone;
					tempForm.current = copied;
					setFormValue(tempForm.current);
				}).catch(err => {
					console.error("Error fetching customer data:", err);
				});
			} else if (dataField === "CustomerAddress" && formRead.current) {
				const lines = e.value.split(/\r?\n|\\n/);
				// console.log(e.value);
				// const copied = {...tempForm.current};
				copied["CustomerAddress"] = utils.toUpperCase(e.value);
				copied["Address1"] = utils.toUpperCase(lines[0]);
				copied["Address2"] = utils.toUpperCase(lines[1]);
				copied["Address3"] = utils.toUpperCase(lines[2]);

				tempForm.current = copied;
				setFormValue(tempForm.current);
			}
		} catch (err) {
			console.log(err);
		}
	}

	function handleSubmit(stay = false) {
		const submittedForm = formValue;

		loading("Saving Registration...");
		baseapi.httppost(utils.extendUrlVar("/api/WKRegistration/save", { id: formID.current || "new", del: false }), submittedForm).then((response) => {
			const { data } = response;
			utils.popupFormSuccessfulSubmit(formRef, data);
			utils.popupFormSetErrorForm(formRef, {
				visible: true,
				message: data.message,
				type: data.status ? "Success" : "Warning",
				action: data.action
			});
		});
	}

	const clearForm = () => {
		props.closePopup(null);
		formRead.current = false;
		formID.current = null;
		tempForm.current = {};
		setFormValue(tempForm.current);
	};

	function onInitialized(e) {
		if (formID.current === "new") loading("Loading New Registration...");
		else loading("Loading Existing Registration...");

		baseapi.httpget("/api/WKRegistration/Get", { id: formID.current || "" }).then((response) => {
			const { data } = response;
			tempForm.current = data;
			setFormValue(tempForm.current);
			closeLoading();
			formRead.current = true;
		});
	}

	return (
		<div>
			<PopupForm
				ref={formRef}
				onClosing={clearForm}
				fullScreen={false}
				width={"95%"}
				height={"90%"}
				headerClassName={"workshop-module-form-title-bg popup-form-title"}
				creditChecking={false}
				title={"Registration"}
				onShown={onInitialized}
				directSaveAndClose={props.directSaveAndClose}
				onSuccessfulSubmit={({ stay }) => {
					if (stay) onInitialized();
				}}
				copyFields={{
					Parent: formValue,
				}}
				onPasteForm={(e) => {
					tempForm.current = e.Parent;
					setFormValue(e.Parent);
				}}
				copyStorage={"WorkshopRegistrationCopiedData"}
				formData={formValue}
				onValidating={setStartValidation}
				startValidation={startValidation}
				isEditing={isEditing}
				lookupValidateRef={[modelLookupRef, customerLookupRef]}
				onSaving={handleSubmit}>
				<div className='popup-from-group-container'>
					<div className='popup-form-group-name'>Information</div>
					<div className="custom-hr2"></div>



					<div className='popup-form-item-container3'>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Registration No : </div>

							<div className='popup-group-form-input'>
								<AsyncTextBox
									value={formValue["Code"]}
									elementAttr={{ dataField: "Code" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									disabled={formID.current !== "new"}
									startValidation={startValidation !== 0}
									asyncURL={"/api/WKRegistration/CheckDuplicateRegNo"}
									requiredMessage={"Registration No is required!"}
									asyncMessage={"This Registration No is already taken!"}
									asyncProperty={"regNo"}
									asyncArgs={{ regNo: formValue["Code"] || "", id: formID.current }}
								/>
							</div>
						</div>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Reg Date : </div>

							<div className='popup-group-form-input'>
								<DateBox
									value={formValue["RegDate"]}
									elementAttr={{ dataField: "RegDate" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									displayFormat='dd/MM/yyyy'
									dateSerializationFormat='yyyy-MM-dd'
									useMaskBehavior={true}>
									<Validator>{startValidation !== 0 && <ValidatorRequired message={"Reg Date is required!"} />}</Validator>
								</DateBox>
							</div>
						</div>



					</div>
					<div className='popup-form-item-container3'>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Chasis No: </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["ChasisNo"]}
									elementAttr={{ dataField: "ChasisNo" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									startValidation={startValidation !== 0}

								>
									<Validator>{startValidation !== 0 && <ValidatorRequired message={"Chasis No is required!"} />}</Validator>
								</TextBox>
							</div>
						</div>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Engine No: </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["EngineNo"]}
									elementAttr={{ dataField: "EngineNo" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									startValidation={startValidation !== 0}

								>
									<Validator>{startValidation !== 0 && <ValidatorRequired message={"Engine No is required!"} />}</Validator>
								</TextBox>
							</div>
						</div>
					</div>
					<div className='popup-form-item-container3'>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Car Model: </div>

							<div className='popup-group-form-input'>
								<CustomizedLookup
									ref={modelLookupRef}
									className={"workshop-listing-datagrid"}
									dataSource={modelList}
									displayExpr={"code"}
									valueExpr={"id"}
									value={formValue["ModelID"]}
									onSelectionChanged={(e) => {
										valueOnChange(e, "ModelID");
									}}
									allowAdd={true}
									popupForm={<ModelForm formID={"new"} closePopup={null} directSaveAndClose={true} onRefresh={() => {
										if (modelLookupRef.current && typeof modelLookupRef.current.resetPagingIndex === 'function') 
											modelLookupRef.current.resetPagingIndex();
										workshopModel.store.load().then((list) => {
											setModelList(list.data);
										});
									}} />}
									startValidation={startValidation !== 0}
									required={true}
									requiredMessage={"Model is required!"}
									onDataSourceChanged={setModelList}
									dataSourceURL={"api/Utilities/GetItemModels"}
									displayText={formValue["ModelCode"]}>
									<Column dataField='code'></Column>
									<Column dataField='name' caption={"Description"}></Column>
								</CustomizedLookup>
							</div>
						</div>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Car Color: </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["Colour"]}
									elementAttr={{ dataField: "Colour" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
								></TextBox>
							</div>
						</div>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Company Car : </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["CompanyCar"]}
									elementAttr={{ dataField: "CompanyCar" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
								></TextBox>
							</div>
						</div>

					</div>
					<br></br>
					<div className="custom-hr2"></div>
					<div className='popup-form-item-container3'>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Customer Code : </div>

							<div className='popup-group-form-input'>
								<CustomizedLookup
									ref={customerLookupRef}
									className={"workshop-listing-datagrid"}
									dataSource={customerList}
									displayExpr={"code"}
									valueExpr={"id"}
									value={formValue["CustomerID"]}
									onSelectionChanged={(e) => {
										valueOnChange(e, "CustomerID");
									}}

									allowAdd={true}
									popupForm={<CustomerForm title={"Customer"} formID={"new"} closePopup={null} directSaveAndClose={true} onRefresh={() => {
										customerLookupRef.current?.resetPagingIndex();
										customerOptions.store.load().then((list) => {
											setCustomerList(list.data);
										});
									}} />}
									dataSourceURL={"api/Utilities/GetCustomers"}
									onDataSourceChanged={setCustomerList}
									startValidation={startValidation !== 0}
									required={true}
									requiredMessage={"Customer is required!"}
									displayText={formValue["CustomerCode"]}>
									<Column dataField='code'></Column>
									<Column dataField='name' caption={"Customer Name"}></Column>
								</CustomizedLookup>
							</div>
						</div>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Owner Name: </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["OwnerName"]}
									elementAttr={{ dataField: "OwnerName" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
								></TextBox>
							</div>
						</div>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Contact: </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["ContactPerson"]}
									elementAttr={{ dataField: "ContactPerson" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
								></TextBox>
							</div>
						</div>
					</div>
					<div className='popup-form-item-container3'>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Mobile: </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["HPTelephone"]}
									elementAttr={{ dataField: "HPTelephone" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
								// disabled={formID.current !== "new"}
								></TextBox>
							</div>
						</div>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Telephone no. : </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["Telephone"]}
									elementAttr={{ dataField: "Telephone" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
								// disabled={formID.current !== "new"}
								></TextBox>
							</div>
						</div>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Fax: </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["Fax"]}
									elementAttr={{ dataField: "Fax" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
								// disabled={formID.current !== "new"}
								></TextBox>
							</div>
						</div>
					</div>

					<div className='popup-form-item-container5'>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label' style={{ paddingBottom: '50px' }}>Address: </div>

							<div className='popup-group-form-input'>
								<TextArea
									value={formValue["CustomerAddress"]}
									elementAttr={{ dataField: "CustomerAddress" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									height={110}
								// disabled={formID.current !== "new"}
								></TextArea>
							</div>
						</div>
					</div>
				</div>

				<div className='popup-from-group-container '>
					<div className='popup-form-group-name'>Next Service</div>
					<div className="custom-hr2"></div>
					<div className='popup-form-item-container6'>
						<div className='popup-form-item-container'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Mileage : </div>

								<div className='popup-group-form-input'>
									<div className='popup-form-item-container5'>
										<NumberBox
											value={formValue["NextMileage"]}
											elementAttr={{ dataField: "NextMileage" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											format="#,##0"
											width={"auto"}
											readOnly={true}
										>
										</NumberBox>
										<TextBox
											value={formValue["MileageType"]}
											elementAttr={{ dataField: "MileageType" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											defaultValue={"KM"}
											readOnly={true}
										></TextBox>
									</div>
								</div>

							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Next Service Date: </div>

								<div className='popup-group-form-input'>
									<DateBox
										value={formValue["NextDate"]}
										elementAttr={{ dataField: "NextDate" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										displayFormat='dd/MM/yyyy'
										dateSerializationFormat='yyyy-MM-dd'
										useMaskBehavior={true}
										readOnly={true}
									></DateBox>
								</div>
							</div>


						</div>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label' style={{ paddingBottom: "55px" }}>Remarks:</div>

							<div className='popup-group-form-input'>
								<TextArea
									value={formValue["Remarks"]}
									elementAttr={{ dataField: "Remarks" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									height={80}
									alignment='left'
									width={"auto"}></TextArea>
							</div>
						</div>
					</div>

				</div>
			</PopupForm>
		</div>
	);
}
