import React, { useEffect } from 'react';
import swal from 'sweetalert2';

const SESSION_TIMEOUT_KEY = 'sessionTimeout';

function SessionTimeout() {
  const handleLoginClick = () => {
    window.location.href = '/login';
        // Set session timeout flag in localStorage
    localStorage.setItem(SESSION_TIMEOUT_KEY, Date.now());
  };

  useEffect(() => {
    // Show SweetAlert2 modal on component mount
    swal.fire({
      title: 'Session Timeout',
      html: '<p>Your session has expired due to inactivity. Please log in again.</p>',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'Login',
      allowOutsideClick:false,
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `
      }
    }).then((result) => {
      if (result.isConfirmed) {
        handleLoginClick();
      }
    });



  }, []); // Empty dependency array ensures the effect runs only once on mount

  // Render nothing initially because SweetAlert2 will handle display
  return null;
}

export default SessionTimeout;
