import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import baseapi from "../../api/baseapi";
import DataGrid, { Button as ButtonColumn, Column, Editing, Pager, Paging, Scrolling, Toolbar, Popup, Form, Item, RequiredRule, AsyncRule, Label } from "devextreme-react/data-grid";
import { saveToLS, getFromLS } from "../../utils/localstorage";
import CustomStore from "devextreme/data/custom_store";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import utils from "../../utils/common";
import { DateBox } from "devextreme-react/date-box";
import PathIndicator from "../path-indicator/PathIndicator";
import ErrorPopUpForm from "../popup-form/ErrorPopupForm";

export default forwardRef(function SettingListing(props, ref) {
	const apiURL = props.apiURL !== undefined ? props.apiURL : "";
	const listURL = props.listingURL !== undefined ? props.listingURL : "";
	const gridRef = useRef(null);
	const searchBoxRef = useRef(null);
	const fromDate = useRef(null);
	const toDate = useRef(null);
	const query = useRef(null);
	const pageSize = useRef(25);
	const selectedPage = useRef(1);
	const sortOdr = useRef(true);
	const sortColumn = useRef(props.sortColumn !== undefined ? props.sortColumn : null);
	const popupMessageRef = useRef(null);
	const remoteOperations = {
		groupPaging: false,
		paging: true,
		filtering: true,
		sorting: true,
		grouping: false,
		summary: false
	};

	const [editingID, setEditingID] = useState(null);
	const [gridDataSource, setGridDataSource] = useState({
		store: new CustomStore({
			key: "id",
			load: () => {
				return baseapi
					.httpget(listURL, { q: query.current, fromDate: fromDate.current, toDate: toDate.current, rows: pageSize.current, page: selectedPage.current, sord: sortOdr.current, sidx: sortColumn.current })
					.then((response) => {
						return {
							data: response.data.items,
							totalCount: response.data.total
						};
					})
					.catch(() => {
						throw "Network error";
					});
			},
			insert: (values) => {
				return baseapi
					.httppost(apiURL + "/save?del=false&id='new'", values)
					.then((response) => {
						const { data } = response;
						utils.displayPopupMessage(popupMessageRef, {
							visible: true,
							message: data.message,
							type: data.status ? "Success" : "Danger",
						});
					})
					.catch((error) => {});
			},
			update: (key, values) => {
				return baseapi
					.httppost(apiURL + "/save?del=false&id=" + encodeURIComponent(key), values)
					.then((response) => {
						const { data } = response;
						utils.displayPopupMessage(popupMessageRef, {
							visible: true,
							message: data.message,
							type: data.status ? "Success" : "Danger",
						});
					})
					.catch(() => {});
			},
			remove: (key) => {
				return baseapi.httpdel(apiURL, { id: key }).then((response) => {
					const { data } = response;
					utils.displayPopupMessage(popupMessageRef, {
						visible: true,
						message: data.status ? `${props.listingTitle !== undefined ? props.listingTitle : " record"} ` + data.message : data.message,
						type: data.status ? "Success" : "Danger",
					});
				});
			}
			// errorHandler: (error) => {
			//     console.log("Error", error)
			// }
		})
	});

	const storageName = props.storageName !== undefined ? props.storageName : null;

	function handleAdd() {
		gridRef.current.instance.addRow();
	}

	const refresh = () => {
		setGridDataSource({
			store: new CustomStore({
				key: "id",
				load: () => {
					return baseapi
						.httpget(listURL, { q: query.current, rows: pageSize.current, page: selectedPage.current, sord: sortOdr.current, sidx: sortColumn.current })
						.then((response) => {
							return {
								data: response.data.items,
								totalCount: response.data.total
							};
						})
						.catch(() => {
							throw "Network error";
						});
				},
				insert: (values) => {
					return baseapi
						.httppost(apiURL + "/save?del=false&id='new'", values)
						.then((response) => {
							const { data } = response;
							utils.displayPopupMessage(popupMessageRef, {
								visible: true,
								message: data.message,
								type: data.status ? "Success" : "Danger",
							});
						})
						.catch((error) => {});
				},
				update: (key, values) => {
					return baseapi
						.httppost(apiURL + "/save?del=false&id=" + encodeURIComponent(key), values)
						.then((response) => {
							const { data } = response;
							utils.displayPopupMessage(popupMessageRef, {
								visible: true,
								message: data.message,
								type: data.status ? "Success" : "Danger",
							});
						})
						.catch(() => {});
				},
				remove: (key) => {
					return baseapi.httpdel(apiURL, { id: key }).then((response) => {
						const { data } = response;
						utils.displayPopupMessage(popupMessageRef, {
							visible: true,
							message: data.status ? `${props.listingTitle !== undefined ? props.listingTitle : " record"} ` + data.message : data.message,
							type: data.status ? "Success" : "Danger",
						});
					});
				}
			})
		});
	};

	const ButtonColumnWidth = utils.getColumnWidth("buttons", storageName) === null ? 80 : utils.getColumnWidth("buttons", storageName);
	const CodeColumnWidth = utils.getColumnWidth("Code", storageName) === null ? undefined : utils.getColumnWidth("Code", storageName);
	const NameColumnWidth = utils.getColumnWidth("Name", storageName) === null ? undefined : utils.getColumnWidth("Name", storageName);
	const ActiveColumnWidth = utils.getColumnWidth("Active", storageName) === null ? undefined : utils.getColumnWidth("Active", storageName);
	const ModifiedColumnWidth = utils.getColumnWidth("Modified", storageName) === null ? undefined : utils.getColumnWidth("Modified", storageName);

	const ButtonVisibleIndex = utils.getColumnVisibleIndex("buttons", storageName) === null ? 0 : utils.getColumnVisibleIndex("buttons", storageName);
	const CodeVisibleIndex = utils.getColumnVisibleIndex("Code", storageName) === null ? 1 : utils.getColumnVisibleIndex("Code", storageName);
	const NameVisibleIndex = utils.getColumnVisibleIndex("Name", storageName) === null ? 2 : utils.getColumnVisibleIndex("Name", storageName);
	const ActiveVisibleIndex = utils.getColumnVisibleIndex("Active", storageName) === null ? 3 : utils.getColumnVisibleIndex("Active", storageName);
	const ModifiedVisibleIndex = utils.getColumnVisibleIndex("Modified", storageName) === null ? 4 : utils.getColumnVisibleIndex("Modified", storageName);

	const defaultTemplateActive = (e) => {
		e.data.Cancelled = true;
	};

	const codeAsyncValidation = (e) => {
		return new Promise(function (resolve, reject) {
			// console.log(editingID);
			baseapi.httpget(`${apiURL}/CheckDuplicateCode`, { code: e.value || "", id: editingID }).then((response) => {
				const { data } = response;
				// console.log("Response", data)
				resolve(data);
			});
		});
	};

	const onRowPrepared = (e) => {
		if (e.rowType === "header" && storageName !== null) {
			const find = e.columns.find((c) => c.command === "transparent");
			//Ignore the fixed columns
			if (utils.isNullOrEmpty(find)) {
				const columns = e.columns;
				const previousRecord = getFromLS(storageName);

				if (previousRecord === undefined || previousRecord.length !== columns.length) {
					for (var i = 0; i < columns.length; i++) {
						columns[i]["columnWidth"] = null;
					}
				} else {
					for (var i = 0; i < columns.length; i++) {
						columns[i]["columnWidth"] = previousRecord[i]["columnWidth"];
					}
				}

				saveToLS(storageName, columns);
			}
		}
	};

	const onOptionChanged = (e) => {
		if (e.name === "columns" && e.fullName.includes("width") && storageName !== null) {
			//Sample format : columns[0].width
			const columns = getFromLS(storageName);
			const columnIndex = parseInt(e.fullName.substring(8, 9));
			const arrayIndex = columns.findIndex((x) => x.index === columnIndex);
			// Save the new width
			columns[arrayIndex]["columnWidth"] = e.value;
			saveToLS(storageName, columns);
		}
	};

	const onCellClick = (e) => {
		const column = e.column;
		const rowType = e.rowType;

		if (rowType === "header" && column.name !== "buttons") {
			if (column.name === sortColumn.current) {
				sortOdr.current = !sortOdr.current;
			} else {
				sortOdr.current = true;
			}

			sortColumn.current = column.name;
			refresh();
		}
	};

	const onRowUpdating = (e) => {
		e.newData = Object.assign(e.oldData, e.newData);
	};

	const onEditorPrepared = (e) => {
		const data = e.row.data;
		setEditingID(data.id);
	};

	const onPageSizeChange = (e) => {
		pageSize.current = e;
		refresh();
	};

	const onPageIndexChange = (e) => {
		selectedPage.current = e + 1;
		refresh();
	};

	const popupOnShown = (e) => {
		if (!utils.isNullOrEmpty(props.popupClassName)) {
			e.component.content().parentElement.parentElement.classList.add(props.popupClassName);
		}
	};

	useImperativeHandle(ref, () => ({
		getEditingID() {
			return editingID;
		}
	}));

	return (
		<div>
			<div className='listing-page-title-container'>
				<span className='datagrid-customized-title'>
					<PathIndicator />
				</span>
			</div>

			<div className='listing-datebox-container'>
				<TextBox
					width={"350px"}
					height={"36px"}
					ref={searchBoxRef}
					className='listing-page-search-box'
					placeholder='Search'
					value={query.current}
					valueChangeEvent='keyup'
					onValueChanged={(e) => {
						query.current = e.value;
						refresh();
						gridRef.current.instance.searchByText(e.value);
						// console.log("On change", e)
					}}
				/>

				{props.dateFilter !== false && (
					<div className='listing-date-from-container'>
						<span>From: </span>
						<DateBox
							format='dd/MM/yyyy'
							dateSerializationFormat='yyyy-MM-dd'
							type='date'
							showClearButton={true}
							onValueChanged={(e) => {
								fromDate.current = e.value;
								refresh();
								// console.log("On change", e)
							}}
						/>
					</div>
				)}

				{props.dateFilter !== false && (
					<div className='listing-date-to-container'>
						<span>To: </span>
						<DateBox
							format='dd/MM/yyyy'
							dateSerializationFormat='yyyy-MM-dd'
							type='date'
							showClearButton={true}
							onValueChanged={(e) => {
								toDate.current = e.value;
								refresh();
								// console.log("On change", e)
							}}
						/>
					</div>
				)}

				{props.addButton !== false && <Button height={"38px"} icon='plus' onClick={handleAdd} text='Add' className={`listing-page-add-btn ${getBackgroundColorClass()}`} />}
			</div>

			<DataGrid
				id={props.id}
				ref={gridRef}
				className={"listing-page-datagrid ".concat(props.className !== undefined ? props.className : "")}
				width={props.width !== undefined ? props.width : "100%"}
				height={props.height}
				loadPanel={{ enabled: true }}
				showBorders={false}
				dataSource={gridDataSource}
				allowColumnReordering={true}
				columnResizingMode='widget'
				rowAlternationEnabled={false}
				allowColumnResizing={true}
				columnAutoWidth={true}
				errorRowEnabled={false}
				sorting={{ mode: "multiple" }}
				remoteOperations={remoteOperations}
				hoverStateEnabled={true}
				onRowPrepared={onRowPrepared}
				onOptionChanged={onOptionChanged}
				onCellClick={onCellClick}
				onRowUpdating={onRowUpdating}
				onEditorPrepared={onEditorPrepared}
				onInitNewRow={props.onInitNewRow !== undefined ? props.onInitNewRow : props.defaultSettingTemplate === true && defaultTemplateActive}>
				<Toolbar visible={false} />

				{/*fixed edit & delete buttons at right side*/}
				{/* {props.children && props.children.map(child => {
					if (child && child.props && child.props.type === 'buttons') {
						return React.cloneElement(child, { fixed: true });
					}
					return child; 
				})} */}

				{props.defaultSettingTemplate !== true && props.children}

				{/* Default Template Columns */}
				{props.defaultSettingTemplate && (
					<Column type='buttons' width={ButtonColumnWidth} visibleIndex={ButtonVisibleIndex} fixed={true}>
						<ButtonColumn name='edit' />
						<ButtonColumn name='delete' />
					</Column>
				)}

				{props.defaultSettingTemplate && <Column dataField='Code' dataType='string' width={CodeColumnWidth} visibleIndex={CodeVisibleIndex} setCellValue={utils.GridCellValueToUpper} />}
				{props.defaultSettingTemplate && <Column dataField='Name' dataType='string' width={NameColumnWidth} visibleIndex={NameVisibleIndex} />}
				{props.defaultSettingTemplate && (
					<Column
						dataField='Cancelled'
						caption={"Active"}
						dataType='string'
						customizeText={(v) => {
							return v.value ? "Yes" : "No";
						}}
						width={ActiveColumnWidth}
						visibleIndex={ActiveVisibleIndex}
					/>
				)}

				{props.defaultSettingTemplate && <Column dataField='Modified' format='dd/MM/yyyy' dataType={"date"} width={ModifiedColumnWidth} visibleIndex={ModifiedVisibleIndex} />}

				{/* End of Default Template Column */}

				{props.customizedPopup !== true && (
					<Editing mode='popup' useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true}>
						<Popup title={props.listingTitle !== undefined ? props.listingTitle : "Title Not Given"} showTitle={true} width='auto' height='auto' dragEnabled={false} onShown={popupOnShown} />

						{/* Default Template Form */}
						{props.defaultSettingTemplate && (
							<Form>
								<Item dataField='Code'>
									<RequiredRule message={"Code is required!"} />
									<AsyncRule message={"This code is already taken!"} validationCallback={codeAsyncValidation} />
								</Item>
								<Item dataField='Name'>
									<RequiredRule message={"Name is required!"} />
								</Item>
								<Item dataField='Cancelled' editorType='dxCheckBox' defaultValue={true} enableThreeStateBehavior={false}>
									<Label text={"Active"} />
								</Item>
							</Form>
						)}
						{/* End of Default Template Form */}
					</Editing>
				)}

				<Scrolling columnRenderingMode='standard' showScrollbar={"onHover"} />
				<Pager allowedPageSizes={[10, 25, 50, 100]} showPageSizeSelector={true} visible={true} displayMode={"full"} showInfo={true} />
				<Paging defaultPageSize={25} onPageSizeChange={onPageSizeChange} onPageIndexChange={onPageIndexChange} />
			</DataGrid>

			<ErrorPopUpForm
				ref={popupMessageRef}
			/>
		</div>
	);

	// add-btn background color based on listingTitle
	function getBackgroundColorClass() {
		const className = props.className;
		let btn_backgroundColorClass = "";
		console.log(className);

		switch (className) {
			case "sales-listing-datagrid":
				btn_backgroundColorClass = "sales-listing-btn";
				break;

			case "stock-listing-datagrid":
				btn_backgroundColorClass = "stock-listing-btn";
				break;

			case "workshop-listing-datagrid":
				btn_backgroundColorClass = "workshop-listing-btn";
				break;

			default:
				// Default background color class for add btn
				btn_backgroundColorClass = "listing-page-add-btn";
				break;
		}

		return btn_backgroundColorClass;
	}
});
