import { Popup } from "devextreme-react/popup";
import { useEffect, useRef, useState } from "react";
import { TextBox } from "devextreme-react/text-box";
import { NumberBox } from "devextreme-react/number-box";
import { SelectBox } from "devextreme-react/select-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { Button } from "devextreme-react/button";
import { TagBox } from "devextreme-react";

import { Validator, RequiredRule as ValidatorRequired, CompareRule, EmailRule, PatternRule, StringLengthRule, RangeRule, AsyncRule } from "devextreme-react/validator";
import ValidationSummary from "devextreme-react/validation-summary";

export default function PopupForm(props) {
	const form = useRef(null);
	const isRead = useRef(false);
	const [formValue, setFormValue] = useState(null);
	const [validationGroupName, setValidationGroupName] = useState(props.ValidationGroupName);

	function valueOnChange(e, column) {
		// console.log("I am in value changed", e)
		try {
			const dataField = e.element.getAttribute("dataField");
			const copied = { ...form.current };
			copied[dataField] = e.value;
			setFormValue(copied);

			if (column.onValueChanged) {
				const returnValue = {
					form: copied,
					FieldChanged: dataField,
					FieldValue: e.value
				};
				column.onValueChanged(returnValue);
			}
		} catch (err) {
			console.log(err);
		}
	}

	const formValidation = (e) => {
		const res = e.validationGroup.validate();
		// console.log(res)
		if (res.status === "valid" && res.isValid) {
			props.onSubmit(formValue);
			props.closeButtonClick(false);
			// console.log("children form validity:", res.status);
		}

		res.status === "pending" &&
			res.complete.then((r) => {
				if (r.status === "valid") {
					props.onSubmit(formValue);
					props.closeButtonClick(false);
					// console.log("children form validity:", res.status);
				}
			});
	};

	useEffect(() => {
		// console.log("props out", props.form, "form", form.current, "isRead", isRead.current)

		if (JSON.stringify(form.current) !== JSON.stringify(props.form) && props.visible && !isRead.current && props.form !== null) {
			// console.log("props in", props.form)
			form.current = props.form;
			isRead.current = true;
			setFormValue(props.form);

			if (props.ValidationGroupName) setValidationGroupName(props.ValidationGroupName);
			else setValidationGroupName("Default Group");
		}
	});

	return (
		<Popup
			visible={props.visible === undefined ? false : props.visible}
			onHiding={(e) => {
				props.onHiding(false);
				isRead.current = false;
			}}
			dragEnabled={props.dragEnabled === undefined ? false : props.dragEnabled}
			hideOnOutsideClick={props.hideOnOutsideClick === undefined ? false : props.hideOnOutsideClick}
			showCloseButton={props.showCloseButton === undefined ? true : props.showCloseButton}
			showTitle={props.showTitle === undefined ? true : props.showTitle}
			title={props.title === undefined ? "Popup Form" : props.title}
			container='.dx-viewport'
			height={props.height === undefined ? "auto" : props.height}
			width={props.width === undefined ? 800 : props.width}>
			<div className={props.colSpan === undefined || props.colSpan === 1 ? "popup-form-item-container1" : props.colSpan === 2 ? "popup-form-item-container2" : props.colSpan === 3 ? "popup-form-item-container3" : "popup-form-item-container3"}>
				{props.colums.map((column) => {
					// console.log("column",props, "form", form)
					if (column.inputType === "CheckBox") {
						return (
							<div className='popup-form-item'>
								<div className='popup-form-label'>{column.caption}: </div>

								<div className='popup-form-input'>
									<CheckBox
										value={formValue !== null ? formValue[column.dataField] : ""}
										elementAttr={{ dataField: column.dataField }}
										onValueChanged={(e) => {
											valueOnChange(e, column);
										}}
										alignment='left'
										disabled={column.disabled}>
										<Validator validationGroup={validationGroupName}>
											{column.validation &&
												column.validation.map((validate) => {
													if (validate.validationType === "required") {
														return <ValidatorRequired message={validate.validationMessage} />;
													} else if (validate.validationType === "pattern") {
														return <PatternRule message={validate.validationMessage} pattern={validate.pattern} />;
													} else if (validate.validationType === "compare") {
														if (validate.compareDataField) {
															return (
																<CompareRule
																	message={validate.validationMessage}
																	comparisonTarget={
																		formValue === null
																			? () => {
																					return "";
																			  }
																			: () => {
																					return formValue[validate.compareDataField];
																			  }
																	}
																	comparisonType={validate.comparisonType}
																/>
															);
														} else {
															return <CompareRule message={validate.validationMessage} comparisonTarget={validate.comparisonTarget} comparisonType={validate.comparisonType} />;
														}
													} else if (validate.validationType === "range") {
														return <RangeRule message={validate.validationMessage} max={validate.max} min={validate.min} />;
													} else if (validate.validationType === "async") {
														return (
															<AsyncRule
																message={validate.validationMessage}
																validationCallback={(params) => {
																	return new Promise(function (resolve, reject) {
																		column.validation
																			.find((c) => c === validate)
																			.validationCallBack(params.value)
																			.then((valid) => {
																				if (valid) {
																					resolve(true);
																				} else {
																					resolve(false);
																				}
																			});
																	});
																}}
															/>
														);
													}
												})}
										</Validator>
									</CheckBox>
								</div>
							</div>
						);
					} else if (column.inputType === "NumberBox") {
						return (
							<div className='popup-form-item'>
								<div className='popup-form-label'>{column.caption}: </div>

								<div className='popup-form-input'>
									<NumberBox
										value={formValue !== null ? formValue[column.dataField] : ""}
										elementAttr={{ dataField: column.dataField }}
										onValueChanged={(e) => {
											valueOnChange(e, column);
										}}
										alignment='left'
										disabled={column.disabled}
										format='#,##0.00'>
										<Validator validationGroup={validationGroupName}>
											{column.validation &&
												column.validation.map((validate) => {
													if (validate.validationType === "required") {
														return <ValidatorRequired message={validate.validationMessage} />;
													} else if (validate.validationType === "pattern") {
														return <PatternRule message={validate.validationMessage} pattern={validate.pattern} />;
													} else if (validate.validationType === "compare") {
														if (validate.compareDataField) {
															return (
																<CompareRule
																	message={validate.validationMessage}
																	comparisonTarget={
																		formValue === null
																			? () => {
																					return "";
																			  }
																			: () => {
																					return formValue[validate.compareDataField];
																			  }
																	}
																	comparisonType={validate.comparisonType}
																/>
															);
														} else {
															return <CompareRule message={validate.validationMessage} comparisonTarget={validate.comparisonTarget} comparisonType={validate.comparisonType} />;
														}
													} else if (validate.validationType === "range") {
														return <RangeRule message={validate.validationMessage} max={validate.max} min={validate.min} />;
													} else if (validate.validationType === "async") {
														return (
															<AsyncRule
																message={validate.validationMessage}
																validationCallback={(params) => {
																	return new Promise(function (resolve, reject) {
																		column.validation
																			.find((c) => c === validate)
																			.validationCallBack(params.value)
																			.then((valid) => {
																				if (valid) {
																					resolve(true);
																				} else {
																					resolve(false);
																				}
																			});
																	});
																}}
															/>
														);
													}
												})}
										</Validator>
									</NumberBox>
								</div>
							</div>
						);
					} else if (column.inputType === "SelectBox") {
						return (
							<div className='popup-form-item'>
								<div className='popup-form-label'>{column.caption}: </div>

								<div className='popup-form-input'>
									<SelectBox
										dataSource={column.dataSource}
										valueExpr={column.valueExpr}
										displayExpr={column.displayExpr}
										elementAttr={{ dataField: column.dataField }}
										value={formValue !== null ? formValue[column.dataField] : ""}
										onValueChanged={(e) => {
											valueOnChange(e, column);
										}}
										disabled={column.disabled}>
										<Validator validationGroup={validationGroupName}>
											{column.validation &&
												column.validation.map((validate) => {
													if (validate.validationType === "required") {
														return <ValidatorRequired message={validate.validationMessage} />;
													} else if (validate.validationType === "pattern") {
														return <PatternRule message={validate.validationMessage} pattern={validate.pattern} />;
													} else if (validate.validationType === "compare") {
														if (validate.compareDataField) {
															return (
																<CompareRule
																	message={validate.validationMessage}
																	comparisonTarget={
																		formValue === null
																			? () => {
																					return "";
																			  }
																			: () => {
																					return formValue[validate.compareDataField];
																			  }
																	}
																	comparisonType={validate.comparisonType}
																/>
															);
														} else {
															return <CompareRule message={validate.validationMessage} comparisonTarget={validate.comparisonTarget} comparisonType={validate.comparisonType} />;
														}
													} else if (validate.validationType === "range") {
														return <RangeRule message={validate.validationMessage} max={validate.max} min={validate.min} />;
													} else if (validate.validationType === "async") {
														return (
															<AsyncRule
																message={validate.validationMessage}
																validationCallback={(params) => {
																	return new Promise(function (resolve, reject) {
																		column.validation
																			.find((c) => c === validate)
																			.validationCallBack(params.value)
																			.then((valid) => {
																				if (valid) {
																					resolve(true);
																				} else {
																					resolve(false);
																				}
																			});
																	});
																}}
															/>
														);
													}
												})}
										</Validator>
									</SelectBox>
								</div>
							</div>
						);
					} else if (column.inputType === "DateBox") {
						return (
							<div className='popup-form-item'>
								<div className='popup-form-label'>{column.caption}: </div>

								<div className='popup-form-input'>
									<DateBox
										value={formValue !== null ? formValue[column.dataField] : ""}
										elementAttr={{ dataField: column.dataField }}
										onValueChanged={(e) => {
											valueOnChange(e, column);
										}}
										dateSerializationFormat= 'yyyy-MM-dd'
										alignment='left'
										disabled={column.disabled}>
										<Validator validationGroup={validationGroupName}>
											{column.validation &&
												column.validation.map((validate) => {
													if (validate.validationType === "required") {
														return <ValidatorRequired message={validate.validationMessage} />;
													} else if (validate.validationType === "pattern") {
														return <PatternRule message={validate.validationMessage} pattern={validate.pattern} />;
													} else if (validate.validationType === "compare") {
														if (validate.compareDataField) {
															return (
																<CompareRule
																	message={validate.validationMessage}
																	comparisonTarget={
																		formValue === null
																			? () => {
																					return "";
																			  }
																			: () => {
																					return formValue[validate.compareDataField];
																			  }
																	}
																	comparisonType={validate.comparisonType}
																/>
															);
														} else {
															return <CompareRule message={validate.validationMessage} comparisonTarget={validate.comparisonTarget} comparisonType={validate.comparisonType} />;
														}
													} else if (validate.validationType === "range") {
														return <RangeRule message={validate.validationMessage} max={validate.max} min={validate.min} />;
													} else if (validate.validationType === "async") {
														return (
															<AsyncRule
																message={validate.validationMessage}
																validationCallback={(params) => {
																	return new Promise(function (resolve, reject) {
																		column.validation
																			.find((c) => c === validate)
																			.validationCallBack(params.value)
																			.then((valid) => {
																				if (valid) {
																					resolve(true);
																				} else {
																					resolve(false);
																				}
																			});
																	});
																}}
															/>
														);
													}
												})}
										</Validator>
									</DateBox>
								</div>
							</div>
						);
					} else if (column.inputType === "TagBox") {
						return (
							<div className='popup-form-item'>
								<div className='popup-form-label'>{column.caption}: </div>

								<div className='popup-form-input'>
									<TagBox
										dataSource={column.dataSource}
										valueExpr={column.valueExpr}
										displayExpr={column.displayExpr}
										elementAttr={{ dataField: column.dataField }}
										value={formValue !== null ? formValue[column.dataField] : ""}
										onValueChanged={(e) => {
											valueOnChange(e, column);
										}}
										disabled={column.disabled}
										hint={props.hint === undefined ? "Select option(s)" : props.hint}>
										<Validator validationGroup={validationGroupName}>
											{column.validation &&
												column.validation.map((validate) => {
													if (validate.validationType === "required") {
														return <ValidatorRequired message={validate.validationMessage} />;
													} else if (validate.validationType === "pattern") {
														return <PatternRule message={validate.validationMessage} pattern={validate.pattern} />;
													} else if (validate.validationType === "compare") {
														if (validate.compareDataField) {
															return (
																<CompareRule
																	message={validate.validationMessage}
																	comparisonTarget={
																		formValue === null
																			? () => {
																					return "";
																			  }
																			: () => {
																					return formValue[validate.compareDataField];
																			  }
																	}
																	comparisonType={validate.comparisonType}
																/>
															);
														} else {
															return <CompareRule message={validate.validationMessage} comparisonTarget={validate.comparisonTarget} comparisonType={validate.comparisonType} />;
														}
													} else if (validate.validationType === "range") {
														return <RangeRule message={validate.validationMessage} max={validate.max} min={validate.min} />;
													} else if (validate.validationType === "async") {
														return (
															<AsyncRule
																message={validate.validationMessage}
																validationCallback={(params) => {
																	return new Promise(function (resolve, reject) {
																		column.validation
																			.find((c) => c === validate)
																			.validationCallBack(params.value)
																			.then((valid) => {
																				if (valid) {
																					resolve(true);
																				} else {
																					resolve(false);
																				}
																			});
																	});
																}}
															/>
														);
													}
												})}
										</Validator>
									</TagBox>
								</div>
							</div>
						);
					} else {
						return (
							<div className='popup-form-item'>
								<div className='popup-form-label'>{column.caption}: </div>

								<div className='popup-form-input'>
									<TextBox
										value={formValue !== null ? formValue[column.dataField] : ""}
										elementAttr={{ dataField: column.dataField }}
										onValueChanged={(e) => {
											valueOnChange(e, column);
										}}
										alignment='left'
										disabled={column.disabled}>
										<Validator validationGroup={validationGroupName}>
											{column.validation &&
												column.validation.map((validate) => {
													if (validate.validationType === "required") {
														return <ValidatorRequired message={validate.validationMessage} />;
													} else if (validate.validationType === "pattern") {
														return <PatternRule message={validate.validationMessage} pattern={validate.pattern} />;
													} else if (validate.validationType === "compare") {
														if (validate.compareDataField) {
															return (
																<CompareRule
																	message={validate.validationMessage}
																	comparisonTarget={
																		formValue === null
																			? () => {
																					return "";
																			  }
																			: () => {
																					return formValue[validate.compareDataField];
																			  }
																	}
																	comparisonType={validate.comparisonType}
																/>
															);
														} else {
															return <CompareRule message={validate.validationMessage} comparisonTarget={validate.comparisonTarget} comparisonType={validate.comparisonType} />;
														}
													} else if (validate.validationType === "range") {
														return <RangeRule message={validate.validationMessage} max={validate.max} min={validate.min} />;
													} else if (validate.validationType === "async") {
														return (
															<AsyncRule
																message={validate.validationMessage}
																validationCallback={(params) => {
																	return new Promise(function (resolve, reject) {
																		column.validation
																			.find((c) => c === validate)
																			.validationCallBack(params.value)
																			.then((valid) => {
																				if (valid) {
																					resolve(true);
																				} else {
																					resolve(false);
																				}
																			});
																	});
																}}
															/>
														);
													}
												})}
										</Validator>
									</TextBox>
								</div>
							</div>
						);
					}
				})}
				<ValidationSummary className='validator-summary' validationGroup={validationGroupName} />
			</div>

			<div className='modal-footer'>
				<Button
					width={"auto"}
					text='Close'
					type='danger'
					stylingMode='contained'
					onClick={(e) => {
						props.closeButtonClick(false);
						isRead.current = false;
					}}
					className='popup-form-btn'
				/>
				<Button width={"auto"} text='Save changes' type='success' stylingMode='contained' useSubmitBehavior={false} validationGroup={validationGroupName} onClick={formValidation} />
			</div>
		</Popup>
	);
}
