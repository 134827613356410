import { useState, useEffect } from "react";
import routes from "../../app-routes";
import { useLocation } from "react-router-dom";

export default function PathIndicator() {

    const [pageTitle, setPageTitle] = useState(null);
    const location = useLocation();

    const checkParentExist = (route) => {
        if (route.path === "/users") {
            return "User >> Users Listing";
        } else if (route["parent"] !== null) {
            return checkParentExist(route["parent"]) + " >> " + route.text;
        } else {
            return route.text;
        }
    }

    useEffect(() => {
        if (pageTitle === null) {
            const found = routes.find(route => route.path === location.pathname);

            if (found !== undefined) {
                const pathText = checkParentExist(found);
                const arr = pathText.split(" >> ");

                setPageTitle(
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {arr.map((path, i) => {
                            const isLastSegment = i + 1 === arr.length;
                            let className = isLastSegment ? "last-segment" : "parent-segment";

                            // Set a single color for all paths
                            const color = "#E5B50A";

                            return (
                                <span
                                    key={i}
                                    className={className}
                                    style={{
                                        color: '#46CF64',
                                        fontWeight: isLastSegment ? "500" : "400",
                                        fontSize: isLastSegment ? "23px" : "20px",
                                    }}
                                >
                                    {path}
                                    {!isLastSegment && <span style={{ padding: "0px 10px" }}>{">>"}</span>}
                                </span>
                            );
                        })}
                    </div>
                );
            }
        }
    }, [pageTitle, location.pathname]);

    return <span>{pageTitle}</span>;
}