import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import baseapi from '../../api/baseapi';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { ScrollView } from "devextreme-react";
import utils from "../../utils/common";
import { handleUserProfileAndAuthorization } from '../../api/auth';
import { handleSubscriptionExpirationAlerts, handleSubscriptionExpirationSoonAlerts } from '../../utils/common';
const CompanySelectionForm = ({ visible, onClose, backHiding, confirmAlert }) => {
    const [selectedIndex, setSelectedIndex] = useState(null); // Track selected index, null means no selection
    const [invitedCompanies, setInvitedCompanies] = useState([]); // State for invited companies
    const [ownerCompanies, setOwnerCompanies] = useState([]); // State for owner companies
    const [loading, setLoading] = useState(true); // Loading state
    const [hasRun, setHasRun] = useState(false); // Track if the effect has already run
    const [selectedCompanyId, setSelectedCompanyId] = useState(null); // State for the selected company ID
    const { setUser } = useAuth();
    const navigate = useNavigate();
    // Combine owner and invited companies into a single list
    const companies = [...ownerCompanies, ...invitedCompanies];
    const [popupWidth, setPopupWidth] = useState("55%");
    const [scrollViewHeight, setScrollViewHeight] = useState(utils.GetScrollViewHeightV2());
    const companyRefs = useRef([]);
    useEffect(() => {
        // Update popup width based on screen size
        const updatePopupWidth = () => {
            if (window.innerWidth < 768) {
                setPopupWidth("95%");
            } else {
                setPopupWidth("55%");
            }
        };

        updatePopupWidth(); // Initial check
        window.addEventListener('resize', updatePopupWidth); // Listen for resize events

        return () => {
            window.removeEventListener('resize', updatePopupWidth); // Cleanup listener on unmount
        };
    }, []);
    useEffect(() => {
        if (visible) {
            const fetchCompanies = async () => {
                try {
                    setLoading(true);
                    const response = await baseapi.httpget('/api/UserCompany/list');
                    setOwnerCompanies(response.data.Owner || []);
                    setInvitedCompanies(response.data.Invited || []);
                } catch (error) {
                    console.error('Error fetching companies:', error.message);
                } finally {
                    setLoading(false);
                }
            };

            fetchCompanies();
        }
    }, [visible]);

    const confirmAndSubmit = async () => {
        const company = companies.find(company => company.Id === selectedCompanyId);
        if (company && company.isCurrentUsed) {
            // Skip confirmation if the company is currently used
            await handleSubmit(false);
        } else if (confirmAlert) {
            Swal.fire({
                title: 'Switch Company',
                html: `Are you sure you want to switch to <b>"${company?.OwnerCompanyName}"</b>?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await handleSubmit(true);
                }
            });
        } else {
            await handleSubmit(false);
        }
    };

    const handleSubmit = async (showAlert) => {
        try {
            if (selectedCompanyId) {
                const response = await baseapi.httppost('/api/UserCompany/switch', { id: selectedCompanyId });
                const { data } = response;
                if (data.status) {
                    const userProfile = await handleUserProfileAndAuthorization(data.access_token);
                    // Update user context with the fetched profile data
                    setUser({ ...userProfile, email: userProfile.LoginID });
                    onClose();

                    // Show the SweetAlert with a 2-second timer and refresh the page when the timer ends
                    if (showAlert) {
                        const companyName = companies.find(company => company.Id === selectedCompanyId)?.OwnerCompanyName;
                        let timerInterval;
                        Swal.fire({
                            title: "Switching Company",
                            html: `Now redirecting to the company "${companyName}" in <b>2</b> seconds.`,
                            timer: 2000,
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading();
                                const timer = Swal.getPopup().querySelector("b");
                                timerInterval = setInterval(() => {
                                    const seconds = Math.ceil(Swal.getTimerLeft() / 1000); // Convert milliseconds to seconds
                                    timer.textContent = `${seconds}`;
                                }, 1000); // Update every second
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                                window.location.reload();
                            }
                        });
                    }
                    if (userProfile.IsSubscriptionExpired === true || userProfile.IsFreeTrialExpired === true) {
                        handleSubscriptionExpirationAlerts(userProfile);
                    }
                } else {
                    notify(response.data.message, 'error', 2000);
                }
            }
        } catch (error) {
            console.error('Error occurred while submitting:', error.message);
        }
    };

    const handleBackToLogin = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    // Handle keydown events for navigation
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (companies.length > 0) {
                if (event.key === 'ArrowDown') {
                    setSelectedIndex((prevIndex) => {
                        const newIndex = Math.min(prevIndex + 1, companies.length - 1);
                        setSelectedCompanyId(companies[newIndex]?.Id); // Update selected company ID
                        return newIndex;
                    });
                } else if (event.key === 'ArrowUp') {
                    setSelectedIndex((prevIndex) => {
                        const newIndex = Math.max(prevIndex - 1, 0);
                        setSelectedCompanyId(companies[newIndex]?.Id); // Update selected company ID
                        return newIndex;
                    });
                } else if (event.key === 'Enter') {
                    confirmAndSubmit();  // Trigger the submit action
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [companies, confirmAndSubmit]);

    useEffect(() => {
        // Update the selected class for company details
        const companyDetails = document.querySelectorAll('.company-selection-details');
        companyDetails.forEach((detail, index) => {
            detail.classList.toggle('selected', index === selectedIndex);
        });
    }, [selectedIndex]);

    // Handle click events for selection
    const handleClick = (index) => {
        setSelectedIndex(index);
        setSelectedCompanyId(companies[index]?.Id); // Update selected company ID
    };

    // Automatically select the currently used company
    useEffect(() => {
        if (companies.length > 0 && !hasRun) {
            const defaultIndex = companies.findIndex(company => company && company.isCurrentUsed); // Check if company is not null
            if (defaultIndex !== -1) {
                setSelectedIndex(defaultIndex);
                setSelectedCompanyId(companies[defaultIndex]?.Id); // Set ID of the default company
            }
            setHasRun(true); // Mark the effect as run
        }
    }, [companies, hasRun]);

    // Navigate to /addcompany when "New" button is clicked
    const handleNewCompanyClick = () => {
        handleBackToLogin();
        navigate('/addcompany');
    };

    useEffect(() => {
        if (companies.length > 0 && selectedIndex !== null) {
            // Ensure the DOM is updated before scrolling
            setTimeout(() => {
                const selectedElement = companyRefs.current[selectedIndex];
                if (selectedElement) {
                    selectedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }, 100); // Small delay to ensure DOM update
        }
    }, [companies, selectedIndex]);


    return (
        <>
            <Popup
                visible={visible}
                onHiding={onClose}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={false}
                width={popupWidth}
                height='auto'
            >
                <div className="company-selection-popup-content">
                    <div className="company-selection-header">
                        <h2>Company</h2>
                        <button
                            className="company-selection-new-btn"
                            onClick={handleNewCompanyClick} // Add onClick handler
                        >
                            <span className="plus-sign">+</span> New
                        </button>
                    </div>
                    <hr className="custom-hr" />
                    {loading ? (
                        <div className="loading-indicator-container">
                            <div className="loader"></div>
                            <br />
                        </div>
                    ) : (
                        <>
                            <div className="company-selection-container">
                                {ownerCompanies.length > 0 && (
                                    <div className="company-selection-section">
                                        <div className="company-selection-company owner">
                                            <h3>Owner</h3>
                                            {ownerCompanies.map((company, index) => (
                                                <div
                                                    key={index}
                                                    className={`company-selection-details ${selectedIndex === index ? 'selected' : ''} ${company.isCurrentUsed ? 'active' : 'inactive'}`}
                                                    onClick={() => handleClick(index)}
                                                    ref={el => companyRefs.current[index] = el}
                                                >
                                                    <div className="company-selection-name">
                                                        <div>
                                                            {company.OwnerCompanyName}
                                                            <span className={`company-selection-status ${company.Active ? 'active' : 'inactive'}`}>
                                                                {company.Active ? 'Active' : 'Inactive'}
                                                            </span>
                                                        </div>
                                                        {company.isCurrentUsed && (
                                                            <div className="company-selection-status-container">
                                                                <span className="company-selection-current-using">Current Using</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="company-selection-info">
                                                        <div className="company-selection-label">Plan</div>
                                                        <div className="company-selection-value">: {company.PlanName}</div>
                                                    </div>
                                                    <div className="company-selection-info">
                                                        <div className="company-selection-label">Subscription until</div>
                                                        <div className="company-selection-value">: {new Date(company.SubscriptionEndDate).toLocaleDateString()}</div>
                                                    </div>
                                                    <div className="company-selection-info">
                                                        <div className="company-selection-label">No. of Users</div>
                                                        <div className="company-selection-value">: {company.LicensedUser}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {invitedCompanies.length > 0 && (
                                    <div className="company-selection-section">
                                        <div className="company-selection-company invited">
                                            <h3>Invited</h3>
                                            {invitedCompanies.map((company, index) => (
                                                <div
                                                    key={index}
                                                    className={`company-selection-details ${selectedIndex === (ownerCompanies.length ? index + ownerCompanies.length : index) ? 'selected' : ''} ${company.isCurrentUsed ? 'active' : 'inactive'}`}
                                                    onClick={() => handleClick(ownerCompanies.length ? index + ownerCompanies.length : index)}
                                                    ref={el => companyRefs.current[index + ownerCompanies.length] = el}
                                                >
                                                    <div className="company-selection-name">
                                                        <div>
                                                            {company.OwnerCompanyName}
                                                            <span className={`company-selection-status ${company.Active ? 'active' : 'inactive'}`}>
                                                                {company.Active ? 'Active' : 'Inactive'}
                                                            </span>
                                                        </div>
                                                        {company.isCurrentUsed && (
                                                            <div className="company-selection-status-container">
                                                                <span className="company-selection-current-using">Current Using</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="company-selection-invited-by">
                                                        Invited by: <span className="invited-email">{company.OwnerEmail}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>

                        </>
                    )}
                    <div className="company-selection-buttons">
                        {!backHiding && (
                            <button className="company-selection-cancel-btn" onClick={handleBackToLogin}>Back</button>
                        )}
                        <button className="company-selection-continue-btn" onClick={confirmAndSubmit}>Continue</button>
                    </div>
                </div>


            </Popup>
        </>
    );
};

export default CompanySelectionForm;
