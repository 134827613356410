import Header from "../header/HeaderV2";
import Drawer from 'devextreme-react/drawer';
import './SideNavigationMenuV2.scss';
import NavigationTreeList from "../../layouts/navigation/NavigationTreeList";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ScrollView from 'devextreme-react/scroll-view';
import { Template } from 'devextreme-react/core/template';
import { useScreenSize } from '../../utils/media-query';
import { useNavigation } from "../../contexts/navigation";

export default function SideNavigationMenu(props){
    const [drawerOpen, setDrawerOpen] = useState(true);
    const navigate = useNavigate();
    const screenSize = useScreenSize();
    const [isMobile, setIsMobile] = useState(null);
    const { navigationData: { currentPath } } = useNavigation();

    useEffect(() => {
        // console.log("screen size changes", screenSize)

        if(screenSize.isSmall || screenSize.isXSmall){
            setIsMobile(true);
        }
        else{
            setIsMobile(false);
        }

    }, [screenSize]);

    useEffect(() => {
        // console.log("screen size changes", screenSize)

        if(isMobile){
            setDrawerOpen(false);
        }
        else{
            setDrawerOpen(true);
        }

    }, [isMobile]);

    useEffect(() => {
        if(isMobile){
            setDrawerOpen(false);
        }
    }, [currentPath]);

    return (
        <div>
            <Header 
                onMenuButtonClick={setDrawerOpen}
                menuOpen={drawerOpen}
                isMobile={isMobile}
            />

            <div className="main-container">
                <div className={`side-nav-container side-nav-mobile-${isMobile} side-nav-mobile-open-${drawerOpen}`}>
                    <Drawer
                        position={'before'}
                        // openedStateMode={isMobile ? 'overlap' : 'shrink'}
                        openedStateMode={'shrink'}
                        closeOnOutsideClick={isMobile ? true : false}
                        revealMode={'expand'}
                        maxSize={270}
                        shading={false}
                        opened={drawerOpen}
                        height={"100vh"}
                        template={"menu"}
                        // component={() => {
                        //     return( 
                        //         // <ScrollView height={"100vh"}>
                        //             <NavigationTreeList 
                        //                 visible={drawerOpen}
                        //                 selectedItemChanged={(e) => {
                        //                     const element = e.element;
                        //                     const data = e.itemData;
                        //                     element.classList.add("Testing-Class");
                        //                     if(data.path !== undefined){
                        //                         navigate(data.path);
                        //                     }
                        //                 }}
                        //             />
                        //         // </ScrollView>
                        //     )
                        // }}
                    >
                        <Template name="menu">
                            <NavigationTreeList 
                                visible={drawerOpen}
                                selectedItemChanged={(e) => {
                                    const element = e.element;
                                    const data = e.itemData;
                                    element.classList.add("Testing-Class");
                                    if(data.path !== undefined){
                                        navigate(data.path);
                                    }
                                }}
                            />
                        </Template>
                        
                    </Drawer>
                </div>


                <div className={`main-content-container main-content-full-length-${!drawerOpen} main-content-mobile-${isMobile} 
                main-content-mobile-open-${(drawerOpen && isMobile)}`}>
                    <ScrollView height={"calc(100vh - 78px)"}>
                        <div>
                            {props.children}
                        </div>
                    </ScrollView>
                </div>

            </div>

        </div>
    );
}