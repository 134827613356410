import React, { useCallback, useEffect, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import baseapi from '../../api/baseapi';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { SelectBox } from 'devextreme-react/select-box';
const PlanSelectionForm = ({ visible, onClose }) => {
    const [selectedPlanID, setSelectedPlanID] = useState(null);
    const [companyPlan, setCompanyPlan] = useState(null);
    const [userCount, setUserCount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [initialPlanID, setInitialPlanID] = useState(null);
    const [initialUserCount, setInitialUserCount] = useState(0);
    const { setUser } = useAuth();
    const navigate = useNavigate();
    const [popupWidth, setPopupWidth] = useState("55%");
    const [planPrices, setPlanPrices] = useState({});
    const [selectedDuration, setSelectedDuration] = useState(null);
    const [dropDownList, setDropDownList] = useState({
       planDurationList: []
    });
 
    useEffect(() => {
        const updatePopupWidth = () => {
            if (window.innerWidth < 768) {
                setPopupWidth("95%");
            } else {
                setPopupWidth("55%");
            }
        };

        updatePopupWidth();
        window.addEventListener('resize', updatePopupWidth);

        return () => {
            window.removeEventListener('resize', updatePopupWidth);
        };
    }, []);

    useEffect(() => {
        if (planPrices && selectedPlanID && userCount !== null) {
            updateTotalPrice(userCount, selectedPlanID);
        }
    }, [planPrices, selectedPlanID, userCount]);

    useEffect(() => {
        if (visible) {
            const fetchCompanies = async () => {
                try {
                    const [planResponse, companyResponse, planDurationResponse] = await Promise.all([
                        baseapi.httpget('/api/register/GetGreenPlusPlans'),
                        baseapi.httpget('/api/UserCompany/CompanyPlanList'),
                        baseapi.httpget('/api/register/GetGreenPlusPlansDuration')
                    ]);
    
                    const planData = planResponse.data.reduce((acc, plan) => {
                        acc[plan.id] = plan.LicensedUserPrice;
                        return acc;
                    }, {});
                    setPlanPrices(planData);
    
                    const companyData = companyResponse.data;
                    //set id 
                    setCompanyPlan(companyData);
                    setSelectedPlanID(companyData?.PlanID);
                    setInitialPlanID(companyData?.PlanID);


                    //set lincensed user 
                    const licensedUser = companyData?.LicensedUser || 0;
                    setUserCount(licensedUser);
                    setInitialUserCount(licensedUser);
    
                    // Set plan duration list from the new API call
                    setDropDownList({
                        planDurationList: planDurationResponse.data
                    });

                    const durationValue = companyData?.Duration;
                    if (durationValue) {
                        // Convert to string
                        setSelectedDuration(String(durationValue));
                    }
    
                } catch (error) {
                    console.error('Error fetching companies:', error.message);
                }
            };
    
            fetchCompanies();
        }
    }, [visible]);
    const updateTotalPrice = (userCount, planID) => {
        const planUserCountPrice = planPrices[planID] || 0;
        let freeUsers = 0;

        if (planID === 'lw0I0Mvhves%3d') { // Plan 1
            freeUsers = 1;
        } else if (planID === '8uSJUCajIf8%3d') { // Plan 2
            freeUsers = 2;
        } else if (planID === 'b_xPwSzI5Y0%3d') { // Plan 3
            freeUsers = 3;
        }

        const chargeableUsers = Math.max(userCount - freeUsers, 0);
        setTotalPrice(chargeableUsers * planUserCountPrice);
    };


    const handleIncrement = () => {
        setUserCount(prevCount => {
            const newCount = prevCount + 1;
            updateTotalPrice(newCount, selectedPlanID);
            return newCount;
        });
    };

    const handleDecrement = () => {
        setUserCount(prevCount => {
            if (selectedPlanID === 'b_xPwSzI5Y0%3d' && prevCount <= 3) {
                return 3; // Ensure userCount stays at 3 or above for Plan 3
            }
            if (selectedPlanID === '8uSJUCajIf8%3d' && prevCount <= 2) {
                return 2; // Ensure userCount stays at 2 or above for Plan 2
            }
            if (selectedPlanID === 'lw0I0Mvhves%3d' && prevCount <= 1) {
                return 1; // Ensure userCount stays at 1 or above for Plan 1
            }
            const newCount = prevCount > companyPlan?.LicensedUser ? prevCount - 1 : prevCount;
            updateTotalPrice(newCount, selectedPlanID);
            return newCount;
        });
    };

    const handlePlanSelection = (planID) => {
        setSelectedPlanID(planID);

        if (planID === 'b_xPwSzI5Y0%3d' && userCount < 3) {
            setUserCount(3);
        } else if (planID === '8uSJUCajIf8%3d' && userCount < 2) {
            setUserCount(2);
        } else if (planID === 'lw0I0Mvhves%3d' && userCount < 1) {
            setUserCount(1);
        }

        updateTotalPrice(userCount, planID); // Update price based on new plan selection
    };

    const handleSubmit = () => {
        const planChanged = selectedPlanID !== initialPlanID;
        const userCountChanged = userCount !== initialUserCount;

        let message = 'You have made the following changes:<br>';
        if (planChanged) {
            const planName = getPlanName(selectedPlanID); // Function to get plan name based on ID
            message += `<b> - Change plan to ("${planName}")</b><br>`;
        }

        // Skip user count change message for Plan 2 with user count 2 or Plan 3 with user count 3
        if (userCountChanged) {
            const skipUserCountChangeMessage =
                (selectedPlanID === '8uSJUCajIf8%3d' && userCount === 2) ||
                (selectedPlanID === 'b_xPwSzI5Y0%3d' && userCount === 3);

            if (!skipUserCountChangeMessage) {
                message += `<b>- ${userCount > initialUserCount ? 'Increase' : 'Decrease'} user count to ${userCount}</b><br>`;
            }
        }

        if (planChanged || userCountChanged) {
            Swal.fire({
                title: 'Are you sure?',
                html: message, // Use html for HTML formatting
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, apply changes',
                cancelButtonText: 'Cancel'
            }).then(result => {
                if (result.isConfirmed) {
                    baseapi.httppost('/api/UserCompany/SwitchCompanyPlan', {
                        planID: selectedPlanID,
                        licensedUserCount: userCount
                    }).then(response => {
                        const { data } = response;
                        if (data.status) {
                            notify(data.message, 'success', 2000);
                            onClose();
                        } else {
                            notify(data.message, 'error', 2000);
                        }
                    }).catch(error => {
                        console.error('Error occurred while submitting:', error.message);
                    });
                }
            });
        } else {
            onClose();
        }
    };

    const handleOnClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    // Function to get plan name based on ID
    const getPlanName = (planID) => {
        switch (planID) {
            case 'lw0I0Mvhves%3d': return 'Basic';
            case '8uSJUCajIf8%3d': return 'Essential';
            case 'b_xPwSzI5Y0%3d': return 'Plus';
            default: return 'Unknown';
        }
    };

    // Determine which plans should be disabled
    const isPlanDisabled = (planID) => {
        if (!companyPlan) return false;
        const currentPlanID = companyPlan.PlanID;

        // Disable plans based on the selected plan
        if (currentPlanID === 'b_xPwSzI5Y0%3d') {
            return planID !== 'b_xPwSzI5Y0%3d'; // Plan 3
        }
        if (currentPlanID === '8uSJUCajIf8%3d') {
            return planID === 'lw0I0Mvhves%3d'; // Plan 2
        }
        return false;
    };

    return (
        <>
            <Popup
                visible={visible}
                onHiding={onClose}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                width={popupWidth}
                height={"auto"}
                title='Change your plan'
            >
                <div className="plan-selection-section">
                    <div className="plan-selection-section__plans">
                        <div
                            className={`plan-selection-section__plan ${selectedPlanID === 'lw0I0Mvhves%3d' ? 'plan-selection-section__plan-selected' : ''} ${isPlanDisabled('lw0I0Mvhves%3d') ? 'plan-disabled' : ''}`}
                            onClick={() => !isPlanDisabled('lw0I0Mvhves%3d') && handlePlanSelection('lw0I0Mvhves%3d')}
                        >
                            <div className="plan-selection-wrapper">
                                <div className="plan-selection-section__plan-name">Basic</div>
                                <div className="plan-selection-section__plan-description">Description description description</div>
                            </div>
                            <div className="plan-selection-section__plan-price">RM 50 / month</div>
                        </div>
                        <div
                            className={`plan-selection-section__plan ${selectedPlanID === '8uSJUCajIf8%3d' ? 'plan-selection-section__plan-selected' : ''} ${isPlanDisabled('8uSJUCajIf8%3d') ? 'plan-disabled' : ''}`}
                            onClick={() => !isPlanDisabled('8uSJUCajIf8%3d') && handlePlanSelection('8uSJUCajIf8%3d')}
                        >
                            <div className="plan-selection-wrapper">
                                <div className="plan-selection-section__plan-name">Essential</div>
                                <div className="plan-selection-section__plan-description">Description description description</div>
                            </div>
                            <div className="plan-selection-section__plan-price">RM 100 / month</div>
                        </div>
                        <div
                            className={`plan-selection-section__plan ${selectedPlanID === 'b_xPwSzI5Y0%3d' ? 'plan-selection-section__plan-selected' : ''} ${isPlanDisabled('b_xPwSzI5Y0%3d') ? 'plan-disabled' : ''}`}
                            onClick={() => !isPlanDisabled('b_xPwSzI5Y0%3d') && handlePlanSelection('b_xPwSzI5Y0%3d')}
                        >
                            <div className="plan-selection-wrapper">
                                <div className="plan-selection-section__plan-name">Plus</div>
                                <div className="plan-selection-section__plan-description">Description description description</div>
                            </div>
                            <div className="plan-selection-section__plan-price">RM 150 / month</div>
                        </div>
                    </div>
                    <hr className="hr-vertical" />

                    <div className="plan-selection-section__users">
                        <div className="plan-selection-section__users-title">Users</div>
                        <div className="plan-selection-section__user-counter">
                            <button
                                className={`plan-selection-section__user-decrement ${userCount <= companyPlan?.LicensedUser || (selectedPlanID === 'b_xPwSzI5Y0%3d' && userCount <= 3) || (selectedPlanID === '8uSJUCajIf8%3d' && userCount <= 2) ? 'disabled' : ''}`}
                                onClick={handleDecrement}
                                disabled={userCount <= companyPlan?.LicensedUser}
                            >
                                <i className="fa fa-minus"></i>
                            </button>
                            <div className="plan-selection-section__user-number">{userCount}</div>
                            <button
                                className="plan-selection-section__user-increment"
                                onClick={handleIncrement}
                            >
                                <i className="fa fa-plus"></i>
                            </button>
                        </div>
                        <div className="plan-selection-section__total-price">
                            <b><span className="price-highlight">RM {totalPrice}</span></b> per month total
                        </div>
                        <div className="company-selection-buttons">
                            <button className="company-selection-cancel-btn" onClick={handleOnClose}>Cancel</button>
                            <button className="company-selection-continue-btn" onClick={handleSubmit}>Continue</button>
                        </div>
                    </div>
                </div>

                <div className="plan-selection-section">
                    <div className="plan-selection-section__plans">


                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label htmlFor="duration-selectbox" style={{ marginRight: '40px' }}>Duration</label>
                            <SelectBox
                                id="duration-selectbox"
                                dataSource={dropDownList.planDurationList}
                                onValueChanged={e => {
                                    // formData.current.subscriptionEndDate = e.value;
                                }}
                                value={selectedDuration} 
                                alignment="left"
                                width="100%"
                                displayExpr={"Name"}
                                valueExpr={"Duration"}
                            />
                        </div>
                    </div>

                    <hr className="hr-vertical" />
                    <div className="plan-selection-section__users">
                    </div>
                </div>

            </Popup>
        </>
    );
};

export default PlanSelectionForm;

