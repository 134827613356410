import React, { useEffect, useState } from 'react';
import { Column, Lookup,} from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import Container from '../../../components/container';
import ARReceiptForm from './form';
import Listing from '../../../components/listing/newListing';

export default function ARReceiptListing() {

    const apiURL = `/api/ARReceipt`;
    const [dropDownList, setDropDownList] = useState({
        forexList: []
    });
    const [currentEditID, setCurrentEditID] = useState(null);

    useEffect(() => {
        Promise.allSettled([forexOptions.store.load()]).then((lists) => {
            setDropDownList({
                forexList: lists[0].value.data,
            });
        });
    }, []);

    function handleEdit(e){
        const id = e.row.key;
        setCurrentEditID(id);
    }
    
    return <Container>
        <div id='normal-listing'>
        <Listing
            className="ar-listing-datagrid"
            apiURL={apiURL}
            listingURL="/api/ARReceipt/list"
            sortColumn={"DocumentNo"}
            listingTitle={"AR Receipt"}
            storageName={"ARReceiptListingColumnWidth"}
            onAddClick={setCurrentEditID}
            handleEdit={handleEdit}
        >
            <Column dataField="DocumentNo"/>
            <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"}/>
            <Column dataField="CustomerCode" />
            <Column dataField="CustomerName" />
            <Column dataField="ForexID" caption={"Forex"}>
                <Lookup dataSource={dropDownList.forexList} valueExpr="id" displayExpr={"code"} allowClearing={true} searchEnabled={true} />
            </Column>
            <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="OutstandingAmount" caption={"U/A Amount"} format={{ type: "fixedPoint", precision: 2}} />
        </Listing>
        </div>
        
        <ARReceiptForm 
            formID={currentEditID}
            closePopup={setCurrentEditID}
            apiURL={apiURL}
            title={"A/R Receipt"}
        />
    </Container>;
}