import React from 'react';

class Container extends React.Component {
    render() {
        var { title, children } = this.props;
        return <div className='content-block dx-card responsive-paddings'>
            <h3>{title}</h3>
            {children}
        </div>;
    }
}

export default Container;

