import React, { useRef, useEffect, useState } from 'react';
import { uomOptions, categoryOptions, brandOptions, itemTypeOptions, materialOptions, stockGroupOptions, stockClassOptions } from '../../utils/lookupstore';
import { Column, Editing, Popup, Form, Item, Label, Button as ButtonColumn, Lookup } from 'devextreme-react/data-grid';
import { GroupItem } from 'devextreme-react/form';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';
import utils from '../../utils/common';
import Listing from '../../components/listing/newListing';
import StockForm from './form';
import baseapi from '../../api/baseapi';


export default function StockListing() {
    const apiURL = "/api/Stock";
    const [currentEditID, setCurrentEditID] = useState(null);
  
    function handleEdit(e) {
        const id = e.row.key;
        setCurrentEditID(id);
    }
    return <Container>
        <div id='normal-listing'>
            <Listing
                apiURL={apiURL}
                listingURL="/api/Stock/list"
                sortColumn={"Code"}
                className={"stock-listing-datagrid"}
                listingTitle={"Stock"}
                storageName={"StockListingColumnWidth"}
                onAddClick={setCurrentEditID}
                dateFilter={false}
                handleEdit={handleEdit}
            >
                <Column visible={true} dataField="Code" />
                <Column visible={true} dataField="Description" width={500} />
                <Column visible={true} dataField="StockGroupCode" caption="Stock Group" />
                <Column visible={true} dataField="UOMCode" caption="UOM" />
                <Column visible={true} dataField="PriceI" caption="Price I" format={{ type: 'fixedPoint', precision: 2 }} />
                <Column visible={true} dataField="PriceII" caption="Price II" format={{ type: 'fixedPoint', precision: 2 }} />
                <Column visible={true} dataField="PriceIII" caption="Price III" format={{ type: 'fixedPoint', precision: 2 }} />
            </Listing>
        </div>

        <StockForm
            formID={currentEditID}
            closePopup={setCurrentEditID}
            apiURL={apiURL}
        />
    </Container>;
}

