import propTypes from 'prop-types';
import React from 'react';
import { done, processing, notfound } from '../../utils/common';
import utils from '../../utils/common';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, {
    Pager,
    Paging, Editing, FilterRow, StateStoring
} from 'devextreme-react/data-grid';

const pageSizes = [10, 25, 50, 100];
const dataSourceOptions = {
    store: null,
};
const remoteOperations = {
    groupPaging: false,
    paging: true,
    filtering: true,
    sorting: true,
    grouping: false,
    summary: false
};
class Listing extends React.Component {
    constructor(props) {
        super();

        var { rows, page, sidx, sord, filter, title } = props;

        this.state = {
            collapsed: false,
            filter: {
                sidx,
                sord,

                ...filter
            },
            page,
            rows,
            data: {
                total: -1,
                items: []
            },
            loading: true
        };

        this.refresh = this.refresh.bind(this);
        this.onContentReady = this.onContentReady.bind(this);
        var { api, url, source, listingURL } = props;
        dataSourceOptions.store = new CustomStore({
            load: (options) => {
                let sort = this.table.instance.getVisibleColumns().find(col => col.sortIndex != null) || {dataField:null,sortOrder:'desc'};
                let { filter, page, rows } = this.state;
                return api.httpget(listingURL, { ...filter, page, rows, sidx:sort.dataField, sord:sort.sortOrder == 'asc' })
                    .then(response => {
                        var { data } = response;
                        var oriData = data;
                        if (!data) {
                            data = {
                                ...this.state.data,
                                total: 0,
                                items: []
                            };
                        }

                        var { items, page, rows } = data;
                        //this.props.initData(source, items.map((item, i) => ({ ...item, No: (page - 1) * rows + 1 + i })));
                        // this.setState({ data: { ...data, items: source }, loading: false }, () => {
                        //     if (this.props.onDataReady)
                        //         this.props.onDataReady(oriData);
                        // });
                        return {
                            data: response.data.items,
                            totalCount: response.data.total
                        };
                    }).catch(() => { throw 'Network error' });
            },
            key: 'id',
            byKey: (key,ext) => {
                return api.httpget(api.url + url + "?id=" + encodeURIComponent(ext.id)).then(response => response).catch(() => { throw 'Network error' });
            },
            insert: (values) => {
                return api.httppost(api.url + url + "/save?del=false&id='new'", values)
                    .then(response => {
                        const { data } = response;
                        if (data) {
                            utils.NotifyUser(`Record saved successfully`, "success", 150, 1500, "center");
                        } 
                    }).catch((error) => {});
            },
            update: (key, values) => {
                return api.httppost(api.url + url + "/save?del=false&id=" + encodeURIComponent(key), values)
                    .then(response => {
                        const { data } = response;
                        if (data) {
                            utils.NotifyUser(`Record saved successfully`, "success", 150, 1500, "center");
                        } 
                    }).catch(() => {});
            },
            remove: (key) => {
                return api.httpdel(api.url + url, { id: key })
                    .then(response => {
                        const { data } = response;
                        if (data) {
                            utils.NotifyUser(`Record deleted successfully`, "error", 150, 1500, "center");
                        } else {
                            utils.NotifyUser('Not able to delete record. The item might be already tied with some other record(s), please release its usage before continue'
                            , "warning", 150, 3000, "center");
                        }
                    });
            }
        });
    }

    componentDidMount() {
        //this.refresh();
    }

    onContentReady(e) {
        if (!this.state.collapsed) {
          e.component.expandRow(['EnviroCare']);
          this.setState({
            collapsed: true,
          });
        }
    }

    refresh(filter) {
        this.setState({ filter });
        const { delay } = this.props;
        if (this._timeout) clearTimeout(this._timeout);
        this._timeout = setTimeout(() => {
            this.table.instance.refresh();
        }, delay);
    }

    setRows(rows) {
        const { data } = this.state;
        this.setState({ data: { ...data, rows } }, this.refresh);
    }
    onEditorPreparing(e) {
        // console.log(e);debugger;
        // e.component.byKey('',{id:e.data.id});
      }

    // handlePropertyChange (e) {
    //     console.log('columns',e);
    //     console.log('state',e.component.state());
    //     if(e.name === "columns") {
    //         console.log('column dataField',e.component.columnOption(1));
    //             let propertyPath = e.fullName.split(".");
    //             let propertyShortName = propertyPath[ propertyPath.length - 1 ];

    //             if ( propertyShortName == "sortOrder" ) console.log ( "new sort order: ", e.value );

    //     }
    // }
    render() {
        var { children, onSaving, onInitNewRow, onCellClick } = this.props;
        const { page, rows } = this.state;

        if (!Array.isArray(children))
            children = [children];

        return <DataGrid
            className={''}
            ref={table => this.table = table}
            dataSource={dataSourceOptions}
            allowColumnReordering={true}
            rowAlternationEnabled={true}
            showBorders={true}
            onContentReady={this.onContentReady}
            columnHidingEnabled={true}
            remoteOperations={remoteOperations}
            repaintChangesOnly={false}
            twoWayBindingEnabled={true}
            cacheEnabled={false}
            onSaving={onSaving || null}
            onEditingStart={this.onEditorPreparing}
            onRowUpdating={(e) => {
                e.newData = { ...e.oldData, ...e.newData };
                delete e.newData.Modified;
                delete e.newData.id;
            }}
            onInitNewRow={onInitNewRow || null}
            allowColumnResizing={true}
            columnAutoWidth={true}
            onCellClick={onCellClick || null}
            // onOptionChanged={this.handlePropertyChange}
        >
            {/* <StateStoring enabled={true} /> */}
            {/* <Editing mode="popup" useIcons={true} allowUpdating={true} allowDeleting={true} /> */}
            <FilterRow visible={false} applyFilter="onClick" />
            {/* <GroupPanel visible={true} /> */}
            {/* <SearchPanel visible={true} highlightCaseSensitive={true} /> */}
            {/* <Grouping autoExpandAll={false} /> */}
            {children}

            <Pager visible={true} allowedPageSizes={pageSizes} showPageSizeSelector={true} />
            <Paging enabled={true} pageSize={rows} onPageSizeChange={(v) => { this.setState({ rows: v, page: 1 }) }} pageIndex={page - 1} onPageIndexChange={(v) => { this.setState({ page: v + 1 }) }} />
        </DataGrid>;
    }
}

Listing.propTypes = {
    url: propTypes.string.isRequired,
    api: propTypes.object,
    source: propTypes.string,
    page: propTypes.number,
    rows: propTypes.number,
    numbering: propTypes.bool,
    pagination: propTypes.bool,
    paginationLabel: propTypes.any,
    filter: propTypes.object,
    delay: propTypes.number,
    onDataReady: propTypes.func,
    rowConfig: propTypes.oneOfType([propTypes.object, propTypes.func]),
    rowProps: propTypes.oneOfType([propTypes.object, propTypes.func]),
    rowOptions: propTypes.bool
};

Listing.defaultProps = {
    source: 'freedata',
    page: 1,
    rows: 10,
    numbering: true,
    pagination: true,
    delay: 500,
    rowOptions: true
};

export default Listing;