import React from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';

class TaxType extends React.Component {
    // render() {
    //     return <CommonTable title="Tax Type" url="/api/TaxType" hasCode sidx={"Code"} sord={true}/>;
    // }

    render() {
        return <Container>
            <div id='normal-listing'>
                <SettingListing
                    listingTitle="Tax Type"
                    apiURL="/api/TaxType"
                    listingURL="/api/TaxType/list"
                    sortColumn={"Code"}
                    defaultSettingTemplate={true}
                    dateFilter={false}
                    storageName={"SettingTaxTypeListing"}
                />
            </div>
        </Container>
    }
}

export default TaxType;