import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import PopupForm from "../../../components/popup-form/PopupFormV2";
import DataGrid, { Column, Pager, Toolbar, Item as ToolbarItem, Selection, Editing, Lookup } from "devextreme-react/data-grid";
import baseapi from "../../../api/baseapi";
// import TabPanel from 'devextreme-react/tab-panel';
import utils, { loading, closeLoading } from "../../../utils/common";
import { uomOptions, salesmanOptions, creditTermOptions, stockOptions } from "../../../utils/lookupstore";
import CustomizedLookup from "../../../components/lookup/Lookup";
import TabPanel from "../../../components/tab-panel/TabPanel";
import { TextBox } from "devextreme-react/text-box";
import { saveToLS } from '../../../utils/localstorage';
export default forwardRef(function Transfer(props, ref) {
    const formRef = useRef(null);
    const fullDocDatagridRef = useRef(null);
    const [fullDataSource, setFullDataSource] = useState(null);
    const [filterValue, setFilterValue] = useState(null);
    const tabSelection = [{ link: "Full Document Transfer" }, { link: "Partial Item Transfer" }];
    const [transferData, setTransferData] = useState(null);
    const regNoLookupRef = useRef(null);
    const [registrationList, setRegistrationList] = useState([]);
    const [paramValue, setParamValue] = useState([]);
    const searchTextRef = useRef("");

    const handleSubmit = () => {
        // Check if there are any selected rows (i.e., paramValue is not empty)
        if (paramValue.length === 0) {
            utils.popupFormSetErrorForm(formRef, {
                visible: true,
                message: "Please select record to transfer!",
                type: "Danger"
            });
        } else {
            // Find the parent with the latest DocumentDate and CreatedDate
            const sortedData = fullDataSource
                .filter(parent => parent.Details && parent.Details.some(detail => paramValue.includes(detail)))
                .sort((a, b) => {
                    // Sort by DocumentDate first
                    if (new Date(b.DocumentDate) - new Date(a.DocumentDate) !== 0) {
                        return new Date(b.DocumentDate) - new Date(a.DocumentDate);
                    }
                    // If DocumentDate is the same, sort by CreatedDate
                    return new Date(b.CreatedDate) - new Date(a.CreatedDate);
                });

            // Get the most recent parent
            const selectedParent = sortedData[0];
            if (!selectedParent) {
                utils.popupFormSetErrorForm(formRef, {
                    visible: true,
                    message: "No parent found for the selected details!",
                    type: "Danger"
                });
                return;
            }

            const parent = { ...selectedParent };
            delete parent.Details;
            // Store the form value details in local storage
            const model = {
                Parent: parent,
                Children: paramValue,
            }

            saveToLS(props.localStorage, model);
            setTransferData(model);

        }
    };

    const datagridSearch = (e) => {
        if (fullDocDatagridRef.current) {
            fullDocDatagridRef.current.instance.searchByText(e.value);
        }

        // if (partialDocDatagridRef.current) {
        //     partialDocDatagridRef.current.instance.searchByText(e.value);
        // }
    };

    const onInitialized = (e = {}) => {
        const value = e.value;
        if (!utils.isNullOrEmpty(value)) {
            setFilterValue(value);

            const url = props.apiUrl;

            if (!utils.isNullOrEmpty(url)) {
                loading("Loading Transfer Data...");
                baseapi.httpget(url, { regID: value }).then((response) => {
                    const { data } = response;

                    if (!utils.isNullOrEmpty(data)) {
                        let combinedData = [];

                        // Assuming data is an array of objects with MainData and Details
                        combinedData = data.map(item => ({
                            ...item.MainData,
                            Details: item.Details
                        }));

                        setFullDataSource(combinedData);
                    }
                    closeLoading();
                });
            }
        }
    };

    useEffect(() => {
        if (!utils.isNullOrEmpty(transferData)) {
            if (props.transferData) {
                props.transferData(transferData);
            }
        }
    }, [transferData]);

    const handleSelectionChanged = (e) => {
        const selectedRows = e.selectedRowsData;
        let updatedParamValue = [];

        selectedRows.forEach(row => {
            if (row.Details) {
                updatedParamValue = [...updatedParamValue, ...row.Details];
            }
        });

        console.log(selectedRows, "selectedRows")
        setParamValue(updatedParamValue);

    };

    const clearForm = () => {
        setFullDataSource(null);
        searchTextRef.current = ""; // Clear search text ref
        if (fullDocDatagridRef.current) {
            fullDocDatagridRef.current.instance.searchByText(""); // Clear the search in DataGrid
        }
        setFilterValue('');
    };

    useImperativeHandle(ref, () => ({
        openForm() {
            utils.popupFormOpen(formRef);
        },
        onClosing() {
            clearForm();
            utils.popupFormClose(formRef);
        }
    }));
    return (
        <div>
            <PopupForm
                ref={formRef}
                onClosing={clearForm}
                fullScreen={false}
                width={"95%"}
                height={"90%"}
                headerClassName={"workshop-module-form-title-bg popup-form-title"}
                creditChecking={false}
                title={props.title}
                onSaving={handleSubmit}
                actionDisabled={true}
            >
                <div className="transform-container">
                    <div className="listing-datebox-container">
                        <CustomizedLookup
                            ref={regNoLookupRef}
                            className={"workshop-listing-datagrid"}
                            dataSource={registrationList}
                            displayExpr={"code"}
                            valueExpr={"id"}
                            value={filterValue}
                            onSelectionChanged={onInitialized}
                            dataSourceURL={props.filterUrl}
                            onDataSourceChanged={setRegistrationList}
                        >
                            <Column dataField='code'></Column>
                            <Column dataField='modelCode'></Column>
                        </CustomizedLookup>

                        <TextBox
                            className="margin-right-1"
                            mode="search"
                            placeholder="Search"
                            width="400px"
                            valueChangeEvent="keyup"
                            onValueChanged={datagridSearch}

                        />
                    </div>

                    <DataGrid
                        ref={fullDocDatagridRef}
                        dataSource={fullDataSource}
                        onSelectionChanged={handleSelectionChanged}
                    >
                        <Pager allowedPageSizes={[10, 25, 50, 100]} showPageSizeSelector={true} visible={true} displayMode={"full"} showInfo={true} />
                        <Selection
                            mode="multiple"
                            allowSelectAll={true}
                            showCheckBoxesMode={"always"}
                            selectAllMode={"page"}
                        />
                        <Editing
                            allowAdding={false}
                            allowDeleting={false}
                            allowUpdating={true}
                            mode="cell"
                        />

                        <Column dataField="DocumentNo" allowEditing={false} />
                        <Column dataField="DocumentDate" caption="Date" allowEditing={false} cellRender={utils.offsetLogDateDisplay} />
                        <Column dataField="ModelCode" />
                        <Column dataField="MechanicianName" />
                        <Column dataField="CustomerCode" allowEditing={false} />
                        <Column dataField="CompanyName" caption="Company Name" allowEditing={false} />
                        <Column dataField="Salesman" caption="Salesman" allowEditing={false} />
                    </DataGrid>
                </div>
            </PopupForm>
        </div>
    );

});