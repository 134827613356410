
import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import Container from '../container';
import PathIndicator from "../path-indicator/PathIndicator";
import baseapi from "../../api/baseapi";
import { loading, closeLoading } from "../../utils/common";
import { NumberBox } from "devextreme-react/number-box";
import { TextBox } from "devextreme-react/text-box";
import CustomizedLookup from "../lookup/Lookup";
import DataGrid, { LoadPanel, Column, Editing, Selection, Scrolling, Lookup, KeyboardNavigation, ColumnChooser, Summary, TotalItem, Toolbar, Pager, Paging, Item as ToolbarItem } from "devextreme-react/data-grid";
import PlanSelectionForm from '../popup-form/PlanSelectionForm';
import './SubscriptionForm.scss';
import utils from '../../utils/common';
import { AuthProvider, useAuth } from '../../contexts/auth';
export default function Subscription() {

    const { user } = useAuth();
    const [formValue, setFormValue] = useState({});
    const gridRef = useRef(null);
    const [gridDataSource, setGridDataSource] = useState({});
    const [planSelectionVisible, setPlanSelectionVisible] = useState(false);
    const currentToken = localStorage.getItem("Authorization").substring(7);
    useEffect(() => {
        // Fetch data from /api/UserCompany/CompanySubscriptionPlan
        const fetchCompanySubscriptionPlans = async () => {
            try {
                loading(); // Show loading spinner
                const response = await baseapi.httpget('/api/UserCompany/CompanySubscriptionPlan');
                const { CompanyPlans, CurrentPlan } = response.data; // Destructure response to get CompanyPlans and CurrentPlan

                // Set the fetched data into state
                setGridDataSource(CompanyPlans);  // Use CompanyPlans for DataGrid
                setFormValue(CurrentPlan); // Store CurrentPlan in formValue to display in the form

                closeLoading();
            } catch (error) {
                console.error('Error fetching company subscription plans:', error.message);
                closeLoading();
            }
        };

        fetchCompanySubscriptionPlans();
    }, []);

    const handlePlanSelectionClose = () => {
        setPlanSelectionVisible(false);
    };

    const handlePayment = async () => {
        try {
            loading(); // Show loading spinner

            // Construct the payload for the payment request
            const paymentPayload = {
                planId: formValue.PlanID,
                amount: formValue.UserAmount,
                // Add any other necessary fields
            };

            // Make POST request to payment gateway endpoint
            const response = await baseapi.httppost('/api/Payment/ProcessPayment', paymentPayload);

            // Handle the response from the payment gateway
            if (response.data.success) {
                // Handle success scenario (e.g., redirect, show confirmation, etc.)
                alert('Payment successful!');
            } else {
                // Handle error scenario (e.g., show error message)
                alert('Payment failed: ' + response.data.message);
            }
        } catch (error) {
            console.error('Error processing payment:', error.message);
            alert('An error occurred while processing payment.');
        } finally {
            closeLoading();
        }
    };
    const handleAdvancedPayment = async () => {
        try {
            loading(); // Show loading spinner

            // // Construct the payload for the advanced payment request
            // const advancedPaymentPayload = {
            //     planId: formValue.PlanID,
            //     amount: formValue.UserAmount,
            //     // Add any other necessary fields
            // };

            // // Make POST request to advanced payment gateway endpoint
            // const response = await baseapi.httppost('/api/AdvancedPayment/ProcessAdvancedPayment', advancedPaymentPayload);

            // // Handle the response from the advanced payment gateway
            // if (response.data.success) {
            //     alert('Advanced payment successful!');
            // } else {
            //     alert('Advanced payment failed: ' + response.data.message);
            // }

            // Construct the payload for the advanced payment request
            const advancedPaymentPayload = {
                planId: formValue.PlanID,
                amount: formValue.UserAmount,
                // Add any other necessary fields
            };

            // Make POST request to advanced payment gateway endpoint
            const response = await baseapi.httppost('/api/Payment/ProcessAdvancedPayment', advancedPaymentPayload);

            // Handle the response from the advanced payment gateway
            if (response.data.success) {
                alert('Advanced payment successful!');
            } else {
                alert('Advanced payment failed: ' + response.data.message);
            }


        } catch (error) {
            console.error('Error processing advanced payment:', error.message);
            alert('An error occurred while processing advanced payment.');
        } finally {
            closeLoading();
        }
    };
    const handlePrintPdf = (cell) => {
        baseapi.httpget(`${utils.ReportURL}/api/Report/getReportDetailsByReportID?paramReportName=Subscription Payment Receipt (Half)&accessToken=${currentToken}`).then((response) => {
            const { data } = response;
            data.map((items) => {
                confirmReportParameters(items.ID, items.PDFViewerAccessURL, cell.ID);
            });
        });

    };

    const confirmReportParameters = async (reportID, ViewerAccessURL, CompanyPlanID) => {
        var documentViewerLink = ViewerAccessURL;

        try {

            documentViewerLink += `&${'CompanyPlanID'}=${CompanyPlanID}`;

            // Open the document viewer link in a new tab
            window.open(documentViewerLink, "_blank");
        } catch (error) {
            console.error("Error fetching report parameters:", error);
        }
    };
    return <Container>

        <div>
            <div className="listing-container">

                <div className="sub-container">

                    <div style={{ paddingTop: '15px' }}>
                        <span className='datagrid-customized-title'>
                            <PathIndicator />
                        </span>
                    </div>

                    <div className='custom-form-grid-section-55-5-40'>
                        <div>
                            <div className='subscription-custom-form-grid-section-50-50'>
                                <div className='popup-form-item-container2'>

                                    <div class="subscription-plan-container">
                                        <div className='subscription-plan-title '>Current Plan </div>
                                        <div className='subscription-plan-description '>
                                            {formValue?.PlanName || 'N/A'}
                                        </div>
                                    </div>

                                    <div class="subscription-plan-container">
                                        <div className='subscription-plan-title '>Included </div>
                                        <div className='subscription-plan-description '>
                                            {formValue?.IncludedUser > 1 ? `${formValue.IncludedUser} User (s)` : `${formValue?.IncludedUser || 'N/A'} User`}
                                        </div>
                                    </div>

                                </div>


                                <div className='popup-form-item-container2'>
                                    <div class="subscription-plan-container">
                                        <div className='subscription-plan-title '>Additional </div>
                                        <div className='subscription-plan-description '>
                                            + {formValue?.AdditionalUser > 1 ? `${formValue.AdditionalUser} User (s)` : `${formValue?.AdditionalUser || 'N/A'} User`}
                                        </div>
                                    </div>

                                    <div class="subscription-plan-container">
                                        <div className='subscription-plan-description'>
                                            <span class="subscription-price-bold">RM {formValue.PerMonthFee}</span> <span class="price-month">/ month</span>
                                        </div>
                                        <div className='subscription-plan-title'>Billed every {formValue.Duration}</div>

                                    </div>
                                </div>

                            </div>

                            <div className='subscription-custom-form-grid-section-50-50'>

                                <div className='popup-form-item-container2'>
                                    <div class="subscription-plan-container">
                                        <div className='subscription-plan-title '>Subscription Start Date </div>
                                        <div className='subscription-plan-description '>
                                            {new Date(formValue.SubscriptionStartDate).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })}
                                        </div>
                                    </div>

                                    <div class="subscription-plan-container">
                                        <div className='subscription-plan-title '>Subscription End Date </div>
                                        <div className='subscription-plan-description '>
                                            {new Date(formValue.SubscriptionEndDate).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })}
                                        </div>
                                    </div>

                                </div>


                                <div className='popup-form-item-container2'>
                                    <div class="subscription-plan-container">
                                        <div className='subscription-plan-title '>Last Payment </div>
                                        <div className='subscription-plan-description '>
                                            {new Date(formValue.LastPaymentDate).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })}
                                        </div>
                                    </div>
                                    <div class="subscription-plan-container">
                                        <div className='subscription-plan-title payment'>Next Billing Date </div>
                                        <div className='subscription-plan-description '>
                                            {new Date(formValue.NextBillingDate).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })}
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className='subscription-custom-form-grid-section-70-30'>
                                <div class="subscription-plan-container">
                                    <div className='price-highlight'>RM {formValue.TotalPrice}</div>
                                    <div className='subscription-plan-title  '>
                                        [ RM {formValue.PerMonthFee} x {formValue.Duration} ] 
                                        {formValue.AdditionalUser > 0 && (
                                            <>
                                                &nbsp; + [ RM {formValue.LicensedUserPrice} x {formValue.AdditionalUser} User{formValue.AdditionalUser > 1 ? 's' : ''} x {formValue.Duration} ]
                                            </>
                                        )}


                                    </div>
                                </div>


                            </div>



                        </div>
                        <div></div>
                        <div className="subscription-change-plan">

                            <div className="manage-plan-section">
                                <div className="plan-header">
                                    <div className="plan-title">Manage Your Plan</div>
                                    <div className="plan-description">Desc Desc Desc Desc Desc</div>
                                </div>
                                <div></div>
                                <div className="plan-actions">
                                    <button className="payment-background-white-button">Upcoming Plan</button>
                                    <button className="upgrade-plan">Upgrade Plan</button>
                                </div>
                            </div>
                            <hr className="hr-subscription" />


                            <div className="plan-details-section">
                                <div className="plan-info">
                                    <div className="plan-name"> {formValue?.PlanName || 'N/A'}</div>
                                    <div className="new-plan">New Plan</div>
                                </div>
                                <div className="subscription-start">
                                    New Subscription start on <div className="subscription-plan-description">
                                    {new Date(formValue.SubscriptionEndDate).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            })}
                                    </div>
                                </div>

                                <div className="plan-actions">
                                    <button className="plan-link">View details</button>
                                    <button className="plan-button manage-plan"  onClick={() => setPlanSelectionVisible(true)}>Manage Plan</button>
                                </div>
                            </div>
                            <hr className="hr-subscription" />
                            {/* Pricing Section */}
                            <div className="subscription-pricing-section">
                                <div>
                                    <div className="price-highlight">RM {formValue.TotalPrice}  </div>


                                    <div className="subscription-plan-title">
                                        [ RM {formValue.PerMonthFee} x {formValue.Duration} ] + [ RM {formValue.LicensedUserPrice} x {formValue.AdditionalUser} User(s) x {formValue.Duration} ]
                                    </div>
                                </div>
                                <div className="plan-make-payment">
                                    <button className="payment-background-white-button">Make Payment</button>
                                </div>
                            </div>

                            {/* Additional Info */}
                            <div className="subscription-note-containter">
                                <div className="subscription-note">

                                    **Please note that your new subscription plan will commence once your current plan expires. To ensure uninterrupted access to the system, please make the payment before the current plan ends.

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="paymentHistory">
                        Payment History
                    </div>

                    <div style={{ width: '100%' }}>

                        <div className='inventory-module-border-bottom' style={{ padding: "0 0" }}>

                            <DataGrid
                                id="id"
                                ref={gridRef}
                                dataSource={gridDataSource}
                                showBorders={false}
                                width={"100%"}
                                sorting={{ mode: "multiple" }}
                                allowColumnReordering={true}
                                allowColumnResizing={true}
                                rowHeight={50}
                                hoverStateEnabled={true}
                                columnResizingMode='widget'
                                rowAlternationEnabled={false}
                                columnAutoWidth={false}
                                errorRowEnabled={false}
                                className={"listing-page-datagrid"}
                                loadPanel={{ enabled: true }}
                                height={300}
                                focusedRowEnabled={false}

                            >

                                <Column dataField='SubscriptionPlan' caption={"Subscription Plan"} editorOptions={{ readOnly: true }} width={200} />
                                <Column dataField='IncludedUser' caption={"Included"} editorOptions={{ readOnly: true }} width={150} />
                                <Column dataField='AdditionalUser' caption="Additional" editorOptions={{ disabled: true }} width={150} />
                                <Column dataField='TotalPrice' caption="Total Price (RM)" dataType='number' editorOptions={{ disabled: true }} width={200} />
                                <Column
                                    dataField='PaymentStatus'
                                    caption="Payment Status"
                                    cellRender={(cellData) => {
                                        const isSuccess = cellData.value; // Get the boolean value
                                        const displayText = isSuccess ? 'Successful' : 'Unsuccessful'; // Text to display
                                        const textColor = isSuccess ? 'green' : 'red'; // Set text color based on status

                                        return (
                                            <span style={{ color: textColor }}>
                                                {displayText}
                                            </span>
                                        );
                                    }}
                                    width={200}
                                />
                                <Column dataField='PaymentDate' caption="Payment Date" dataType='date' editorOptions={{ disabled: true }} format="dd/MM/yyyy" width={200} />
                                <Column
                                    dataField='Action'
                                    caption="Action"
                                    alignment="center"
                                    cellRender={(cellData) => {
                                        const isPaymentSuccessful = cellData.data.PaymentStatus; // Check PaymentStatus for the current row
                                        return isPaymentSuccessful ? (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                                <button
                                                    onClick={() => handlePrintPdf(cellData.data)} // Pass row data to handlePrintPdf
                                                    style={{ border: 'none', background: 'none', cursor: 'pointer' }}
                                                >
                                                    <img src="/images/downloadicon.svg" alt="Download" style={{ width: '24px', height: '20px' }} /> {/* Render the SVG */}
                                                </button>
                                            </div>
                                        ) : null; // Return null if payment is unsuccessful, hiding the button
                                    }}
                                    width={200}
                                />

                                <Scrolling columnRenderingMode='standard' showScrollbar={"onHover"} />

                            </DataGrid>


                        </div>
                    </div>
                </div>
            </div>
        </div>

        <PlanSelectionForm visible={planSelectionVisible} onClose={handlePlanSelectionClose} />
    </Container>



}