import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import PopupForm from "../../../components/popup-form/PopupFormV2";
import DataGrid, { Column, Pager, Toolbar, Item as ToolbarItem,  Selection, Editing, Lookup } from "devextreme-react/data-grid";
import baseapi from "../../../api/baseapi";
// import TabPanel from 'devextreme-react/tab-panel';
import utils, { loading, closeLoading } from "../../../utils/common";
import { uomOptions, salesmanOptions, creditTermOptions, stockOptions } from "../../../utils/lookupstore";
import CustomizedLookup from "../../../components/lookup/Lookup";
import TabPanel from "../../../components/tab-panel/TabPanel";
import { TextBox } from "devextreme-react/text-box";

export default forwardRef(function Transfer(props, ref){
	const formRef = useRef(null);
    const fullDocDatagridRef = useRef(null);
    const partialDocDatagridRef = useRef(null);
    const selectedIndex = useRef(0);
    const gridOldValue = useRef(null);
    const [fullDataSource, setFullDataSource] = useState(null);
    const [partialDataSource, setPartialDataSource] = useState(null);
    const [uomList, setUomList] = useState([]);
    const [salesmanList, setSalesmanList] = useState([]);
    const [creditTermList, setCreditTermList] = useState([]);
    const [stockList, setStockList] = useState([]);
    const [customerCodeList, setCustomerCodeList] = useState([]);
    const [filterValue, setFilterValue] = useState(null);
    const tabSelection = [{link : "Full Document Transfer"}, {link: "Partial Item Transfer"}];
    const [transferData, setTransferData] = useState(null);
    const [filterURL, setFilterURL] = useState(null);

    useEffect(() => {
        if (utils.isEmptyArray(uomList)) {
			uomOptions.getAll().then((list) => {
				setUomList(list.data);
			});
		}

        if (utils.isEmptyArray(salesmanList)) {
			salesmanOptions.getAll().then((list) => {
				setSalesmanList(list.data);
			});
		}

        if (utils.isEmptyArray(creditTermList)) {
			creditTermOptions.getAll().then((list) => {
				setCreditTermList(list.data);
			});
		}

        if (utils.isEmptyArray(stockList)) {
			stockOptions.getAll().then((list) => {
				setStockList(list.data);
			});
		}
    }, []);

    useEffect(() => {
        if(!utils.isNullOrEmpty(transferData)){
            if(props.transferData){
                props.transferData(transferData);
            }
        }
    }, [transferData]);

    useEffect(() => {
        if(partialDocDatagridRef.current){
            setGridOldValue(partialDataSource);
        }
    }, [partialDataSource]);

    useEffect(() => {
        if(!utils.isNullOrEmpty(props.apiPath) && !utils.isNullOrEmpty(props.title)){
            setFilterURL(props.apiPath[props.title]["FilterPath"]);
        }
    }, [props.apiPath, props.title]);

    useEffect(() => {
        if(!utils.isNullOrEmpty(filterURL)){
            //clear the look up data
            clearFilter();
        }
    }, [filterURL]);

    const clearFilter = () => {
        setCustomerCodeList([]);
    };

    const setDataSource = (value) => {
        if(selectedIndex.current === 0){
            setFullDataSource(value);
        }
        else{
            setPartialDataSource(value);
        }
    };

    const getDataSource = () =>{
        const dataSource = [];
        const rows = selectedIndex.current === 0 ? 
        fullDocDatagridRef.current.instance.getVisibleRows():
        partialDocDatagridRef.current.instance.getVisibleRows();

        for (var i = 0; i < rows.length; i++) {
            dataSource.push(rows[i]["data"]);
        }

        return dataSource;
    };

    const checkEmpty = () => {
        if(selectedIndex.current === 0 && utils.isNullOrEmpty(fullDataSource)){
            return true;
        }
        else if(utils.isNullOrEmpty(partialDataSource)){
            return true;
        }
        return false;
    };

    const onInitialized = (e = {}) => {
        const value = e.value;
        if(!utils.isNullOrEmpty(props.apiPath) && !utils.isNullOrEmpty(props.title) && !utils.isNullOrEmpty(value)){
            setFilterValue(value);
            const listingType = selectedIndex.current === 0 ? "FullDocumentListing" : "PartialDocumentListing";
            const url = props.apiPath[props.title][listingType];

            if(!utils.isNullOrEmpty(url)){
                loading("Loading Transfer Data...");

                baseapi.httpget(url, {q : value}).then((response) => {
                    const { data } = response;
                    if(!utils.isNullOrEmpty(data.items)){
                        setDataSource(data.items);
                    }
                    closeLoading();
                });
            }
        }
    };

    const clearForm = () => {
        setFullDataSource(null);
        setPartialDataSource(null);
        gridOldValue.current = null;
    };

    const getSelectedData = () => {
        const dataSource = getDataSource();
        if(selectedIndex.current === 0){
            if(fullDocDatagridRef.current){
                const selectedkeys = fullDocDatagridRef.current.instance.getSelectedRowKeys();
                return dataSource.filter(data => selectedkeys.includes(data.ID));
            }
        }
        else{
            if(partialDocDatagridRef.current){
                const selectedkeys = partialDocDatagridRef.current.instance.getSelectedRowKeys();
                return dataSource.filter(data => selectedkeys.includes(data.ID));
            }
        }

        return [];
    };

    const handleSubmit = () => {
        const formData = {};
        const selectedData = getSelectedData();
        const listingType = selectedIndex.current === 0 ? "FullDocumentTransfer" : "PartialDocumentTransfer";
        const url = props.apiPath[props.title][listingType];

        if(checkSubmit()){
            utils.popupFormSetErrorForm(formRef, {
                visible: true, 
                message: "Please select record to transfer!",
                type: "Danger"
            })
        }
        else{
            baseapi.httppost(url, selectedData)
            .then((response) => {
                const { data } = response;
                formData["Parent"] = data.model.Parent;
                formData["Children"] = data.model.Children;
                formData["status"] = data.status;
                formData["stockGroupDetails"] = data.stockGroupDetails;
                setTransferData(formData);
                utils.popupFormSuccessfulSubmit(formRef, data);
            });
        }
        
    };

    const deselectRow = (key) => {
        if(selectedIndex.current === 0){
            if(fullDocDatagridRef.current){
                fullDocDatagridRef.current.instance.deselectRows(key);
            }
        }
        else{
            if(partialDocDatagridRef.current){
                partialDocDatagridRef.current.instance.deselectRows(key);
            }
        }
    };

    const cellValue = (rowIndex, dataField, value) => {
        if(selectedIndex.current === 0){
            if(fullDocDatagridRef.current){
                fullDocDatagridRef.current.instance.cellValue(rowIndex, dataField, value);
            }
        }
        else{
            if(partialDocDatagridRef.current){
                partialDocDatagridRef.current.instance.cellValue(rowIndex, dataField, value);
            }
        }
    };

    const selectRow = (key, preserve = true) => {
        if(selectedIndex.current === 0){
            if(fullDocDatagridRef.current){
                fullDocDatagridRef.current.instance.selectRows(key, preserve);
            }
        }
        else{
            if(partialDocDatagridRef.current){
                partialDocDatagridRef.current.instance.selectRows(key, preserve);
            }
        }
    };

    const onPartialUpdating = (e) => {
        const oldValue = Object.assign({}, e.oldData);
		const newValue = e.newData;
        const maxQty  = oldValue["BalanceQuantity"] + oldValue["NewQty"];
        //New Qty validation
        if(newValue["NewQty"] > maxQty){
            newValue["NewQty"] = maxQty;
        }
        else if(newValue["NewQty"] < 0){
            newValue["NewQty"] = 0;
        }

        newValue["BalanceQuantity"] = oldValue["NewQty"] + oldValue["BalanceQuantity"] - newValue["NewQty"];

        //Auto select the row
        if(newValue["NewQty"] !== 0){
            selectRow([e.key]);
        }
        else{
            deselectRow([e.key]);
        }
        
    };

    const quantityValidation = (e) => {
        const data = e.data;
        const oldValue = gridOldValue.current;
        var oldRow = null

        if(!utils.isNullOrEmpty(oldValue) && Array.isArray(oldValue)){
            oldRow = oldValue.find(c => c.ID === data["ID"]);
        }

        if(!utils.isNullOrEmpty(oldRow) && e.value > oldRow["BalanceQuantity"] + oldRow["oldNewQty"]){
            return false;
        }
        else if(utils.isNullOrEmpty(oldValue) && e.value > data.BalanceQuantity){
            return false
        }
        else if(e.value < 0 || data["BalanceQuantity"] < 0){
            return false;
        }

        return true
    };

    const onSelectionChanged = ({selectedRowKeys, currentSelectedRowKeys, currentDeselectedRowKeys}) => {
        const dataSource = getDataSource();
        // gridOldValue.current = dataSource; //Assign the old value;
        // console.log("selected", selectedRowKeys, "current", currentSelectedRowKeys)
        //If there is any selection
        if(currentSelectedRowKeys.length > 0){
            for(var i = 0; i < currentSelectedRowKeys.length; i++){
                const index = dataSource.findIndex(element => element.ID === currentSelectedRowKeys[i]);
                if(index !== -1){
                    cellValue(index, "NewQty", dataSource[index]["BalanceQuantity"] + dataSource[index]["NewQty"]);
                    cellValue(index, "BalanceQuantity", 0);
                }
            }
        }
        
        //If there is any deselection
        if(currentDeselectedRowKeys.length > 0){
            for(var i = 0; i < currentDeselectedRowKeys.length; i++){
                const index = dataSource.findIndex(element => element.ID === currentDeselectedRowKeys[i]);
                if(index !== -1){
                    cellValue(index, "BalanceQuantity", dataSource[index]["BalanceQuantity"] + dataSource[index]["NewQty"]);
                    cellValue(index, "NewQty", 0);
                }
            }
        }
    };

    const itemRender = (index) => {
        const datagridSearch = (e) => {
            if(index === 0){
                if(fullDocDatagridRef.current){
                    fullDocDatagridRef.current.instance.searchByText(e.value);
                }
            }
            else{
                if(partialDocDatagridRef.current){
                    partialDocDatagridRef.current.instance.searchByText(e.value);
                }
            }
        }

        if(index === 0){
            return <div className="transform-container">
                <div className="listing-datebox-container">
                    <CustomizedLookup 
                        className={"workshop-listing-datagrid"}
                        dropdownClassName={"listing-page-searching-lookup"}
                        dataSource={customerCodeList}
                        dataSourceURL={filterURL}
                        onDataSourceChanged={setCustomerCodeList}
                        displayExpr={"code"}
                        valueExpr={"code"}
                        onSelectionChanged={onInitialized}
                        value={filterValue}
                    >
                        <Column dataField={"code"} caption={"Supplier Code"} />
                        <Column dataField={"name"} caption={"Supplier Name"} />
                    </CustomizedLookup>

                    <TextBox 
                        className="margin-right-1"
                        mode="search"
                        placeholder="Search"
                        width="400px"
                        valueChangeEvent="keyup"
                        onValueChanged={datagridSearch}
                    />
                </div>
                
                <DataGrid
                    ref={fullDocDatagridRef}
                    dataSource={fullDataSource}
                    keyExpr={"ID"}
                >
                    <Pager allowedPageSizes={[10, 25, 50, 100]} showPageSizeSelector={true} visible={true} displayMode={"full"} showInfo={true} />
                    <Selection
                        mode="multiple"
                        allowSelectAll={true}
                        showCheckBoxesMode={"always"}
                        selectAllMode={"page"}
                    />
                    <Editing 
                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={true}
                        mode='cell' 
                    />

                    {/* <Toolbar>
                        <ToolbarItem 
                            location="before" 
                            widget="dxTextBox"
                            options={{
                                mode: "search",
                                placeholder: 'Search',
                                width: "400px",
                                valueChangeEvent: 'keyup',
                                onValueChanged: datagridSearch,
                            }}
                        />

                        <ToolbarItem 
                            location="before" 
                            component={renderFilter}
                        >
                        </ToolbarItem>
                    </Toolbar> */}

                    <Column dataField="SupplierCode" allowEditing={false} />
                    <Column dataField="DocumentNo" allowEditing={false} />
                    <Column dataField="DocumentDate" caption={"Date"} allowEditing={false} cellRender={utils.offsetLogDateDisplay}/>
                    <Column dataField="SupplierName" caption={"Company Name"} allowEditing={false} />
                    <Column dataField="SalesmanID" caption={"Salesman"} allowEditing={false}>
                        <Lookup dataSource={salesmanList} valueExpr="id" displayExpr={"code"} allowClearing={true} searchEnabled={true} />
                    </Column>
                    <Column dataField="CreditTermsID" allowEditing={false} >
                        <Lookup dataSource={creditTermList} valueExpr="id" displayExpr={"code"} allowClearing={true} searchEnabled={true} />
                    </Column>
                    <Column dataField="MainForexAmount" caption={"Net Total"} allowEditing={false} format={{ type: "fixedPoint", precision: 2}}/>
                </DataGrid>
            </div>
        }
        else if(index === 1){
            return <div className="transform-container">
                <div className="listing-datebox-container">
                    <CustomizedLookup 
                        className={"workshop-listing-datagrid"}
                        dropdownClassName={"listing-page-searching-lookup"}
                        dataSource={customerCodeList}
                        dataSourceURL={filterURL}
                        onDataSourceChanged={setCustomerCodeList}
                        displayExpr={"code"}
                        valueExpr={"code"}
                        onSelectionChanged={onInitialized}
                        value={filterValue}
                    >
                        <Column dataField={"code"} caption={"Supplier Code"} />
                        <Column dataField={"name"} caption={"Supplier Name"} />
                    </CustomizedLookup>
                    
                    <TextBox 
                        className="margin-right-1"
                        mode="search"
                        placeholder="Search"
                        width="400px"
                        valueChangeEvent="keyup"
                        onValueChanged={datagridSearch}
                    />
                </div>

                <DataGrid
                    ref={partialDocDatagridRef}
                    dataSource={partialDataSource}
                    keyExpr={"ID"}
                    onRowUpdating={onPartialUpdating}
                    // onRowUpdated={() => {console.log("on row updated")}}
                    onSelectionChanged={onSelectionChanged}
                >
                    <Pager allowedPageSizes={[10, 25, 50, 100]} showPageSizeSelector={true} visible={true} displayMode={"full"} showInfo={true} />
                    <Selection
                        mode="multiple"
                        allowSelectAll={true}
                        showCheckBoxesMode={"always"}
                        selectAllMode={"page"}
                    />
                    
                    <Editing 
                        allowAdding={false}
                        allowDeleting={false}
                        allowUpdating={true}
                        mode='cell' 
                    />

                    {/* <Toolbar>
                        <ToolbarItem 
                            location="before" 
                            widget="dxTextBox"
                            options={{
                                mode: "search",
                                placeholder: 'Search',
                                width: "400px",
                                valueChangeEvent: 'keyup',
                                onValueChanged: datagridSearch,
                            }}
                        />
                    </Toolbar> */}

                    <Column dataField="NewQty" allowEditing={true}>
                        {/* <CustomRule message={"New Quantity cannot over Balance Quantity or be negative!"} validationCallback={quantityValidation} /> */}
                    </Column>
                    <Column dataField="UOMID" allowEditing={false} caption={"UOM"}>
                        <Lookup dataSource={uomList} valueExpr="id" displayExpr={"code"} allowClearing={true} searchEnabled={true} />
                    </Column>
                    <Column dataField="Quantity" caption="OrgQty" allowEditing={false} />
                    <Column dataField="BalanceQuantity" allowEditing={false} />
                    <Column dataField="SupplierCode" allowEditing={false} />
                    <Column dataField="SupplierName" allowEditing={false} />
                    <Column dataField="DocumentNo" allowEditing={false} />
                    <Column dataField="StockCode" caption="ItemCode" allowEditing={false} />
                    <Column dataField="Description" allowEditing={false} />
                    <Column dataField="Description2" allowEditing={false} />
                    <Column dataField="DeliveryDate" allowEditing={false} cellRender={utils.offsetLogDateDisplay}/>
                </DataGrid>
            </div>
        }
    };

    const onSelectedIndexChange = (index) => {
        //Update the selected index
        selectedIndex.current = index;
        clearFilter();
        setFilterValue(null);
    };

    const setGridOldValue = (value = null) => {
		if (value) {
			gridOldValue.current = value;
		} else {
			gridOldValue.current = getDataSource();
		}
	};

    const checkSubmit = () => {
       
        if(utils.isEmptyArray(getSelectedData()))
        {
            return true;
        }

        return false;
    };

    useImperativeHandle(ref, () => ({
        openForm(){
            utils.popupFormOpen(formRef);
        }
    }));

    return <div>
        <PopupForm
            ref={formRef}
            onClosing={clearForm}
            fullScreen={false}
            width={"95%"}
            height={"90%"}
            headerClassName={"sales-module-form-title-bg popup-form-title"}
            creditChecking={false}
            title={props.title}
            onSaving={handleSubmit}
            actionButtonEnabled={false}
            saveButtonOnly={true}
            saveButtonOnlyText={"Confirm"}
        >
            {/* <TabPanel
                dataSource={tabSelection}
                itemComponent={itemRender}
                focusStateEnabled={false}
                animationEnabled={true}
                onSelectedIndexChange={onSelectedIndexChange}
            /> */}
            <TabPanel 
                dataSource={tabSelection}
                itemRender={itemRender}
                onSelectedIndexChange={onSelectedIndexChange}
            />
        </PopupForm>
    </div>;
    
});