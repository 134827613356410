import axios from "axios";
import React from "react";
import dayjs from "dayjs";
import swal from "sweetalert2";
import FileDownload from "js-file-download";
import $ from "jquery";
import { getFromLS, saveToLS, removeFromLS } from "./localstorage";
import Notify from "devextreme/ui/notify";
import baseapi from "../api/baseapi";
import { useNavigate } from 'react-router-dom';
import notify from 'devextreme/ui/notify';
var _href = window.location.href.toLowerCase();

var _root = window.location.origin;
// eslint-disable-next-line
var subFolder = "/"; //eval('_subFolder');
if (_href.indexOf(subFolder) >= 0) _root = _root + subFolder;
let loadingAlertInstance = null;
let subscriptionAlertInstance = null;
let sessionAlertInstance = null;
const isLocalhost = _root.indexOf("localhost") >= 0;

const utils = {
	ReportURL: "https://greenplus-reportapi.onlinestar.com.my", // "https://greenplus-reportapi.onlinestar.com.my"  "https://localhost:7167"
	pathCombine(str1, str2) {
		if (str1 === undefined) str1 = "";
		if (str2 === undefined) str2 = "";
		if (!str1.endsWith("/")) str1 = str1 + "/";
		if (str2.startsWith("/")) str2 = str2.substring(1, str2.length);
		return str1 + str2;
	},
	getMvcRoot: function () {
		return _root;
	},
	fromRoot: function (url, d) {
		if (url) {
			var tempUrl = url;
			if (url.startsWith(utils.getMvcRoot())) tempUrl = url;
			if (url.startsWith("/")) tempUrl = utils.pathCombine(utils.getMvcRoot(), url);
			if (d) return utils.extendUrlVar(tempUrl, d);
			else return tempUrl;
		}
	},
	getUrlVars: function (url) {
		var vars = {};
		url = url || window.location.href;
		if (url.contains("?")) {
			var hashes = url.slice(url.indexOf("?") + 1).split("&");
			for (var i = 0; i < hashes.length; i++) {
				var indexOfTerm = hashes[i].indexOf("=");
				var hash0 = unescape(hashes[i].substring(0, indexOfTerm));
				var hash1 = hashes[i].substring(indexOfTerm + 1);
				if (hash1 && hash1.endsWith("#")) hash1 = hash1.substr(0, hash1.length - 1);
				if (hash1) {
					while (hash1.contains("+")) hash1 = hash1.replace("+", " ");
				}
				var val = unescape(hash1);
				vars[hash0] = val;
			}
		}
		return vars;
	},
	getUrlVar: function (name) {
		return utils.getUrlVars()[name];
	},
	addUrlVar: function (url, name, val) {
		if (url && name) {
			var parts = url.split("?");
			var searchs = utils.getUrlVars(url);
			searchs[name] = val;
			return parts[0] + "?" + $.param(searchs);
		}
		return url;
	},
	extendUrlVar: function (url, data) {
		var parts = url.split("?");
		return parts[0] + "?" + $.param({ ...utils.getUrlVars(url), ...data });
	},
	roundNum: function (num, dec) {
		var pow = Math.pow(10, dec || 0);
		return Math.round((num * (pow * 10)) / 10) / pow;
	},
	parseNum: function (txt) {
		if (typeof txt === "string") {
			var isNegative = txt.trim().startsWith("-");
			var a = txt.match(/\d*\.?\d/g);
			txt = a && a.length ? a.join("") : "";
		} else if (txt && txt.CurrencyValue) txt = txt.CurrencyValue;

		var result = parseFloat(txt);
		if (isNaN(parseFloat(result))) return 0;
		else if (isNegative) return -result;
		else return result;
	},
	roundAdj: function (n) {
		var rounding = n * 100;
		var sen = rounding % 10;
		if (sen < 3) n = parseInt(rounding / 10, 10) / 10;
		//else if (sen < 5) n = parseInt(rounding / 10) / 10 + 0.05;
		else if (sen < 8) n = parseInt(rounding / 10, 10) / 10 + 0.05;
		else n = parseInt(rounding / 10, 10) / 10 + 0.1;
		return n;
	},
	currency: function (n) {
		if (n === undefined || n === null) return "";
		else return utils.roundNum(utils.parseNum(n), 2).formatMoney(2, ".", ",");
	},
	thousand: function (n, dec) {
		if (n === undefined || n === null) return "";
		else return utils.roundNum(utils.parseNum(n), dec).thousand();
	},
	stockPrice: function (n) {
		if (n === null || isNaN(n) || !isFinite(n)) return "";
		else {
			var price = utils.parseNum(n);
			if (price >= 1 || price <= -1) return utils.currency(price);
			else return utils.roundNum(price, 3).formatMoney(3, ".", ",");
		}
	},
	dayLeft: function (ed) {
		var b = dayjs(),
			a = dayjs(ed),
			intervals = ["years", "months", "weeks", "days"],
			out = [];

		for (var i = 0; i < intervals.length; i++) {
			var diff = a.diff(b, intervals[i]);
			if (diff > 0) {
				b.add(diff, intervals[i]);
				out.push(diff + " " + intervals[i]);
			}
		}
		return out.join(", ");
	},
	ifNull: function (v, d) {
		if (v === 0) return 0;
		return v || d;
	},
	stockPriceUnit: function (n) {
		var unit;
		if (n >= 100) unit = 0.1;
		else if (n >= 10) unit = 0.02;
		else if (n >= 1) unit = 0.01;
		else unit = 0.005;
		return unit;
	},
	roundStockPrice: function (n, downward) {
		var unit = utils.stockPriceUnit(n);
		var steps = Math.floor(n / unit);
		var amount = steps * unit;
		if (downward) amount -= unit;
		else amount += unit;
		return amount;
	},
	priceChange: function (n, symbol) {
		if (n === undefined || n === null) return "";
		else {
			if (symbol === undefined) symbol = "";
			var price = utils.parseNum(n);
			if (price >= 100) return "+" + symbol + utils.thousand(utils.roundNum(price, 3));
			else if (price <= -100) return "-" + symbol + utils.thousand(utils.roundNum(Math.abs(price), 3));
			else if (price > 0) return "+" + symbol + utils.roundNum(price, 3);
			else if (price < 0) return "-" + symbol + utils.roundNum(Math.abs(price), 3);
			else return symbol + utils.stockPrice(price);
		}
	},
	thousandChange: function (n, symbol) {
		if (n === undefined || n === null) return "";
		else {
			if (symbol === undefined) symbol = "";
			var price = utils.parseNum(n);
			if (price > 0) return "+" + symbol + utils.thousand(price);
			else if (price < 0) return "-" + symbol + utils.thousand(Math.abs(price));
			else return symbol + utils.thousand(price);
		}
	},
	numberFormat: function (n) {
		if (n === undefined || n === null) return "";
		else {
			if (typeof n !== "number") n = utils.parseNum(n);

			if (n >= 1000000000) return utils.currency(n / 1000000000) + "b";
			else if (n >= 1000000) return utils.roundNum(n / 1000000, 2).toString() + "m";
			else if (n >= 1000) return utils.roundNum(n / 1000, 2).toString() + "k";
			else if (n > -1000) return n;
			else if (n > -1000000) return utils.roundNum(n / 1000, 2).toString() + "k";
			else if (n > -1000000000) return utils.roundNum(n / 1000000, 2).toString() + "m";
			else return utils.roundNum(n / 1000000000, 2).toString() + "b";
		}
	},
	percent: function (n) {
		if (n === undefined || n === null) return "";
		else return utils.roundNum(utils.parseNum(n), 2).toFixed(2);
	},
	comparePercent: function (n1, n2) {
		n1 = utils.parseNum(n1);
		n2 = utils.parseNum(n2);
		if (!n2 || !n1 || !(n1 - n2)) return 0;
		return utils.roundNum(((n2 - n1) * 100) / n1, 1);
	},
	financialFormat: function (n) {
		if (n === undefined || n === null) return "";
		else if (!utils.parseNum(n)) return "-";
		else if (n.indexOf("-") >= 0) return "({0})".format(n.replace("-", ""));
		else return n;
	},
	lastWorkingDay: function () {
		if (dayjs().day() === 6) return dayjs().subtract(1, "day");
		else if (dayjs().day() === 0) return dayjs().subtract(2, "day");
		return dayjs();
	},
	pad: function (num, size) {
		var s = num + "";
		while (s.length < size) s = "0" + s;
		return s;
	},
	S4: function () {
		return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
	},
	newGuid: function () {
		return utils.S4() + utils.S4() + "-" + utils.S4() + "-" + utils.S4() + "-" + utils.S4() + "-" + utils.S4() + utils.S4() + utils.S4();
	},
	validate: function (f) {
		if (f) {
			var isValid = true;
			for (var i in f) {
				if (f[i] && f[i].validate && !f[i].validate()) {
					isValid = isValid && false;
				}
			}
		}
		return isValid;
	},
	redirect: function (url) {
		if (url) window.location = url;
	},
	compareObject: function (obj1, x) {
		var MAX_DEPTH = 10;
		var testEq = function (obj1, x, depth) {
			if (depth < MAX_DEPTH) {
				for (var p in obj1) {
					if (typeof obj1[p] !== typeof x[p]) {
						return false;
					}
					if ((obj1[p] === null) !== (x[p] === null)) {
						return false;
					}
					switch (typeof obj1[p]) {
						case "undefined":
							if (typeof x[p] !== "undefined") {
								return false;
							}
							break;
						case "object":
							if (obj1[p] !== null && x[p] !== null && (obj1[p].constructor.toString() !== x[p].constructor.toString() || !testEq(obj1[p], x[p], depth + 1))) {
								return false;
							}
							break;
						case "function":
							if (p !== "equals" && obj1[p].toString() !== x[p].toString()) {
								return false;
							}
							break;
						default:
							if (obj1[p] !== x[p]) {
								return false;
							}
					}
				}
			}
			return true;
		};
		// this is a little ugly, but the algorithm above fails the following: testEq([[1,2],[]], [[1,2],[1,3]], 0)
		return testEq(obj1, x, 0) && testEq(x, obj1, 0);
	},
	isNullOrEmpty: function (v) {
		return v === undefined || v === null || v === "";
	},
	absoluteUrl: function (url) {
		if (url) {
			if (url.indexOf(":") < 0) {
				url = "http://" + url;
			}
		}

		return url;
	},
	isUrlUnique(arr, url) {
		return (
			arr.filter((a) => {
				if (a.indexOf(url) >= 0 && a.length !== url.length) {
					return true;
				} else {
					return false;
				}
			}).length === 0
		);
	},
	processRegex(message, re) {
		var text = message;
		var urls = [];
		var match;
		do {
			if (match) {
				urls.push(match);
				// var newReg = new RegExp(match, 'g');
				// text = text.replace(newReg, '');
				while (text.indexOf(match) >= 0) {
					text = text.replace(match, "");
				}
			}

			var matches = re.exec(text);
			if (matches && matches.length) {
				match = matches[0];
			} else {
				match = null;
			}
		} while (match && text.indexOf(match) >= 0);

		return urls;
	},
	changeUrlToLink(message) {
		var tests = [];
		// eslint-disable-next-line
		utils.processRegex(message, /((http|ftp|https):\/\/[\w\-_]+([\.\:][\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?)/).forEach((m) => {
			tests.push(m);
		});
		// eslint-disable-next-line
		utils.processRegex(message, /((http|ftp|https):\/\/(localhost)([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?)/).forEach((m) => {
			tests.push(m);
		});
		// eslint-disable-next-line
		utils.processRegex(message, /([\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?)/).forEach((m) => {
			tests.push(m);
		});

		var urls = [];
		tests.forEach((t) => {
			if (utils.isUrlUnique(tests, t) && urls.indexOf(t) < 0) {
				if (!/^-?\d+\.?\d*%?$/.exec(t)) urls.push(t);
			}
		});

		urls.forEach((url) => {
			var re = url.replace("?", "\\?");
			re = new RegExp(re, "g");
			var href = url;
			if (href.indexOf("http") < 0) href = "http://" + href;
			message = message.replace(re, "<a target='_blank' href='{0}'>{1}</a>".format([href, url]));
		});

		return message;
	},
	dateFormat() {
		return "DD MMM YYYY";
	},
	dateTimeFormat() {
		return "DD MMM YYYY HH:mm";
	},
	dateboxFormat(x) {
		var date = new Date(x);
		if (isNaN(date.getTime())) {
			throw new Error("Invalid date");
		}

		var minDate = new Date(1900, 0, 1);

		if (date.getTime() < minDate.getTime()) {
			date = minDate;
		}

		// Format the date components with leading zeros if necessary
		var day = String(date.getDate()).padStart(2, "0");
		var month = String(date.getMonth() + 1).padStart(2, "0");
		var year = date.getFullYear();

		// Return the formatted date
		return `${year}-${month}-${day}`;
	},
	getSelectBoxOption(source) {
		return {
			dataSource: source,
			deferRendering: false,
			displayExpr: "name",
			valueExpr: "id",
			searchEnabled: true,
			searchExpr: "name"
		};
	},
	getSelectBoxOptionByCode(source) {
		return {
			dataSource: source,
			deferRendering: false,
			displayExpr: "name",
			valueExpr: "code",
			searchEnabled: true,
			searchExpr: "name"
		};
	},
	getSelectBoxOptionWithDisplay(source) {
		return {
			dataSource: source,
			deferRendering: false,
			displayExpr: function (item) {
				return item.code + " - " + item.name;
			},
			valueExpr: "id",
			searchEnabled: true,
			searchExpr: "name"
		};
	},
	moneyFormatter(x) {
		var stringNum = String(x);
		var index = stringNum.indexOf(".");
		var output;

		if (index == -1) {
			output = x;
		} else {
			if (stringNum.length >= index + 3) {
				output = stringNum.substring(0, index) + "." + stringNum.substring(index + 1, index + 3);
			} else if (stringNum.length == index + 2) {
				output = stringNum.substring(0, index) + "." + stringNum.substring(index + 1) + "0";
			} else {
				output = stringNum.substring(0, index) + "." + stringNum.substring(index + 1);
			}
		}

		return output;
	},
	dateFormatter(date) {
		return dayjs(date).format("DD/MM/YYYY");
	},
	offsetLogDateDisplay(e) {
		if (e.displayValue) {
			const date = e.displayValue;
			if (dayjs(date).isValid()) {
				return dayjs(date).format("DD/MM/YYYY");
			}
		}
		return null;
	},
	getColumnVisibleIndex(columnName, storageName) {
		const columns = getFromLS(storageName);
		if (columns === undefined) {
			return null;
		} else {
			const foundColumn = columns.find((x) => x.name === columnName);
			if (foundColumn === undefined) {
				return null;
			} else {
				return foundColumn["visibleIndex"];
			}
		}
	},
	getColumnWidth(columnName, storageName) {
		const columns = getFromLS(storageName);
		if (columns === undefined) {
			return null;
		} else {
			const foundColumn = columns.find((x) => x.name === columnName);
			if (foundColumn === undefined) {
				return null;
			} else {
				return foundColumn["columnWidth"];
			}
		}
	},
	NotifyUser(message, type = "success", width = 150, duration = 1500, position = "center", direction = "up-push") {
		Notify(
			{
				message: `${message}`,
				height: "auto",
				width: "auto",
				minWidth: `${width}`,
				type: `${type}`,
				displayTime: `${duration}`,
				animation: {
					show: {
						type: "fade",
						duration: 400,
						from: 0,
						to: 1
					},
					hide: { type: "fade", duration: 40, to: 0 }
				}
			},
			{
				position: `${position}`,
				direction: `${direction}`
			}
		);
	},
	checkGridValid(grid, validateColumns, ignoreLastRow = true) {
		const gridStatus = {
			valid: true,
			brokenRules: []
		};
		const lastRow = ignoreLastRow && grid.length > 1 ? 1 : 0;

		for (var i = 0; i < grid.length - lastRow; i++) {
			validateColumns.forEach((columnName) => {
				if (!this.isNullOrEmpty(grid[i][columnName])) {
					if (grid[i][columnName].trim().length === 0) {
						gridStatus.brokenRules.push({
							rowIndex: i,
							column: columnName,
							brokenRules: "Required"
						});
					}
				} else {
					gridStatus.brokenRules.push({
						rowIndex: i,
						column: columnName,
						brokenRules: "Required"
					});
				}
			});
		}

		if (gridStatus.brokenRules.length > 0) {
			gridStatus.valid = false;
		}

		return gridStatus;
	},
	filterGridValue(grid, validateColumns) {
		const invalidIndex = [];
		const validChildren = [];

		if (Array.isArray(grid)) {
			for (var i = 0; i < grid.length; i++) {
				validateColumns.forEach((column) => {
					if (!this.isNullOrEmpty(column.rules) && Array.isArray(column.rules)) {
						//Required Checking
						const requiredChecked = column.rules.find((c) => c.name === "Required");
						if (!this.isNullOrEmpty(requiredChecked)) {
							if (!this.isNullOrEmpty(grid[i][column.name])) {
								if (grid[i][column.name].trim().length === 0) {
									invalidIndex.push({
										rowIndex: i,
										column: column.name,
										brokenRules: "Required"
									});
								}
							} else {
								invalidIndex.push({
									rowIndex: i,
									column: column.name,
									brokenRules: "Required"
								});
							}
						}

						//Min Value Checking
						const minChecked = column.rules.find((c) => c.name === "Min");
						if (!this.isNullOrEmpty(grid[i][column.name]) && !this.isNullOrEmpty(minChecked) && this.isNumber(grid[i][column.name])) {
							const minValue = minChecked.value;
							if (!this.isNullOrEmpty(minValue)) {
								if (grid[i][column.name] < minValue) {
									invalidIndex.push({
										rowIndex: i,
										column: column.name,
										brokenRules: "Min"
									});
								}
							}
						}
					}
				});
			}

			const sortedData = invalidIndex.reduce((acc, { rowIndex, column, brokenRules }) => {
				if (!acc[rowIndex]) {
					acc[rowIndex] = [];
				}
				acc[rowIndex].push({ column, brokenRules });
				return acc;
			}, {});

			for (var i = 0; i < grid.length; i++) {
				const invalid = sortedData[i];

				// console.log("Delete index", deletedIndex)
				if (this.isNullOrEmpty(invalid)) {
					validChildren.push(grid[i]);
				} else {
					const totalInvalidColumns = invalid.reduce((acc, { column, brokenRules }) => {
						if (!acc[column]) {
							acc[column] = [];
						}

						acc[column].push({ column, brokenRules });
						return acc;
					}, {});

					//If not all filter columns are invalid, then it will not be removed
					if (Object.keys(totalInvalidColumns).length !== validateColumns.length) {
						validChildren.push(grid[i]);
					}
				}
			}
		}

		return validChildren;
	},
	GridCellValueToUpper(newData, value) {
		newData.Code = value.toUpperCase();
	},
	GetScrollViewHeight() {
		if (window.innerWidth < 425) {
			return "calc(100% - 80px)";
		} else {
			return "calc(100% - 45px)";
		}
	},
	GetScrollViewHeightV2() {
		if (window.innerWidth < 425) {
			return "calc(100% - 145px)";
		} else {
			return "calc(100% - 105px)";
		}
	},
	isObject(value) {
		return typeof value === "object" && value !== null && !Array.isArray(value);
	},
	mergeObject(target = {}, source = {}) {
		if (!this.isObject(target)) {
			target = {};
		}

		if (!this.isObject(source)) {
			source = {};
		}

		return Object.assign(target, source);
	},
	mergeObjects(target = {}, ...source){
		for (var i = 0; i < source.length; i++) {
			console.log("source", source[i])
			target = this.mergeObject(target, source[i]);
		}
		console.log("target", target, source.length)
		return target;
	},
	objectEqual(obj1, obj2) {
		if (!this.isObject(obj1) || !this.isObject(obj2)) return false;

		// Get the keys of both objects
		const keys1 = Object.keys(obj1);
		const keys2 = Object.keys(obj2);

		// Check if both objects have the same number of keys
		if (keys1.length !== keys2.length) {
			return false;
		}

		// Check if all keys and values are the same
		for (let key of keys1) {
			if (obj1[key] !== obj2[key]) {
				return false;
			}
		}

		return true;
	},
	arrayEqual(arr1, arr2) {
		// Check if both arrays have the same length
		if (arr1.length !== arr2.length) {
			return false;
		}

		// Check if every object in arr1 has a corresponding equal object in arr2
		for (let i = 0; i < arr1.length; i++) {
			if (this.isObject(arr1[i]) && this.isObject(arr2[i])) {
				// If any object is not equal, return false
				if (!this.objectEqual(arr1[i], arr2[i])) {
					return false;
				}
			} else {
				if (arr1[i] !== arr2[i]) {
					return false;
				}
			}
		}

		// If all elements are equal, return true
		return true;
	},
	sortArrayByProp(arr, prop, ascending = true) {
		if (prop === false) {
			return arr;
		}

		return arr.sort((a, b) => {
			if (a[prop] > b[prop]) {
				return ascending ? 1 : -1;
			} else if (a[prop] < b[prop]) {
				return ascending ? -1 : 1;
			} else {
				return 0;
			}
		});
	},
	GridAddRow(columns, defaultValue = null) {
		const row = {};

		if (Array.isArray(columns)) {
			for (var i = 0; i < columns.length; i++) {
				if (defaultValue instanceof Object) {
					if (defaultValue[columns[i]?.dataField] !== undefined) {
						row[columns[i]?.dataField] = defaultValue[columns[i]?.dataField];
					} else {
						row[columns[i]?.dataField] = null;
					}
				} else {
					row[columns[i]?.dataField] = null;
				}
			}
		}

		//Fill in the value for the missing column
		if (this.isObject(defaultValue)) {
			for (const [key, value] of Object.entries(defaultValue)) {
				if (row[key] === undefined) {
					row[key] = value;
				}
				// console.log(`${key}: ${value}`);
			}
		}

		row["ID"] = crypto.randomUUID();

		return row;
	},
	isNumber(value) {
		const notNumber = isNaN(value);
		const validFloat = parseFloat(value);

		if (typeof value === "string") return false;
		if (notNumber) return false;
		if (Object.is(validFloat, NaN)) return false;
		return true;
	},
	isNumberString(value) {
		const notNumber = isNaN(value);
		const validFloat = parseFloat(value);

		if (notNumber) return false;
		if (Object.is(validFloat, NaN)) return false;
		return true;
	},
	numberToCurrency(number) {
		var formattedNumber = number;

		if (this.isNumberString(number)) {
			const roundedNumber = parseFloat(parseFloat(number).toFixed(2));
			const currency = new Intl.NumberFormat().format(roundedNumber);
			var formatNumber = currency.includes(".") ? currency : currency + ".00";
			const splitFormatNumber = formatNumber.split(".");

			if (splitFormatNumber[1].length == 1) {
				formatNumber += "0";
			}

			return formatNumber;
		}

		return formattedNumber;
	},
	roundUp(value, decimalPlace = 2) {
		if (this.isNumber(value)) {
			const formattedString = value.toFixed(decimalPlace);
			return parseFloat(formattedString);
		} else {
			return value;
		}
	},
	roundUpObjectProperties(object, decimalPlace = 2) {
		if (this.isObject(object)) {
			for (const property in object) {
				if (this.isNumber(object[property]) && object[property]) {
					object[property] = this.roundUp(object[property], decimalPlace);
				}
			}
		} else if (Array.isArray(object)) {
			for (var i = 0; i < object.length; i++) {
				if (this.isObject(object[i])) {
					for (const property in object[i]) {
						if (this.isNumber(object[i][property]) && object[i][property]) {
							object[i][property] = this.roundUp(object[i][property], decimalPlace);
						}
					}
				}
			}
		}

		return object;
	},
	codeAsyncValidation(apiURL, value) {
		return new Promise(function (resolve, reject) {
			baseapi.httpget(`${apiURL}/CheckDuplicateCode`, { code: value || "" }).then((response) => {
				const { data } = response;
				// console.log("Response", data)
				resolve(data);
			});
		});
	},
	codeAsyncPropsValidation(apiURL, props) {
		return new Promise(function (resolve, reject) {
			baseapi.httpget(`${apiURL}/CheckDuplicateCode`, props).then((response) => {
				const { data } = response;
				// console.log("Response", data)
				resolve(data);
			});
		});
	},
	getMissingRecord(lookup, children, column, property) {
		var missingRecord = "";

		if (Array.isArray(lookup) && Array.isArray(children)) {
			const missingArray = [];

			for (var i = 0; i < children.length; i++) {
				const found = lookup.find((c) => c[property] === children[i][column]);
				if (found === undefined) {
					missingArray.push(children[i][column]);
				}
			}
			missingRecord = missingArray.join(" ");
		}

		return missingRecord;
	},
	asyncValidateCallBack(column, value, apiURL) {
		const args = {};
		args[column] = value || "";
		return new Promise(function (resolve, reject) {
			baseapi.httpget(`${apiURL}`, args).then((response) => {
				const { data } = response;
				// console.log("Response", data)
				resolve(data);
			});
		});
	},
	asyncArgsValidateCallBack(args, apiURL) {
		return new Promise(function (resolve, reject) {
			baseapi.httpget(`${apiURL}`, args).then((response) => {
				const { data } = response;
				// console.log("Response", data)
				resolve(data);
			});
		});
	},
	formCopy(storageName, object, disabledColumns = null) {
		// The disabledColumns object must have the same property name
		// as the object's property name in order to make this function work
		// Sample : object = { Parent : {DocumentNo: "123", Amount : 123, ...}}
		// Then the disabledColumns object should be like this
		// Sample : disabledColumns = { Parent : ['DocumentNo'] }

		if (!this.isNullOrEmpty(disabledColumns)) {
			for (const objectName in disabledColumns) {
				if (Array.isArray(disabledColumns[objectName])) {
					const disableColumnArr = disabledColumns[objectName];

					for (var i = 0; i < disableColumnArr.length; i++) {
						if (Array.isArray(object[objectName])) {
							const objectArr = object[objectName];
							for (var j = 0; j < objectArr.length; j++) {
								delete objectArr[j][disableColumnArr[i]];
							}
						} else {
							delete object[objectName][disableColumnArr[i]];
						}
					}
				}
			}
		}

		saveToLS(storageName, object);
	},
	isInArray(arr, target, prop = null) {
		var result = false;
		if (Array.isArray(arr)) {
			if (prop === null) {
				var found = arr.find((c) => c === target);

				if (found !== undefined) {
					result = true;
				}
			} else {
				var found = arr.find((c) => c[prop] === target);

				if (found !== undefined) {
					result = true;
				}
			}
		}

		return result;
	},
	isEmptyArray(arr) {
		var check = false;
		if (Array.isArray(arr)) {
			if (arr.length === 0) check = true;
		}
		return check;
	},
	isEmptyObject(obj) {
		return Object.keys(obj).length === 0;
	},
	getDefaultForexRM() {
		return "b_xPwSzI5Y0%3d";
	},
	isVListing(result) {
		if (!Array.isArray(result) && result.items !== undefined) {
			return true;
		} else {
			return false;
		}
	},
	getUserID() {
		return getFromLS("userID");
	},
	multiply(...args) {
		let total = 0;
		for (var i = 0; i < args.length; i++) {
			if (i === 0) total = parseFloat(args[i]);
			else total *= parseFloat(args[i]);
		}
		return this.roundUp(total);
	},
	add(...args) {
		let total = 0;
		for (var i = 0; i < args.length; i++) {
			if (i === 0) total = parseFloat(args[i]);
			else total += parseFloat(args[i]);
		}
		return this.roundUp(total);
	},
	divide(...args) {
		let total = 0;
		for (var i = 0; i < args.length; i++) {
			if (i === 0) total = parseFloat(args[i]);
			else total /= parseFloat(args[i]);
		}
		return this.roundUp(total);
	},
	subtract(...args) {
		let total = 0;
		for (var i = 0; i < args.length; i++) {
			if (i == 0) total = parseFloat(args[i]);
			else total -= parseFloat(args[i]);
		}
		return this.roundUp(total);
	},
	isLockedForm(e = {}) {
		if (!utils.isNullOrEmpty(e["row"])) {
			const data = e.row?.data;
			if (!utils.isNullOrEmpty(data)) {
				const MainForexAmount = data.MainForexAmount;
				const OutstandingAmount = data.OutstandingAmount;
				if (!utils.isNullOrEmpty(MainForexAmount) && !utils.isNullOrEmpty(OutstandingAmount)) {
					if (MainForexAmount !== OutstandingAmount) {
						return true;
					}
				}
			}
		}

		return false;
	},
	childrenGridAddRow(ref) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				ref.current.addRow();
			}
		}
	},
	childrenGridSetFocusCellIndex(ref, value = null) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				ref.current.setCurrentFocusCellIndex(value);
			}
		}
	},
	childrenGridGetFocusCellIndex(ref) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				return ref.current.getCurrentFocusCellIndex();
			}
		}
	},
	childrenGridLength(ref) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				return ref.current.length();
			}
		}

		return 0;
	},
	calculateDaysDifferenceExcluding31st(startDate, endDate) {
		let daysDifference = 0;
		let currentDate = new Date(startDate);

		while (currentDate <= endDate) {
			// Check if the current date is not the 31st
			if (currentDate.getDate() !== 31) {
				daysDifference++;
			}
			// Move to the next day
			currentDate.setDate(currentDate.getDate() + 1);
		}

		return daysDifference - 1;
	},
	childrenGridSetSource(ref, value = []) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				ref.current.setDataGridSource(value);
			}
		}
	},
	childrenGridGetSource(ref) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				return ref.current.getDataSource();
			}
		}
		return [];
	},
	childGridGetStorageName(ref) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				return ref.current.getStorageName();
			}
		}
	},
	childrenGridGetName(ref) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				return ref.current.getChildrenGridName();
			}
		}
	},
	childrenGridSetParent(ref, value = {}) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				return ref.current.setParent(value);
			}
		}

		return {};
	},
	popupFormOpen(ref) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				return ref.current.openForm();
			}
		}
	},
	popupFormClose(ref) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				return ref.current.closeForm();
			}
		}
	},
	popupFormSuccessfulSubmit(ref, submissionProps = {}) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				return ref.current.successfulSubmit(submissionProps);
			}
		}
	},
	popupFormSubmitWithoutChecking(ref, submissionProps = {}) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				return ref.current.submitWithoutChecking(submissionProps);
			}
		}
	},
	popupFormSetErrorForm(ref, props) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				return ref.current.setErrorForm(props);
			}
		}
	},
	popupFormAfterInitialization(ref, props, data) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				return ref.current.afterInitialization(props, data);
			}
		}
	},
	popupFormGetDefaultValues(ref) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				return ref.current.getDefaultValues();
			}
		}
	},
	displayPopupMessage(ref, props) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				return ref.current.displayMessage(props);
			}
		}
	},
	childGridDefaultSetting(dataField, action = null) {
		if (action === "Max Length") {
			const result = this.childGridMaxLengthSetting[dataField];
			// Return max length
			return utils.isNullOrEmpty(result) ? null : result;
		} else if (action === "Width") {
			const result = this.childGridWidthSetting[dataField];
			// Return width length
			return utils.isNullOrEmpty(result) ? undefined : result;
		} else if (action === "Caption") {
			const result = this.childGridCaptionSetting[dataField];
			// Return caption
			return utils.isNullOrEmpty(result) ? undefined : result;
		} else if (action === "Read Only") {
			const result = this.childGridReadOnlySetting[dataField];
			// Return caption
			return utils.isNullOrEmpty(result) ? false : result;
		} else if (action === "Max") {
			const result = this.childGridMaxSetting[dataField];
			// Return caption
			return utils.isNullOrEmpty(result) ? undefined : result;
		} else if (action === "Min") {
			const result = this.childGridMinSetting[dataField];
			// Return caption
			return utils.isNullOrEmpty(result) ? undefined : result;
		} else if (action === "Format") {
			const result = this.childGridFormat[dataField];
			// Return caption
			return utils.isNullOrEmpty(result) ? "" : result;
		} else if (action === "Customize Text") {
			const result = this.childGridCustomizeText[dataField];
			// Return caption
			return utils.isNullOrEmpty(result) ? null : result;
		}

		//return null if no such a setting
		return null;
	},
	childGridNumberToPercent(data) {
		return data.valueText + "%";
	},
	transformOpen(ref) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				return ref.current.openForm();
			}
		}
	},
	gainAndLossFormOpen(ref, details = null) {
		if (!this.isNullOrEmpty(ref)) {
			if (!this.isNullOrEmpty(ref.current)) {
				return ref.current.openForm(details);
			}
		}
	},
	arrayHasProperty(array, prop) {
		return array.every((item) => item.hasOwnProperty(prop));
	},
	arrayAddKey(array) {
		return array.map((item) => ({
			...item,
			key: crypto.randomUUID() // or any other unique identifier
		}));
	},
	toUpperCase(value) {
		if (!this.isNullOrEmpty(value) && !this.isNumber(value)) {
			return value.toUpperCase();
		}
		return value;
	},
	isString(input) {
		return typeof input === "string" || input instanceof String;
	},
	trim(value) {
		if (!this.isNullOrEmpty(value) && this.isString(value)) {
			return value.trim();
		}

		return null;
	},
	ensureArray(value) {
		if (Array.isArray(value)) {
			return value;
		} else {
			return value.split(",");
		}
	},
	arrayToString(arr) {
		if (Array.isArray(arr)) {
			return arr.join(" ");
		} else {
			return arr;
		}
	},
	async creditTerm(parentData, isAR) {
		const res = await baseapi.httpget("/api/Utilities/GetCreditTermOutAmtList", {
			documentDate: parentData.DocumentDate,
			id: isAR ? parentData.CustomerID : parentData.SupplierID,
			isAR: isAR,
		});

		// console.log("API response:", res);

		const { data } = res;
		// console.log(data)
		// console.log(parentData)
		if (!data.status && !utils.isNullOrEmpty(data.status)) {
			return null;
		}

		const getParentDate = new Date(data.dateTime);
		const dateToCompare = new Date(parentData.DocumentDate);
		const getExpiredDate = new Date(
			getParentDate.getFullYear(),
			getParentDate.getMonth(),
			getParentDate.getDate() + parentData.CreditTermsDays
		);
		const daysDifference = utils.calculateDaysDifferenceExcluding31st(getExpiredDate, dateToCompare);

		if(daysDifference === -1) {
			return null;
		}

		return {
			creditTerm: parentData.CreditTermsDays,
			overdueDays: daysDifference,
			userType: isAR ? parentData.CustomerCode : parentData.SupplierCode,
			totalOutAmt: data.outAmt,
			isAR: isAR,
			visible: true,
			isBoth: false,
			isTerm: true,
		};
	},
	async creditLimit(parentData, isAR) {
		if(parentData.CreditLimit !== 0) {
			// console.log(parentData)
			var currentAmount = (parentData.MainForexAmount - parentData.PreviousMainForexAmount) + parentData.OutstandingBalance;
			if(currentAmount > parentData.CreditLimit) {
				return {
					outstandingAmt: currentAmount,
					creditLimit: parentData.CreditLimit,
					userType: isAR ? parentData.CustomerCode : parentData.SupplierCode,
					isAR: isAR,
					visible: true,
					isBoth: false,
					isTerm: false,
				};
			} else {
				return null;
			}
		}
	},
	TimeFormatMask: {
		H: (char) => char >= 0 && char <= 2,
		h: (char, index, fullStr) => {
			if (fullStr[0] == "2") return [0, 1, 2, 3].includes(parseInt(char));
			else return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(parseInt(char));
		},
		M: (char) => char >= 0 && char <= 5,
		m: (char) => char >= 0 && char <= 9
	},
	CommonCalculationColumns: {
		ForexAmount: 0,
		ForexTaxable: 0,
		ForexTaxAmount: 0,
		ForexTaxAdjust: 0,
		ForexTaxAmountOrignal: 0,
		ForexTaxablePlusTax: 0,
		ForexTaxAutoAdjust: 0,
		FinalLocalAmountAdjust: 0,
		LocalAmount: 0,
		LocalTaxAdjust: 0,
		LocalTaxable: 0,
		LocalTaxAmount: 0,
		LocalTaxAmountOrignal: 0,
		LocalTaxablePlusTax: 0,
		LocalTaxAutoAdjust: 0,
		TaxRate: 0,
		TaxInclusive: false,
		NettPrice: 0,
		ItemDiscount: 0,
		ItemDiscountAmt: 0,
		UnitPrice: 0
	},
	childGridWidthSetting: {
		Line: 40,
		Description: 200,
		Description2: 200,
		Quantity: 50,
		TaxRate: 50,
		StockID: 100,
		ChartAccountID: 100,
		TaxCodeID: 100,
		ProjectID: 100,
		TaxInclusive: 80,
		MoreDescription: 50
	},
	childGridMaxLengthSetting: {
		Description: 250,
		Description2: 250
	},
	childGridCaptionSetting: {
		Line: "No",
		StockID: "Stock Code",
		ProjectID: "Project",
		Quantity: "Qty",
		ItemDiscount: "Disc",
		UnitPrice: "U/Price",
		NettPrice: "Net Price",
		TaxCodeID: "Tax Code",
		TaxRate: "Tax Rate",
		TaxInclusive: "Tax Inclusive",
		ForexTaxable: "Tax FX Taxable",
		ForexTaxAmount: "Tax Amount",
		ChartAccountID: "Chart Account",
		ForexTaxAmountOrignal: "Tax FX Amount Org",
		ForexTaxAdjust: "Tax FX Adj",
		ForexTaxablePlusTax: "Sub Total",
		LocalTaxable: "Tax LC Taxable",
		LocalTaxAmount: "Tax LC Amount",
		LocalTaxAmountOrignal: "Tax LC Amount Org",
		LocalTaxAdjust: "Tax LC Adj",
		LocalTaxablePlusTax: "Tax LC Taxable plus Tax",
		Particular: "Description",
		BankInDate: "Bank In Date",
		ForexAmount: "Amount",
		UOMID: "UOM"
	},
	childGridReadOnlySetting: {
		Line: true,
		TaxRate: true,
		ForexTaxable: true,
		ForexTaxAmount: true,
		NettAmount: true,
		LocalTaxAdjust: true,
		UOM: true,
		NettPrice: true,
		MoreDescription: true,
		ImportDocumentNo: true
	},
	childGridMaxSetting: {
		ItemDiscount: 100
	},
	childGridMinSetting: {
		ItemDiscount: 0
	},
	childGridFormat: {
		UnitPrice: "#,##0.00",
		NettPrice: "#,##0.00",
		ForexTaxable: "#,##0.00",
		ForexTaxAmount: "#,##0.00",
		ForexAmount: "#,##0.00",
		UniCost: "#,##0.00",
		ForexTaxAmountOrignal: "#,##0.00",
		ForexTaxAdjust: "#,##0.00",
		ForexTaxablePlusTax: "#,##0.00",
		LocalTaxable: "#,##0.00",
		LocalTaxAmount: "#,##0.00",
		LocalTaxAmountOrignal: "#,##0.00",
		LocalTaxAdjust: "#,##0.00",
		LocalTaxablePlusTax: "#,##0.00",
		NettAmount: "#,##0.00",
		LocalAmount: "#,##0.00",
		ItemDiscountAmt: "#,##0.00"
	},
	childGridCustomizeText: {
		ItemDiscount: (data) => {
			return data.valueText + "%";
		},
		TaxRate: (data) => {
			return data.valueText + "%";
		}
	},
	deleteMessage(props, data) {
		return {
			visible: true,
			message: data.status ? `${props.title !== undefined ? props.title : "Record"} ` + data.message : data.message,
			type: data.status ? "Success" : "Danger"
		};
	},
	offsetByMessage(document) {
		return {
			visible: true,
			message: `This Document is being offset by ${document ?? "another Document"}, you cannot edit`,
			type: "Warning"
		};
	},
	transferredByMessage(document) {
		return {
			visible: true,
			message: `This Document is being transferred by ${document ?? "another Document"}, you cannot edit`,
			type: "Warning"
		};
	},
	receiptMessage(formRef, document) {
		if (document.cashSalesLocked) {
			this.popupFormSetErrorForm(formRef, {
				visible: true,
				message: "This Document is offset by Cash Sales Payment, cannot be deleted!",
				type: "Warning"
			});
			return true;
		} else if (document.cashPurchaseLocked) {
			this.popupFormSetErrorForm(formRef, {
				visible: true,
				message: "This Document is offset by Cash Purchase Payment, cannot be deleted!",
				type: "Warning"
			});
			return true;
		} else if (document.refundAmount && document.refundAmount > 0) {
			this.popupFormSetErrorForm(formRef, {
				visible: true,
				message: "This Document is being offset by AR Refund, cannot be deleted!",
				type: "Warning"
			});
			return true;
		} else if (document.workShopLocked) {
			this.popupFormSetErrorForm(formRef, {
				visible: true,
				message: "This Document is being offset by Workshop Invoice Payment, cannot be deleted!",
				type: "Warning"
			});
			return true;
		} else {
			return false;
		}
	},
	DocumentCancelledMessage: {
		visible: true,
		message: `This Document is being cancelled, it will not be posted to report!`,
		type: "Warning"
	},
	LocalRateFormat: "#,##0.000000"
};

export default utils;

function checkTime(i) {
	if (i < 10) {
		i = "0" + i;
	}

	return i;
}
const clearSession = () => {
	baseapi.setAuthorization(null);
	removeFromLS("Displayname");
	removeFromLS("Role");
	window.location.reload();
};
function handleError(error, reject) {
	console.log(error);
	reject(error);

	const { response } = error;
	if (response) {
		const { status, data, statusText } = response;
		switch (status) {
			case 401:
				if (window.location.href.toLowerCase().indexOf("login") < 0)
					decision('<div>Please login to use the features. If you are not register with us yet, you may want to<br /><a href="{0}">Register a free account here</a>. :)</div>'.format([utils.fromRoot("/account/register")]), "Proceed to login?", {
						confirmButtonText: "Go to Login"
					}).then((result) => {
						if (result.value) {
							window.location = utils.fromRoot("/login", {
								returnUrl: window.location.href
							});
						}
					});
				break;
			case 200:
				break;
			case 204:
				break;
			case 409:
				showSessionAlert(data);
				break;
			case 402:
				if (data && data.Title && data.Description) {
					swal.fire({
						title: data.Title,
						text: data.Description,
						icon: "warning",
						confirmButtonText: "Renew Subscription",
						showCancelButton: true,
						cancelButtonText: "Later"
					}).then((result) => {
						if (result.isConfirmed) {
							// Redirect to subscription renewal page
							window.location = utils.fromRoot("/subscription/renew");
						}
					});
				} else {
					// Fallback if Title and Description are not provided
					swal.fire({
						title: "Payment Required",
						text: "Your payment is required to continue using the service.",
						icon: "warning",
						confirmButtonText: "Renew Subscription",
						showCancelButton: true,
						cancelButtonText: "Later"
					}).then((result) => {
						if (result.isConfirmed) {
							window.location = utils.fromRoot("/subscription/renew");
						}
					});
				}
				break;
			default:
				var msg = statusText || "Something wrong";
				var messages = [];
				if (typeof data === "string") {
					messages.push(data);
				} else if (data) {
					const { Message, errors } = data;
					if (Message) messages.push(Message);
					if (errors) {
						for (var i in errors) {
							var err = errors[i];
							if (Array.isArray(err)) err = err.join("; ");
							messages.push(err);
						}
					}
				}

				msg = messages.join("; ") || msg;

				if (isLocalhost || status === 409 || status === 400)
					swal.fire({
						type: "info",
						title: "Opps!",
						text: msg,
						showConfirmButton: false
					});
				break;
		}
	} else if (isLocalhost)
		swal.fire({
			type: "error",
			title: "Error",
			text: error.message || "Something wrong",
			showConfirmButton: false
		});
}

export function httpget(url, data, options) {
	if (url.indexOf("http") < 0) url = utils.fromRoot(url);
	return new Promise((resolve, reject) => {
		url = utils.fromRoot(url);
		options = {
			...options,
			params: data
			// paramsSerializer: function (params) {
			//     return qs.stringify(params);
			// }
		};
		axios
			.get(url, options)
			.then(function (response) {
				if (response) resolve(response);
			})
			.catch(function (error) {
				if (!options || options.promptError !== false) handleError(error, reject);
			});
	});
}

export function httppost(url, data, options) {
	if (url.indexOf("http") < 0) url = utils.fromRoot(url);
	return new Promise((resolve, reject) => {
		axios
			.post(utils.fromRoot(url), data, options)
			.then(function (response) {
				switch (response.status) {
					case 200:
					case 204:
						resolve(response);
						break;
					default:
						handleError({ response }, reject);
						break;
				}
			})
			.catch(function (error) {
				if (!options || options.promptError !== false) handleError(error, reject);
			});
	});
}

export function httpput(url, data, options) {
	if (url.indexOf("http") < 0) url = utils.fromRoot(url);
	return new Promise((resolve, reject) => {
		axios
			.put(utils.fromRoot(url), data, options)
			.then(function (response) {
				if (response.status !== 200) handleError({ response }, reject);
				else resolve(response);
			})
			.catch(function (error) {
				if (!options || options.promptError !== false) handleError(error, reject);
			});
	});
}

export function upload(url, data, options) {
	const { headers, ...others } = options || {};
	const opt = {
		headers: {
			...headers,
			"Content-Type": "multipart/form-data"
		},
		...others
	};
	return httppost(utils.fromRoot(url), data, opt);
}

export function download(url, data, options) {
	return new Promise((resolve, reject) => {
		axios
			.post(utils.fromRoot(url), data, {
				...options,
				responseType: "arraybuffer"
			})
			.then((response) => {
				var fileName = dayjs().format("YYYYMMDD_HHmmss") + ".xlsx";
				if (response) {
					var content = response.headers["content-disposition"];
					if (content && content.contains("filename=")) fileName = content.split("filename=")[1];
				}
				FileDownload(response.data, fileName);
				if (response) resolve(response);
			})
			.catch(function (error) {
				if (!options || options.promptError !== false) handleError(error, reject);
			});
	});
}

export function downloadget(url, data, options) {
	return new Promise((resolve, reject) => {
		axios
			.get(utils.fromRoot(url), {
				...options,
				responseType: "arraybuffer",
				params: data
				// paramsSerializer: function (params) {
				//     return qs.stringify(params);
				// }
			})
			.then((response) => {
				var fileName = dayjs().format("YYYYMMDD_HHmmss") + ".xlsx";
				if (response) {
					var content = response.headers["content-disposition"];
					if (content && content.contains("filename=")) fileName = content.split("filename=")[1];
				}
				FileDownload(response.data, fileName);
				if (response) resolve(response);
			})
			.catch(function (error) {
				if (!options || options.promptError !== false) handleError(error, reject);
			});
	});
}

//export function httpdel(url, data, options) {
//    return new Promise((resolve, reject) => {
//        axios.delete(utils.fromRoot(url), data, options)
//            .then(function (response) {
//                resolve(response);
//            })
//            .catch(function (error) {
//                if (!options || options.promptError !== false)
//                    handleError(error, reject);
//            });
//    });
//}
export function httpdel(url, data, options) {
	return httppost(utils.extendUrlVar(utils.pathCombine(url, "Delete"), data), null, options);
}

export function yesno(msg, title, opt) {
	if (msg && !msg.startsWith("<div>")) msg = "<div>{0}</div>".format(msg);
	return swal.fire({
		title: title || "Confirmation",
		html: msg || "<div>Confirm to continue?</div>",
		type: "question",
		customClass: {
			confirmButton: "yesno-btn yesno-confirm-btn",
			cancelButton: "yesno-btn yesno-cancel-btn"
		},
		buttonsStyling: false,
		showCancelButton: true,
		focusConfirm: false,
		confirmButtonText: "Yes",
		cancelButtonText: "No"
	});
}

export function decision(msg, title, opt) {
	if (msg && !msg.startsWith("<div>")) msg = "<div>{0}</div>".format(msg);
	return swal.fire({
		title: title || "Confirmation",
		html: msg || "<div>Confirm to continue?</div>",
		type: "question",
		confirmButtonClass: "btn btn-success",
		cancelButtonClass: "btn btn-default",
		buttonsStyling: false,
		showCancelButton: true,
		focusConfirm: false,
		confirmButtonText: "Confirm",
		...opt
	});
}

export function showMessage(title, msg, opt) {
	return swal.fire({
		type: "info",
		title: title,
		html: msg,
		showConfirmButton: false,
		...(opt || {})
	});
}

export function processing(msg) {
	return swal.fire({
		type: "info",
		title: "Processing",
		html: msg || "Please wait...",
		showConfirmButton: false,
		allowOutsideClick: false
	});
}

export function loading(msg, title = "Loading") {
	// return swal.fire({
	// 	type: "info",
	// 	title: title,
	// 	text: msg || "Please wait...",
	// 	showConfirmButton: false,
	// 	allowOutsideClick: false
	// });
	return swal.fire({
		showConfirmButton: false,   // Hide the "OK" button
		allowOutsideClick: false,   // Prevent closing by clicking outside
		allowEscapeKey: false,     // Disable closing with escape key
		didOpen: () => {
		  swal.showLoading();       // Show the loading spinner
		},
		customClass: {
		  popup: 'transparent-bg',  // Custom class to make the popup background transparent
		}
	  });
}

export function notfound(title, msg) {
	return swal.fire({
		type: "warning",
		title: title || "Not found",
		html: msg,
		showConfirmButton: false
	});
}

export function done(msg, opt) {
	return swal.fire({
		type: "success",
		title: msg || "Done",
		showConfirmButton: false,
		timer: 300,
		...opt
	});
}

export function CustomDone(msg, delay = 300, opt) {
	return swal.fire({
		type: "success",
		title: msg || "Done",
		showConfirmButton: false,
		timer: delay,
		...opt
	});
}

export function datasaved(msg) {
	return swal.fire({
		type: "success",
		title: msg || "Data saved successfully",
		showConfirmButton: false,
		timer: 1500
	});
}

export function deleted(msg) {
	swal.fire({
		type: "success",
		title: msg || "Data deleted successfully",
		showConfirmButton: false,
		timer: 1500
	});
}

export function showError(msg, opt) {
	return swal.fire({
		...{
			type: "error",
			title: "Error",
			text: msg,
			timer: 3000
		},
		...opt
	});
}


export function showLoadingAlert(msg, title = "Loading") {
	// Only show loading alert if no other alerts are currently displayed
	if (!loadingAlertInstance && !subscriptionAlertInstance) {
		loadingAlertInstance = swal.fire({
			title: title,
			text: msg || "Please wait...",
			showConfirmButton: false,
			allowOutsideClick: false
		});
	}
}

export function closeLoadingAlert() {
	if (loadingAlertInstance) {
		loadingAlertInstance.close();
		loadingAlertInstance = null;
	}
}
function showSubscriptionExpireAlert(title, description) {
		// Close other alerts
		closeLoadingAlert();
    // Close any existing alerts before showing a new subscription alert
    if (subscriptionAlertInstance) {
        subscriptionAlertInstance.close();
        subscriptionAlertInstance = null;
    }


    // Only show subscription alert if not already open
    if (!subscriptionAlertInstance) {
        subscriptionAlertInstance = swal.fire({
            title: title,
            html: description,
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'Subscribe Now',
            cancelButtonText: 'Cancel', // Add text to the Cancel button
            icon: 'warning',
            allowOutsideClick: false,
            preConfirm: () => {
                window.location.href= '/subscribe';
            }
        }).then((result) => {
            // If cancel button is clicked
            if (result.dismiss === swal.DismissReason.cancel) {
                window.location.reload(); // Refresh the page
            }
        }).finally(() => {
            // Reset subscriptionAlertInstance after it is closed
            subscriptionAlertInstance = null;
        });
    }
}


export function showSessionAlert(data) {
	// Close other alerts
	closeLoadingAlert();
	if (subscriptionAlertInstance) {
		subscriptionAlertInstance.close();
		subscriptionAlertInstance = null;
	}

	// Show session alert
	swal.fire({
		title: data,
		text: "Another session with this login is detected. Please log out now.",
		icon: "error",
		confirmButtonText: "OK",
		allowOutsideClick: false
	}).then((result) => {
		if (result.isConfirmed) {
			clearSession(); // Call the provided function to clear the session
		}
	});
}


export function closeLoading() {
	swal.close();
}

export const fetchDefaultLocation = () => {
	return baseapi.httpget('/api/Utilities/GetDefaultLocation')
	  .then((response) => {
		if (response.status === 200) {
		  const locationData = response.data;
		  return { id: locationData.id, code: locationData.code }; // Return the default location data
		} else {
		  console.error('Default location not found');
		  return null; // Handle no default location
		}
	  })
	  .catch((error) => {
		console.error('Error fetching default location:', error);
		return null; // Handle error case
	  });
  };

  
// Function to determine which alert to show based on user data
export function handleSubscriptionExpirationAlerts(user) {

	console.log(user?.IsUsingFreeTrial,"user?.IsUsingFreeTrial")
	if (!user?.IsUsingFreeTrial) {
		showSubscriptionExpireAlert(
			'Subscription Expired',
			`
		  Your subscription has expired. You can still view your records, but you will no longer be able to perform any actions. 
		  To continue enjoying all features of the system, please renew your subscription. 
		  <a href="/subscribe" style="color: #007bff; text-decoration: underline;">Renew Now</a>
		`
		);
		notify("Your subscription plan has expired.", 'error', 2000);
	} else if (user.IsUsingFreeTrial) {
		showSubscriptionExpireAlert(
			'Your Free Trial Period Ended',
			`
		  You can still view your records, but you will not be able to perform any actions until you subscribe. 
		  To continue using all features of the system, please subscribe. 
		  <a href="/subscribe" style="color: #007bff; text-decoration: underline;">Renew Now</a>
		`
		);
		notify("Your free trial has expired. ", 'error', 2000);
	}
}

export function buildColumn(column, data, i) {
	if (Array.isArray(column)) return column.map((c) => buildColumn(c, data, i));

	if (column.config) {
		if (typeof column.config === "function") {
			if (data) column = column.config(column, data, i);
		} else column = column.config;
	}

	if (column.Header !== "" && !column.Header) column.Header = column.id.toName();

	if (column.placeholder !== "" && !column.placeholder) {
		if (column.type === "select") column.placeholder = "- Select -";
		else if (column.type === "checkbox") {
			if (!column.Header) column.placeholder = column.id.toName();
		} else if (column.required) column.placeholder = "mandatory";
		else if (column.type === "email") column.placeholder = "user@example.com";
		else if (column.type === "htmleditor") column.placeholder = "Edit content here";
		else if (column.type !== "text" && column.type !== "date" && column.type !== "datetime") column.placeholder = column.type;
	}

	if (!column.required && column.placeholder === "mandatory") column.placeholder = null;

	if (!column.addon) {
		switch (column.type) {
			case "currency":
				column.addon = "RM";
				break;
			case "percent":
				column.addon = "%";
				break;
			case "date":
			case "datetime":
				column.addon = <i className='fa fa-calendar-alt' />;
				break;
			default:
				break;
		}
	}

	if (!column.accessor) column.accessor = (d) => getData(d, column.id);
	return column;
}

export function getData(data, name) {
	if (data && name) {
		if (name.indexOf(".") >= 0) {
			var names = name.split(".");
			name = names.splice(0, 1);
			return getData(data[name], names.join("."));
		} else {
			var val = data[name];
			if (val && val.CurrencyValue !== undefined) return utils.currency(val.CurrencyValue);
			else return val;
		}
	}
	return null;
}

// function isInt(value) {
//     if (typeof value === 'string') {
//         if (value.contains('.'))
//             return false;
//         value = value.replaceAll(',', '');
//     }
//     return !isNaN(value) && (function (x) { return (x | 0) === x; })(parseFloat(value));
// }

export function setData(data, opt, value) {
	var name = opt;
	if (opt.target) {
		if (opt.target.type === "checkbox") value = opt.target.checked;
		else value = opt.target.value;
		name = opt.target.name;
	}

	if (!data) data = {};
	if (name.indexOf(".") >= 0) {
		var names = name.split(".");
		name = names.splice(0, 1);
		data[name] = setData(data[name], names.join("."), value);
	} else data[name] = value;
	return data;
}

export function retry(fn, retriesLeft = 5, interval = 1000) {
	return new Promise((resolve, reject) => {
		fn()
			.then(resolve)
			.catch((error) => {
				setTimeout(() => {
					if (retriesLeft === 1) {
						// reject('maximum retries exceeded');
						reject(error);
						return;
					}

					// Passing on "reject" is the important part
					retry(fn, retriesLeft - 1, interval).then(resolve, reject);
				}, interval);
			});
	});
}

export const CheckChequeNoValid = async (formRef, cheque_input, documentNo) => {
	var chequeNo = [];

	if (Array.isArray(cheque_input)) {
		chequeNo = chequeNo.concat(cheque_input);
	} else if (!utils.isNullOrEmpty(cheque_input) && utils.isString(cheque_input)) {
		chequeNo.push(cheque_input);
	} else {
		return true;
	}

	const result = await baseapi
		.httppost(
			utils.extendUrlVar("/api/Utilities/CheckChequeNosValid", {
				DocumentNo: documentNo
			}),
			chequeNo
		)
		.then(async (response) => {
			const { data } = response;
			if (!data) {
				const confirm = await yesno(`found duplicate cheque no ${chequeNo.join(",")}. Are you sure you want to save this record?`, "Duplicate Cheque Confirmation", ["Yes", "No"]);

				if (!confirm.isConfirmed) {
					utils.popupFormSetErrorForm(formRef, {
						visible: true,
						message: `Please write a new cheque no`,
						type: "Warning"
					});
				} else {
					return true;
				}
			}
			return data;
		});

	return result;
};

export const CheckCreditLimit = async (parentData, isAR, isBoth = false, isTerm = false) => {
    // console.log("CheckCreditLimit called with:", parentData, isAR);
    try {
        if ((parentData.CustomerID || parentData.SupplierID) !== null && (parentData.CustomerID || parentData.SupplierID) !== undefined) {
			if(isBoth) {
				var creditLimit = await utils.creditLimit(parentData, isAR);
				var creditTerm = await utils.creditTerm(parentData, isAR);

				// console.log(creditLimit)
				// console.log(creditTerm)

				if(!utils.isNullOrEmpty(creditLimit) && !utils.isNullOrEmpty(creditTerm)) {
					var mergedObjects = utils.mergeObject(creditLimit, creditTerm);
					var updateIsBoth = utils.mergeObject(mergedObjects, {isBoth: true});	

					return updateIsBoth;
				} else if(utils.isNullOrEmpty(creditTerm)) {
					return creditLimit;
				} else if (utils.isNullOrEmpty(creditLimit)) {
					// console.log("In here")
					return creditTerm;
				}
			} else {
				if(isTerm) {
					return await utils.creditTerm(parentData, isAR);
				} else {
					return await utils.creditLimit(parentData, isAR);
				}
			}
        } else {
            console.log("CustomerID or SupplierID is null or undefined");
            return null;
        }
    } catch (e) {
        console.log("Error in CheckCreditLimit:", e);
        return null;
    }
};

Object.assign(String.prototype, {
	//startsWith: function (str) {
	//    return this.match('^' + str) == str;
	//},
	//endsWith: function (str) {
	//    return this.match(str + '$') != null;
	//},
	toName: function () {
		var text = this;
		if (!text) return "";

		if (text.contains(".")) {
			var parts = text.split(".");
			text = parts[parts.length - 1];
		}

		text = text.substr(0, 1).toUpperCase() + text.substr(1);

		var matches = text.match(/[A-Z][a-z]+|[0-9]+/g);
		if (matches) {
			matches.sort(function (a, b) {
				return b.length - a.length;
			});
			for (var i in matches) {
				var match = matches[i];
				if (match) text = text.replace(match, " {" + i + "} ");
			}
			text = text.format(matches);
			text = text.trim().replaceAll("  ", " ").replaceAll("_", " ");
			if (text.contains(" And ")) text = text.replaceAll(" And ", " & ");
			if (text.endsWith(" No")) text += ".";
			else if (text.endsWith(" Perc")) text = text.replaceAll(" Perc", "%");
			return text;
		}

		return text;
	},
	formatregex: new RegExp("{-?[0-9]+}", "g"),
	format: function (args) {
		if (!Array.isArray(args)) args = [args];
		var str = this;
		return str.replace(String.prototype.formatregex, function (item) {
			var intVal = parseInt(item.substring(1, item.length - 1), 10);
			var replace;
			if (intVal >= 0) {
				replace = args[intVal];
			} else if (intVal === -1) {
				replace = "{";
			} else if (intVal === -2) {
				replace = "}";
			} else {
				replace = "";
			}
			if (replace === undefined) replace = "";
			return replace;
		});
	},
	contains: function (o) {
		return this.indexOf(o) > -1;
	},
	trim: function () {
		return this.replace(/^\s+|\s+$/g, "");
	},
	ltrim: function () {
		return this.replace(/^\s+/, "");
	},
	rtrim: function () {
		return this.replace(/\s+$/, "");
	},
	replaceAll: function (searchValue, replaceValue) {
		var text = this;
		while (text.contains(searchValue)) text = text.replace(searchValue, replaceValue);
		return text;
	},
	normalize: function () {
		var chars = " ~`‘’!@#$%^&*()={}\\:;\"'<>,.?/+";
		var text = this;
		for (var i in chars) text = text.replaceAll(chars[i], "-");
		return text.replaceAll("--", "-");
	}
});
