import utils from "./common";

export function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
        try {
            ls = global.localStorage.getItem('data');
            if (ls) ls = JSON.parse(ls);
        } catch (e) {
            /*Ignore*/
        }
    }

    if (ls) {
        if (key)
            return ls[key];
        else if (key === undefined)
            return ls;
    }
}

export function saveToLS(key, value) {
    if (global.localStorage) {
        var data = getFromLS();
        global.localStorage.setItem(
            'data',
            JSON.stringify({ ...data, [key]: value })
        );
    }
}

export function saveJsonToLS(obj) {
    if (global.localStorage) {
        var data = getFromLS();
        global.localStorage.setItem(
            'data',
            JSON.stringify({ ...data, ...obj })
        );
    }
}

export function removeFromLS(key) {
    if (global.localStorage) {
        var data = getFromLS();
        if (data) {
            delete data[key];
            global.localStorage.setItem(
                'data',
                JSON.stringify(data)
            );
        }
    }
}

export function getAuthorization() {
    var ls = localStorage.getItem("Authorization");
    if (ls)
        ls = ls.replace('Bearer ', '');
    // else
    //     window.location = '#/login';
    return ls;
}

export function setAuthorization(token) {
    localStorage.setItem("Authorization", 'Bearer ' + token);
}

export function getBearer() {
    return localStorage.getItem("Authorization");
}

export function getSharedState(){
    const sharedState = localStorage.getItem("sharedState");

    try{
        return JSON.parse(sharedState);
    }
    catch(e){
        return sharedState
    }
};

export function setSharedState(value){
    const sharedState = JSON.stringify(localStorage.getItem("sharedState"));
    if(utils.isObject(sharedState) && utils.isObject(value)){
        localStorage.setItem("sharedState", JSON.stringify(Object.assign(sharedState, value)));
    }
    else if(utils.isObject(value)){
        localStorage.setItem("sharedState", JSON.stringify(value));
    }
    else{
        localStorage.setItem("sharedState", value);
    }
};