import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './unauthorized.scss'; 

export default function UnauthorizedContent() {
  const navigate = useNavigate();

  useEffect(() => {

    const timeout = setTimeout(() => {
      navigate(-1); // Navigate back to previous page
    }, 2000); // Redirect after 5 seconds

    return () => clearTimeout(timeout); // Cleanup the timeout on component unmount
  }, [navigate]);

  return (
    <div className="unauthorizedBody">
      <div className="unauthorizedBodylock"></div>
      <div className="unauthorizedMessage">
        <h1>Access to this page is restricted</h1>
        <p>Please check with the site admin if you believe this is a mistake.</p>
      </div>
    </div>
  );
}
