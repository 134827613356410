import React, { useEffect, useState } from 'react';
import { Column, Lookup, } from 'devextreme-react/data-grid';
import { forexOptions } from '../../../utils/lookupstore';
import APPurchaseForm from './form';
import CashPurchaseForm from '../../Purchase-Module/cash-purchase/form';
import PurchaseInvoiceForm from '../../Purchase-Module/invoice/form';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import baseapi from '../../../api/baseapi';
import utils from '../../../utils/common';

export default function APPurchaseListing() {

    const apiURL = `/api/APPurchase`;
    const [dropDownList, setDropDownList] = useState({
        forexList: []
    });
    const [currentEditID, setCurrentEditID] = useState(null);
    const [currentEditID2, setCurrentEditID2] = useState(null);
    const [currentEditID3, setCurrentEditID3] = useState(null);

    useEffect(() => {
        Promise.allSettled([forexOptions.store.load()]).then((lists) => {
            setDropDownList({
                forexList: lists[0].value.data,
            })
        });
    }, []);

    function handleEdit(e){
        const rowData = e.row.data;
        baseapi.httpget("/api/APPurchase/CheckPurchaseCopied", { docNo: rowData.DocumentNo }).then((response) => {
			const { data } = response;
			if(!utils.isNullOrEmpty(data)){
                if(data.isCopied){
                    if(data.type === "Cash Purchase"){
                        setCurrentEditID2(data.id);
                    }
                    else{
                        setCurrentEditID3(data.id);
                    }
                }
                else{
                    setCurrentEditID(rowData.id);
                }
            }
		});
    }

    const closePopup = (id) => {
        setCurrentEditID(id);
        setCurrentEditID2(id);
        setCurrentEditID3(id);
    }
    
    return <Container>
        <div id='normal-listing'>
        <Listing
            className="ap-listing-datagrid"
            apiURL={apiURL}
            listingURL="/api/APPurchase/list"
            sortColumn={"DocumentNo"}
            listingTitle={"AP Purchase"}
            storageName={"APPurchaseListingColumnWidth"}
            onAddClick={setCurrentEditID}
            handleEdit={handleEdit}
        >
            <Column dataField="DocumentNo" />
            <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"}/>
            <Column dataField="SupplierCode" />
            <Column dataField="SupplierName" />
            <Column dataField="ForexID" caption={"Forex"} >
                <Lookup dataSource={dropDownList.forexList} valueExpr="id" displayExpr={"code"} allowClearing={true} searchEnabled={true} />
            </Column>
            <Column dataField="MainForexAmount" caption={"Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="MainLocalAmount" caption={"Local Amount"} format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="OutstandingAmount" caption={"Outstanding Amount"} format={{ type: "fixedPoint", precision: 2}} />
        </Listing>
        </div>

        <APPurchaseForm 
            formID={currentEditID}
            closePopup={closePopup}
            title={"A/P Invoice"}
            apiURL={apiURL}
        />
        
        <CashPurchaseForm 
            formID={currentEditID2} 
            closePopup={closePopup} 
            title={"Cash Purchase"}
            apiURL={`/api/PurchaseCashPurchase`} 
        />

        <PurchaseInvoiceForm 
            formID={currentEditID3} 
            closePopup={closePopup} 
            title={"Purchase Invoice"}
            apiURL={`/api/PurchaseInvoice`} 
        />

    </Container>;
}