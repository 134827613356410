import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import 'devextreme/dist/css/dx.greenmist.css';
import './themes/generated/theme.additional.css';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import './styles/default.scss';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import UnauthenticatedContent from './UnauthenticatedContent';
import UnauthorizedContent from './UnauthorizedContent';
import { loading as LoadingScreen, CustomDone, closeLoading } from './utils/common';
import baseapi from './api/baseapi';
import SessionTimeout from './sessionTimeOut';
import swal from 'sweetalert2';
import { removeFromLS } from './utils/localstorage';
import CompanySelection from './components/company-selection/CompanySelection';
import { getUser } from './api/auth';

const SESSION_EXTENDED_KEY = 'sessionExtended';
const LAST_ACTIVITY_KEY = 'lastActivityTime';
const SESSION_TIMEOUT_KEY = 'sessionTimeout';

function App() {
  const { user, loading, companySelection } = useAuth();

  // Online state
  const [firstTimeLoad, setFirstTimeLoad] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const networkStatus = useRef(null);
  const location = useLocation();
  const alertHandledRef = useRef(false);
  const [sessionTimedOut, setSessionTimedOut] = useState(false);
  const idleTimerRef = useRef(null);
  let timerInterval;


  const resetIdleTimer = () => {

    if (!user) return;
    const timeout = user.IdleTime || 7200000;

    if (idleTimerRef.current) {
      clearTimeout(idleTimerRef.current);
    }

    idleTimerRef.current = setTimeout(() => {
      const swalInstance = swal.fire({
        title: "Session Expiring Soon",
        html: `Your session will expire in <b></b> seconds.<br><br>Click 'Extend Session' to continue.`,
        icon: "warning",
        confirmButtonText: "Extend My Session",
        allowOutsideClick: false,
        timer: 5000,
        timerProgressBar: true,
        didOpen: () => {
          let timerInterval; // Declare timerInterval variable

          const updateTimer = () => {
            const content = swal.getHtmlContainer();
            if (content) {
              const timer = content.querySelector('.swal2-timer-progress-bar');
              if (timer) {
                const remainingTime = Math.ceil(swal.getTimerLeft() / 1000);
                timer.textContent = `${remainingTime}s`;
              }
              const font = content.querySelector("b");
              if (font) {
                const remainingTime = Math.ceil(swal.getTimerLeft() / 1000);
                font.textContent = `${remainingTime}s`;
              }
            }
          };

          updateTimer(); // Initial update

          timerInterval = setInterval(updateTimer, 100);

          // Handle Extend Session button click
          const extendButton = swal.getConfirmButton();
          extendButton.addEventListener('click', () => {
            clearInterval(timerInterval); // Stop the timer interval
            resetIdleTimer(); // Reset timer upon extension
            swal.close(); // Close the Swal alert
            localStorage.setItem(SESSION_EXTENDED_KEY, Date.now()); // Update session extension flag
          });
        },
        willClose: () => {
          clearInterval(timerInterval); // Clear interval when Swal is closed
        }
      }).then((result) => {
        if (result.dismiss === swal.DismissReason.timer) {
          // Session timed out
          clearSession();
          setSessionTimedOut(true);

        }
      });
    }, timeout - 60000); // Display Swal alert when there are 60000 milliseconds (60 seconds) left until timeout

    localStorage.setItem(LAST_ACTIVITY_KEY, Date.now());
  };

  const clearSession = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('data'));

      if (user?.LoginID && user?.CompanyID) {
        const { LoginID, CompanyID } = user;
        await baseapi.httppost('/oauth/Logout', { username: LoginID, companyID: CompanyID });
      }
    } catch (error) {
      console.error("Error during sign out:", error);
    } finally {
      baseapi.setAuthorization(null);
      removeFromLS('Displayname');
      removeFromLS('Role');
    }
  };


  useEffect(() => {
    if (user) {
      const events = ['mousemove', 'keydown', 'scroll', 'touchstart'];
      const eventHandler = () => resetIdleTimer();

      events.forEach(event => {
        window.addEventListener(event, eventHandler);
      });

      resetIdleTimer();

      return () => {
        if (idleTimerRef.current) {
          clearTimeout(idleTimerRef.current);
        }
        events.forEach(event => {
          window.removeEventListener(event, eventHandler);
        });
      };
    }
  }, [user, resetIdleTimer]);
  useEffect(() => {
    if (user) {
      const checkSubscriptionStatus = () => {
        const subscriptionEndDate = new Date(user.SubscriptionEndDate);
        const currentDate = new Date();

        if (subscriptionEndDate < currentDate && !alertHandledRef.current) {
  
          if (user.IsSubscriptionExpired === true || user.IsFreeTrialExpired === true) {
           
          } else {
            let timerInterval;
            // Display expired subscription countdown
            swal.fire({
              title: "Subscription Expired!",
              html: `Your subscription will expire in <b>3</b> seconds.`,
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: false, // Disable confirm button
              allowOutsideClick: false,
              didOpen: () => {
                const timer = swal.getPopup().querySelector("b");
                timerInterval = setInterval(() => {
                  const seconds = Math.ceil(swal.getTimerLeft() / 1000); // Convert milliseconds to seconds
                  timer.textContent = `${seconds}`;
                }, 1000); // Update every second
              },
              willClose: () => {
                clearInterval(timerInterval);
                window.location.reload();
                localStorage.setItem(SESSION_TIMEOUT_KEY, Date.now());
              }
            });
          }

          alertHandledRef.current = true; // Mark the alert as handled
        }
      };

      // Run the subscription status check on page load and when user data changes
      checkSubscriptionStatus();

      // Optionally: you can reset the check on user interactions, if needed
      const events = ['mousemove', 'keydown', 'scroll', 'touchstart'];
      const eventHandler = () => checkSubscriptionStatus();

      events.forEach(event => {
        window.addEventListener(event, eventHandler);
      });

      // Clean up the event listeners on component unmount
      return () => {
        events.forEach(event => {
          window.removeEventListener(event, eventHandler);
        });
      };
    }
  }, [user]);
  useEffect(() => {
    const handleStorageEvent = (event) => {
      if (event.key === LAST_ACTIVITY_KEY) {
        const lastActivity = parseInt(localStorage.getItem(LAST_ACTIVITY_KEY), 10);
        const currentTime = Date.now();
        // Reset idle timer if last activity was less than the timeout duration ago
        if (currentTime - lastActivity < (user?.IdleTime || 7200000)) {
          resetIdleTimer();
        }
      } else if (event.key === SESSION_EXTENDED_KEY) {
        swal.close(); // Close Swal alert if session is extended in another tab
      } else if (event.key === SESSION_TIMEOUT_KEY ) {
        window.location.reload(); // Refresh all tabs on session timeout
      }
    };

    window.addEventListener('storage', handleStorageEvent);

    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, [resetIdleTimer]);

  useEffect(() => {
    if (!firstTimeLoad) {
      // Update network status
      const handleStatusChange = () => {
        setIsOnline(navigator.onLine);

        if (navigator.onLine === false) {
          networkStatus.current = false;
        }
      };

      // Listen to the online status
      window.addEventListener('online', handleStatusChange);

      // Listen to the offline status
      window.addEventListener('offline', handleStatusChange);
      setFirstTimeLoad(true);
    }
  }, [firstTimeLoad]);

  useEffect(() => {
    if (isOnline) {
      if (networkStatus.current === false) {
        CustomDone("Network Reconnected", 1000);
        networkStatus.current = true;
      }
    } else {
      LoadingScreen("Looks like you lost your connection. Please check it and try again.", "Connection Lost");
    }
  }, [isOnline]);

  if (loading) {
    LoadingScreen();
    return <LoadPanel visible={false} showIndicator={true} shading={true} />;
  } else {
   closeLoading();
  }

  if (sessionTimedOut) {
    return (<SessionTimeout />);
  }


  if (companySelection) {
    return (<CompanySelection />);
  }
  if (user) {

    let userAccessRights = localStorage.getItem('userAccessRights');
    let userRight = null;

    try {
      userRight = userAccessRights ? JSON.parse(userAccessRights) : null;
    } catch (e) {
      console.error('Failed to parse userAccessRights from localStorage', e);
    }

    if (userRight) {
      const accessRight = userRight.find(ur => ur.URL === location.pathname);
      if (accessRight && !accessRight.DirectAccess) {
        return <UnauthorizedContent />;
      }
    }

    return <Content />;
  }

  return <UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
}
