import React from "react";
import DataGrid, { Column, Grouping, GroupPanel, Pager, Paging, SearchPanel } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { Popup } from "devextreme-react/popup";
import { useState, useEffect } from "react";
import { Toast } from "devextreme-react/toast";
import SelectBox from "devextreme-react/select-box";
import { TagBox, TextBox } from "devextreme-react";
import { LoadPanel } from "devextreme-react/load-panel";
import { Button } from "devextreme-react/button";
import DateBox from "devextreme-react/date-box";
import util from "../../utils/common";
import baseapi from "../../api/baseapi";

export default function RestoreReport() {
	const headers = {
		"Content-Type": "application/json"
	};
	const path = "/api/Dashboard";
	const authPath = "/api/Login";
	const currentToken = localStorage.getItem("Authorization").substring(7);
	const [selectedFilter, setSelectedFilter] = useState("");
	const [specificRecords, setSpecificRecords] = useState([]);
	const [documentViewerParameter, setDocumentViewerParameter] = useState([]);
	const [documentViewerValue, setDocumentViewerValue] = useState([]);
	const [rangeRecords, setRangeRecords] = useState([]);
	const [ViewerAccessURL, setViewerAccessURL] = useState("");
	const [collapsed, setCollapsed] = useState(false);
	const [documentViewerPopup, setDocumentViewerPopup] = useState(false);
	const [displayLoadPanel, setDisplayLoadPanel] = useState(false);
	const [reportDesignerParams, setReportDesignerParams] = useState([]);
	const [gridDataSource, setGridDataSource] = useState();
	const [toastConfig, setToastConfig] = useState({
		isVisible: false,
		type: "info",
		message: ""
	});
	const [companyFilter, setCompanyFilter] = useState([]);

	function handleErrors(response) {
		if (!response.ok) {
			throw Error(response.statusText);
		}
		return response;
	}

	const pageSizes = [10, 25, 50, 100];

	const cellRender = (col) => {
		return (
			<span className='action-row'>
				<Button
					className='action-btn'
					width={90}
					text='Designer'
					type='default'
					icon='background'
					stylingMode='outlined'
					onClick={(e) => {
						window.open(`${col.data.DesignerAccessURL}`, "_blank");
					}}
				/>

				<Button
					className='action-btn'
					width={90}
					text='Viewer'
					type='default'
					icon='file'
					stylingMode='outlined'
					onClick={(e) => {
						showDocumentViewer(col.data.ID, col.data.ViewerAccessURL);
					}}
				/>

				<Button className='action-btn' width={90} text='Restore' type='default' icon='folder' stylingMode='outlined' onClick={(e) => handleRestoreReport(col.data.ID)} />
			</span>
		);
	};

	const onContentReady = (e) => {
		if (collapsed) {
			e.component.expandRow(["EnviroCare"]);
			setCollapsed(true);
		}
	};

	const showDocumentViewer = (reportID, viewerLink = "") => {
		setDocumentViewerPopup(true);
		setViewerAccessURL(viewerLink);
		//Clear the previous layout
		setReportDesignerParams([]);
		setDocumentViewerParameter([]);

		const paramValueArray = [];

		baseapi
			.httppost(util.ReportURL + path + "/CheckReportParameters?token=" + currentToken + "&reportID=" + reportID)
			.then((result) => {
				result = result.data;
				const paramSettingArray = [];

				for (var i = 0; i < result.length; i++) {
					result[i].randomID = crypto.randomUUID();
					paramSettingArray.push(result[i]);
					paramValueArray.push({
						RandomID: result[i].randomID,
						ParameterID: result[i].parameterID,
						ParameterName: result[i].parameterName,
						ParameterValue: ""
					});
				}

				setDocumentViewerParameter(paramSettingArray);

				baseapi
					.httpget(util.ReportURL + "/api/ReportDesignerParams/GetReportParamsDetailByReportID?id=" + reportID + "&token=" + currentToken)
					.then((result) => {
						result = result.data;
						const existingParamArr = [];
						for (var i = 0; i < result.length; i++) {
							const existingParam = {
								RandomID: crypto.randomUUID(),
								ID: result[i].ID,
								ParameterName: result[i].ParameterName,
								ParameterType: result[i].ParameterType,
								ParameterDescription: result[i].ParameterDescription,
								ParameterValue: result[i].GroupByValue
							};

							existingParamArr.push(existingParam);
							paramValueArray.push(existingParam);
						}

						setReportDesignerParams(existingParamArr);
						setDocumentViewerValue(paramValueArray);
					})
					.catch((error) => console.log("error", error));
			})
			.catch((error) => console.log("error", error));
	};

	const specificRecord = (tableName, columnName) => {
		const params = "?token=" + currentToken + "&tableName=" + tableName + "&columnName=" + columnName;

		baseapi
			.httppost(util.ReportURL + path + "/getRecordsByTableColumns" + params)
			.then((result) => {
				result = result.data;
				const specificArr = [];

				for (var i = 0; i < result.length; i++) {
					specificArr.push({ value: result[i], label: result[i] });
				}

				setSpecificRecords(specificArr);
			})
			.catch((error) => console.log("error", error));
	};

	const DocumentViewerValueText = (id, value) => {
		const copyArr = [...documentViewerValue];
		copyArr.find((c) => c.RandomID === id).ParameterValue = value;
		setDocumentViewerValue(copyArr);
	};

	const handleDocumentViewerValue = (id, select, range = "") => {
		// console.log("Select length: " + select.length)
		const copyArr = [...documentViewerValue];

		if (select.length !== undefined) {
			if (select.length === 0) {
				copyArr.find((c) => c.RandomID === id).ParameterValue = "";
			} else {
				for (var i = 0; i < select.length; i++) {
					if (i === 0) {
						copyArr.find((c) => c.RandomID === id).ParameterValue = select[i];
					} else {
						copyArr.find((c) => c.RandomID === id).ParameterValue += "," + select[i];
					}
				}
			}
		} else {
			if (range === "") {
				copyArr.find((c) => c.RandomID === id).ParameterValue = select.value;
			} else if (range === "From") {
				const currentValue = copyArr.find((c) => c.RandomID === id).ParameterValue;

				if (currentValue.length === 0) {
					copyArr.find((c) => c.RandomID === id).ParameterValue = select.value + ",";
				} else {
					const rangeArr = currentValue.split(",");

					if (rangeArr.length === 2) {
						copyArr.find((c) => c.RandomID === id).ParameterValue = select.value + "," + rangeArr[1];
					} else {
						copyArr.find((c) => c.RandomID === id).ParameterValue = select.value + ",";
					}
				}
			} else if (range === "To") {
				const currentValue = copyArr.find((c) => c.RandomID === id).ParameterValue;

				if (currentValue.length === 0) {
					copyArr.find((c) => c.RandomID === id).ParameterValue = "," + select.value;
				} else {
					const rangeArr = currentValue.split(",");

					if (rangeArr.length === 2) {
						copyArr.find((c) => c.RandomID === id).ParameterValue = rangeArr[0] + "," + select.value;
					} else {
						copyArr.find((c) => c.RandomID === id).ParameterValue = "," + select.value;
					}
				}
			}
		}

		setDocumentViewerValue(copyArr);
	};

	const DocumentViewerWithoutParameter = () => {
		window.open(ViewerAccessURL, "_blank");
	};

	const confirmReportParameters = () => {
		var documentViewerLink = ViewerAccessURL;

		for (var i = 0; i < documentViewerValue.length; i++) {
			documentViewerLink += "&" + documentViewerValue[i].ParameterName + "=" + documentViewerValue[i].ParameterValue + "";
		}

		window.open(documentViewerLink, "_blank");
	};

	const selectFilter = (selected) => {
		setSelectedFilter(selected.value);
		setGridDataSource({
			store: new CustomStore({
				key: "ID",
				loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
				load: () => {
					return baseapi
						.httpget(`${util.ReportURL}/api/Report/GetDeletedReportsByCompanyID?companyID=${selected.value}&accessToken=${currentToken}`)
						.then((response) => response.data)
						.catch(() => {
							throw "Network error";
						});
				}
			})
		});
	};

	const checkAvailableCompanies = () => {
		const params = "?token=" + currentToken;

		baseapi
			.httppost(util.ReportURL + path + "/getAvailableCompanies" + params)
			.then((companiesReceived) => {
				const companies = [];

				for (const [key, value] of Object.entries(companiesReceived.data)) {
					companies.push({ value: key, label: value });
				}

				//Management
				companies.push({ value: 0, label: "Management" });

				setCompanyFilter(companies);
			})
			.catch((error) => console.log("error", error));
	};

	const handleRestoreReport = (reportID) => {
		if (window.confirm("Are you sure you want to restore this report?") === true) {
			setDisplayLoadPanel(true);
			const params = "?reportID=" + reportID + "&accessToken=" + currentToken;

			baseapi.httppost(util.ReportURL + path + "/restoreReport" + params).then((result) => {
				// console.log(result);
				if (result.data === "Updated") {
					setGridDataSource({
						store: new CustomStore({
							key: "ID",
							loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
							load: () => {
								return baseapi
									.httpget(`${util.ReportURL}/api/Report/GetDeletedReportsByCompanyID?companyID=${selectedFilter}&accessToken=${currentToken}`)
									.then((response) => response.data)
									.catch(() => {
										throw "Network error";
									});
							}
						})
					});

					setDisplayLoadPanel(false);
					setToastConfig({
						type: "success",
						isVisible: true,
						message: "Report restored successfully!"
					});
				} else if (result === "Duplicate Name") {
					setDisplayLoadPanel(false);
					setToastConfig({
						type: "error",
						isVisible: true,
						message: "Duplicate Report name found! Report Restoration failed."
					});
				} else {
					setDisplayLoadPanel(false);
					setToastConfig({
						type: "error",
						isVisible: true,
						message: "Something went wrong!"
					});
				}
			});
		}
	};

	const rangeRecord = (tableName, columnName) => {
		const params = "?token=" + currentToken + "&tableName=" + tableName + "&columnName=" + columnName;

		baseapi
			.httppost(util.ReportURL + path + "/getRecordsByTableColumns" + params)
			.then((result) => {
				result = result.data;
				const rangeArr = [];

				for (var i = 0; i < result.length; i++) {
					rangeArr.push({ value: result[i], label: result[i] });
				}

				setRangeRecords(rangeArr);
			})
			.catch((error) => console.log("error", error));
	};

	const formatDateTimeValue = (value) => {
		const date = new Date(value);
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const day = date.getDate();
		// const hour = value.substring(11, 13);
		// const minute = value.substring(14, 16);
		// const unit = hour >= 12 ? "PM" : "AM";
		// const formattedHour = hour >= 12 ? hour - 12 : hour;

		// return `${day}/${month}/${year} ${formattedHour}:${minute}:00 ${unit}`;
		return `${day}/${month}/${year}`;
	};

	useEffect(() => {
		if (companyFilter.length === 0) {
			checkAvailableCompanies();
		}
	});

	return (
		<React.Fragment>
			<LoadPanel shadingColor='rgba(0,0,0,0.4)' visible={displayLoadPanel} showIndicator={true} shading={true} showPane={true} hideOnOutsideClick={false} />

			<div>
				<div className={"content-block"}>
					<div className={"dx-card responsive-paddings"}>
						<h3>Restore Report</h3>

						<div className='mb-3'>
							Filter:
							<SelectBox
								displayExpr={"label"}
								valueExpr={"value"}
								items={companyFilter}
								hint={"Select Company"}
								elementAttr={{ class: "mt-1" }}
								searchEnabled={true}
								onSelectionChanged={(e) => {
									selectFilter(e.selectedItem);
								}}
								width={300}
							/>
						</div>

						<DataGrid id='gridContainer' dataSource={gridDataSource} allowColumnReordering={true} rowAlternationEnabled={true} showBorders={true} onContentReady={onContentReady}>
							<GroupPanel visible={true} />
							<SearchPanel visible={true} highlightCaseSensitive={true} />
							<Grouping autoExpandAll={false} />

							<Column dataField='ReportName' caption='Report Name' alignment='left' width={"auto"} />

							<Column dataField='IsSubReport' caption='Is SubReport' alignment='left' />

							<Column dataField='CompanyID' caption='Company ID' alignment='right' />

							<Column dataField='CreatedBy' caption='Created By' dataType='number' alignment='right' allowGrouping={false} cssClass='bullet' />
							<Column dataField='CreatedDate' caption='Created Date' dataType='datetime' alignment='right' width={"auto"} />

							<Column dataField='ModifiedBy' caption='Deleted By' dataType='number' alignment='right' allowGrouping={false} cssClass='bullet' />

							<Column dataField='DeletedDate' caption='Deleted Date' dataType='datetime' alignment='right' width={"auto"} />
							<Column dataField='ID' type='buttons' caption='Action' cellRender={cellRender} width={"auto"}></Column>

							<Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} />
							<Paging defaultPageSize={10} />
						</DataGrid>
					</div>
				</div>

				{/* Document Viewer Popup */}
				<Popup
					visible={documentViewerPopup}
					onHiding={(e) => setDocumentViewerPopup(false)}
					dragEnabled={false}
					hideOnOutsideClick={false}
					showCloseButton={true}
					showTitle={true}
					title='Document Viewer'
					container='.dx-viewport'
					height={"auto"}
					width={800}>
					<div className='popup-scroll'>
						{documentViewerParameter.length !== 0 && <div className='text-left mb-3 document-viewer-title'>SQL Parameters</div>}

						{documentViewerParameter.length === 0 && reportDesignerParams.length === 0 ? (
							<div className='mb-3 ml-2 text-danger error-text'>No Parameter is added to the report yet</div>
						) : (
							documentViewerParameter.map((parameter) => {
								const tableName = parameter.lookupTableName;
								const columnName = parameter.lookupKeyCode;

								return (
									<div className='form-group row' key={parameter.randomID}>
										<label className='col-sm-2 col-form-label'>{parameter.parameterDescription}: </label>
										<div className='col-sm-10'>
											{parameter.parameterType === "Text" && (
												<span>
													<TextBox
														elementAttr={{ name: "report-parameter-input" }}
														onValueChanged={(e) => {
															DocumentViewerValueText(parameter.randomID, e.value);
														}}
													/>
												</span>
											)}

											{parameter.parameterType === "Date" && (
												<span>
													<DateBox
														dateSerializationFormat= 'yyyy-MM-dd'
														elementAttr={{ name: "report-parameter-input" }}
														onValueChanged={(e) => {
															DocumentViewerValueText(parameter.randomID, formatDateTimeValue(e.value));
														}}
													/>
												</span>
											)}

											{parameter.parameterType === "Look Up" && parameter.lookupType === "Range" && (
												<div>
													<div className=''>
														<label className='ml-1 mt-2 mb-0'>From</label>
														<SelectBox
															displayExpr={"label"}
															valueExpr={"value"}
															items={rangeRecords}
															hint={"From Records"}
															elementAttr={{ class: "mt-1" }}
															searchEnabled={true}
															onSelectionChanged={(e) => {
																handleDocumentViewerValue(parameter.randomID, e.selectedItem, "From");
															}}
															onInitialized={(e) => {
																rangeRecord(tableName, columnName);
															}}
														/>
													</div>

													<div className=''>
														<label className='ml-1 mt-1 mb-0'>To</label>
														<SelectBox
															displayExpr={"label"}
															valueExpr={"value"}
															items={rangeRecords}
															hint={"To Records"}
															elementAttr={{ class: "mt-1" }}
															searchEnabled={true}
															onSelectionChanged={(e) => {
																handleDocumentViewerValue(parameter.randomID, e.selectedItem, "To");
															}}
														/>
													</div>

													<input type='hidden' name='parameter-id-range' value={parameter.parameterID} />
												</div>
											)}

											{parameter.parameterType === "Look Up" && parameter.lookupType === "Specific" && (
												<div className=''>
													<TagBox
														items={specificRecords}
														displayExpr={"label"}
														valueExpr={"value"}
														searchEnabled={true}
														isClearable={true}
														hint={"Specific Record(s)"}
														elementAttr={{ class: "mt-1" }}
														onValueChanged={(e) => handleDocumentViewerValue(parameter.randomID, e.value)}
														onInitialized={(e) => {
															specificRecord(tableName, columnName);
														}}
													/>
													<input type='hidden' name='parameter-id-specific' value={parameter.parameterID} />
												</div>
											)}
										</div>
									</div>
								);
							})
						)}

						{reportDesignerParams.length !== 0 && <div className='text-left mb-3 document-viewer-title'>Report Designer Parameters</div>}

						{reportDesignerParams.map((designerParam, index) => {
							return (
								<div className='form-group row' key={designerParam.randomID}>
									<label className='col-sm-2 col-form-label'>{designerParam.ParameterDescription}: </label>
									<div className='col-sm-10'>
										{(designerParam.ParameterType === "Text" || designerParam.ParameterType === "String") && (
											<span>
												<TextBox
													elementAttr={{ name: "GroupByValue" }}
													onValueChanged={(e) => {
														DocumentViewerValueText(designerParam.RandomID, e.value);
													}}
												/>
											</span>
										)}

										{designerParam.ParameterType === "DateTime" && (
											<span>
												<DateBox
													elementAttr={{ name: "GroupByValue" }}
													dateSerializationFormat= 'yyyy-MM-dd'
													onValueChanged={(e) => {
														DocumentViewerValueText(designerParam.RandomID, formatDateTimeValue(e.value));
													}}
												/>
											</span>
										)}
									</div>
								</div>
							);
						})}
					</div>

					<div className='modal-footer'>
						{/* {JSON.stringify(documentViewerValue)} */}
						{/* {JSON.stringify(reportDesignerParams)} */}
						<input type='hidden' id='document-viewer-report-id' />
						<input type='hidden' id='document-viewer-link' />

						<Button width={"auto"} type='default' text='View Without Parameters' stylingMode='contained' className='document-viewer-btn' onClick={DocumentViewerWithoutParameter} />
						<Button width={"auto"} type='success' text='Confirm Parameter(s)' stylingMode='contained' onClick={confirmReportParameters} />
					</div>
				</Popup>
			</div>

			<Toast
				visible={toastConfig.isVisible}
				message={toastConfig.message}
				type={toastConfig.type}
				onHiding={(e) => {
					setToastConfig({
						...toastConfig,
						isVisible: false
					});
				}}
				displayTime={3000}
			/>
		</React.Fragment>
	);
}
