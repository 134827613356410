import { Routes, Route, Navigate, Link} from 'react-router-dom';
import { SingleCard } from './layouts';
import { LoginForm, ResetPasswordForm, ChangePasswordForm, CreateAccountForm } from './components';
import { useState, useEffect } from 'react';
import RegistrationForm from './pages/Workshop-Module/registration/form';
import RegisterForm from './components/register-form/RegisterForm';
import ResetPassword from './components/reset-password-invited/ResetPassword';
import RegisterTest from './pages/Registration/Test';
import VerifyEmail from './components/verify-email-form/VerifyEmail';


export default function UnauthenticatedContent() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Routes>
      <Route
        path='/login'
        element={
          <>
            <img src={"/images/nav-logo.png"} alt={"Greenplus Accounting"} width={110} id='nav-logo' style={{ paddingTop: '15px' }} />

            <div className="auth-container">
              <div className="description">
                <span className='title'>
                  Greenplus
                  <p className='subtitle'>
                    Streamlining Efficiency, Perfecting Precision.
                  </p>
                </span>
              </div>

              <div className='auth-section-container'>
                <div className="auth-content">
                  <div className='auth-form'>
                    <span className="authcontent-title">Login</span>
                    <br />
                    <p className="authcontent-subtitle" style={{ marginTop: '15px',marginBottom: '30px' }}>
                    {windowWidth > 500 ? 'And start using Greenplus Accounting to boost your business!' : 'Use Greenplus Accounting To boost your business!'}
                    </p>
                    <LoginForm />
                  </div>
                </div>

                <div className="auth-footer">
                  <label htmlFor="rememberMe" className="auth-footer-label">Don’t have an account?</label>
                  <Link to="/register">Register</Link>
                </div>
              </div>
            </div>

            <img className="wave" src="images/wave.svg" />
          </>
        }
      />
      <Route path='/register' element={<RegisterForm />} />
      <Route path='/resetpassword' element={<ResetPassword />} />
      <Route path='/verify-email' element={<VerifyEmail />} />
      <Route path='/register-test' element={<RegisterTest />} />
      <Route path='*' element={<Navigate to={'/login'} />} />
    </Routes>
  );
}
