import React, { useEffect, useRef, useState } from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';
import { Column, Editing, Popup, Form, Item, RequiredRule, Button as ButtonColumn, Lookup } from 'devextreme-react/data-grid';
import utils from '../../utils/common';
import { transactionTypeOptions } from '../../utils/lookupstore';

export default function DocumentNumberSetting() {

    const StorageName = "SettingDocumentSettingListing";
    const ButtonColumnWidth = utils.getColumnWidth("buttons", StorageName) === null ? 80 : utils.getColumnWidth("buttons", StorageName);
    const ButtonVisibleIndex = utils.getColumnVisibleIndex("buttons", StorageName) === null ? 0 : utils.getColumnVisibleIndex("buttons", StorageName);
    const dropDownRead = useRef(false);
    const [transactionTypeList, setTransactionTypeList] = useState([]);

    useEffect(() => {

        if (dropDownRead.current !== true) {

            transactionTypeOptions.store.load().then((list) => {
                setTransactionTypeList(list.data);
            });
        }

    }, [dropDownRead]);

    return <Container>
        <div id="normal-listing">
            <SettingListing
                listingTitle="Document Number Setting"
                apiURL="/api/DocumentSetting"
                listingURL="/api/DocumentSetting/list"
                sortColumn={"Code"}
                dateFilter={false}
                storageName={"SettingDocumentSettingListing"}
                addButton={false}
                customizedPopup={true}
                applyFixedButtons={true}
            >
                <Column type="buttons" width={ButtonColumnWidth} visibleIndex={ButtonVisibleIndex} fixed={true}>
                    <ButtonColumn name="edit" />
                    <ButtonColumn name="delete" />
                </Column>

                <Column dataField={"Code"} />
                <Column dataField={"Name"} />
                <Column dataField={"TransactionTypeID"}>
                    <Lookup dataSource={transactionTypeList} valueExpr={"id"} displayExpr={"name"} />
                </Column>
                <Column dataField={"RunningNumber"} />
                <Column dataField={"Prefix"} />

                <Editing useIcons={true}
                    allowAdding={true}
                    allowUpdating={true}
                    allowDeleting={true}
                    mode="popup"
                >
                    <Popup title="Document Number Setting" showTitle={true} width="auto" height="auto" dragEnabled={false} />
                    <Form>
                        <Item dataField="Code" editorOptions={{ disabled: true }}>
                            <RequiredRule message={"Code is required!"} />
                            {/* <AsyncRule message={"This code is already taken!"} validationCallback={(e) => utils.codeAsyncValidation("/api/Forex", e.value)}/> */}
                        </Item>
                        <Item dataField="Name" editorOptions={{ disabled: true }}>
                            <RequiredRule message={"Name is required!"} />
                        </Item>
                        <Item dataField="TransactionTypeID" editorOptions={{ disabled: true }}>
                            <RequiredRule message={"Transaction Type is required!"} />
                        </Item>
                        <Item dataField="RunningNumber" >
                            <RequiredRule message={"Running Number is required!"} />
                        </Item>
                        <Item dataField="Prefix" >
                            <RequiredRule message={"Prefix is required!"} />
                        </Item>
                    </Form>
                </Editing>
            </SettingListing>
        </div>
    </Container>

}

