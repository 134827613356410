import { useState, useMemo, useCallback, useEffect, useRef } from "react";
import Container from "../../../components/container";
import TabPanel, { Item } from "devextreme-react/tab-panel";
import utils from "../../../utils/common";
import DataGrid, { Column, Editing } from "devextreme-react/data-grid";
import { Button } from "devextreme-react";
import PathIndicator from "../../../components/path-indicator/PathIndicator";
import { loading, closeLoading } from "../../../utils/common";
import baseapi from "../../../api/baseapi";
import CustomizedGridLookup from "../../../components/lookup/GridLookup";
import ErrorPopUpForm from "../../../components/popup-form/ErrorPopupForm";

export default function DefaultSettingListing() {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);
	const [listingDataSource, setListingDataSource] = useState([]);
	const tabNames = ["Default Account", "Default Journal"];
	const [accountEdit, setAccountEdit] = useState(false);
	const [journalEdit, setJournalEdit] = useState(false);
	const [cancelVisible, setCancelVisible] = useState(false);
	const [editVisible, setEditVisible] = useState(true);
	const [disableSave, setDisableSave] = useState(true);
	const [type, setType] = useState(0);
	const [details, setDetails] = useState([]);
	const [chartAccountList, setChartAccountList] = useState([]);
	const [journalTypesList, setJournalTypesList] = useState([]);
	const [gridLookupOpen, setGridLookupOpen] = useState(false);
	const popupMessageRef = useRef(null);

	function getBackgroundColorClass() {
		return btnBackgroundColorClass["gl-listing-datagrid"];
	}

	const onTabSelectionChanged = useCallback((e) => {
		// console.log(tabNames.indexOf(e.addedItems[0].title));
		// console.log(e.addedItems[0].title);
		setDisableSave(true);
		setCancelVisible(false);
		setEditVisible(true);
		setDetails([]);
		var title = e.addedItems[0].title;
		setSelectedTabIndex(tabNames.indexOf(title));
		if (tabNames.indexOf(title) === 0) {
			setType(0);
		} else {
			setType(1);
		}
		handleCancel();
	}, []);

	const btnBackgroundColorClass = useMemo(() => {
		return {
			"gl-listing-datagrid": "gl-listing-btn1",
			"ar-listing-datagrid": "ar-listing-btn",
			"ap-listing-datagrid": "ap-listing-btn",
			"sales-listing-datagrid": "sales-listing-btn",
			"stock-listing-datagrid": "stock-listing-btn",
			"workshop-listing-datagrid": "workshop-listing-btn"
		};
	});

	const handleSubmit = () => {
		// console.log(details);
		var submitForm = null;
		if (type === 0) {
			loading("Updating Default Account Value(s)...");
			submitForm = {
				DefaultChartAccountModel: details,
				DefaultJournalTypeModel: null
			};
		} else {
			loading("Updating Default Journal Value(s)...");
			submitForm = {
				DefaultChartAccountModel: null,
				DefaultJournalTypeModel: details
			};
		}
		// console.log(submitForm);
		baseapi.httppost(utils.extendUrlVar(`/api/DefaultSetting/save?type=${type}`), submitForm).then((res) => {
			// console.log(res);
			const { data } = res;

			if (data.status) {
				setDisableSave(true);
				setCancelVisible(false);
				setEditVisible(true);
				handleCancel();
				refresh();
			}
			utils.displayPopupMessage(popupMessageRef, {
				visible: true,
				message: data.message,
				type: data.status ? "Success" : "Danger",
				action: data.action
			});
			closeLoading();
		});
	};

	const handleEdit = () => {
		setDisableSave(false);
		setCancelVisible(true);
		setEditVisible(false);
		if (selectedTabIndex === 0) {
			setAccountEdit(true);
		} else if (selectedTabIndex === 1) {
			setJournalEdit(true);
		}
	};

	const handleCancel = () => {
		setDetails([]);
		setDisableSave(true);
		setCancelVisible(false);
		setEditVisible(true);
		setAccountEdit(false);
		setJournalEdit(false);
	};

	const refresh = () => {
		baseapi
			.httpget(`/api/DefaultSetting/list?type=${type}`)
			.then((response) => {
				const { data } = response;
				if (data !== null) {
					// console.log(type);
					// console.log(data.items);
					// setID("");
					// console.log(data.items);
					setListingDataSource(data.items);
				}
			})
			.catch((e) => {
				throw e;
			});
	};

	useEffect(() => {
		baseapi
			.httpget(`/api/DefaultSetting/list?type=${type}`)
			.then((response) => {
				const { data } = response;
				if (data !== null) {
					// console.log(type);
					// console.log(data.items);
					// setID("");
					// console.log(data.items);
					setListingDataSource(data.items);
				}
			})
			.catch((e) => {
				throw e;
			});
	}, [selectedTabIndex]);

	const gridOnSelectionChanged = (e, columnName) => {
		setGridLookupOpen(false);
		const selectedItem = e.selectedItem;

		if (columnName === "ChartAccountID") {
			const copiedArr = [...chartAccountList];
			if (!utils.isInArray(chartAccountList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setChartAccountList(copiedArr);
		} else if (columnName === "JournalTypeID") {
			const copiedArr = [...journalTypesList];
			if (!utils.isInArray(journalTypesList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setJournalTypesList(copiedArr);
		}
	};

	return (
		<Container>
			<div className='listing-page-title-container'>
				<span className='datagrid-customized-title'>
					<PathIndicator />
				</span>
			</div>
			<TabPanel id='tabPanel' loop={false} animationEnabled={true} swipeEnabled={false} selectedIndex={selectedTabIndex} onSelectionChanged={onTabSelectionChanged}>
				<Item title='Default Account'>
					<DataGrid
						dataSource={listingDataSource}
						className={"gl-listing-datagrid DefaultAccount"}
						onOptionChanged={(e) => {
							// console.log(e.name);
							if (e.name === "editing" && e.fullName === "editing.changes") {
								// console.log(details);
								// console.log(e.value);
								setDetails((prevDetails) => {
									const newDetails = [...prevDetails]; // Use array instead of object
									for (var i = 0; i < e.value.length; i++) {
										const key = e.value[i].key;
										const data = e.value[i].data;
										console.log(e.value[i]);
										// console.log(newDetails);
										const existingDetailIndex = newDetails.findIndex((detail) => detail.ID === key.id);
										if (existingDetailIndex !== -1) {
											const existingDetail = newDetails[existingDetailIndex];
											existingDetail.ChartAccountID = data.ChartAccountID;
											existingDetail.Module = data.Module ?? key.Module;
											existingDetail.ModifiedDate = key.ModifiedDate;
										} else {
											var tempObj = {
												ID: key.id,
												ChartAccountID: data.ChartAccountID,
												Module: data.Module ?? key.Module,
												ModifiedDate: key.ModifiedDate
											};
											newDetails.push(tempObj);
										}
									}
									// console.log(newDetails);
									return newDetails;
								});
							}
						}}>
						<Column dataField={"Name"} caption={"Default Account"} editorOptions={{ readOnly: true }} />
						<Column
							visible={true}
							dataField='ChartAccountID'
							dataType='string'
							caption='Account'
							cellRender={(e) => {
								const data = e.data;
								var result = chartAccountList.find((c) => c.id === data.ChartAccountID)?.code;
								result = data.ChartAccountID === null ? null : result;
								return result === undefined ? data.ChartAccountCode : result;
							}}
							editCellComponent={(component) => (
								<CustomizedGridLookup
									{...component}
									opened={gridLookupOpen}
									className={"ar-listing-datagrid"}
									onSelectionChanged={(e) => gridOnSelectionChanged(e, "ChartAccountID")}
									dataSourceURL={"api/Utilities/GetGLChartAccounts"}
									onDataSourceChanged={setChartAccountList}
									sourceList={chartAccountList}>
									<Column dataField='code'></Column>
									<Column dataField='name' caption={"Description"} width={"300px"}></Column>
								</CustomizedGridLookup>
							)}></Column>
						<Editing allowUpdating={accountEdit} mode='batch' />
					</DataGrid>
				</Item>
				<Item title='Default Journal'>
					<DataGrid
						dataSource={listingDataSource}
						className={"gl-listing-datagrid DefaultJournal"}
						onOptionChanged={(e) => {
							// console.log(e.name);
							if (e.name === "editing" && e.fullName === "editing.changes") {
								// console.log(details);
								// console.log(e.value);
								setDetails((prevDetails) => {
									const newDetails = [...prevDetails]; // Use array instead of object
									for (var i = 0; i < e.value.length; i++) {
										// console.log(e.value[i]);
										const key = e.value[i].key;
										const data = e.value[i].data;
										const existingDetailIndex = newDetails.findIndex((detail) => detail.ID === key.id);
										if (existingDetailIndex !== -1) {
											const existingDetail = newDetails[existingDetailIndex];
											existingDetail.ID = key.id;
											existingDetail.JournalTypeID = data.JournalTypeID;
											existingDetail.Module = data.Module ?? key.Module;
											existingDetail.ModifiedDate = key.ModifiedDate;
										} else {
											var tempObj = {
												ID: key.id,
												JournalTypeID: data.JournalTypeID,
												Module: data.Module ?? key.Module,
												ModifiedDate: key.ModifiedDate
											};
											newDetails.push(tempObj);
										}
									}
									// console.log(newDetails);
									return newDetails;
								});
							}
						}}>
						<Column dataField={"Name"} caption={"Default Journal"} editorOptions={{ readOnly: true }} />
						{/* <Column dataField={"JournalTypeName"} caption={"Journal"} /> */}
						<Column
							visible={true}
							dataField='JournalTypeID'
							dataType='string'
							caption='Journal'
							cellRender={(e) => {
								const data = e.data;
								var result = journalTypesList.find((c) => c.id === data.JournalTypeID)?.name;
								result = data.JournalTypeID === null ? null : result;
								// console.log(data);
								return result === undefined ? data.JournalTypeName : result;
							}}
							editCellComponent={(component) => (
								<CustomizedGridLookup
									{...component}
									opened={gridLookupOpen}
									className={"ar-listing-datagrid"}
									onSelectionChanged={(e) => gridOnSelectionChanged(e, "JournalTypeID")}
									dataSourceURL={"api/Utilities/GetJournalTypes"}
									onDataSourceChanged={setJournalTypesList}
									sourceList={journalTypesList}
									displayExpr={"name"}>
									<Column dataField='code'></Column>
									<Column dataField='name' caption={"Description"} width={"300px"}></Column>
								</CustomizedGridLookup>
							)}></Column>
						<Editing allowUpdating={journalEdit} mode='batch' />
					</DataGrid>
				</Item>
			</TabPanel>

			<div style={{ textAlign: "right", marginTop: "20px" }}>
				<Button visible={cancelVisible} text='Cancel' type='danger' style={{ marginRight: "10px" }} onClick={() => handleCancel()} />
				<Button visible={editVisible} text='Edit' type='success' style={{ marginRight: "10px", backgroundColor: "#F68627" }} onClick={() => handleEdit()} />
				<Button text='Save' type='success' disabled={disableSave} onClick={() => handleSubmit()} />
			</div>

			<ErrorPopUpForm ref={popupMessageRef} />
		</Container>
	);
}
