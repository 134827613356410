import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate, useParams } from "react-router-dom";
import baseapi from '../../api/baseapi';
import DataGrid, { Column, Editing, Popup, Paging, Lookup } from 'devextreme-react/data-grid';
import Form, { Item, GroupItem, Label, SimpleItem, ButtonItem, ColCountByScreen } from 'devextreme-react/form';
import { uomOptions, categoryOptions, brandOptions, itemTypeOptions, materialOptions, stockGroupOptions, stockClassOptions } from '../../utils/lookupstore';
import utils, { done, processing } from '../../utils/common';
import Container from '../../components/container';

export default function StockEdit() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [form, setForm] = useState(null);
    const [dropDownList, setDropDownList] = useState({
        uomList: [], categoryList: [], brandList: [], itemTypeList: [], materialList: [], stockGroupList: [], stockClassList: []
    });
    const submitButtonOptions = {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: false,
        onClick: handleSubmit
    };
    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'normal',
        useSubmitBehavior: false,
        onClick: handleCancel
    };

    useEffect(() => {
        Promise.allSettled([uomOptions.store.load(), categoryOptions.store.load(), brandOptions.store.load(), itemTypeOptions.store.load(),
        materialOptions.store.load(), stockGroupOptions.store.load(), stockClassOptions.store.load()]).then((lists) => {
            setDropDownList({
                uomList: lists[0].value.data, categoryList: lists[1].value.data, brandList: lists[2].value.data, itemTypeList: lists[3].value.data,
                materialList: lists[4].value.data, stockGroupList: lists[5].value.data, stockClassList: lists[6].value.data
            })
        });
    }, [])

    function onInitialized(e) {
        if (id != 'new') {
            baseapi.httpget('/api/Stock', { id: (id || '') })
                .then(response => {
                    const { data } = response;
                    setForm(data);
                });
        }
    }

    function handleSubmit(e) {
        // console.log(form);

        if (e.validationGroup.validate().isValid) {
            processing();
            baseapi.httppost(utils.extendUrlVar('/api/Stock/save?', { id: (id || ''), del: false }), form)
                .then((response) => {
                    const { id } = response;
                    done().then(() => { onInitialized() });
                });
        }
    }

    function handleCancel() {
        navigate('/stocks');
    }

    return <Container>
        <Form
            id="form"
            formData={form} onInitialized={onInitialized}
        >
            <GroupItem colCount={2} caption="Stock">
                <Item dataField="Name" />
                <Item dataField="PriceI" />
                <Item dataField="Code" />
                <Item dataField="PriceII" />
                <Item dataField="BinNo" />
                <Item dataField="PriceIII" />
                <Item dataField="StandardCost" />
                <Item dataField="PriceIV" />
                <Item dataField="CurrentQuantity" />
                <Item dataField="ApplicationCode" />
                <Item
                    dataField="UOMID"
                    editorType="dxSelectBox"
                    editorOptions={utils.getSelectBoxOption(dropDownList.uomList)}
                >
                    <Label text="UOM" />
                </Item>
                <Item
                    dataField="ItemTypeID"
                    editorType="dxSelectBox"
                    editorOptions={utils.getSelectBoxOption(dropDownList.itemTypeList)}
                >
                    <Label text="Item Type" />
                </Item>
                <Item
                    dataField="CategoryID"
                    editorType="dxSelectBox"
                    editorOptions={utils.getSelectBoxOption(dropDownList.categoryList)}
                >
                    <Label text="Category" />
                </Item>
                <Item
                    dataField="MaterialID"
                    editorType="dxSelectBox"
                    editorOptions={utils.getSelectBoxOption(dropDownList.materialList)}
                >
                    <Label text="Material" />
                </Item>
                <Item
                    dataField="BrandID"
                    editorType="dxSelectBox"
                    editorOptions={utils.getSelectBoxOption(dropDownList.brandList)}
                >
                    <Label text="Brand" />
                </Item>
                <Item
                    dataField="StockGroupID"
                    editorType="dxSelectBox"
                    editorOptions={utils.getSelectBoxOption(dropDownList.stockGroupList)}
                >
                    <Label text="Stock Group" />
                </Item>
                <Item dataField="ModelCode" />
                <Item
                    dataField="StockClassID"
                    editorType="dxSelectBox"
                    editorOptions={utils.getSelectBoxOption(dropDownList.stockClassList)}
                >
                    <Label text="Stock Class" />
                </Item>
            </GroupItem>
            <GroupItem>
                <ColCountByScreen xs={10} sm={10} md={10} lg={10} />
                <ButtonItem horizontalAlignment='left' buttonOptions={submitButtonOptions} />
                <ButtonItem horizontalAlignment='left' buttonOptions={cancelButtonOptions} />
            </GroupItem>
        </Form>
    </Container>;

}
