export const areas = [{
    country: 'Russia',
    area: 12,
  }, {
    country: 'Canada',
    area: 7,
  }, {
    country: 'USA',
    area: 7,
  }, {
    country: 'China',
    area: 7,
  }, {
    country: 'Brazil',
    area: 6,
  }, {
    country: 'Australia',
    area: 5,
  }, {
    country: 'India',
    area: 2,
  }, {
    country: 'Others',
    area: 55,
  }];


  export const ChartDataSource = [{
    day: 'COMPANY',
    oranges: 8000,
  }, 
  {
    day: 'JACQUELINE',
    oranges: 1500,
  }, 
  {
    day: 'JEREMY',
    oranges: 3790,
  }, 
  {
    day: 'MICHEAL',
    oranges: 1800,
  }, 
  {
    day: 'ANDREA',
    oranges: 3200,
  }, 
  {
    day: 'EMILY',
    oranges: 2500,
  },
];

  export const architectureSources = [
    { value: 'smp', name: 'SMP' },
    { value: 'mmp', name: 'MMP' },
    { value: 'cnstl', name: 'Cnstl' },
    { value: 'cluster', name: 'Cluster' },
  ];
  
  export const sharingStatisticsInfo = [{
    year: 1997,
    smp: 263,
    mmp: 208,
    cnstl: 9,
    cluster: 1,
  }, {
    year: 1999,
    smp: 169,
    mmp: 270,
    cnstl: 61,
    cluster: 7,
  }, {
    year: 2001,
    smp: 57,
    mmp: 261,
    cnstl: 157,
    cluster: 45,
  }, {
    year: 2003,
    smp: 0,
    mmp: 154,
    cnstl: 121,
    cluster: 211,
  }, {
    year: 2005,
    smp: 0,
    mmp: 97,
    cnstl: 39,
    cluster: 382,
  }, {
    year: 2007,
    smp: 0,
    mmp: 83,
    cnstl: 3,
    cluster: 437,
  }];
  
  export const seriesTypeLabel = { 'aria-label': 'Series Type' };
  
  export const populationByRegions = [{
    region: 'Payment',
    val: 21590,
  }, 
  {
    region: 'Unpaid',
    val: 7350,
  },
  ];

  export const grossProductData = [{
    state: 'Illinois',
    year2016: 803,
    year2017: 823,
    year2018: 863,
  }, {
    state: 'Indiana',
    year2016: 316,
    year2017: 332,
    year2018: 332,
  }, {
    state: 'Michigan',
    year2016: 452,
    year2017: 459,
    year2018: 470,
  }, {
    state: 'Ohio',
    year2016: 621,
    year2017: 642,
    year2018: 675,
  }, {
    state: 'Wisconsin',
    year2016: 290,
    year2017: 294,
    year2018: 301,
  }];

  export const facebookUsers = [
    { age: '13-17', number: 6869661 },
    { age: '18-24', number: 40277957 },
    { age: '25-34', number: 53481235 },
    { age: '35-44', number: 40890002 },
    { age: '45-54', number: 31916371 },
    { age: '55-64', number: 13725406 },
    { age: '65+', number: 16732183 },
  ];

  export const energySources = [
    { value: 'Sales', name: 'Sales of the month' },
  ];
  
  export const countriesInfo = [{
    salesman: 'Jan',
    Sales: 4000,
  }, 
  {
    salesman: 'Feb',
    Sales: 4500,
  }, 
  {
    salesman: 'Mar',
    Sales: 4700,
  }, 
  {
    salesman: 'Apr',
    Sales: 5500,
  }, 
  {
    salesman: 'May',
    Sales: 6000,
  }, 
  {
    salesman: 'Jun',
    Sales: 5700,
  }, 
  {
    salesman: 'Jul',
    Sales: 5200,
  }, 
  {
    salesman: 'Aug',
    Sales: 4900,
  }, 
  {
    salesman: 'Sep',
    Sales: 5000,
  },
  {
    salesman: 'Oct',
    Sales: 5500,
  }, 
  {
    salesman: 'Nov',
    Sales: 5000,
  }, 
  {
    salesman: 'Dec',
    Sales: 4900,
  }
];
  