import utils from "./common"

export const moduleCalculation = (data, parent = {}) =>{

    //Check if data is in the valid data type, an array of objects
    if(utils.isNullOrEmpty(data) && !Array.isArray(data) && !utils.isObject(data) && !utils.isObject(parent)){
        return null;
    }

    if(Array.isArray(data)){
        for(var i = 0; i < data.length; i++){
            if(!utils.isObject(data[i])){
                return null;
            }
        }

        for(var i = 0; i < data.length; i++){
            data[i] = calculationFormula(data[i], parent);
            
            //Check if Line exists 
            if(!utils.isNullOrEmpty(data[i]["Line"])){
                data[i]["Line"] = i + 1;
            }
        }

        return data;
    }

    return calculationFormula(data, parent);
}

const calculationFormula = (data, parent) => {
    var total = 0;
    var itemDiscountAmount = 1; //Represents 100%
    var taxInclusive = null;

    //Quantity cannot be null
    if(data["Quantity"] === null){
        data["Quantity"] = 0;
    }

    //Total Calculation
    if(!utils.isNullOrEmpty(data["Quantity"]) && !utils.isNullOrEmpty(data["UnitPrice"])){
        total = utils.multiply(data["Quantity"], data["UnitPrice"]);
    }

    //Item Discount Calculation
    if(!utils.isNullOrEmpty(data["ItemDiscount"]) && data["ItemDiscount"] > 0){
        itemDiscountAmount = (utils.subtract(100, data["ItemDiscount"])/ 100);
    }

    //ForexAmount Calculation
    if(!utils.isNullOrEmpty(data["ForexAmount"])){
        //If quantity is not zero then use ForexAmount as total
        if(data["Quantity"] !== 0 && total === 0){
            total = data["ForexAmount"];
        }
        // total === 0 ? total = data["ForexAmount"] : total = total;
        data["ForexAmount"] = utils.multiply(total, itemDiscountAmount);

        //Item Discount Amount Calculation
        if(!utils.isNullOrEmpty(data["ItemDiscountAmt"])){
            data["ItemDiscountAmt"] = utils.subtract(total, data["ForexAmount"]);
        }
    }

    //NettPrice Calculation
    if(!utils.isNullOrEmpty(data["NettPrice"])){
        data["NettPrice"] = utils.multiply(total, itemDiscountAmount);
    }

    //Tax Inclusive Calculation
    if(!utils.isNullOrEmpty(data["TaxInclusive"])){
        taxInclusive = data["TaxInclusive"];
    }

    //Tax Rate Calculation
    if(!utils.isNullOrEmpty(data["TaxRate"])){
        if(utils.isNullOrEmpty(data["TaxCodeID"])){
            data["TaxRate"] = 0;
        }
    }

    //ForexTaxAdjust Calculation
    if(utils.isNullOrEmpty(data["ForexTaxAdjust"])){
        data["ForexTaxAdjust"] = 0;
    }

    //LocalTaxAdjust Calculation
    if(!utils.isNullOrEmpty(parent["LocalRate"])){
        data["LocalTaxAdjust"] = utils.multiply(data["ForexTaxAdjust"], parent["LocalRate"]);
    }
    else{
        data["LocalTaxAdjust"] = 0;
    }

    // LocalAmount Calculation
    if(!utils.isNullOrEmpty(data["LocalAmount"])){
        if(!utils.isNullOrEmpty(parent["LocalRate"]) && !utils.isNullOrEmpty(data["ForexAmount"])){
            data["LocalAmount"] = utils.multiply(data["ForexAmount"], parent["LocalRate"]);
        }
    }

    //Tax Inclusive Calculation
    if(taxInclusive){
        //ForexTaxAmountOrignal Calculation
        if(!utils.isNullOrEmpty(data["ForexTaxAmountOrignal"])){
            if(!utils.isNullOrEmpty(data["ForexAmount"]) && !utils.isNullOrEmpty(data["TaxRate"])){
                data["ForexTaxAmountOrignal"] = utils.divide(
                    utils.multiply(data["ForexAmount"], data["TaxRate"]),
                    utils.add(100, data["TaxRate"])
                )
            }
        }

        // ForexTaxAmount Calculation
        if(!utils.isNullOrEmpty(data["ForexTaxAmount"])){
            if(!utils.isNullOrEmpty(data["ForexTaxAmountOrignal"]) && !utils.isNullOrEmpty(data["ForexTaxAdjust"])){
                data["ForexTaxAmount"] = utils.add(data["ForexTaxAmountOrignal"], data["ForexTaxAdjust"]);
            }            
        }

        //ForexTaxable Calculation
        if(!utils.isNullOrEmpty(data["ForexTaxable"])){
            if(!utils.isNullOrEmpty(data["ForexTaxAmount"]) && !utils.isNullOrEmpty(data["ForexAmount"])){
                data["ForexTaxable"] = utils.subtract(data["ForexAmount"], data["ForexTaxAmount"]);
            }
        }

        // LocalTaxAmountOrignal calculation
        if(!utils.isNullOrEmpty(data["LocalTaxAmountOrignal"])){
            if(!utils.isNullOrEmpty(data["LocalAmount"]) && !utils.isNullOrEmpty(data["TaxRate"])){
                data["LocalTaxAmountOrignal"] = utils.divide(
                    utils.multiply(data["LocalAmount"], data["TaxRate"]),
                    utils.add(100, data["TaxRate"])
                )
            }
        }

        // LocalTaxAmount Calculation
        if(!utils.isNullOrEmpty(data["LocalTaxAmount"])){
            if(!utils.isNullOrEmpty(data["LocalTaxAmountOrignal"]) && !utils.isNullOrEmpty(data["ForexTaxAdjust"])){
                data["LocalTaxAmount"] = utils.add(data["LocalTaxAmountOrignal"], data["LocalTaxAdjust"]);
            }            
        }

        // LocalTaxable Calculation
        if(!utils.isNullOrEmpty(data["LocalTaxable"]) && !utils.isNullOrEmpty(data["LocalAmount"])){
            data["LocalTaxable"] = utils.subtract(data["LocalAmount"], data["LocalTaxAmount"]);
        }
    }
    else{
        // ForexTaxAmountOrignal calculation
        if(!utils.isNullOrEmpty(data["ForexTaxAmountOrignal"])){
            if(!utils.isNullOrEmpty(data["ForexAmount"]) && !utils.isNullOrEmpty(data["TaxRate"])){
                data["ForexTaxAmountOrignal"] = utils.multiply(data["ForexAmount"], utils.divide(data["TaxRate"], 100));
            }
        }

        // ForexTaxAmount Calculation
        if(!utils.isNullOrEmpty(data["ForexTaxAmount"])){
            if(!utils.isNullOrEmpty(data["ForexTaxAmountOrignal"]) && !utils.isNullOrEmpty(data["ForexTaxAdjust"])){
                data["ForexTaxAmount"] = utils.add(data["ForexTaxAmountOrignal"], data["ForexTaxAdjust"]);
            }            
        }

        //ForexTaxable Calculation
        if(!utils.isNullOrEmpty(data["ForexTaxable"] && !utils.isNullOrEmpty(data["ForexAmount"]))){
            data["ForexTaxable"] = data["ForexAmount"];
        }

        // LocalTaxAmountOrignal calculation
        if(!utils.isNullOrEmpty(data["LocalTaxAmountOrignal"])){
            if(!utils.isNullOrEmpty(data["LocalAmount"]) && !utils.isNullOrEmpty(data["TaxRate"])){
                data["LocalTaxAmountOrignal"] = utils.multiply(data["LocalAmount"], utils.divide(data["TaxRate"], 100));
            }
        }

        // LocalTaxAmount Calculation
        if(!utils.isNullOrEmpty(data["LocalTaxAmount"])){
            if(!utils.isNullOrEmpty(data["LocalTaxAmountOrignal"]) && !utils.isNullOrEmpty(data["ForexTaxAdjust"])){
                data["LocalTaxAmount"] = utils.add(data["LocalTaxAmountOrignal"], data["LocalTaxAdjust"]);
            }            
        }

        // LocalTaxable Calculation
        if(!utils.isNullOrEmpty(data["LocalTaxable"]) && !utils.isNullOrEmpty(data["LocalAmount"])){
            data["LocalTaxable"] = data["LocalAmount"];
        }
    }

    
    //ForexTaxablePlusTax Calculation
    if(!utils.isNullOrEmpty(data["ForexTaxablePlusTax"])){
        data["ForexTaxablePlusTax"] = utils.add(data["ForexTaxable"], data["ForexTaxAmount"]);
    }

    // LocalTaxablePlusTax Calculation
    if(!utils.isNullOrEmpty(data["LocalTaxablePlusTax"])){
        data["LocalTaxablePlusTax"] = utils.add(data["LocalTaxable"], data["LocalTaxAmount"])
    }

    //NettAmount Calculation
    if(!utils.isNullOrEmpty(data["NettAmount"]) && !utils.isNullOrEmpty(data["ForexTaxablePlusTax"])){
        data["NettAmount"] = data["ForexTaxablePlusTax"];
    }

    return data;
};

export const ParentValueCalculation = (children, parent) => {
    const results = {};
    var totalForex = 0;
    var totalLocal = 0;
    var totalAmount = 0;
    if(Array.isArray(children) && utils.isObject(parent)){
        for(var i = 0; i < children.length; i++){
            const row = children[i];

            //Reset the line
            if(!utils.isNullOrEmpty(row["Line"])){
                row["Line"] = i + 1;
            }

            if(!utils.isNullOrEmpty(row["ForexTaxablePlusTax"])){
                totalForex = utils.add(totalForex, row["ForexTaxablePlusTax"]);
            }

            if(!utils.isNullOrEmpty(row["LocalTaxablePlusTax"])){
                totalLocal = utils.add(totalLocal, row["LocalTaxablePlusTax"]);
            }

            if(!utils.isNullOrEmpty(row["NettAmount"])){
                totalAmount = utils.add(totalLocal, row["NettAmount"]);
            }
        }

        //Calculate MainForexAmount
        parent["MainForexAmount"] = totalForex;
        parent["MainLocalAmount"] = totalLocal;
        parent["InvoiceAmount"] = totalAmount;
        //Calculate OutstandingAmount
        if(!utils.isNullOrEmpty(parent["MainForexAmount"])){
            // if(!utils.isNullOrEmpty(parent["PreviousOutstandingAmount"])){
            //     parent["OutstandingAmount"] = utils.subtract(parent["MainLocalAmount"], parent["PreviousOutstandingAmount"]);
            // }
            // else{
            //     parent["OutstandingAmount"] = utils.roundUp(parent["MainLocalAmount"]);
            // }
            parent["OutstandingAmount"] = utils.roundUp(parent["MainForexAmount"]);
        }

        // Deduct Outstanding Amount
        if(!utils.isNullOrEmpty(parent["DeductAmount"])){
            parent["OutstandingAmount"] = utils.roundUp(utils.subtract(parent["MainForexAmount"], parent["DeductAmount"]));
        }

        results["children"] = children;
        results["parent"] = parent;
    }
    
    return results;
}