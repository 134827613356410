import React, { useState, useRef, forwardRef, useImperativeHandle, useMemo, useEffect } from "react";
import baseapi from "../../api/baseapi";
import DataGrid, { Editing, Pager, Paging, Scrolling, ColumnChooser } from "devextreme-react/data-grid";
import { saveToLS, getFromLS } from "../../utils/localstorage";
import CustomStore from "devextreme/data/custom_store";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import utils, { loading, closeLoading } from "../../utils/common";
import { DateBox } from "devextreme-react/date-box";
import PathIndicator from "../path-indicator/PathIndicator";
import ErrorPopUpForm from "../popup-form/ErrorPopupForm";
import { Column, Button as ButtonColumn } from "devextreme-react/data-grid";

export default forwardRef(function Listing(props, ref) {
	const currentToken = localStorage.getItem("Authorization").substring(7);
	const documentViewerLink = useRef(null);
	const apiURL = props.apiURL !== undefined ? props.apiURL : "";
	const listURL = props.listingURL !== undefined ? props.listingURL : "";
	const gridRef = useRef(null);
	const searchBoxRef = useRef(null);
	const fromDate = useRef(null);
	const toDate = useRef(null);
	const query = useRef(null);
	const pageSize = useRef(25);
	const selectedPage = useRef(1);
	const sortOdr = useRef(true);
	const popupMessageRef = useRef(null);
	const fixedButton = props.fixedButton !== undefined ? props.fixedButton : true;
	const allowColumnReordering = props.columnReordering !== undefined ? props.columnReordering : true;
	const sortColumn = useRef(props.sortColumn !== undefined ? props.sortColumn : null);
	const disabledSortColumn = props.disabledSortColumn !== undefined ? (Array.isArray(props.disabledSortColumn) ? props.disabledSortColumn : [props.disabledSortColumn]) : [];
	const remoteOperations = {
		groupPaging: false,
		paging: true,
		filtering: true,
		sorting: true,
		grouping: false,
		summary: true
	};
	const [deleteRight, setDeleteRight] = useState(true);
	const [addRight, setHasAddRight] = useState(false);
	const [gridDataSource, setGridDataSource] = useState({
		store: new CustomStore({
			key: "id",
			load: () => {
				return baseapi
					.httpget(listURL, { q: query.current, rows: pageSize.current, page: selectedPage.current, sord: sortOdr.current, sidx: sortColumn.current })
					.then((response) => {
						return {
							data: response.data.items,
							totalCount: response.data.total
						};
					})
					.catch(() => {
						throw "Network error";
					});
			},
			remove: (key) => {
				return baseapi.httpdel(apiURL, { id: key }).then((response) => {
					const { data } = response;
					const errorProps = {};

					errorProps["visible"] = true;
					if (data.status) {
						errorProps["message"] = `${props.listingTitle !== undefined ? props.listingTitle : " record"} deleted successfully`;
						errorProps["type"] = "Success";
					} else {
						errorProps["message"] = data.message;
						errorProps["type"] = "Warning";
					}

					utils.displayPopupMessage(popupMessageRef, errorProps);
				});
			}
		})
	});

	const storageName = props.storageName !== undefined ? props.storageName : null;

	const btnBackgroundColorClass = useMemo(() => {
		return {
			"gl-listing-datagrid": "gl-listing-btn1",
			"ar-listing-datagrid": "ar-listing-btn",
			"user-listing-datagrid": "user-listing-btn",
			"ap-listing-datagrid": "ap-listing-btn",
			"sales-listing-datagrid": "sales-listing-btn",
			"purchase-listing-datagrid": "purchase-listing-btn",
			"stock-listing-datagrid": "stock-listing-btn",
			"workshop-listing-datagrid": "workshop-listing-btn"
		};
	});

	function handleAdd() {
		if(!utils.isNullOrEmpty(props.onAddClick)){
			props.onAddClick("new");
		}
	}

	const refresh = (param = {}) => {
		setGridDataSource({
			store: new CustomStore({
				key: "id",
				load: () => {
					return baseapi
						.httpget(listURL, {
							q: query.current,
							fromDate: fromDate.current,
							toDate: toDate.current,
							rows: pageSize.current,
							page: selectedPage.current,
							sord: sortOdr.current,
							sidx: sortColumn.current,
							...param
						})
						.then((response) => {
							return {
								data: response.data.items,
								totalCount: response.data.total
							};
						})
						.catch(() => {
							throw "Network error";
						});
				},
				remove: (key) => {
					return baseapi.httpdel(apiURL, { id: key }).then((response) => {
						const { data } = response;
						const errorProps = {};

						errorProps["visible"] = true;
						if (data.status) {
							errorProps["message"] = `${props.listingTitle !== undefined ? props.listingTitle : " record"} deleted successfully`;
							errorProps["type"] = "Success";
						} else {
							errorProps["message"] = data.message;
							errorProps["type"] = "Warning";
						}
						utils.displayPopupMessage(popupMessageRef, errorProps);
					});
				}
			})
		});
	};

	const handleEdit = (e) => {
		if (!utils.isNullOrEmpty(props.handleEdit)) {
			props.handleEdit(e);
		}
	};

	const onRowPrepared = (e) => {
		if (e.rowType === "data") {
			const data = e.data;

			//Highlight the row if it is cancelled
			if (data.Cancelled) {
				e.rowElement.classList.add("listing-row-data-cancelled");
			}
		}

		if (e.rowType === "header" && storageName !== null) {
			const find = e.columns.find((c) => c.command === "transparent");
			//Ignore the fixed columns
			if (utils.isNullOrEmpty(find)) {
				const columns = e.columns;
				const previousRecord = getFromLS(storageName);

				if (previousRecord === undefined || previousRecord.length !== columns.length) {
					for (var i = 0; i < columns.length; i++) {
						columns[i]["columnWidth"] = null;
					}
				} else {
					for (var i = 0; i < columns.length; i++) {
						columns[i]["columnWidth"] = previousRecord[i]["columnWidth"];
					}
				}

				saveToLS(storageName, columns);
			}
		}
	};

	const onOptionChanged = (e) => {
		if (e.name === "columns" && e.fullName.includes("width") && storageName !== null) {
			//Sample format : columns[0].width
			const columns = getFromLS(storageName);
			const columnIndex = parseInt(e.fullName.substring(8, 9));
			const arrayIndex = columns.findIndex((x) => x.index === columnIndex);

			// Save the new width
			columns[arrayIndex]["columnWidth"] = e.value;
			saveToLS(storageName, columns);
		}
	};

	const directToReport = (e) => {
		//// console.log(e)
		// console.log(reportSelected)
		baseapi.httpget(`${utils.ReportURL}/api/Report/Get?accessToken=${currentToken}`).then((response) => {
			const { data } = response;
			for (var i = 0; i < data.length; i++) {
				if (data[i].ID === props.ReportID) {
					documentViewerLink.current = data[i].ViewerAccessURL;
					loading("Redirecting You To The Report");
					window.open(documentViewerLink.current.toString(), "_blank");
					closeLoading();
				}
			}
		});
	};

	const onCellClick = (e) => {
		const column = e.column;
		const rowType = e.rowType;

		if (rowType === "header" && column.name !== "buttons" && !disabledSortColumn.includes(column.name)) {
			if (column.name === sortColumn.current) {
				sortOdr.current = !sortOdr.current;
			} else {
				sortOdr.current = true;
			}

			sortColumn.current = column.name;
			refresh();
		}
	};

	useImperativeHandle(ref, () => ({
		refreshGrid() {
			refresh();
		},
		search(params) {
			refresh(params);
		},
		searchByText(text) {
			if (gridRef.current !== null) {
				gridRef.current.instance.searchByText(text);
			}
		}
	}));

	useEffect(() => {
		let userAccessRights = localStorage.getItem('userAccessRights');
		let userRight = null;
	  
		try {
		  userRight = userAccessRights ? JSON.parse(userAccessRights) : null;
		} catch (e) {
		  console.error('Failed to parse userAccessRights from localStorage', e);
		}

		let deleteAccess = true; // Default to true if no specific delete rights found
		let hasAdd = true; // Default to true if no specific add rights found
		if (userRight) {
			const accessRight = userRight.find(ur => ur.ApiURL === props.apiURL);
			if (accessRight) {
				deleteAccess = accessRight.DeleteAccess !== undefined ? accessRight.DeleteAccess : deleteAccess;
				hasAdd = accessRight.Add !== undefined ? accessRight.Add : hasAdd;
			}
		}

		setHasAddRight(hasAdd);
		setDeleteRight(deleteAccess); // Set delete right with the final value

	}, [props.apiURL]);

	return (
		<div>
			<div className="listing-container">
				{props.listingOnly !== true && (
					<div className="sub-container">
						<div className='listing-page-title-container'>
							<span className='datagrid-customized-title'>
								<PathIndicator />
							</span>
						</div>
						<div className='listing-datebox-container'>
							<TextBox
								width={"350px"}
								height={"34px"}
								ref={searchBoxRef}
								className='listing-page-search-box'
								placeholder='Search'
								value={query.current}
								valueChangeEvent='keyup'
								onValueChanged={(e) => {
									query.current = e.value;
									refresh();
									const arrayText = e.value.split(" ");
									gridRef.current.instance.searchByText(arrayText[arrayText.length - 1]); // By default search the last space word
									// console.log("On change", e)
								}}
							/>

							{props.dateFilter !== false && (
								<div className='listing-date-from-container'>
									<DateBox
										displayFormat='dd/MM/yyyy'
										dateSerializationFormat='yyyy-MM-dd'
										useMaskBehavior={true}
										type='date'
										placeholder="From"
										showClearButton={true}
										onValueChanged={(e) => {
											fromDate.current = e.value;
											refresh();
											// console.log("On change", e)
										}}
									/>
								</div>
							)}

							{props.dateFilter !== false && (
								<div className='listing-date-to-container'>
									<DateBox
										displayFormat='dd/MM/yyyy'
										dateSerializationFormat='yyyy-MM-dd'
										useMaskBehavior={true}
										type='date'
										placeholder="To"
										showClearButton={true}
										onValueChanged={(e) => {
											toDate.current = e.value;
											refresh();
											// console.log("On change", e)
										}}
									/>
								</div>
							)}

							<div className="buttons-container">
								{addRight &&
									props.customizedAddButton && props.customizedAddButton
								}


								{props.customizedAddButton === undefined && addRight && (
									<Button
										height={"38px"}
										icon='plus'
										onClick={(e) => {
											handleAdd();
											props.addButtonOnClick !== undefined && props.addButtonOnClick();
										}}
										text='Add'
										className={`listing-page-add-btn ${getBackgroundColorClass()}`}
									/>
								)}

								{props.viewReportButton && (
									<Button
										height={"38px"}
										icon='textdocument'
										onClick={(e) => {
											loading("Redirecting you to report");
											directToReport();
										}}
										text='View Report'
										className={`listing-page-add-btn ${getBackgroundColorClass()}`}
									/>
								)}

								<Button
									icon="columnchooser"
									hint="Column Chooser"
									onClick={() => gridRef.current.instance.showColumnChooser()}
									className="column-chooser-button"
								/>
							</div>
						</div>
					</div>
				)}

				{props.customizedFilter}

				<DataGrid
					id={props.id}
					ref={gridRef}
					className={"listing-page-datagrid ".concat(props.className !== undefined ? props.className : "")}
					width={props.width !== undefined ? props.width : "100%"}
					height={props.height}
					loadPanel={{ enabled: true }}
					showBorders={false}
					dataSource={gridDataSource}
					allowColumnReordering={allowColumnReordering}
					columnResizingMode='widget'
					rowAlternationEnabled={false}
					allowColumnResizing={true}
					columnAutoWidth={true}
					errorRowEnabled={false}
					rowHeight={50}
					sorting={{ mode: "multiple" }}
					// scrolling={{mode: "virtual"}}
					remoteOperations={remoteOperations}
					hoverStateEnabled={true}
					onRowPrepared={onRowPrepared}
					// columnChooser={{ enabled: true }}
					onOptionChanged={onOptionChanged}
					onCellClick={onCellClick}>
					{/* <ColumnChooser enabled={utils.isNullOrEmpty(props.enabledColumnChooser) ? true : props.enabledColumnChooser} /> */}

					{props.children.map((child) => {
						if (!utils.isNullOrEmpty(child.props)) {
							if (child.props.type !== "buttons") {
								const column = child.props;
								const columnWidth = !utils.isNullOrEmpty(column.width) ? column.width : utils.getColumnWidth(column.dataField, storageName) === null ? undefined : utils.getColumnWidth(column.dataField, storageName);
								const columnIndex = utils.getColumnVisibleIndex(column.dataField, storageName) === null ? 0 : utils.getColumnVisibleIndex(column.dataField, storageName);
								const columnEditorOptions = utils.isNullOrEmpty(column.editorOptions) ? {} : column.editorOptions;

								const gridProps = {
									width: columnWidth,
									visibleIndex: columnIndex,
									caption: utils.isNullOrEmpty(column.caption) ? utils.childGridDefaultSetting(column.dataField, "Caption") : column.caption,
									editorOptions: columnEditorOptions,
									format: utils.isNullOrEmpty(column.format) ? utils.childGridDefaultSetting(column.dataField, "Format") : column.format,
									customizeText: utils.isNullOrEmpty(column.customizeText) ? utils.childGridDefaultSetting(column.dataField, "Customize Text") : column.customizeText
								};
								return React.cloneElement(child, gridProps);
							}
						}
					})}

					{/* {props.children} */}

					<Column
						type='buttons'
						fixed={true}
					// width={ButtonColumnWidth}
					// visibleIndex={ButtonVisibleIndex}
					>
						<ButtonColumn name='edit' onClick={handleEdit} />
						{deleteRight && <ButtonColumn name='delete' />}

					</Column>
					{props.editEnabled !== false && <Editing mode='popup' useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={false} />}
					<Scrolling columnRenderingMode='standard' showScrollbar={"onHover"} />
					<Pager allowedPageSizes={[10, 25, 50, 100]} showPageSizeSelector={true} visible={true} displayMode={"full"} showInfo={true} />
					<Paging
						defaultPageSize={25}
						onPageSizeChange={(e) => {
							pageSize.current = e;
							refresh();
						}}
						onPageIndexChange={(e) => {
							selectedPage.current = e + 1;
							refresh();
						}}
					/>
				</DataGrid>
			</div>


			<ErrorPopUpForm ref={popupMessageRef} />
		</div>
	);

	// add-btn background color based on listingTitle
	function getBackgroundColorClass() {
		const className = props.className;
		return btnBackgroundColorClass[className];
	}
});
