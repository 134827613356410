import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import DataGrid, { Button as Pager, Paging, Scrolling, Grouping, GroupPanel, SearchPanel, Toolbar, MasterDetail, Summary, TotalItem, Column, Selection } from "devextreme-react/data-grid";
import { customerOptions, statementTypeDebtorOptions, projectOptions, areaOptions, salesmanOptions, supplierOptions, GetGLBKAndCSAccount } from "../../../../utils/lookupstore";
import { Button } from "devextreme-react/button";
import { TextBox, SelectBox, DropDownBox, Popover } from "devextreme-react";
import { DateBox } from "devextreme-react/date-box";
import utils, { closeLoading } from "../../../../utils/common";
import CustomizedLookup from "../../../lookup/Lookup";
import baseapi from "../../../../api/baseapi";
import ErrorPopUpForm from "../../../popup-form/ErrorPopupForm";

export default forwardRef(function ListingReport(props, ref) {
	const className = props.className;
	// const isGL = props.isGL !== undefined ? props.isGL : false;
	const [isJL, setIsJL] = useState(false);
	const [isGL, setIsGL] = useState(false);
	const [isAR, setIsAR] = useState(false);
	const [isSales, setIsSales] = useState(false);
	const [isPurchase, setIsPurchase] = useState(false);
	const defaultReportName = props.reportName !== undefined ? props.reportName : "";
	const [reportName, setReportName] = useState("");

	const fromCustomerLookupRef = useRef(null);
	const toCustomerLookupRef = useRef(null);
	const [fromCustomerList, setFromCustomerList] = useState([]);
	const [toCustomerList, setToCustomerList] = useState([]);
	const fromSalesmanLookupRef = useRef(null);
	const toSalesmanLookupRef = useRef(null);
	const [fromSalesmanList, setFromSalesmanList] = useState([]);
	const [toSalesmanList, setToSalesmanList] = useState([]);
	const fromAreaLookupRef = useRef(null);
	const toAreaLookupRef = useRef(null);
	const [fromAreaList, setFromAreaList] = useState([]);
	const [toAreaList, setToAreaList] = useState([]);
	const fromSupplierLookupRef = useRef(null);
	const toSupplierLookupRef = useRef(null);
	const [fromSupplierList, setFromSupplierList] = useState([]);
	const [toSupplierList, setToSupplierList] = useState([]);
	const fromMainProjectLookupRef = useRef(null);
	const toMainProjectLookupRef = useRef(null);
	const [fromMainProjectList, setFromMainProjectList] = useState([]);
	const [toMainProjectList, setToMainProjectList] = useState([]);
	const fromDetailProjectLookupRef = useRef(null);
	const toDetailProjectLookupRef = useRef(null);
	const [fromDetailProjectList, setFromDetailProjectList] = useState([]);
	const [toDetailProjectList, setToDetailProjectList] = useState([]);
	const fromPayMethodLookupRef = useRef(null);
	const toPayMethodLookupRef = useRef(null);
	const [fromPayMethodList, setFromPayMethodList] = useState([]);
	const [toPayMethodList, setToPayMethodList] = useState([]);
	const fromDocNoLookupRef = useRef(null);
	const toDocNoLookupRef = useRef(null);
	const [fromDocNoList, setFromDocNoList] = useState([]);
	const [toDocNoList, setToDocNoList] = useState([]);
	const [isContra, setIsContra] = useState(false);
	const pagingIndex = useRef({
		loading: false,
		page: 1
	});

	const [dropDownList, setDropDownList] = useState({
		reportList: [],
		customerList: [],
		statementList: [],
		projectList: [],
		areaList: [],
		salesmanList: [],
		docNoList: []
	});
	const [originalDropDownList, setOriginalDropDownList] = useState({});
	const userID = JSON.parse(localStorage.getItem("data"));
	const [selectedCust, setSelectedCust] = useState(0);
	const [selectedSales, setSelectedSales] = useState(0);
	const [selectedArea, setSelectedArea] = useState(0);
	const [selectedSupp, setSelectedSupp] = useState(0);
	const [selectedDocNo, setSelectedDocNo] = useState(0);
	const [selectedPayMethod, setSelectedPayMethod] = useState(0);
	const [openCustRange, setOpenCustRange] = useState();
	const [openSalesRange, setOpenSalesRange] = useState();
	const [openAreaRange, setOpenAreaRange] = useState();
	const [openSuppRange, setOpenSuppRange] = useState();
	const [openDocNoRange, setOpenDocNoRange] = useState();
	const [openPayMethodRange, setOpenPayMethodRange] = useState();
	const [url, setUrl] = useState("");
	const [moduleID, setModuleID] = useState("");
	const [fromDate, setFromDate] = useState(
		new Date(new Date().setDate(1)).toLocaleDateString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		})
	);
	const [toDate, setToDate] = useState(
		new Date(new Date()).toLocaleDateString("en-US", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		})
	);
	const [paramValue, setParamValue] = useState([
		{
			ParameterName: "FromDate",
			ParameterValue: utils.dateboxFormat(fromDate)
		},
		{
			ParameterName: "ToDate",
			ParameterValue: utils.dateboxFormat(toDate)
		}
	]);
	const filterTypes = [
		{
			ID: 0,
			Type: "No Filter"
		},
		{
			ID: 1,
			Type: "Filter By Range"
		},
		{
			ID: 2,
			Type: "Filter By Multi-Select"
		}
	];
	const moduleIDs = [
		{
			ID: "b_xPwSzI5Y0%3d", // 3
			Type: ["Supplier Payment Listing", "Supplier Credit Note Listing", "Supplier Debit Note Listing", "Supplier Invoice Listing", "Supplier Contra Listing", "Supplier Refund Listing"]
		},
		{
			ID: "5oxwnlvCJSo%3d", // 4
			Type: ["Customer Receipt Listing", "Customer Credit Note Listing", "Customer Debit Note Listing", "Customer Invoice Listing", "Customer Contra Listing", "Customer Refund Listing"]
		},
		{
			ID: "53OsK3yBsKs%3d", // 5
			Type: ["GL Cash Book PV Listing", "GL Cash Book OR Listing", "GL Cash Book Listing", "GL Journal Listing"]
		},
		{
			ID: "3NR4frUvmkI%3d", // 13
			Type: ["Debit Note Listing", "Credit Note Listing", "Cash Sales Listing", "Invoice Listing", "Delivery Order Listing", "Quotation Listing", "Sales Order Listing"]
		},
		{
			ID: "gXKqHrqxW48%3d", // 14
			Type: ["Purchase Debit Note Listing", "Cash Purchase Listing", "Goods Received Listing", "Purchase Return Listing", "Purchase Customer Listing", "Purchase Request Listing", "Purchase Quotation Listing", "Purchase Order Listing"]
		}
	];
	const tempForm = useRef(null);
	const [mainDataSource, setMainDataSource] = useState([]);
	const [detailDataSource, setDetailDataSource] = useState([]);
	const gridRef = useRef(null);
	const searchBoxRef = useRef(null);
	const currentFocusIndex = useRef(null);
	const dropdownRef = useRef(null);
	const [documentModuleList, setDocumentModuleList] = useState([]);
	const documentModuleLookupRef = useRef(null);
	const popupMessageRef = useRef(null);

	const [printRepDisabled, setPrintRepDisabled] = useState(true);
	const [disableDocNo, setDisableDocNo] = useState(true);
	const [openProjectRange, setOpenProjectRange] = useState();
	const [selectedProject, setSelectedProject] = useState(0);
	const [openDetailProjectRange, setOpenDetailProjectRange] = useState();
	const [selectedDetailProject, setSelectedDetailProject] = useState(0);

	useEffect(() => {
		if (pagingIndex.current["loading"] === true && Array.isArray(dropDownList)) {
			pagingIndex.current["page"] += 1;
			pagingIndex.current["loading"] = false;
		}

		// if (Array.isArray(listingDataSource)) {
		// 	previousDataSource.current = [...listingDataSource];
		// }
	}, [dropDownList]);

	useEffect(() => {
		if (props.onValueChanged !== undefined) {
			props.onValueChanged({ parent: mainDataSource, children: detailDataSource });
		}
	}, [mainDataSource]);

	useEffect(() => {
		if (props.onButtonDisable !== undefined) {
			props.onButtonDisable({ value: printRepDisabled });
		}
	}, [printRepDisabled]);

	useEffect(() => {
		if (props.onReportModuleChanged !== undefined) {
			props.onReportModuleChanged({ value: dropDownList.reportList, documentModule: returnSelectedValue("DocumentModule") });
		}
	}, [dropDownList]);

	useEffect(() => {
		if (props.isContra !== undefined) {
			props.isContra({ value: isContra });
		}
	}, [isContra]);

	useImperativeHandle(ref, () => ({
		getMain() {
			onInitialized();
		},
		getParams() {
			return paramValue;
		}
	}));

	useEffect(() => {
		const pathname = window.location.pathname;

		const lastIndex = pathname.lastIndexOf("/");
		const lastSegment = lastIndex !== -1 ? pathname.substring(lastIndex + 1) : pathname;
		setUrl(lastSegment);
	}, []);

	useEffect(() => {
		if (url === "ARDocumentListing") {
			updateParamValue("DocumentModule", "Customer Invoice Listing");
			setIsAR(true);
		} else if (url === "APDocumentListing") {
			setIsAR(false);
			setReportName("Supplier Invoice Listing by Supplier Code");
			updateParamValue("DocumentModule", "Supplier Invoice Listing");
		} else if (url === "GLDocumentListing") {
			setIsGL(true);
			setReportName("GL - Cash Book Listing");
			updateParamValue("DocumentModule", "GL Cash Book Listing");
		} else if (url === "SalesDocumentListing") {
			setIsAR(true);
			setIsSales(true);
			setReportName("Sales - Quotation Listing");
			updateParamValue("DocumentModule", "Quotation Listing");
		} else if (url === "PurchaseDocumentListing") {
			setReportName("Purchase Quotation Listing");
			updateParamValue("DocumentModule", "Purchase Quotation Listing");
			setIsAR(false);
			setIsPurchase(true);
		}
	}, [url]);

	useEffect(() => {
		baseapi.httpget(`api/Utilities/GetDocumentNo?module=${returnSelectedValue("DocumentModule")}`).then((res) => {
			const { data } = res;
			// console.log(data);
			const reportLists = data.map((item) => item.reportList);
			// console.log(reportLists[0]);
			setDropDownList((prevState) => ({
				...prevState,
				reportList: reportLists[0]
			}));
			setPrintRepDisabled(false);
			// setReportName(props.reportName);
		});
		const module = moduleIDs.find((module) => module.Type.includes(returnSelectedValue("DocumentModule")));
		setModuleID(module ? module.ID : "");
	}, [paramValue, props.isGL]);

	useEffect(() => {
		Promise.allSettled([customerOptions.store.load(), statementTypeDebtorOptions.store.load(), projectOptions.store.load(), salesmanOptions.store.load(), areaOptions.store.load(), supplierOptions.store.load(), GetGLBKAndCSAccount.store.load()]).then(
			(lists) => {
				setDropDownList({
					customerList: lists[0].value.data,
					statementList: lists[1].value.data,
					projectList: lists[2].value.data,
					salesmanList: lists[3].value.data,
					areaList: lists[4].value.data,
					supplierList: lists[5].value.data,
					payMethodList: lists[6].value.data
				});
				setOriginalDropDownList({
					customerList: lists[0].value.data,
					statementList: lists[1].value.data,
					projectList: lists[2].value.data,
					salesmanList: lists[3].value.data,
					areaList: lists[4].value.data,
					supplierList: lists[5].value.data,
					payMethodList: lists[6].value.data
				});

				// console.log(lists[0].value.data)
			}
		);
	}, []);

	const onInitialized = () => {
		// console.log(selectedSalesKey);
		// loading("Loading Data");
		var reportListingType = "isListing";
		var report = "";

		if (isAR) {
			reportListingType += " isAR";
		} else if (isGL) {
			reportListingType += " isGL";
			if (isJL) {
				reportListingType += " isJL";
			}
		}

		if (isSales) {
			reportListingType += " isSales";
		} else if (isPurchase) {
			reportListingType += " isPurchase";
		}

		if (reportName === "") {
			report = defaultReportName;
		} else {
			report = reportName;
		}

		var parameters = `?userID=${userID?.userID}&reportListingType=${reportListingType}&reportName=${report}`;

		tempForm.current = [...paramValue];

		tempForm.current.forEach((param) => {
			if (param.ParameterName.includes("List") && Array.isArray(param.ParameterValue)) {
				param.ParameterValue = param.ParameterValue.join("$%");
			}
		});

		var copied = tempForm.current;
		// console.log("Parameters", paramValue);
		baseapi
			.httppost(`${utils.ReportURL}/api/Report/ReturnSQLResults2${parameters}`, copied)
			.then((response) => {
				const { data } = response;
				var mainDataArray = [];
				var detailsDataArray = [];
				console.log(data);
				if (data !== null) {
					for (var i = 0; i < data.length; i++) {
						// if (data[i].DocumentNo.Amount !== 0)
						mainDataArray.push(data[i].DocumentNo);

						if (isGL || isSales || isPurchase) {
							for (var j = 0; j < data[i].Details.length; j++) {
								// console.log(data[i].Details[j]);
								if (data[i].Details[j].Code !== null) detailsDataArray = detailsDataArray.concat(data[i].Details[j]);
							}
						}
					}
					// console.log(detailsDataArray);
					setMainDataSource(mainDataArray);
					setDetailDataSource(detailsDataArray);
					if (data.length > 0) {
						setPrintRepDisabled(false);
					}
				}

				if (data.status) {
					utils.displayPopupMessage(popupMessageRef, {
						visible: true,
						message: data.message,
						type: "Danger"
					});
				}
				closeLoading();
			})
			.catch((e) => {
				// closeLoading();
				console.log(e);
			});
	};

	function valueOnChange(e, type = null) {
		try {
			if (e.value !== undefined) {
				const dataField = e.element.getAttribute("dataField");
				setPrintRepDisabled(true);
				if (dataField === "FromDate") {
					setFromDate(utils.dateboxFormat(e.value));
				} else if (dataField === "ToDate") {
					setToDate(utils.dateboxFormat(e.value));
				} else if (dataField === "FilterNumber") {
					if (e.value === 1) {
						switch (type) {
							case "Customer":
								setSelectedCust(e.value);
								setOpenCustRange(true);
								break;
							case "Salesman":
								setSelectedSales(e.value);
								setOpenSalesRange(true);
								break;
							case "Area":
								setSelectedArea(e.value);
								setOpenAreaRange(true);
								break;
							case "Supplier":
								setSelectedSupp(e.value);
								setOpenSuppRange(true);
								break;
							case "DocNo":
								setSelectedDocNo(e.value);
								setOpenDocNoRange(true);
								break;
							case "Project":
								setSelectedProject(e.value);
								setOpenProjectRange(true);
								break;
							case "DetailProject":
								setSelectedDetailProject(e.value);
								setOpenDetailProjectRange(true);
								break;
							case "PayMethod":
								setSelectedPayMethod(e.value);
								setOpenPayMethodRange(true);
								break;
							default:
								// setSelectedID(0);
								break;
						}
					}
					if (e.value === 2) {
						switch (type) {
							case "Customer":
								setSelectedCust(e.value);
								break;
							case "Salesman":
								setSelectedSales(e.value);
								break;
							case "Area":
								setSelectedArea(e.value);
								break;
							case "DocNo":
								setSelectedDocNo(e.value);
								break;
							case "Supplier":
								setSelectedSupp(e.value);
								break;
							case "Project":
								setSelectedProject(e.value);
								break;
							case "DetailProject":
								setSelectedDetailProject(e.value);
								setOpenDetailProjectRange(true);
								break;
							case "PayMethod":
								setSelectedPayMethod(e.value);
								setOpenPayMethodRange(true);
								break;
							default:
								// setSelectedID(0);
								break;
						}
					}
				}

				if (dataField !== "FilterNumber") updateParamValue(dataField, e.value);
			}
		} catch (err) {
			console.log(err);
		}
	}

	const dataGridOnSelectionChanged = (e, type) => {
		if (type === "Customer") {
			updateParamValue("CustList", e.selectedRowKeys);
		} else if (type === "Salesman") {
			updateParamValue("SalesList", e.selectedRowKeys);
		} else if (type === "Area") {
			updateParamValue("AreaList", e.selectedRowKeys);
		} else if (type === "DocNo") {
			updateParamValue("DocNoList", e.selectedRowKeys);
		} else if (type === "Supplier") {
			updateParamValue("SuppList", e.selectedRowKeys);
		} else if (type === "Project") {
			updateParamValue("MainProjectList", e.selectedRowKeys);
		} else if (type === "DetailProject") {
			updateParamValue("DetailProjectList", e.selectedRowKeys);
		} else if (type === "PayMethod") {
			updateParamValue("PayMethodList", e.selectedRowKeys);
		}
	};

	const returnDropDownList = (type) => {
		if (type === "Customer") {
			return dropDownList.customerList;
		} else if (type === "Salesman") {
			return dropDownList.salesmanList;
		} else if (type === "Area") {
			return dropDownList.areaList;
		} else if (type === "DocNo") {
			return dropDownList.docNoList;
		} else if (type === "Supplier") {
			return dropDownList.supplierList;
		} else if (type === "Project" || type === "DetailProject") {
			return dropDownList.projectList;
		} else if (type === "PayMethod") {
			return dropDownList.payMethodList;
		}
	};

	const returnDropDownListURL = (type) => {
		if (type === "Customer") {
			return "api/Utilities/GetCustomers";
		} else if (type === "Salesman") {
			return "api/Utilities/GetSalesmans";
		} else if (type === "Area") {
			return "api/Utilities/GetAreas";
		} else if (type === "Supplier") {
			return "api/Utilities/GetSuppliers";
		} else if (type === "Project" || type === "DetailProject") {
			return "api/Utilities/GetProjects";
		} else if (type === "PayMethod") {
			return "api/Utilities/GetGLBKAndCSAccount";
		}
	};

	function refreshLookupData(type) {
		if (type === "Customer") {
			setDropDownList((prevState) => ({
				...prevState,
				customerList: [...originalDropDownList.customerList]
			}));
		} else if (type === "Salesman") {
			setDropDownList((prevState) => ({
				...prevState,
				salesmanList: [...originalDropDownList.salesmanList]
			}));
		} else if (type === "Area") {
			setDropDownList((prevState) => ({
				...prevState,
				areaList: [...originalDropDownList.areaList]
			}));
		} else if (type === "Supplier") {
			setDropDownList((prevState) => ({
				...prevState,
				supplierList: [...originalDropDownList.supplierList]
			}));
		} else if (type === "Project" || type === "DetailProject") {
			setDropDownList((prevState) => ({
				...prevState,
				projectList: [...originalDropDownList.projectList]
			}));
		} else if (type === "PayMethod") {
			setDropDownList((prevState) => ({
				...prevState,
				payMethodList: [...originalDropDownList.payMethodList]
			}));
		}
	}

	const arrayToString = (arr) => {
		if (Array.isArray(arr)) {
			return arr.join(" ");
		} else {
			return arr;
		}
	};

	const updateDropDownList = (paramValue, type, page, paging) => {
		const typeToArrayNameMap = {
			Customer: "customerList",
			Salesman: "salesmanList",
			Area: "areaList",
			Supplier: "supplierList",
			Project: "projectList",
			DetailProject: "projectList",
			PayMethod: "payMethodList"
		};
		// console.log(paramValue);
		var value = "";

		if (!paging) {
			page = 0;
		}

		if (paramValue !== undefined) {
			value = paramValue;
		}

		const arrayName = typeToArrayNameMap[type];
		const query = value.length === 0 ? `${paramValue}` : `${paramValue} ${arrayToString(returnSelectedKeys(type))}`;

		baseapi
			.httpget(returnDropDownListURL(type), { q: query, singleReturn: true, page: page })
			.then((response) => {
				const data = response.data;
				setDropDownList((prevState) => {
					const existingArray = prevState[arrayName] || [];
					const newData = data.filter((newItem) => !existingArray.some((existingItem) => existingItem.id === newItem.id));
					return {
						...prevState,
						[arrayName]: [...existingArray, ...newData]
					};
				});
			})
			.catch(() => {
				throw "Network error";
			});
	};

	function DataGridMultiRender(type) {
		let selectedKeys = returnSelectedKeys(type);

		return (
			<div className='customized-lookup-container'>
				<div className='customized-lookup-search-container'>
					<div>
						<TextBox
							ref={searchBoxRef}
							placeholder='Search...'
							width={250}
							valueChangeEvent='keyup'
							showClearButton={true}
							onValueChanged={(e) => {
								gridRef.current.instance.searchByText(e.value);
								updateDropDownList(e.value, type, pagingIndex.current["page"], false);
							}}
							onKeyDown={(e) => {
								// console.log("key down", e.event)
								if (e.event.key === "ArrowDown") {
									gridRef.current.instance.focus();
								}
							}}
						/>
					</div>

					<div className='customized-lookup-btn-section'>
						<Button
							text='Clear'
							onClick={(e) => {
								// if (type === "Customer") {
								// 	removeParamValue("CustList");
								// } else if (type === "Salesman") {
								// 	removeParamValue("SalesList");
								// } else if (type === "Area") {
								// 	removeParamValue("AreaList");
								// } else if (type === "DocNo") {
								// 	removeParamValue("DocNoList");
								// } else if (type === "Supplier") {
								// 	removeParamValue("SuppList");
								// } else if (type === "Project") {
								// 	removeParamValue("MainProjectList");
								// } else if (type === "DetailProject") {
								// 	removeParamValue("DetailProjectList");
								// } else if (type === "PayMethod") {
								// 	removeParamValue("PayMethodList");
								// }

								if (searchBoxRef.current !== null) searchBoxRef.current.instance.reset();
								// refreshLookupData(type);
							}}
						/>
					</div>

					<div>
						<Button
							style={{ marginLeft: "5px" }}
							text='Clear Filter'
							stylingMode='text'
							type='default'
							onClick={() => {
								if (type === "Customer") {
									setSelectedCust(0);
									removeParamValue("CustList");
									removeParamValue("FromCust");
									removeParamValue("ToCust");
								} else if (type === "Salesman") {
									setSelectedSales(0);
									removeParamValue("SalesList");
									removeParamValue("FromSales");
									removeParamValue("ToSales");
								} else if (type === "Area") {
									setSelectedArea(0);
									removeParamValue("AreaList");
									removeParamValue("FromArea");
									removeParamValue("ToArea");
								} else if (type === "DocNo") {
									setSelectedDocNo(0);
									removeParamValue("DocNoList");
									removeParamValue("FromDocNo");
									removeParamValue("ToDocNo");
								} else if (type === "Supplier") {
									setSelectedSupp(0);
									removeParamValue("SuppList");
									removeParamValue("FromSupp");
									removeParamValue("ToSupp");
								} else if (type === "Project") {
									removeParamValue("MainProjectList");
									removeParamValue("FromMainProject");
									removeParamValue("ToMainProject");
									setSelectedProject(0);
								} else if (type === "DetailProject") {
									removeParamValue("DetailProjectList");
									removeParamValue("FromDetailProject");
									removeParamValue("ToDetailProject");
									setSelectedDetailProject(0);
								} else if (type === "PayMethod") {
									removeParamValue("PayMethodList");
									removeParamValue("FromPayMethod");
									removeParamValue("ToPayMethod");
									setSelectedPayMethod(0);
								}
								refreshLookupData(type);
							}}
							elementAttr={{ dataField: "FilterNumber" }}
						/>
					</div>
				</div>
				<DataGrid
					ref={gridRef}
					height={200}
					className={"lookup-datagrid " + className}
					showBorders={true}
					dataSource={returnDropDownList(type)}
					scrolling={{ columnRenderingMode: "standard", showScrollbar: "onHover" }}
					columnChooser={{ enabled: false }}
					allowColumnResizing={true}
					allowColumnReordering={true}
					hoverStateEnabled={true}
					paging={{ enabled: false }}
					keyExpr={type === "PayMethod" ? "name" : "code"}
					focusedRowEnabled={true}
					focusedRowKey={selectedKeys}
					selectedRowKeys={selectedKeys}
					onSelectionChanged={(e) => dataGridOnSelectionChanged(e, type)}
					onKeyDown={(e) => {
						// if(e.event.key === "Enter"){
						//     setDropdownOpened(false);
						// }
						if (e.event.key === "ArrowUp") {
							// If focus is one the first row then brings focus back to the search box
							if (currentFocusIndex.current === 0) {
								if (type === "Customer") {
									removeParamValue("CustList");
								} else if (type === "Salesman") {
									removeParamValue("SalesList");
								} else if (type === "Area") {
									removeParamValue("AreaList");
								} else if (type === "DocNo") {
									removeParamValue("DocNoList");
								} else if (type === "Supplier") {
									removeParamValue("SuppList");
								} else if (type === "Project") {
									removeParamValue("MainProjectList");
								} else if (type === "DetailProject") {
									removeParamValue("DetailProjectList");
								} else if (type === "PayMethod") {
									removeParamValue("PayMethodList");
								}
								refreshLookupData(type);
							}
						}
					}}
					onContentReady={(element) => {
						const scrollable = element.component.getScrollable();
						if (scrollable !== undefined) {
							scrollable.on("scroll", function (e) {
								if (e.reachedBottom) {
									if (pagingIndex.current["loading"] === false) {
										// console.log("page", pagingIndex.current["page"]);
										pagingIndex.current["loading"] = true;
										updateDropDownList("", type, pagingIndex.current["page"], true);
										// console.log(e);
									}
								}
							});
						}
					}}
					onFocusedRowChanging={(e) => {
						if (e.event === null) {
							e.cancel = true;
						} else if (e.event.key === undefined) {
							e.cancel = true;
						}
					}}
					// onFocusedRowChanged={(e) => {
					//   const data = e.row.data;
					//   currentFocusIndex.current = e.rowIndex;
					//   if(e.event.key === "Enter") {
					//     setSelectedCustKey(data.id);
					//   }
					// }}
				>
					<Column
						dataField='code'
						elementAttr={{
							dataField:
								type === "Customer"
									? "CustomerCode"
									: type === "Salesman"
									? "SalesmanCode"
									: type === "Area"
									? "AreaCode"
									: type === "Supplier"
									? "SupplierCode"
									: type === "PayMethod"
									? "PayMethodCode"
									: type === "Project" || type === "DetailProject"
									? "ProjectCode"
									: "DocNo"
						}}
						caption={
							type === "Customer"
								? "Customer Code"
								: type === "Salesman"
								? "Salesman Code"
								: type === "Area"
								? "Area Code"
								: type === "Supplier"
								? "Supplier Code"
								: type === "PayMethod"
								? "Payment Method Code"
								: type === "Project" || type === "DetailProject"
								? "Project Code"
								: "Document No"
						}
						visible={true}
						alignment='left'
						allowGrouping={false}
					/>
					{type !== "DocNo" && (
						<Column
							dataField='name'
							elementAttr={{
								dataField:
									type === "Customer"
										? "CustomerName"
										: type === "Salesman"
										? "SalesmanName"
										: type === "Supplier"
										? "SupplierName"
										: type === "PayMethod"
										? "PayMethodName"
										: type === "Project" || type === "DetailProject"
										? "ProjectName"
										: "AreaName"
							}}
							caption={
								type === "Customer"
									? "Customer Name"
									: type === "Salesman"
									? "Salesman Name"
									: type === "Supplier"
									? "Supplier Name"
									: type === "PayMethod"
									? "Payment Method Name"
									: type === "Project" || type === "DetailProject"
									? "Project Name"
									: "Area Name"
							}
							visible={true}
							alignment='left'
							allowGrouping={false}
						/>
					)}

					<Selection mode='multiple' showSelectAll={true} selectAllMode='page' showCheckBoxesMode={"always"} />
					<Toolbar visible={false} />
				</DataGrid>
			</div>
		);
	}

	const popFunction = (e, type) => {
		if (e.cancel === false) {
			if (type === "Customer") {
				setOpenCustRange(false);
			} else if (type === "Salesman") {
				setOpenSalesRange(false);
			} else if (type === "Area") {
				setOpenAreaRange(false);
			} else if (type === "DocNo") {
				setOpenDocNoRange(false);
			} else if (type === "Supplier") {
				setOpenSuppRange(false);
			} else if (type === "Project") {
				setOpenProjectRange(false);
			} else if (type === "DetailProject") {
				setOpenDetailProjectRange(false);
			} else if (type === "PayMethod") {
				setOpenPayMethodRange(false);
			}
		} else {
			if (type === "Customer") {
				setOpenCustRange(true);
			} else if (type === "Salesman") {
				setOpenSalesRange(true);
			} else if (type === "Area") {
				setOpenAreaRange(true);
			} else if (type === "DocNo") {
				setOpenDocNoRange(true);
			} else if (type === "Supplier") {
				setOpenSuppRange(true);
			} else if (type === "Project") {
				setOpenProjectRange(true);
			} else if (type === "DetailProject") {
				setOpenDetailProjectRange(true);
			} else if (type === "PayMethod") {
				setOpenPayMethodRange(true);
			}
		}
	};

	const updateParamValue = (key, value) => {
		setMainDataSource({});
		setDetailDataSource([]);
		setParamValue((prevParamValue) => {
			const updatedParamValue = [...prevParamValue]; // Create a copy of the previous state array

			// Find the index of the item with the matching key
			const index = updatedParamValue.findIndex((item) => item.ParameterName === key);

			if (key.includes("List")) {
				if (index !== -1) {
					// Update the existing item if it exists
					updatedParamValue[index] = { ParameterName: key, ParameterValue: value };
				} else {
					// Add a new item if the key doesn't exist
					updatedParamValue.push({ ParameterName: key, ParameterValue: value });
				}
			} else {
				if (index !== -1) {
					// Update the existing item if it exists
					updatedParamValue[index] = { ParameterName: key, ParameterValue: value };
				} else {
					// Add a new item if the key doesn't exist
					updatedParamValue.push({ ParameterName: key, ParameterValue: value });
				}
			}

			return updatedParamValue;
		});
	};

	const removeParamValue = (key) => {
		setParamValue((prevParamValue) => {
			// Filter out the item with the matching ParameterName
			const updatedParamValue = prevParamValue.filter((item) => item.ParameterName !== key);
			return updatedParamValue;
		});
	};

	const returnSelectedKeys = (type) => {
		const paramObject = paramValue.reduce((acc, curr) => {
			acc[curr.ParameterName] = curr.ParameterValue;
			return acc;
		}, {});

		let selectedKeys = [];

		if (type === "Customer" && paramObject["CustList"]) {
			selectedKeys = ensureArray(paramObject["CustList"]);
		} else if (type === "Salesman" && paramObject["SalesList"]) {
			selectedKeys = ensureArray(paramObject["SalesList"]);
		} else if (type === "Area" && paramObject["AreaList"]) {
			selectedKeys = ensureArray(paramObject["AreaList"]);
		} else if (type === "DocNo" && paramObject["DocNoList"]) {
			selectedKeys = ensureArray(paramObject["DocNoList"]);
		} else if (type === "Supplier" && paramObject["SuppList"]) {
			selectedKeys = ensureArray(paramObject["SuppList"]);
		} else if (type === "Project" && paramObject["MainProjectList"]) {
			selectedKeys = ensureArray(paramObject["MainProjectList"]);
		} else if (type === "DetailProject" && paramObject["DetailProjectList"]) {
			selectedKeys = ensureArray(paramObject["DetailProjectList"]);
		} else if (type === "PayMethod" && paramObject["PayMethodList"]) {
			selectedKeys = ensureArray(paramObject["PayMethodList"]);
		}

		return selectedKeys;
	};

	const ensureArray = (value) => {
		if (Array.isArray(value)) {
			return value;
		} else {
			return value.split("$%");
		}
	};

	const returnSelectedValue = (type) => {
		const filtered = paramValue.filter((x) => x.ParameterName === type);
		return filtered.length > 0 ? filtered[0].ParameterValue : null;
	};

	useEffect(() => {
		// console.log(returnSelectedValue("DocumentModule"));
		if (returnSelectedValue("DocumentModule") !== null) {
			if (returnSelectedValue("DocumentModule") === "Customer Invoice Listing") {
				setReportName("Customer Invoice Listing by Area Code");
				setIsAR(true);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Customer Receipt Listing") {
				setReportName("Customer Receipt Listing by Area Code");
				setIsAR(true);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Customer Credit Note Listing") {
				setReportName("Customer Credit Note Listing By Area Code");
				setIsAR(true);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Customer Debit Note Listing") {
				setReportName("Customer Debit Note Listing By Area Code");
				setIsAR(true);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Supplier Invoice Listing") {
				setReportName("Supplier Invoice Listing by Supplier Code");
				setIsAR(false);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Supplier Debit Note Listing") {
				setReportName("Supplier Debit Note Listing by Supplier Code");
				setIsAR(false);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Supplier Credit Note Listing") {
				setReportName("Supplier Credit Note Listing by Supplier Code");
				setIsAR(false);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Supplier Payment Listing") {
				setReportName("Supplier Payment Listing by Supplier Code");
				setIsAR(false);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "GL Cash Book Listing") {
				setReportName("GL - Cash Book Listing");
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "GL Cash Book PV Listing") {
				setReportName("GL - Payment Voucher Listing");
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "GL Cash Book OR Listing") {
				setReportName("GL - Official Receipt Listing");
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "GL Journal Listing") {
				setReportName("GL - Journal Voucher Listing");
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Debit Note Listing") {
				setReportName("Sales - Debit Note Listing");
				setIsAR(true);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Credit Note Listing") {
				setReportName("Sales - Credit Note Listing");
				setIsAR(true);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Cash Sales Listing") {
				setReportName("Sales - Cash Sales Listing");
				setIsAR(true);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Invoice Listing") {
				setReportName("Sales - Invoice Listing");
				setIsAR(true);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Delivery Order Listing") {
				setReportName("Sales - Delivery Order Listing");
				setIsAR(true);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Quotation Listing") {
				setReportName("Sales - Quotation Listing");
				setIsAR(true);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Sales Order Listing") {
				setReportName("Sales - Sales Order Listing");
				setIsAR(true);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Purchase Debit Note Listing") {
				setReportName("Purchase Debit Note Listing");
				setIsAR(false);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Cash Purchase Listing") {
				setReportName("Purchase - Cash Purchase Listing");
				setIsAR(false);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Goods Received Listing") {
				setReportName("Purchase - Goods Received Listing");
				setIsAR(false);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Purchase Return Listing") {
				setReportName("Purchase Return Listing");
				setIsAR(false);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Purchase Invoice Listing") {
				setReportName("Purchase Invoice Listing");
				setIsAR(false);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Purchase Request Listing") {
				setReportName("Purchase Request Listing");
				setIsAR(false);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Purchase Quotation Listing") {
				setReportName("Purchase Quotation Listing");
				setIsAR(false);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Purchase Order Listing") {
				setReportName("Purchase Order Listing");
				setIsAR(false);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Customer Contra Listing") {
				setReportName("Customer Contra Listing");
				setIsAR(true);
				setIsContra(true);
			} else if (returnSelectedValue("DocumentModule") === "Supplier Contra Listing") {
				setReportName("Supplier Contra Listing");
				setIsContra(true);
				setIsAR(false);
			} else if (returnSelectedValue("DocumentModule") === "Customer Refund Listing") {
				setReportName("Customer Refund Listing");
				setIsAR(true);
				setIsContra(false);
			} else if (returnSelectedValue("DocumentModule") === "Supplier Refund Listing") {
				setReportName("Supplier Refund Listing");
				setIsContra(false);
				setIsAR(false);
			}

			baseapi.httpget(`api/Utilities/GetDocumentNo?module=${returnSelectedValue("DocumentModule")}`).then((res) => {
				const { data } = res;
				// console.log(data);
				const reportLists = data.map((item) => item.reportList);
				// console.log(reportLists[0]);
				if (data.length !== 0) {
					setDropDownList((prevState) => ({
						...prevState,
						docNoList: data,
						reportList: reportLists[0]
					}));
					// console.log(data);
					// console.log(reportLists[0]);
					setPrintRepDisabled(false);
					setDisableDocNo(false);
				}
			});
		}
	}, [returnSelectedValue("DocumentModule")]);

	useEffect(() => {
		const toDateObj = new Date(toDate);
		const fromDateObj = new Date(fromDate);

		if (fromDateObj > toDateObj) {
			utils.displayPopupMessage(popupMessageRef, {
				visible: true,
				message: "To Date cannot be earlier than From Date.",
				type: "Warning"
			});

			const setDate = new Date(); // current date

			if (fromDateObj > setDate) {
				setToDate(utils.dateboxFormat(fromDate));
			} else {
				setToDate(utils.dateboxFormat(setDate));
			}
		}
	}, [toDate, fromDate]);

	return (
		<div>
			<div className='popup-form-item-container1'>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'> Document </div>
					<CustomizedLookup
						ref={documentModuleLookupRef}
						className={"" + className}
						elementAttr={{ dataField: "DocumentModule" }}
						value={returnSelectedValue("DocumentModule")}
						displayText={returnSelectedValue("DocumentModule")}
						dataSource={documentModuleList}
						displayExpr={"code"}
						valueExpr={"id"}
						onSelectionChanged={(e) => {
							// valueOnChange(e, "Project");
							if (e.value === null) {
								setDisableDocNo(true);
								removeParamValue("DocumentModule");
							} else {
								updateParamValue("DocumentModule", e.value);
							}

							if (e.value === "GL Journal Listing") {
								setIsJL(true);
							} else {
								setIsJL(false);
							}
							setPrintRepDisabled(true);
						}}
						dataSourceURL={`api/Utilities/GetDocumentModules?moduleID=${moduleID}`}
						onDataSourceChanged={setDocumentModuleList}>
						<Column dataField='code' caption={"Document Module"}></Column>
					</CustomizedLookup>
				</div>
			</div>

			<div className='popup-form-item-container2' style={{ marginTop: "-0.5%" }}>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'>Date</div>
					<DateBox
						type='date'
						value={fromDate}
						elementAttr={{ dataField: "FromDate" }}
						useMaskBehavior={true}
						label='From'
						labelMode='floating'
						displayFormat='dd/MM/yyyy'
						dateSerializationFormat='yyyy-MM-dd'
						onValueChanged={(e) => {
							valueOnChange(e, "FromDate");
						}}
					/>

					<DateBox
						type='date'
						value={toDate}
						elementAttr={{ dataField: "ToDate" }}
						useMaskBehavior={true}
						label='To'
						labelMode='floating'
						displayFormat='dd/MM/yyyy'
						dateSerializationFormat='yyyy-MM-dd'
						onValueChanged={(e) => {
							valueOnChange(e, "ToDate");
						}}
						style={{ marginLeft: "5%" }}
					/>
				</div>
			</div>

			{isAR && !isGL && (
				<div className='popup-form-item-container1'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'> Customer </div>
						{selectedCust === 0 && (
							<SelectBox
								width={"250px"}
								dataSource={filterTypes}
								value={selectedCust}
								displayExpr={"Type"}
								valueExpr={"ID"}
								elementAttr={{ dataField: "FilterNumber" }}
								onValueChanged={(e) => {
									valueOnChange(e, "Customer");
								}}
							/>
						)}
						{selectedCust === 1 && (
							<div onClick={() => setOpenCustRange(true)}>
								<TextBox
									value={returnSelectedValue("FromCust") || returnSelectedValue("ToCust") ? `${returnSelectedValue("FromCust")} to ${returnSelectedValue("ToCust")}` : "No Range Selected Yet"}
									// useMaskBehavior={true} // Enable mask behavior
									// mask="cccccc" // Define the input mask
									id='Customer'
									style={{ marginRight: "10px" }}
									width={"250px"}>
									<Popover
										target='#Customer'
										showEvent={"click"}
										// hideEvent="mouseleave"
										position='bottom'
										visible={openCustRange}
										onHiding={(e) => popFunction(e, "Customer")}
										dragEnabled={false}
										hideOnOutsideClick={true}
										width={500}
										height={125}>
										<div>
											<div className='popup-form-item-container2'>
												<div>
													<label>From</label>
													<CustomizedLookup
														ref={fromCustomerLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={fromCustomerList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("FromCust")}
														onSelectionChanged={(e) => {
															updateParamValue("FromCust", e.value);
														}}
														dataSourceURL={"api/Utilities/GetCustomers"}
														displayText={returnSelectedValue("FromCust")}
														onDataSourceChanged={setFromCustomerList}>
														<Column dataField='code' elementAttr={{ dataField: "CustomerCode" }} visible={true} caption='Customer Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "CustomerName" }} visible={true} caption='Customer Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
												<div>
													<label>To</label>

													<CustomizedLookup
														ref={toCustomerLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={toCustomerList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("ToCust")}
														onSelectionChanged={(e) => {
															updateParamValue("ToCust", e.value);
														}}
														dataSourceURL={"api/Utilities/GetCustomers"}
														displayText={returnSelectedValue("ToCust")}
														onDataSourceChanged={setToCustomerList}>
														<Column dataField='code' elementAttr={{ dataField: "CustomerCode" }} visible={true} caption='Customer Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "CustomerName" }} visible={true} caption='Customer Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
											</div>
											<div>
												<Button
													style={{ position: "absolute", top: "65%", left: "75%" }}
													text='Clear Filter'
													stylingMode='text'
													type='default'
													onClick={() => {
														removeParamValue("CustList");
														removeParamValue("FromCust");
														removeParamValue("ToCust");
														setSelectedCust(0);
													}}
													elementAttr={{ dataField: "FilterNumber" }}
												/>
											</div>
										</div>
									</Popover>
								</TextBox>
							</div>
						)}
						{selectedCust === 2 && (
							<div>
								<TextBox
									value={returnSelectedKeys("Customer").length !== 0 ? `${returnSelectedKeys("Customer").length} Selected  (${returnSelectedKeys("Customer")})` : `${returnSelectedKeys("Customer").length} Selected`}
									style={{ marginTop: "-35px" }}
									height={0}
									showClearButton={false}
									width={"250px"}>
									<DropDownBox
										// onClosed={(e) => closeDropDownBox(e, "Customer")}
										ref={dropdownRef}
										// opened={openCustSpecific}
										valueExpr='code'
										deferRendering={false}
										displayExpr='code'
										showClearButton={false}
										contentRender={(e) => DataGridMultiRender("Customer")}
										defaultOpened={true}
										width={"250px"}
										acceptCustomValue={true}
										dropDownOptions={{ width: "auto" }}
									/>
								</TextBox>
							</div>
						)}
					</div>
				</div>
			)}

			{!isAR && !isGL && (
				<div className='popup-form-item-container3'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'> Supplier </div>
						{selectedSupp === 0 && (
							<SelectBox
								width={"250px"}
								dataSource={filterTypes}
								value={selectedSupp}
								displayExpr={"Type"}
								valueExpr={"ID"}
								elementAttr={{ dataField: "FilterNumber" }}
								onValueChanged={(e) => {
									valueOnChange(e, "Supplier");
								}}
							/>
						)}
						{selectedSupp === 1 && (
							<div onClick={() => setOpenSuppRange(true)}>
								<TextBox
									value={returnSelectedValue("FromSupp") || returnSelectedValue("ToSupp") ? `${returnSelectedValue("FromSupp")} to ${returnSelectedValue("ToSupp")}` : "No Range Selected Yet"}
									// useMaskBehavior={true} // Enable mask behavior
									// mask="cccccc" // Define the input mask
									id='Supplier'
									style={{ marginRight: "10px" }}
									width={"250px"}>
									<Popover
										target='#Supplier'
										showEvent={"click"}
										// hideEvent="mouseleave"
										position='bottom'
										visible={openSuppRange}
										onHiding={(e) => popFunction(e, "Supplier")}
										dragEnabled={false}
										hideOnOutsideClick={true}
										width={500}
										height={125}>
										<div>
											<div className='popup-form-item-container2'>
												<div>
													<label>From</label>
													<CustomizedLookup
														ref={fromSupplierLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={fromSupplierList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("FromSupp")}
														onSelectionChanged={(e) => {
															updateParamValue("FromSupp", e.value);
														}}
														dataSourceURL={"api/Utilities/GetSuppliers"}
														displayText={returnSelectedValue("FromSupp")}
														onDataSourceChanged={setFromSupplierList}>
														<Column dataField='code' elementAttr={{ dataField: "SupplierCode" }} visible={true} caption='Supplier Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "SupplierName" }} visible={true} caption='Supplier Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
												<div>
													<label>To</label>

													<CustomizedLookup
														ref={toSupplierLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={toSupplierList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("ToSupp")}
														onSelectionChanged={(e) => {
															updateParamValue("ToSupp", e.value);
														}}
														dataSourceURL={"api/Utilities/GetSuppliers"}
														displayText={returnSelectedValue("ToSupp")}
														onDataSourceChanged={setToSupplierList}>
														<Column dataField='code' elementAttr={{ dataField: "SupplierCode" }} visible={true} caption='Supplier Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "SupplierName" }} visible={true} caption='Supplier Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
											</div>
											<div>
												<Button
													style={{ position: "absolute", top: "65%", left: "75%" }}
													text='Clear Filter'
													stylingMode='text'
													type='default'
													onClick={() => {
														setSelectedSupp(0);
														removeParamValue("SuppList");
														removeParamValue("FromSupp");
														removeParamValue("ToSupp");
													}}
													elementAttr={{ dataField: "FilterNumber" }}
												/>
											</div>
										</div>
									</Popover>
								</TextBox>
							</div>
						)}
						{selectedSupp === 2 && (
							<div>
								<TextBox
									value={returnSelectedKeys("Supplier").length !== 0 ? `${returnSelectedKeys("Supplier").length} Selected  (${returnSelectedKeys("Supplier")})` : `${returnSelectedKeys("Supplier").length} Selected`}
									style={{ marginTop: "-32px" }}
									height={0}
									showClearButton={false}
									width={"250px"}>
									<DropDownBox
										// onClosed={(e) => closeDropDownBox(e, "Supplier")}
										ref={dropdownRef}
										valueExpr='code'
										deferRendering={false}
										displayExpr='code'
										showClearButton={false}
										contentRender={(e) => DataGridMultiRender("Supplier")}
										defaultOpened={true}
										width={"250px"}
										dropDownOptions={{ width: "auto" }}
									/>
								</TextBox>
							</div>
						)}
					</div>
				</div>
			)}

			<div className='popup-form-item-container3'>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'> Document No. </div>
					{selectedDocNo === 0 && (
						<SelectBox
							width={"250px"}
							disabled={disableDocNo}
							dataSource={filterTypes}
							value={selectedDocNo}
							displayExpr={"Type"}
							valueExpr={"ID"}
							elementAttr={{ dataField: "FilterNumber" }}
							onValueChanged={(e) => {
								valueOnChange(e, "DocNo");
							}}
						/>
					)}
					{selectedDocNo === 1 && (
						<div onClick={() => setOpenDocNoRange(true)}>
							<TextBox
								value={returnSelectedValue("FromDocNo") || returnSelectedValue("ToDocNo") ? `${returnSelectedValue("FromDocNo")} to ${returnSelectedValue("ToDocNo")}` : "No Range Selected Yet"}
								// useMaskBehavior={true} // Enable mask behavior
								// mask="cccccc" // Define the input mask
								id='DocNo'
								style={{ marginRight: "10px" }}
								width={"250px"}>
								<Popover
									target='#DocNo'
									showEvent={"click"}
									// hideEvent="mouseleave"
									position='bottom'
									visible={openDocNoRange}
									onHiding={(e) => popFunction(e, "DocNo")}
									dragEnabled={false}
									hideOnOutsideClick={true}
									width={500}
									height={125}>
									<div>
										<div className='popup-form-item-container2'>
											<div>
												<label>From</label>
												<CustomizedLookup
													ref={fromDocNoLookupRef}
													className={"ar-listing-datagrid"}
													dataSource={fromDocNoList}
													displayExpr={"code"}
													valueExpr={"code"}
													value={returnSelectedValue("FromDocNo")}
													onSelectionChanged={(e) => {
														updateParamValue("FromDocNo", e.value);
													}}
													dataSourceURL={`api/Utilities/GetDocumentNo?module=${returnSelectedValue("DocumentModule")}`}
													displayText={returnSelectedValue("FromDocNo")}
													onDataSourceChanged={setFromDocNoList}>
													<Column dataField='code' elementAttr={{ dataField: "ToDocumentNo" }} visible={true} caption='Document No' alignment='left' allowGrouping={false} />
												</CustomizedLookup>
											</div>
											<div>
												<label>To</label>

												<CustomizedLookup
													ref={toDocNoLookupRef}
													className={"ar-listing-datagrid"}
													dataSource={toDocNoList}
													displayExpr={"code"}
													valueExpr={"code"}
													value={returnSelectedValue("ToDocNo")}
													onSelectionChanged={(e) => {
														updateParamValue("ToDocNo", e.value);
													}}
													dataSourceURL={`api/Utilities/GetDocumentNo?module=${returnSelectedValue("DocumentModule")}`}
													displayText={returnSelectedValue("ToDocNo")}
													onDataSourceChanged={setToDocNoList}>
													<Column dataField='code' elementAttr={{ dataField: "ToDocumentNo" }} visible={true} caption='Document No' alignment='left' allowGrouping={false} />
												</CustomizedLookup>
											</div>
										</div>
										<div>
											<Button
												style={{ position: "absolute", top: "65%", left: "75%" }}
												text='Clear Filter'
												stylingMode='text'
												type='default'
												onClick={() => {
													removeParamValue("DocNoList");
													removeParamValue("FromDocNo");
													removeParamValue("ToDocNo");
													setSelectedDocNo(0);
												}}
												elementAttr={{ dataField: "FilterNumber" }}
											/>
										</div>
									</div>
								</Popover>
							</TextBox>
						</div>
					)}
					{selectedDocNo === 2 && (
						<div>
							<TextBox
								value={returnSelectedKeys("DocNo").length !== 0 ? `${returnSelectedKeys("DocNo").length} Selected  (${returnSelectedKeys("DocNo")})` : `${returnSelectedKeys("DocNo").length} Selected`}
								style={{ marginTop: "-35px" }}
								height={0}
								showClearButton={false}
								width={"250px"}>
								<DropDownBox
									// onClosed={(e) => closeDropDownBox(e, "Customer")}
									ref={dropdownRef}
									// opened={openCustSpecific}
									valueExpr='code'
									deferRendering={false}
									displayExpr='code'
									showClearButton={false}
									contentRender={(e) => DataGridMultiRender("DocNo")}
									defaultOpened={true}
									width={"250px"}
									dropDownOptions={{ width: "auto" }}
								/>
							</TextBox>
						</div>
					)}
				</div>
			</div>

			{!isJL && isGL && (
				<div className='popup-form-item-container1'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'> Payment Method </div>
						{selectedPayMethod === 0 && (
							<SelectBox
								width={"250px"}
								dataSource={filterTypes}
								value={selectedPayMethod}
								displayExpr={"Type"}
								valueExpr={"ID"}
								elementAttr={{ dataField: "FilterNumber" }}
								onValueChanged={(e) => {
									valueOnChange(e, "PayMethod");
								}}
							/>
						)}
						{selectedPayMethod === 1 && (
							<div onClick={() => setOpenPayMethodRange(true)}>
								<TextBox
									value={returnSelectedValue("FromPayMethod") || returnSelectedValue("ToPayMethod") ? `${returnSelectedValue("FromPayMethod")} to ${returnSelectedValue("ToPayMethod")}` : "No Range Selected Yet"}
									// useMaskBehavior={true} // Enable mask behavior
									// mask="cccccc" // Define the input mask
									id='PaymentMethod'
									style={{ marginRight: "10px" }}
									width={"250px"}>
									<Popover
										target='#PaymentMethod'
										showEvent={"click"}
										// hideEvent="mouseleave"
										position='bottom'
										visible={openPayMethodRange}
										onHiding={(e) => popFunction(e, "PayMethod")}
										dragEnabled={false}
										hideOnOutsideClick={true}
										width={500}
										height={125}>
										<div>
											<div className='popup-form-item-container2'>
												<div>
													<label>From</label>
													<CustomizedLookup
														ref={fromPayMethodLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={fromPayMethodList}
														displayExpr={"name"}
														valueExpr={"name"}
														value={returnSelectedValue("FromPayMethod")}
														onSelectionChanged={(e) => {
															updateParamValue("FromPayMethod", e.value);
														}}
														dataSourceURL={"api/Utilities/GetGLBKAndCSAccount"}
														displayText={returnSelectedValue("FromPayMethod")}
														onDataSourceChanged={setFromPayMethodList}>
														<Column dataField='code' elementAttr={{ dataField: "PayMethodCode" }} visible={true} caption='Payment Method Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "PayMethodName" }} visible={true} caption='Payment Method Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
												<div>
													<label>To</label>

													<CustomizedLookup
														ref={toPayMethodLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={toPayMethodList}
														displayExpr={"name"}
														valueExpr={"name"}
														value={returnSelectedValue("ToPayMethod")}
														onSelectionChanged={(e) => {
															updateParamValue("ToPayMethod", e.value);
														}}
														dataSourceURL={"api/Utilities/GetGLBKAndCSAccount"}
														displayText={returnSelectedValue("ToPayMethod")}
														onDataSourceChanged={setToPayMethodList}>
														<Column dataField='code' elementAttr={{ dataField: "PayMethodCode" }} visible={true} caption='Payment Method Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "PayMethodName" }} visible={true} caption='Payment Method Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
											</div>
											<div>
												<Button
													style={{ position: "absolute", top: "65%", left: "75%" }}
													text='Clear Filter'
													stylingMode='text'
													type='default'
													onClick={() => {
														removeParamValue("PayMethodList");
														removeParamValue("FromPayMethod");
														removeParamValue("ToPayMethod");
														setSelectedPayMethod(0);
													}}
													elementAttr={{ dataField: "FilterNumber" }}
												/>
											</div>
										</div>
									</Popover>
								</TextBox>
							</div>
						)}
						{selectedPayMethod === 2 && (
							<div>
								<TextBox
									value={returnSelectedKeys("PayMethod").length !== 0 ? `${returnSelectedKeys("PayMethod").length} Selected  (${returnSelectedKeys("PayMethod")})` : `${returnSelectedKeys("PayMethod").length} Selected`}
									style={{ marginTop: "-35px" }}
									height={0}
									showClearButton={false}
									width={"250px"}>
									<DropDownBox
										// onClosed={(e) => closeDropDownBox(e, "Customer")}
										ref={dropdownRef}
										// opened={openCustSpecific}
										valueExpr='code'
										deferRendering={false}
										displayExpr='code'
										showClearButton={false}
										contentRender={(e) => DataGridMultiRender("PayMethod")}
										defaultOpened={true}
										width={"250px"}
										acceptCustomValue={true}
										dropDownOptions={{ width: "auto" }}
									/>
								</TextBox>
							</div>
						)}
					</div>
				</div>
			)}

			{!isJL && (
				<div className='popup-form-item-container3'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'> Salesman </div>
						{selectedSales === 0 && (
							<SelectBox
								width={"250px"}
								dataSource={filterTypes}
								value={selectedSales}
								displayExpr={"Type"}
								valueExpr={"ID"}
								elementAttr={{ dataField: "FilterNumber" }}
								onValueChanged={(e) => {
									valueOnChange(e, "Salesman");
								}}
							/>
						)}
						{selectedSales === 1 && (
							<div onClick={() => setOpenSalesRange(true)}>
								<TextBox
									value={returnSelectedValue("FromSales") || returnSelectedValue("ToSales") ? `${returnSelectedValue("FromSales")} to ${returnSelectedValue("ToSales")}` : "No Range Selected Yet"}
									// useMaskBehavior={true} // Enable mask behavior
									// mask="cccccc" // Define the input mask
									id='Salesman'
									style={{ marginRight: "10px" }}
									width={"250px"}>
									<Popover
										target='#Salesman'
										showEvent={"click"}
										// hideEvent="mouseleave"
										position='bottom'
										visible={openSalesRange}
										onHiding={(e) => popFunction(e, "Salesman")}
										dragEnabled={false}
										hideOnOutsideClick={true}
										width={500}
										height={125}>
										<div>
											<div className='popup-form-item-container2'>
												<div>
													<label>From</label>
													<CustomizedLookup
														ref={fromSalesmanLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={fromSalesmanList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("FromSales")}
														onSelectionChanged={(e) => {
															updateParamValue("FromSales", e.value);
														}}
														dataSourceURL={"api/Utilities/GetSalesmans"}
														displayText={returnSelectedValue("FromSales")}
														onDataSourceChanged={setFromSalesmanList}>
														<Column dataField='code' elementAttr={{ dataField: "SalesmanCode" }} visible={true} caption='Salesman Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "SalesmanName" }} visible={true} caption='Salesman Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
												<div>
													<label>To</label>

													<CustomizedLookup
														ref={toSalesmanLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={toSalesmanList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("ToSales")}
														onSelectionChanged={(e) => {
															updateParamValue("ToSales", e.value);
														}}
														dataSourceURL={"api/Utilities/GetSalesmans"}
														displayText={returnSelectedValue("ToSales")}
														onDataSourceChanged={setToSalesmanList}>
														<Column dataField='code' elementAttr={{ dataField: "SalesmanCode" }} visible={true} caption='Salesman Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "SalesmanName" }} visible={true} caption='Salesman Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
											</div>
											<div>
												<Button
													style={{ position: "absolute", top: "65%", left: "75%" }}
													text='Clear Filter'
													stylingMode='text'
													type='default'
													onClick={() => {
														removeParamValue("SalesList");
														removeParamValue("FromSales");
														removeParamValue("ToSales");
														setSelectedSales(0);
													}}
													elementAttr={{ dataField: "FilterNumber" }}
												/>
											</div>
										</div>
									</Popover>
								</TextBox>
							</div>
						)}
						{selectedSales === 2 && (
							<div>
								<TextBox
									value={returnSelectedKeys("Salesman").length !== 0 ? `${returnSelectedKeys("Salesman").length} Selected  (${returnSelectedKeys("Salesman")})` : `${returnSelectedKeys("Salesman").length} Selected`}
									style={{ marginTop: "-35px" }}
									height={0}
									showClearButton={false}
									width={"250px"}>
									<DropDownBox
										ref={dropdownRef}
										// opened={openSalesSpecific}
										// onClosed={(e) => closeDropDownBox(e, "Salesman")}
										valueExpr='code'
										deferRendering={false}
										displayExpr='code'
										showClearButton={false}
										contentRender={() => DataGridMultiRender("Salesman")}
										defaultOpened={true}
										width={"250px"}
										dropDownOptions={{ width: "auto" }}
									/>
								</TextBox>
							</div>
						)}
					</div>

					{!isJL && (
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'> Area </div>
							{selectedArea === 0 && (
								<SelectBox
									width={"250px"}
									dataSource={filterTypes}
									value={selectedArea}
									displayExpr={"Type"}
									valueExpr={"ID"}
									elementAttr={{ dataField: "FilterNumber" }}
									onValueChanged={(e) => {
										valueOnChange(e, "Area");
									}}
								/>
							)}
							{selectedArea === 1 && (
								<div onClick={() => setOpenAreaRange(true)}>
									<TextBox
										value={returnSelectedValue("FromArea") || returnSelectedValue("ToArea") ? `${returnSelectedValue("FromArea")} to ${returnSelectedValue("ToArea")}` : "No Range Selected Yet"}
										// useMaskBehavior={true} // Enable mask behavior
										// mask="cccccc" // Define the input mask
										id='Area'
										style={{ marginRight: "10px" }}
										width={"250px"}>
										<Popover
											target='#Area'
											showEvent={"click"}
											// hideEvent="mouseleave"
											position='bottom'
											visible={openAreaRange}
											onHiding={(e) => popFunction(e, "Area")}
											dragEnabled={false}
											hideOnOutsideClick={true}
											width={500}
											height={125}>
											<div>
												<div className='popup-form-item-container2'>
													<div>
														<label>From</label>
														<CustomizedLookup
															ref={fromAreaLookupRef}
															className={"ar-listing-datagrid"}
															dataSource={fromAreaList}
															displayExpr={"code"}
															valueExpr={"code"}
															value={returnSelectedValue("FromArea")}
															onSelectionChanged={(e) => {
																updateParamValue("FromArea", e.value);
															}}
															dataSourceURL={"api/Utilities/GetAreas"}
															displayText={returnSelectedValue("FromArea")}
															onDataSourceChanged={setFromAreaList}>
															<Column dataField='code' elementAttr={{ dataField: "AreaCode" }} visible={true} caption='Area Code' alignment='left' allowGrouping={false} />
															<Column dataField='name' elementAttr={{ dataField: "AreaName" }} visible={true} caption='Area Name' alignment='left' allowGrouping={false} />
														</CustomizedLookup>
													</div>
													<div>
														<label>To</label>

														<CustomizedLookup
															ref={toAreaLookupRef}
															className={"ar-listing-datagrid"}
															dataSource={toAreaList}
															displayExpr={"code"}
															valueExpr={"code"}
															value={returnSelectedValue("ToArea")}
															onSelectionChanged={(e) => {
																updateParamValue("ToArea", e.value);
															}}
															dataSourceURL={"api/Utilities/GetAreas"}
															displayText={returnSelectedValue("ToArea")}
															onDataSourceChanged={setToAreaList}>
															<Column dataField='code' elementAttr={{ dataField: "AreaCode" }} visible={true} caption='Area Code' alignment='left' allowGrouping={false} />
															<Column dataField='name' elementAttr={{ dataField: "AreaName" }} visible={true} caption='Area Name' alignment='left' allowGrouping={false} />
														</CustomizedLookup>
													</div>
												</div>
												<div>
													<Button
														style={{ position: "absolute", top: "65%", left: "75%" }}
														text='Clear Filter'
														stylingMode='text'
														type='default'
														onClick={() => {
															removeParamValue("AreaList");
															removeParamValue("FromArea");
															removeParamValue("ToArea");
															setSelectedArea(0);
														}}
														elementAttr={{ dataField: "FilterNumber" }}
													/>
												</div>
											</div>
										</Popover>
									</TextBox>
								</div>
							)}
							{selectedArea === 2 && (
								<div>
									<TextBox
										value={returnSelectedKeys("Area").length !== 0 ? `${returnSelectedKeys("Area").length} Selected  (${returnSelectedKeys("Area")})` : `${returnSelectedKeys("Area").length} Selected`}
										style={{ marginTop: "-35px" }}
										height={0}
										showClearButton={false}
										width={"250px"}>
										<DropDownBox
											ref={dropdownRef}
											// opened={openAreaSpecific}
											// onClosed={(e) => closeDropDownBox(e, "Area")}
											valueExpr='code'
											deferRendering={false}
											displayExpr='code'
											showClearButton={false}
											contentRender={() => DataGridMultiRender("Area")}
											defaultOpened={true}
											width={"250px"}
											dropDownOptions={{ width: "auto" }}
										/>
									</TextBox>
								</div>
							)}
						</div>
					)}
				</div>
			)}

			<div className='popup-form-item-container3'>
				{!isJL && (
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'> Project </div>
						{selectedProject === 0 && (
							<SelectBox
								width={"250px"}
								dataSource={filterTypes}
								value={selectedProject}
								displayExpr={"Type"}
								valueExpr={"ID"}
								elementAttr={{ dataField: "FilterNumber" }}
								onValueChanged={(e) => {
									valueOnChange(e, "Project");
								}}
							/>
						)}
						{selectedProject === 1 && (
							<div onClick={() => setOpenProjectRange(true)}>
								<TextBox
									value={returnSelectedValue("FromMainProject") || returnSelectedValue("ToMainProject") ? `${returnSelectedValue("FromMainProject")} to ${returnSelectedValue("ToMainProject")}` : "No Range Selected Yet"}
									// useMaskBehavior={true} // Enable mask behavior
									// mask="cccccc" // Define the input mask
									id='Project'
									style={{ marginRight: "10px" }}
									width={"250px"}>
									<Popover
										target='#Project'
										showEvent={"click"}
										// hideEvent="mouseleave"
										position='bottom'
										visible={openProjectRange}
										onHiding={(e) => popFunction(e, "Project")}
										dragEnabled={false}
										hideOnOutsideClick={true}
										width={500}
										height={125}>
										<div>
											<div className='popup-form-item-container2'>
												<div>
													<label>From</label>
													<CustomizedLookup
														ref={fromMainProjectLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={fromMainProjectList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("FromMainProject")}
														onSelectionChanged={(e) => {
															updateParamValue("FromMainProject", e.value);
														}}
														dataSourceURL={"api/Utilities/GetProjects"}
														displayText={returnSelectedValue("FromMainProject")}
														onDataSourceChanged={setFromMainProjectList}>
														<Column dataField='code' elementAttr={{ dataField: "MainProjectCode" }} visible={true} caption='Project Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "MainProjectName" }} visible={true} caption='Project Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
												<div>
													<label>To</label>

													<CustomizedLookup
														ref={toMainProjectLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={toMainProjectList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("ToMainProject")}
														onSelectionChanged={(e) => {
															updateParamValue("ToMainProject", e.value);
														}}
														dataSourceURL={"api/Utilities/GetProjects"}
														displayText={returnSelectedValue("ToMainProject")}
														onDataSourceChanged={setToMainProjectList}>
														<Column dataField='code' elementAttr={{ dataField: "MainProjectCode" }} visible={true} caption='Project Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "MainProjectName" }} visible={true} caption='Project Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
											</div>
											<div>
												<Button
													style={{ position: "absolute", top: "65%", left: "75%" }}
													text='Clear Filter'
													stylingMode='text'
													type='default'
													onClick={() => {
														removeParamValue("MainProjectList");
														removeParamValue("FromMainProject");
														removeParamValue("ToMainProject");
														setSelectedProject(0);
													}}
													elementAttr={{ dataField: "FilterNumber" }}
												/>
											</div>
										</div>
									</Popover>
								</TextBox>
							</div>
						)}
						{selectedProject === 2 && (
							<div>
								<TextBox
									value={returnSelectedKeys("Project").length !== 0 ? `${returnSelectedKeys("Project").length} Selected  (${returnSelectedKeys("Project")})` : `${returnSelectedKeys("Project").length} Selected`}
									style={{ marginTop: "-35px" }}
									height={0}
									showClearButton={false}
									width={"250px"}>
									<DropDownBox
										// onClosed={(e) => closeDropDownBox(e, "Project")}
										ref={dropdownRef}
										// opened={openCustSpecific}
										valueExpr='code'
										deferRendering={false}
										displayExpr='code'
										showClearButton={false}
										contentRender={(e) => DataGridMultiRender("Project")}
										defaultOpened={true}
										width={"250px"}
										dropDownOptions={{ width: "auto" }}
									/>
								</TextBox>
							</div>
						)}
					</div>
				)}
				{!isContra && (
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'> Project By Item </div>
						{selectedDetailProject === 0 && (
							<SelectBox
								width={"250px"}
								dataSource={filterTypes}
								value={selectedDetailProject}
								displayExpr={"Type"}
								valueExpr={"ID"}
								elementAttr={{ dataField: "FilterNumber" }}
								onValueChanged={(e) => {
									valueOnChange(e, "DetailProject");
								}}
							/>
						)}
						{selectedDetailProject === 1 && (
							<div onClick={() => setOpenDetailProjectRange(true)}>
								<TextBox
									value={returnSelectedValue("FromDetailProject") || returnSelectedValue("ToDetailProject") ? `${returnSelectedValue("FromDetailProject")} to ${returnSelectedValue("ToDetailProject")}` : "No Range Selected Yet"}
									// useMaskBehavior={true} // Enable mask behavior
									// mask="cccccc" // Define the input mask
									id='DetailProject'
									style={{ marginRight: "10px" }}
									width={"250px"}>
									<Popover
										target='#DetailProject'
										showEvent={"click"}
										// hideEvent="mouseleave"
										position='bottom'
										visible={openDetailProjectRange}
										onHiding={(e) => popFunction(e, "DetailProject")}
										dragEnabled={false}
										hideOnOutsideClick={true}
										width={500}
										height={125}>
										<div>
											<div className='popup-form-item-container2'>
												<div>
													<label>From</label>
													<CustomizedLookup
														ref={fromDetailProjectLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={fromDetailProjectList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("FromDetailProject")}
														onSelectionChanged={(e) => {
															updateParamValue("FromDetailProject", e.value);
														}}
														dataSourceURL={"api/Utilities/GetProjects"}
														displayText={returnSelectedValue("FromDetailProject")}
														onDataSourceChanged={setFromDetailProjectList}>
														<Column dataField='code' elementAttr={{ dataField: "DetailProjectCode" }} visible={true} caption='Project Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "DetailProjectName" }} visible={true} caption='Project Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
												<div>
													<label>To</label>

													<CustomizedLookup
														ref={toDetailProjectLookupRef}
														className={"ar-listing-datagrid"}
														dataSource={toDetailProjectList}
														displayExpr={"code"}
														valueExpr={"code"}
														value={returnSelectedValue("ToDetailProject")}
														onSelectionChanged={(e) => {
															updateParamValue("ToDetailProject", e.value);
														}}
														dataSourceURL={"api/Utilities/GetProjects"}
														displayText={returnSelectedValue("ToDetailProject")}
														onDataSourceChanged={setToDetailProjectList}>
														<Column dataField='code' elementAttr={{ dataField: "DetailProjectCode" }} visible={true} caption='Project Code' alignment='left' allowGrouping={false} />
														<Column dataField='name' elementAttr={{ dataField: "DetailProjectName" }} visible={true} caption='Project Name' alignment='left' allowGrouping={false} />
													</CustomizedLookup>
												</div>
											</div>
											<div>
												<Button
													style={{ position: "absolute", top: "65%", left: "75%" }}
													text='Clear Filter'
													stylingMode='text'
													type='default'
													onClick={() => {
														removeParamValue("DetailProjectList");
														removeParamValue("FromDetailProject");
														removeParamValue("ToDetailProject");
														setSelectedDetailProject(0);
													}}
													elementAttr={{ dataField: "FilterNumber" }}
												/>
											</div>
										</div>
									</Popover>
								</TextBox>
							</div>
						)}
						{selectedDetailProject === 2 && (
							<div>
								<TextBox
									value={returnSelectedKeys("DetailProject").length !== 0 ? `${returnSelectedKeys("DetailProject").length} Selected  (${returnSelectedKeys("DetailProject")})` : `${returnSelectedKeys("DetailProject").length} Selected`}
									style={{ marginTop: "-35px" }}
									height={0}
									showClearButton={false}
									width={"250px"}>
									<DropDownBox
										// onClosed={(e) => closeDropDownBox(e, "Project")}
										ref={dropdownRef}
										// opened={openCustSpecific}
										valueExpr='code'
										deferRendering={false}
										displayExpr='code'
										showClearButton={false}
										contentRender={(e) => DataGridMultiRender("DetailProject")}
										defaultOpened={true}
										width={"250px"}
										dropDownOptions={{ width: "auto" }}
									/>
								</TextBox>
							</div>
						)}
					</div>
				)}
			</div>

			<ErrorPopUpForm ref={popupMessageRef} />
		</div>
	);
});
