import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import baseapi from '../../api/baseapi';
import { AuthProvider, useAuth } from '../../contexts/auth';
import CompanySelectionForm from '../popup-form/CompanySelectionForm';
const CompanySelection = ({ visible, onClose }) => {
    const { user, loading, companySelection, signOut,showCompanySelection } = useAuth();

    const handleCompanySelectionClose = useCallback(() => {
        showCompanySelection(false);
    }, []);

    
    return (
        <>
            <img src={"/images/nav-logo.png"} alt={"Greenplus Accounting"} width={110} id='nav-logo' style={{ paddingTop: '15px' }} />
            <img className="wave" src="images/wave.svg" alt="wave" />
            <CompanySelectionForm visible={companySelection} onClose={handleCompanySelectionClose} backHiding={true} confirmAlert={false} />;
        </>
    );
};

export default CompanySelection;
