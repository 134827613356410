import React, { useState, useEffect, useRef, useCallback } from "react";
import Listing from "../../components/listing/newListing";
import Container from '../../components/container';
import { Column } from 'devextreme-react/data-grid';
import ModelForm from "./ModelForm";
export default function ModelListing() {
    const apiURL = `/api/Model`;
    const [currentEditID, setCurrentEditID] = useState(null);
    function handleEdit(e) {
        const id = e.row.key;
        setCurrentEditID(id);
    }

    return <Container>
        <div id='normal-listing'>
            <Listing
                listingTitle="Model"
                apiURL={apiURL}
                listingURL="/api/Model/list"
                sortColumn={"Code"}
                dateFilter={false}
                storageName={"SettingModelListing"}
                className={"maintenance-listing-datagrid"}
                handleEdit={handleEdit}
                onAddClick={setCurrentEditID}
            >
                <Column dataField={"Code"} />
                <Column dataField={"Name"} />
                <Column dataField={"Active"} />
            </Listing>

            <ModelForm
                formID={currentEditID}
                closePopup={setCurrentEditID}
            />
        </div>
    </Container>

}

