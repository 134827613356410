import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import Quill from 'devextreme-quill'; //or import * as Quill from "devextreme-quill";
import HtmlEditor, {
    Toolbar, MediaResizing, ImageUpload, Item,
} from 'devextreme-react/html-editor';
import utils from '../../utils/common';

export default forwardRef(function CustomizedHtmlEditor(props, ref){

    const htmlEditorRef = useRef(null);

    // HTML Editor attributes
    const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
    const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
    const headerValues = [false, 1, 2, 3, 4, 5];
    const fontSizeOptions = {
        inputAttr: {
            'aria-label': 'Font size',
        },
        value: !utils.isNullOrEmpty(props.defaultFontSize) ? props.defaultFontSize : '12pt'
    };

    const fontFamilyOptions = {
        inputAttr: {
            'aria-label': 'Font family',
        },
    };
    const headerOptions = {
        inputAttr: {
            'aria-label': 'Font family',
        },
    };

    const tabs = [
        { name: 'From This Device', value: ['file'] },
        { name: 'From the Web', value: ['url'] },
        { name: 'Both', value: ['file', 'url'] },
    ];
    
    useEffect(() => {
        if(props.enabledDivTag){
            let Block = Quill.import('blots/block');
            Block.tagName = 'div';
            Quill.register(Block);
        }

        if(htmlEditorRef.current !== null){
            //Customized the tab key event
            htmlEditorRef.current.instance.registerKeyHandler('tab', (event) => {
                const range = htmlEditorRef.current.instance.getSelection();
                htmlEditorRef.current.instance.insertText(range.index, "\xA0\xA0\xA0\xA0");
            });

            //Customized the space key event
            if(!utils.isNullOrEmpty(props.spaceCustomized)){
                if(props.spaceCustomized === true){
                    htmlEditorRef.current.instance.registerKeyHandler('space', (event) => {
                        const range = htmlEditorRef.current.instance.getSelection();
                        htmlEditorRef.current.instance.insertText(range.index, "\xA0");
                    });
                }
            }
        }

    }, [htmlEditorRef]);

    useImperativeHandle(ref, () => ({
        getValue() {
            if(htmlEditorRef.current !== null){
                return htmlEditorRef.current.instance.option("value");
            }
        },
        setValue(value){
            if(htmlEditorRef.current !== null){
                return htmlEditorRef.current.instance.option("value", value);
            }
        },
    }));

    return(
        <HtmlEditor
            ref={htmlEditorRef}
            height={"400px"}
            valueType="html"
        >
            <MediaResizing enabled={true} />
            <ImageUpload tabs={tabs[2].value} fileUploadMode="base64" />
            <Toolbar multiline={true}>
                <Item name="undo" />
                <Item name="redo" />
                <Item name="separator" />
                <Item
                name="size"
                acceptedValues={sizeValues}
                options={fontSizeOptions}
                />
                <Item
                name="font"
                acceptedValues={fontValues}
                options={fontFamilyOptions}
                />
                <Item name="separator" />
                <Item name="bold" />
                <Item name="italic" />
                <Item name="strike" />
                <Item name="underline" />
                <Item name="separator" />
                <Item name="alignLeft" />
                <Item name="alignCenter" />
                <Item name="alignRight" />
                <Item name="alignJustify" />
                <Item name="separator" />
                <Item name="orderedList" />
                <Item name="bulletList" />
                <Item name="separator" />
                <Item
                    name="header"
                    acceptedValues={headerValues}
                    options={headerOptions}
                />
                <Item name="separator" />
                <Item name="color" />
                <Item name="background" />
                <Item name="separator" />
                <Item name="link" />
                <Item name="image" visible={utils.isNullOrEmpty(props.imageEnabled) ? false : props.imageEnabled}/>
                <Item name="separator" />
                <Item name="clear" />
                <Item name="codeBlock" visible={utils.isNullOrEmpty(props.codeEnabled) ? false : props.codeEnabled}/>
                <Item name="blockquote" />
                <Item name="separator" />
                <Item name="insertTable" visible={utils.isNullOrEmpty(props.tableEnabled) ? false : props.tableEnabled}/>
                <Item name="deleteTable" visible={utils.isNullOrEmpty(props.tableEnabled) ? false : props.tableEnabled}/>
                <Item name="insertRowAbove" visible={utils.isNullOrEmpty(props.tableEnabled) ? false : props.tableEnabled}/>
                <Item name="insertRowBelow" visible={utils.isNullOrEmpty(props.tableEnabled) ? false : props.tableEnabled}/>
                <Item name="deleteRow" visible={utils.isNullOrEmpty(props.tableEnabled) ? false : props.tableEnabled}/>
                <Item name="insertColumnLeft" visible={utils.isNullOrEmpty(props.tableEnabled) ? false : props.tableEnabled}/>
                <Item name="insertColumnRight" visible={utils.isNullOrEmpty(props.tableEnabled) ? false : props.tableEnabled}/>
                <Item name="deleteColumn" visible={utils.isNullOrEmpty(props.tableEnabled) ? false : props.tableEnabled}/>
            </Toolbar>
        </HtmlEditor>
    );
});