import React, { useState, useEffect } from "react";
import { Column, TotalItem, Summary } from "devextreme-react/data-grid";
import Listing from "../../components/listing/report-component/reportsListing";
import utils from "../../utils/common";
import baseapi from "../../api/baseapi";
import ARCreditNoteForm from "../AR-Module/credit-notes/form";
import ARDebitNoteForm from "../AR-Module/debit-notes/form";
import ARReceiptForm from "../AR-Module/receipt/form";
import ARSalesForm from "../AR-Module/sales/form";
import SalesInvoiceMainForm from "../Sales-Module/invoice-main/form";
import APCreditNoteForm from "../AP-Module/credit-notes/form";
import APDebitNoteForm from "../AP-Module/debit-notes/form";
import APPaymentForm from "../AP-Module/payment/form";
import APPurchaseForm from "../AP-Module/purchase/form";
import CashBookEntryForm from "../GL-Module/cash-book-entry/form";
import ARRefundForm from "../AR-Module/refund/form";
import APRefundForm from "../AP-Module/refund/form";
import SalesCashSalesForm from "../Sales-Module/cash-sales/form";
import SalesCreditNoteForm from "../Sales-Module/credit-note/form";
import SalesDebitNoteForm from "../Sales-Module/debit-note/form";
import SalesDeliveryOrderForm from "../Sales-Module/delivery-order/form";
import SalesQuotationForm from "../Sales-Module/quotation/form";
import SalesOrderForm from "../Sales-Module/order/form";
import PurchaseCashPurchaseForm from "../Purchase-Module/cash-purchase/form";
import PurchaseDebitNoteForm from "../Purchase-Module/debit-note/form";
import PurchaseQuotationForm from "../Purchase-Module/quotation/form";
import PurchaseInvoiceForm from "../Purchase-Module/invoice/form";
import PurchaseOrderForm from "../Purchase-Module/order/form";
import PurchaseReturnForm from "../Purchase-Module/purchase-return/form";
import PurchaseReceivedForm from "../Purchase-Module/received/form";
import PurchaseRequestForm from "../Purchase-Module/request/form";
import ContraForm from "../contra/form";
import JournalEntryForm from "../GL-Module/journal-entry/form";

function creditNote(isAR = false) {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy" },
				{ dataField: "Code", visible: false },
				{ dataField: "Type", caption: isAR ? "Customer Name" : "Supplier Name" },
				{ dataField: "Description", visible: false },
				{ dataField: "MainProjectCode", caption: "Project", visible: false },
				{ dataField: "Area", visible: false },
				{ dataField: "Salesman", visible: false },
				{ dataField: "Forex" },
				{ dataField: "Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "LocalAmount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "OutstandingAmount", caption: "Unapplied Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "OffsetAmount", caption: "Offset Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				// { dataField: "LocalOutstandingAmount", caption: "Local Unapplied Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "Journal" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				},
				{
					key: "detailCNAmount",
					column: "Amount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailCNLocalAmount",
					column: "LocalAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailCNOutstandingAmount",
					column: "OutstandingAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailCNOffsetAmount",
					column: "OffsetAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function debitNote(isAR = false) {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy" },
				{ dataField: "Code" },
				{ dataField: "Type", caption: isAR ? "Customer Name" : "Supplier Name" },
				{ dataField: "Description" },
				{ dataField: "MainProjectCode", caption: "Project" },
				{ dataField: "Area", visible: false },
				{ dataField: "Salesman" },
				{ dataField: "Forex" },
				{ dataField: "Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "TaxAmount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "MainForexAmount", caption: "Sub Total", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "LocalAmount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "Journal" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				},
				{
					key: "detailDNAmount",
					column: "Amount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailDNTaxAmount",
					column: "TaxAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailDNMainForexAmount",
					column: "MainForexAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailDNLocalAmount",
					column: "LocalAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function payment(isAR = false) {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy" },
				{ dataField: "Code", visible: false },
				{ dataField: "Type", caption: isAR ? "Customer Name" : "Supplier Name" },
				{ dataField: "Description", visible: false },
				{ dataField: "MainProjectCode", caption: "Project", visible: false },
				{ dataField: "Area", visible: false },
				{ dataField: "Salesman", visible: false },
				{ dataField: "Forex" },
				{ dataField: "Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "LocalAmount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "OutstandingAmount", caption: "Unapplied Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "OffsetAmount", caption: "Offset Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "BankCharges", caption: "Bank Charge", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				// { dataField: "LocalOutstandingAmount", caption: "Local Unapplied Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "Journal" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				},
				{
					key: "detailAmount",
					column: "Amount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailLocalAmount",
					column: "LocalAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailOutstandingAmount",
					column: "OutstandingAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailOffsetAmount",
					column: "OffsetAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailBankCharges",
					column: "BankCharges",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function invoice(isAR = false) {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy" },
				{ dataField: "Code" },
				{ dataField: "Type", caption: isAR ? "Customer Name" : "Supplier Name" },
				{ dataField: "Description" },
				{ dataField: "MainProjectCode", caption: "Project" },
				{ dataField: "Area", visible: false },
				{ dataField: "Salesman" },
				{ dataField: "Forex" },
				{ dataField: "Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "TaxAmount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "MainForexAmount", caption: "Sub Total", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "LocalAmount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "Journal" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				},
				{
					key: "detailAmount",
					column: "Amount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailTaxAmount",
					column: "TaxAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailMainForexAmount",
					column: "MainForexAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailLocalAmount",
					column: "LocalAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function contra(isAR = false) {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy" },
				{ dataField: "Code", visible: false },
				{ dataField: "Type", caption: isAR ? "Customer Name" : "Supplier Name" },
				{ dataField: "Description", visible: false },
				{ dataField: "MainProjectCode", caption: "Project", visible: false },
				{ dataField: "Area", visible: false },
				{ dataField: "Salesman", visible: false },
				{ dataField: "Forex" },
				{ dataField: "Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "LocalAmount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "TotalOutstandingAmount", caption: "Unapplied Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "OutstandingAmount", caption: "Offset Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "OtherType", caption: isAR ? "Supplier Name" : "Customer Name" },
				{ dataField: "OtherForex", caption: "Forex" },
				{ dataField: "OtherAmount", caption: isAR ? "Amount (Supplier)" : "Amount (Customer)", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				// { dataField: "LocalOutstandingAmount", caption: "Local Unapplied Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "Journal" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				},
				{
					key: "detailAmount",
					column: "Amount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailLocalAmount",
					column: "LocalAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailOutstandingAmount",
					column: "OutstandingAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailOffsetAmount",
					column: "OffsetAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailOtherAmount",
					column: "OtherAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function refund(isAR = false) {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy" },
				{ dataField: "Code", visible: false },
				{ dataField: "Type", caption: isAR ? "Customer Name" : "Supplier Name" },
				{ dataField: "Description", visible: false },
				{ dataField: "MainProjectCode", caption: "Project", visible: false },
				{ dataField: "Area", visible: false },
				{ dataField: "Salesman", visible: false },
				{ dataField: "Forex" },
				{ dataField: "Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "LocalAmount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "OutstandingAmount", caption: "Unapplied Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "OffsetAmount", caption: "Offset Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "BankCharges", caption: "Bank Charge", format: { type: "fixedPoint", precision: 2 }, dataType: "number" }
				// { dataField: "LocalOutstandingAmount", caption: "Local Unapplied Amount", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				// { dataField: "Journal" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				},
				{
					key: "detailAmount",
					column: "Amount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailLocalAmount",
					column: "LocalAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailOutstandingAmount",
					column: "OutstandingAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailOffsetAmount",
					column: "OffsetAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailBankCharges",
					column: "BankCharges",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function journal() {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo", caption: "Doc No" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy" },
				{ dataField: "Description" },
				{ dataField: "JournalType" },
				{ dataField: "ForexCode", caption: "Forex" },
				{ dataField: "LocalRate", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "TotalForexDebit", caption: "Debit Incl. Tax", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "TotalForexCredit", caption: "Credit Incl. Tax", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "TransactionTypeCode", caption: "Type" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				},
				{
					key: "detailTotalForexDebit",
					column: "TotalForexDebit",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailTotalForexCredit",
					column: "TotalForexCredit",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function journalDetail() {
	return [
		{
			detailColumns: [
				{ dataField: "ChartCode", caption: "Chart Code" },
				{ dataField: "GLDescription", caption: "GL Desc" },
				{ dataField: "DetailDesc", caption: "Description" },
				{ dataField: "DetailProjectCode", caption: "Project" },
				{ dataField: "ForexDebit", caption: "Debit", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "ForexCredit", caption: "Credit", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "LocalDebit", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "LocalCredit", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "TaxCode" },
				{ dataField: "TaxRate", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "ForexTaxAmount", caption: "Tax Include", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "DebitIncludeTax", caption: "Debit Incl. Tax", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "CreditIncludeTax", caption: "Credit Incl. Tax", format: { type: "fixedPoint", precision: 2 } }
			]
		},
		{
			detailSummaryItems: [
				{
					key: "detailTotal",
					summaryType: "sum",
					showInColumn: "ChartCode",
					customizeText: () => {
						return "Total";
					}
				},
				{
					key: "detailForexDebit",
					column: "ForexDebit",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailForexCredit",
					column: "ForexCredit",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailLocalDebit",
					column: "LocalDebit",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailLocalCredit",
					column: "LocalCredit",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailDebitIncludeTax",
					column: "DebitIncludeTax",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailCreditIncludeTax",
					column: "CreditIncludeTax",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function cashBook() {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo", caption: "Doc No" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy" },
				{ dataField: "Description" },
				{ dataField: "ChequeNo", caption: "Cheque" },
				{ dataField: "PaymentMethod", caption: "Payment Method" },
				{ dataField: "MainProjectCode", caption: "Project" },
				{ dataField: "ForexCode", caption: "Forex" },
				{ dataField: "LocalRate", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				// { dataField: "TotalForexAmount", caption: "Amount", format: { type: "fixedPoint", precision: 2 } },
				// { dataField: "TotalLocalAmount", caption: "Local Amount", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "TotalForexDebit", caption: "Debit", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "TotalForexCredit", caption: "Credit", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "TransactionTypeCode", caption: "Type" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				}
			]
		}
	];
}

function cashBookDetail() {
	return [
		{
			detailColumns: [
				{ dataField: "ChartCode", caption: "Chart Code" },
				{ dataField: "GLDescription" },
				{ dataField: "Description", caption: "Description" },
				{ dataField: "DetailProjectCode", caption: "Project" },
				{ dataField: "ForexAmount", caption: "Amount", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "LocalAmount", format: { type: "fixedPoint", precision: 2 } }
			]
		},
		{
			detailSummaryItems: [
				{
					key: "detailChartCode",
					column: "ChartCode",
					summaryType: "sum",
					showInColumn: "ChartCode",
					customizeText: () => {
						return "Total";
					}
				},
				{
					key: "detailForexAmount",
					column: "ForexAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailLocalAmount",
					column: "LocalAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function orpv() {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo", caption: "Doc No" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy" },
				{ dataField: "Description" },
				{ dataField: "ChequeNo", caption: "Cheque" },
				{ dataField: "PaymentMethod", caption: "Payment Method" },
				{ dataField: "MainProjectCode", caption: "Project" },
				{ dataField: "ForexCode", caption: "Forex" },
				{ dataField: "LocalRate", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "TotalForexAmount", caption: "Total", format: { type: "fixedPoint", precision: 2 }, dataType: "number" },
				{ dataField: "TransactionTypeCode", caption: "Type" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				}
			]
		}
	];
}

function salPur() {
	return [
		{
			columns: [
				{ dataField: "DocumentID", visible: false },
				{ dataField: "DocumentNo", caption: "Doc No" },
				{ dataField: "Date", dataType: "date", format: "dd/MM/yyyy" },
				{ dataField: "Type", caption: "Code" },
				{ dataField: "Name", caption: "Company Name" },
				{ dataField: "MainProjectCode", caption: "Project" },
				{ dataField: "AreaCode", caption: "Area" },
				{ dataField: "SalesmanCode", caption: "Salesman" },
				{ dataField: "ForexCode", caption: "Forex" },
				{ dataField: "TotalForexAmount", caption: "Total", format: { type: "fixedPoint", precision: 2 }, dataType: "number" }
			]
		},
		{
			summaryItems: [
				{
					key: "detailDocNo",
					column: "DocumentNo",
					summaryType: "count",
					customizeText: (e) => {
						return "Count: " + e.value;
					}
				},
				{
					key: "detailTotalForexAmount",
					column: "TotalForexAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

function salPurDetail(isImport = false) {
	return [
		{
			detailColumns: [
				{ dataField: "StockCode" },
				{ dataField: "StockDesc", caption: "Description" },
				{ dataField: "DetailProjectCode", caption: "Project" },
				{ dataField: "Quantity" },
				{ dataField: "UOMName", caption: "UOM" },
				{ dataField: "UnitPrice", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "NettPrice", caption: "Net Price", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "ForexTaxAmount", caption: "Tax Amount", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "ForexAmount", caption: "Sub Total", format: { type: "fixedPoint", precision: 2 } },
				{ dataField: "ImportDocumentNo", visible: isImport, caption: "From Doc No" },
				{ dataField: "ImportTransCode", visible: isImport, caption: "From Doc Type" }
			]
		},
		{
			detailSummaryItems: [
				{
					key: "detailStockCode",
					column: "StockCode",
					summaryType: "sum",
					showInColumn: "StockCode",
					customizeText: () => {
						return "Total";
					}
				},
				{
					key: "detailUnitPrice",
					column: "UnitPrice",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailNettPrice",
					column: "NettPrice",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailForexTaxAmount",
					column: "ForexTaxAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				},
				{
					key: "detailForexAmount",
					column: "ForexAmount",
					summaryType: "sum",
					customizeText: (e) => {
						return utils.numberToCurrency(e.value);
					}
				}
			]
		}
	];
}

export default function DocumentListing() {
	const [isAR, setIsAR] = useState("null");
	const [isSales, setIsSales] = useState("null");
	const [isGL, setIsGL] = useState(false);
	const [reportName, setReportName] = useState("Customer Invoice Listing by Area Code");
	const [currentEditID, setCurrentEditID] = useState("");
	const [form, setForm] = useState("");
	const [documentModule, setDocumentModule] = useState();
	const [combined, setCombined] = useState([]);
	const [combinedDetails, setCombinedDetails] = useState([]);
	const [url, setUrl] = useState("");

	useEffect(() => {
		const pathname = window.location.pathname;

		const lastIndex = pathname.lastIndexOf("/");
		const lastSegment = lastIndex !== -1 ? pathname.substring(lastIndex + 1) : pathname;
		setUrl(lastSegment);
	}, []);

	useEffect(() => {
		if (url === "ARDocumentListing") {
			setIsGL(false);
			setDocumentModule("Customer Invoice Listing");
			setIsAR(true);
			setIsSales("null");
		} else if (url === "APDocumentListing") {
			setIsGL(false);
			setIsAR(false);
			setIsSales("null");
			setReportName("Supplier Invoice Listing by Supplier Code");
			setDocumentModule("Supplier Invoice Listing");
		} else if (url === "GLDocumentListing") {
			setIsGL(true);
			setIsAR("null");
			setIsSales("null");
			setReportName("GL - Cash Book Listing");
		} else if (url === "SalesDocumentListing") {
			setIsGL(false);
			setIsSales(true);
			setIsAR("null");
			setReportName("Sales Quotation Listing");
			setDocumentModule("Sales Quotation Document Listing");
		} else if (url === "PurchaseDocumentListing") {
			setIsGL(false);
			setReportName("Purchase Quotation Listing");
			setDocumentModule("Purchase Quotation Listing");
			setIsSales(false);
			setIsAR("null");
		}
	}, [url]);

	const docType = [
		{
			TransType: "SA",
			DocumentType: "Sales"
		},
		{
			TransType: "OR-GYYMM",
			DocumentType: "Receipt"
		},
		{
			TransType: "DB",
			DocumentType: "ARDebitNote"
		},
		{
			TransType: "SD",
			DocumentType: "ARDebitNote"
		},
		{
			TransType: "RC",
			DocumentType: "ARCreditNote"
		},
		{
			TransType: "SC",
			DocumentType: "ARCreditNote"
		},
		{
			TransType: "CS-G",
			DocumentType: "CashSales"
		},
		{
			TransType: "SCS",
			DocumentType: "CashSales"
		},
		{
			TransType: "PCP",
			DocumentType: "CashPurchase"
		},
		{
			TransType: "INV",
			DocumentType: "Sales Invoice"
		},
		{
			TransType: "PPI",
			DocumentType: "Purchase Invoice"
		},
		{
			TransType: "INV-G",
			DocumentType: "Invoice"
		},
		{
			TransType: "OPP",
			DocumentType: "Purchase"
		},
		{
			TransType: "PVYYMM",
			DocumentType: "Payment"
		},
		{
			TransType: "PC",
			DocumentType: "APCreditNote"
		},
		{
			TransType: "PD",
			DocumentType: "APDebitNote"
		},
		{
			TransType: "PPD",
			DocumentType: "APDebitNote"
		},
		{
			TransType: "JVYYMM",
			DocumentType: "Journal"
		},
		{
			TransType: "JV",
			DocumentType: "Journal"
		},
		{
			TransType: "GL-PVYYMM",
			DocumentType: "GLPayment"
		},
		{
			TransType: "GL-OR-GYYMM",
			DocumentType: "GLReceipt"
		},
		{
			TransType: "PV",
			DocumentType: "Payment"
		},
		{
			TransType: "GL-PV",
			DocumentType: "GLPayment"
		},
		{
			TransType: "RR",
			DocumentType: "Receipt"
		},
		{
			TransType: "GL-RR",
			DocumentType: "GLReceipt"
		},
		{
			TransType: "RRF",
			DocumentType: "ARRefund"
		},
		{
			TransType: "ARR",
			DocumentType: "ARRefund"
		},
		{
			TransType: "PRF",
			DocumentType: "APRefund"
		},
		{
			TransType: "APR",
			DocumentType: "APRefund"
		},
		{
			TransType: "CT",
			DocumentType: "Contra"
		},
		{
			TransType: "PCT",
			DocumentType: "APContra"
		}
	];

	const salesPurcDocTran = [
		{
			TransType: "PCP-",
			DocumentType: "Purchase Cash Purchase"
		},
		{
			TransType: "POQ-G",
			DocumentType: "Purchase Quotation"
		},
		{
			TransType: "QT-",
			DocumentType: "Sales Quotation"
		},
		{
			TransType: "SOQ",
			DocumentType: "Sales Quotation"
		},
		{
			TransType: "PO-G",
			DocumentType: "Purchase Order"
		},
		{
			TransType: "SO",
			DocumentType: "Sales Order"
		},
		{
			TransType: "DO",
			DocumentType: "Sales Delivery Order"
		},
		{
			TransType: "PPR-",
			DocumentType: "Purchase Received"
		},
		{
			TransType: "SD",
			DocumentType: "Sales Debit Note"
		},
		{
			TransType: "SC",
			DocumentType: "Sales Credit Note"
		},
		{
			TransType: "PQ-",
			DocumentType: "Purchase Request"
		},
		{
			TransType: "PPI-",
			DocumentType: "Purchase Invoice"
		},
		{
			TransType: "PRE",
			DocumentType: "Purchase Return"
		},
		{
			TransType: "CS-G",
			DocumentType: "CashSales"
		},
		{
			TransType: "SCS",
			DocumentType: "CashSales"
		},
		{
			TransType: "PCP",
			DocumentType: "CashPurchase"
		},
		{
			TransType: "INV",
			DocumentType: "Sales Invoice"
		},
		{
			TransType: "PPI",
			DocumentType: "Purchase Invoice"
		}
	];

	useEffect(() => {
		if (documentModule === "Customer Invoice Listing") {
			setCombined(invoice(isAR));
			setCombinedDetails([]);
		} else if (documentModule === "Customer Receipt Listing") {
			setCombined(payment(isAR));
			setCombinedDetails([]);
		} else if (documentModule === "Customer Credit Note Listing") {
			setCombined(creditNote(isAR));
			setCombinedDetails([]);
		} else if (documentModule === "Customer Debit Note Listing") {
			setCombined(debitNote(isAR));
			setCombinedDetails([]);
		} else if (documentModule === "Customer Refund Listing") {
			setCombined(refund(isAR));
			setCombinedDetails([]);
		} else if (documentModule === "Customer Contra Listing") {
			setCombined(contra(isAR));
			setCombinedDetails([]);
		} else if (documentModule === "Supplier Invoice Listing") {
			setCombined(invoice(isAR));
			setCombinedDetails([]);
		} else if (documentModule === "Supplier Credit Note Listing") {
			setCombined(creditNote(isAR));
			setCombinedDetails([]);
		} else if (documentModule === "Supplier Debit Note Listing") {
			setCombined(debitNote(isAR));
			setCombinedDetails([]);
		} else if (documentModule === "Supplier Payment Listing") {
			setCombined(payment(isAR));
			setCombinedDetails([]);
		} else if (documentModule === "Supplier Refund Listing") {
			setCombined(refund(isAR));
			setCombinedDetails([]);
		} else if (documentModule === "Supplier Contra Listing") {
			setCombined(contra(isAR));
			setCombinedDetails([]);
		} else if (documentModule === "GL Cash Book OR Listing" || documentModule === "GL Cash Book PV Listing") {
			setCombined(orpv());
			setCombinedDetails(cashBookDetail());
		} else if (documentModule === "GL Journal Listing") {
			setCombined(journal());
			setCombinedDetails(journalDetail());
		} else if (documentModule === "GL Cash Book Listing") {
			setCombined(cashBook());
			setCombinedDetails(cashBookDetail());
			setReportName("GL - Cash Book Listing");
		} else if (documentModule === "Debit Note Listing" || documentModule === "Credit Note Listing" || documentModule === "Cash Sales Listing" || documentModule === "Invoice Listing" || documentModule === "Delivery Order Listing") {
			setCombined(salPur());
			setCombinedDetails(salPurDetail(true));
		} else if (
			documentModule === "Purchase Debit Note Listing" ||
			documentModule === "Cash Purchase Listing" ||
			documentModule === "Goods Received Listing" ||
			documentModule === "Purchase Return Listing" ||
			documentModule === "Purchase Invoice Listing" ||
			documentModule === "Purchase Request Listing"
		) {
			setCombined(salPur());
			setCombinedDetails(salPurDetail(true));
		} else if (documentModule === "Quotation Listing" || documentModule === "Sales Order Listing") {
			setCombined(salPur());
			setCombinedDetails(salPurDetail(false));
		} else if (documentModule === "Purchase Quotation Listing" || documentModule === "Purchase Order Listing") {
			setCombined(salPur());
			setCombinedDetails(salPurDetail(false));
		}
	}, [documentModule]);

	function getDocTransCode(documentNo) {
		return baseapi.httpget("/api/Utilities/CheckDocumentNoExist", { documentNo: documentNo || "" }).then((response) => {
			const { data } = response;
			if (data.length > 0) {
				// console.log(data[0]);
				return data[0];
			} else {
				return null;
			}
		});
	}

	return (
		<div>
			<Listing
				className='ar-listing-datagrid'
				onReportModuleChanged={(e) => {
					// console.log(e.value);
					setDocumentModule(e.value);
				}}
				isListing={true}
				disableDetails={isGL}
				isGL={isGL}
				keyDetail={"DocumentID"}
				reportName={reportName}
				detailColumns={combinedDetails.length !== 0 ? combinedDetails[0].detailColumns : []}
				summaryDetail={combinedDetails.length !== 0 ? combinedDetails[1].detailSummaryItems : []}>
				{combined.length !== 0 &&
					combined[0].columns.map((column) => {
						if (column.dataField === "DocumentNo") {
							return (
								<Column
									key={column.dataField}
									dataField={column.dataField}
									caption={column.caption}
									dataType={column.dataType}
									format={column.format}
									cellRender={(e) => {
										const data = e.data;
										const id = data.DocumentID;
										const nonIntegerRegex = /[a-zA-Z-]+/g;
										// console.log(data);
										if (data.DocumentNo !== undefined) {
											return (
												<span
													className='clickable-span-tag'
													onClick={() => {
														// console.log("Is AR: ", isAR);
														// console.log("Is GL: ", isGL);
														// console.log("Is Sales: ", isSales);
														// console.log(data);
														getDocTransCode(data.DocNo || data.DocumentNo).then((res) => {
															var valueToCheck = "";
															// console.log(res);

															if (
																res === null ||
																(isSales === "null" && res.transactionCode === "PPD") ||
																(isSales === "null" && res.transactionCode === "PPC") ||
																(isSales === "null" && res.transactionCode === "SC") ||
																(isSales === "null" && res.transactionCode === "SD")
															) {
																setCurrentEditID(id);
																if (isGL) {
																	valueToCheck = data.TransactionTypeCode.trim().match(nonIntegerRegex);
																} else {
																	valueToCheck = data.DocumentPrefix.trim().match(nonIntegerRegex);
																}
															} else {
																setCurrentEditID(res.DocumentID);
																valueToCheck = res.transactionCode.trim().match(nonIntegerRegex);
															}
															valueToCheck = valueToCheck ? valueToCheck.join("") : "";
															// console.log(valueToCheck);

															if ((isAR === true || !isAR || isGL === true || !isGL) && isSales === "null") {
																// console.log(valueToCheck);
																docType.find((item) => {
																	if (item.TransType === valueToCheck) {
																		setForm(item.DocumentType);
																		// console.log(item.DocumentType);
																	}
																});
															}

															if (isAR === "null" && !isGL) {
																// console.log(valueToCheck);
																salesPurcDocTran.find((item) => {
																	if (item.TransType === valueToCheck) {
																		setForm(item.DocumentType);
																		// console.log(item.DocumentType);
																	}
																});
															}
														});
													}}>
													{e.displayValue}
												</span>
											);
										} else {
											return e.displayValue;
										}
									}}
								/>
							);
						} else {
							return <Column key={column.dataField} dataField={column.dataField} caption={column.caption} visible={column.visible} dataType={column.dataType} format={column.format} />;
						}
					})}
				<Summary>{combined.length !== 0 && combined[1].summaryItems.map((item) => <TotalItem key={item.key} column={item.column} summaryType={item.summaryType} showInColumn={item.showInColumn} customizeText={item.customizeText} />)}</Summary>
			</Listing>

			{isAR === true && !isGL && (
				<div>
					{form === "Sales" && <ARSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
					{form === "ARDebitNote" && <ARDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Debit Note Entry"} />}
					{form === "Receipt" && <ARReceiptForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Receipt Entry"} />}
					{form === "ARCreditNote" && <ARCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note Entry"} />}
					{form === "Invoice" && <SalesInvoiceMainForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
					{form === "ARRefund" && <ARRefundForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Refund Entry"} />}
					{form === "Contra" && <ContraForm formID={currentEditID} closePopup={setCurrentEditID} formType={"Customer"} lockedForm={false} />}
					{form === "CashSales" && <SalesCashSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Cash Sales Entry"} />}
					{form === "Sales Invoice" && <SalesInvoiceMainForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
				</div>
			)}

			{!isAR && !isGL && (
				<div>
					{form === "Purchase" && <APPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Entry"} />}
					{form === "APDebitNote" && <APDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Debit Note Entry"} />}
					{form === "Payment" && <APPaymentForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Payment Entry"} />}
					{form === "APCreditNote" && <APCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note Entry"} />}
					{form === "APRefund" && <APRefundForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Refund Entry"} />}
					{form === "APContra" && <ContraForm formID={currentEditID} closePopup={setCurrentEditID} formType={"Supplier"} lockedForm={false} />}
					{form === "CashPurchase" && <PurchaseCashPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Cash Purchase Entry"} />}
					{form === "Purchase Invoice" && <PurchaseInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
				</div>
			)}

			{isGL === true && isAR === "null" && isSales === "null" && (
				<div>
					{form === "Receipt" && <ARReceiptForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Receipt Entry"} />}
					{form === "CashSales" && <SalesCashSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Cash Sales Entry"} />}
					{form === "CashPurchase" && <PurchaseCashPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} title={"Cash Purchase Entry"} />}
					{form === "Payment" && <APPaymentForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Payment Entry"} />}
					{form === "GLPayment" && <CashBookEntryForm formType={"Payment Voucher"} formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Payment Voucher"} />}
					{form === "GLReceipt" && <CashBookEntryForm formType={"Official Receipt"} formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Official Receipt"} />}
					{form === "ARRefund" && <ARRefundForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Refund Entry"} />}
					{form === "APRefund" && <APRefundForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Refund Entry"} />}
					{form === "Journal" && <JournalEntryForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Journal Entry"} />}
				</div>
			)}

			{isSales === true && isAR === "null" && !isGL && (
				<div>
					{form === "CashSales" && <SalesCashSalesForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Cash Sales Entry"} />}
					{form === "Sales Credit Note" && <SalesCreditNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Credit Note"} />}
					{form === "Sales Debit Note" && <SalesDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Debit Note"} />}
					{form === "Sales Delivery Order" && <SalesDeliveryOrderForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Delivery Order"} />}
					{form === "Invoice" && <SalesInvoiceMainForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
					{form === "Sales Order" && <SalesOrderForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Sales Order"} />}
					{form === "Sales Quotation" && <SalesQuotationForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Sales Quotation"} />}
					{form === "Sales Invoice" && <SalesInvoiceMainForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
				</div>
			)}

			{!isSales && (
				<div>
					{form === "CashPurchase" && <PurchaseCashPurchaseForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Cash Purchase Entry"} />}
					{form === "Purchase Request" && <PurchaseRequestForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Request"} />}
					{form === "Purchase Debit Note" && <PurchaseDebitNoteForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Debit Note"} />}
					{form === "Purchase Received" && <PurchaseReceivedForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Received"} />}
					{form === "Purchase Invoice" && <PurchaseInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Invoice Entry"} />}
					{form === "Purchase Order" && <PurchaseOrderForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Order"} />}
					{form === "Purchase Return" && <PurchaseReturnForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Return"} />}
					{form === "Purchase Quotation" && <PurchaseQuotationForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={false} title={"Purchase Quotation"} />}
				</div>
			)}
		</div>
	);
}
