import React from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';

class State extends React.Component {
    // render() {
    //     return <CommonTable title="State" url="/api/State" hasCode sidx={"Code"} sord={true}/>;
    // }

    render() {
        return <Container>
            <div id='normal-listing'>
                <SettingListing
                    listingTitle="State"
                    apiURL="/api/State"
                    listingURL="/api/State/list"
                    sortColumn={"Code"}
                    defaultSettingTemplate={true}
                    dateFilter={false}
                    storageName={"SettingStateListing"}
                />
            </div>
        </Container>
    }
}

export default State;