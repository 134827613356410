import UserPanel from "../../components/user-panel/UserPanelV2";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import baseapi from "../../api/baseapi";
import { useScreenSize } from "../../utils/media-query";

export function HeaderWithNoSide(props){

    const [companyName, setCompanyName] = useState(null);
    const screenSize = useScreenSize();
    const [isMobile, setIsMobile] = useState(null);
    const navigate = useNavigate();

    const onInitialized = (e) => {
        const userID = JSON.parse(localStorage.getItem('data'));
        // var logoLink = "https://greenplus-api.onlinestar.com.my/upload/";
        // var logoLinkLocal = "https://localhost:44320/upload/";
        // console.log( userID?.userID)
        baseapi.httpget(`api/User/Get`, { id: userID?.userID }).then(response => {
            const { data } = response;
            // console.log(data)
            // setCompanyName(data.CompanyName);
            baseapi.httpget(`api/Company/Get`, { id: data.CompanyID }).then(response2 => {
                const { data } = response2;
                // console.log(data)
                if (data.Name !== undefined) {
                    setCompanyName(data.Name);
                }
                else { // incase data.Name is undefined
                    data.items.map(item => {
                        // console.log(item)
                        setCompanyName(item.Name);
                    });
                }
                // logoLinkLocal += data.Logo.replaceAll('//', '/') ?? "/images/nav-logo.png";
                // setImgLink(logoLinkLocal);
            });
        });
    }

    useEffect(() => {
        if (companyName === null) {
            onInitialized();
        }
    }, [companyName]);

    useEffect(() => {
        // console.log("screen size changes", screenSize)

        if(screenSize.isSmall || screenSize.isXSmall){
            setIsMobile(true);
        }
        else{
            setIsMobile(false);
        }

    }, [screenSize]);

    return(
        <div style={{width: "100%"}}>
            <div className="no-side-nav-header">
                <div className={``}>

                    <div style={props.logoStyling} onClick={() => navigate("/home")}>
                        <img src={"/images/nav-logo.png"} alt={"Greenplus Accounting"} width={110} id='nav-logo' />
                    </div>

                </div>

                <div className="no-side-user-panel-container">
                    <div className="nav-header-right-section">
                        <UserPanel
                            companyName={companyName}
                            isMobile={isMobile}
                        />
                    </div>

                </div>
            </div>
            {props.children}
        </div>
    )
}