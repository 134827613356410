import React, {useState} from 'react';
import { Column, Button as ButtonColumn} from 'devextreme-react/data-grid';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import MechanicForm from './form';
export default function MechanicListing(){
    const apiURL = `/api/Mechanic`;
    const [currentEditID, setCurrentEditID] = useState(null);
    function handleEdit(e) {
        const id = e.row.key;
        setCurrentEditID(id);
    }

    
    return <Container>
        <div id='normal-listing'>
        <Listing
            apiURL={apiURL}
            listingURL="/api/Mechanic/list"
            sortColumn={"Code"}
            className={"workshop-listing-datagrid"}
            listingTitle={"Mechanic"}
            storageName={"MechanicListingColumnWidth"}
            onAddClick={setCurrentEditID}
            dateFilter={false}
            handleEdit={handleEdit}
            
        >
         
            <Column dataField="Code" caption={"Mechanic Code"} />
            <Column dataField="Name" />
            <Column dataField="ContactNo"/>
            <Column dataField="Commission"/>
            <Column dataField={"Active"} />
        </Listing>
        </div>

        <MechanicForm
            formID={currentEditID}
            closePopup={setCurrentEditID}
        />
    </Container>;
}