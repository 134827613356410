import React, { useRef, useEffect, useState } from "react";
import { uomOptions, categoryOptions, brandOptions, itemTypeOptions, materialOptions, stockGroupOptions, stockClassOptions } from "../../utils/lookupstore";
import { Column, Editing, Popup, Form, Item, Label, Button as ButtonColumn, Lookup } from "devextreme-react/data-grid";
import { GroupItem } from "devextreme-react/form";
import SettingListing from "../../components/listing/SettingListing";
import Container from "../../components/container";
import utils from "../../utils/common";
import Listing from "../../components/listing/newListing";
import UserForm from "./form";
import baseapi from "../../api/baseapi";

export default function User() {
	const apiURL = "/api/User";
	const [currentEditID, setCurrentEditID] = useState(null);
	useEffect(() => {
		baseapi.httpget("/api/User/list").then((response) => {
			const { data } = response;
			console.log(data);
		});
	});

	function handleEdit(e) {
		const id = e.row.key;
		setCurrentEditID(id);
	}
	function renderUserRoleNameCell(cellData) {
		const style = {
			color: cellData.value === "Admin" ? "blue" : "green"
		};
		return <span style={style}>{cellData.value}</span>;
	}
	return (
		<Container>
			<div id='normal-listing'>
				<Listing
					apiURL={apiURL}
					listingURL='/api/User/list'
					sortColumn={"Username"}
					className={"user-listing-datagrid"}
					listingTitle={"User"}
					storageName={"UserListingColumnWidth"}
					onAddClick={setCurrentEditID}
					dateFilter={false}
					handleEdit={handleEdit}>
					<Column dataField='Username' />
					<Column dataField='Email' />
					<Column dataField='UserRoleName' caption='User Role' width={"200"} cellRender={renderUserRoleNameCell} />
					<Column dataField='Active' />
				</Listing>
			</div>

			<UserForm formID={currentEditID} closePopup={setCurrentEditID} apiURL={apiURL} />
		</Container>
	);
}
