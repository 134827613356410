import { useRef, useState, useMemo, useEffect } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { ContextMenu } from 'devextreme-react/context-menu';
import { navigation } from '../../app-routes';
import { useNavigation } from '../../contexts/navigation';

export default function NavigationTreeList(props) {

  const {
    children,
    selectedItemChanged,
    openMenu,
    compactMode,
    onMenuReady
  } = props;

  const menuItems = [
    { id: 'newTab', text: 'Open In New Tab' },
  ];
  const { navigationData: { currentPath } } = useNavigation();

  const contextMenuRef = useRef(null);
  const treeViewRef = useRef(null);
  const [contextMenuSelectedItem, setContextMenuSelectedItem] = useState(null);
  const [expandedItems, setExpandedItems] = useState([]);
  const [items, setItems] = useState([]);
  const contextMenuItemClick = (e) => {
    const menuOption = e.itemData;
    if (menuOption.text === "Open In New Tab") {
      if (contextMenuSelectedItem === null) {
        alert("Error occured!");
      }
      else {
        window.open(contextMenuSelectedItem.path);
      }
    }
  };

  const treeViewItemContextMenu = (e) => {
    if (e.itemData.path === undefined) {
      contextMenuRef.current.instance.option('items[0].disabled', true);
    }
    else {
      setContextMenuSelectedItem(e.itemData);
    }

  };

  function normalizePath() {
    const userID = JSON.parse(localStorage.getItem('data'));
    const userAccessRights = localStorage.getItem('userAccessRights');
    const userRight = userAccessRights ? JSON.parse(userAccessRights) : null;

    // Function to check if a path is disabled based on user access rights
    const isPathDisabled = (text) => {
      if (userRight) {
        const accessRight = userRight.find(ur => ur.Code === text);
    
        return accessRight ? !accessRight.DirectAccess : false;
      }
      return false;
    };

    // Function to normalize item paths and set disabled property
    const normalizeItem = (item) => ({
      ...item,
      expanded: false,
      path: item.path && !(/^\//.test(item.path)) ? `/${item.path}` : item.path,
      disabled: isPathDisabled(item.text),
      items: item.items ? item.items.map(normalizeItem) : []
    });

    if (!userID.IsWorkshop) {
      return navigation
        .filter(x => !x.hideInMenu && !x.forWorkshop)
        .map(normalizeItem);
    } else {
      return navigation
        .filter(x => !x.hideInMenu)
        .map(normalizeItem);
    }
  }
  // const items = useMemo(
  //   normalizePath,
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   []
  // );

  useEffect(() => {
    setItems(normalizePath())
  }, []);

  useEffect(() => {
    if (props.visible) {

      const treeView = treeViewRef.current && treeViewRef.current.instance;
      if (!treeView) {
        return;
      }

      if (currentPath !== undefined) {
        treeView.selectItem(currentPath);
      }
    }

  }, [currentPath]);

  useEffect(() => {

    if (props.visible) {
      const treeView = treeViewRef.current && treeViewRef.current.instance;
      if (!treeView) {
        return;
      }

      if (currentPath !== undefined) {
        const selectedNode = treeView.getSelectedNodes();
        if (selectedNode.length > 0) {
          const node = selectedNode[0];
          if (node.parent) {
            treeView.expandItem(currentPath);
          }
        }
      }

    }

  }, [props.visible]);

  const onItemExpanded = (e) => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;

    if (!treeView) {
      return;
    }

    const isTopLevelItem = e.node.parent === null;


    if (isTopLevelItem) {
      expandedItems.forEach(item => {
        if (item !== e.node.key) {
          treeView.collapseItem(item);
        }
      });
      setExpandedItems([e.node.key]);
    } else {
      setExpandedItems(prev => [...prev, e.node.key]);
    }
  };

  const onItemCollapsed = (e) => {
    setExpandedItems(prev => prev.filter(item => item !== e.node.key));
  };

  return (
    <div className={'menu-container'}>
      <TreeView
        id="nav-treeview"
        ref={treeViewRef}
        items={items}
        keyExpr={'path'}
        selectionMode={'single'}
        // selectByClick={true}
        focusStateEnabled={false}
        expandEvent={'click'}
        onItemClick={selectedItemChanged}
        // onContentReady={onMenuReady}
        width={'100%'}
        onItemContextMenu={treeViewItemContextMenu}
        height={"calc(100vh - 70px)"}
        visible={props.visible}
        onItemExpanded={onItemExpanded}
        onItemCollapsed={onItemCollapsed}
      />

      <ContextMenu
        ref={contextMenuRef}
        dataSource={menuItems}
        target="#nav-treeview .dx-treeview-item"
        onItemClick={contextMenuItemClick}
      />
    </div>
  );
}