import { useEffect, useRef, useState } from "react";
import { NumberBox } from "devextreme-react/number-box";
import utils from "../../utils/common";
import { Popover } from 'devextreme-react/popover';
import baseapi from "../../api/baseapi";

export default function DataGridNumberAsyncBox(props){
    const message = props.message === undefined ? "This value is not valid" : props.message;
    const parameter = props.parameter === undefined ? null : props.parameter;
    const asyncURL = props.asyncURL === undefined ? null : props.asyncURL;
    const [formValue, setFormValue] = useState(props.data.value);
    const [showError, setShowError] = useState(false);
    const notVerified = useRef(true);
    const timer = useRef(null);
    const formFocusOut = useRef(false);
    const replaceValue = useRef(null);
    const waitTime = utils.isNullOrEmpty(props.waitTime) ? 300 : props.waitTime;

    useEffect(() => {
        if(!utils.isNullOrEmpty(formValue)){
            //If the form value is changed, it has to be re-verified
            notVerified.current = true;
        }
    }, [formValue]);

    useEffect(() => {
        // console.log("data changed", props.data.value)
    }, [props.data])

    const isAsyncValid = () =>{
        if((utils.isNullOrEmpty(parameter) && 
        utils.isNullOrEmpty(props.mergeParams) && 
        utils.isNullOrEmpty(props.useRowAsParam)) || 
        utils.isNullOrEmpty(asyncURL)){
            return false;
        }

        return true;
    };

    const formValidationLogic = (response) => {
        const { data } = response;

        if(utils.isObject(data)){
            const valid = data.valid;
            const validValue = data.replaceValue;

            if(!valid){
                setShowError(true);
                replaceValue.current = validValue;
            }
            else{
                setShowError(false);
                replaceValue.current = null;
            }
        }
        else{
            if(!data){
                setShowError(true);
            }
            else{
                setShowError(false);
            }
        }
        
        //Ensure the value is verified
        notVerified.current = false;
    };

    const asyncCall = (value) => {
        if(isAsyncValid() && !utils.isNullOrEmpty(value) && !formFocusOut.current){
            var rowData = {};
            var obj = {};
            
            if(!utils.isNullOrEmpty(props.parameter)){
                obj[parameter] = value;
            }

            if(!utils.isNullOrEmpty(props.mergeParams) && !Array.isArray(props.mergeParams)){
                const merged = props.mergeParams;
                obj = Object.assign(obj, merged);
            }
            else if(Array.isArray(props.mergeParams)){
                obj = props.mergeParams;
            }
            
            if(!utils.isNullOrEmpty(props.useRowAsParam) && !utils.isNullOrEmpty(props.data)){
                const source = {};
                const dataField = props.data.column.name;
                const fieldValue = props.data.value;
                const currentRowValue = props.data.data;
                
                if(!utils.isNullOrEmpty(dataField) && !utils.isNullOrEmpty(fieldValue)){
                    source[dataField] = fieldValue;
                }
                rowData = Object.assign(currentRowValue, source);
            }

            if(utils.isNullOrEmpty(props.requestType) || props.requestType === "GET"){
                baseapi.httpget(utils.extendUrlVar(`${asyncURL}`, obj), rowData)
                .then(response => {
                    formValidationLogic(response);
                });
            }
            else if(props.requestType === "POST"){
                baseapi.httppost(utils.extendUrlVar(`${asyncURL}`, obj), rowData)
                .then(response => {
                    formValidationLogic(response);
                });
            }
        }
    };

    const setValue = (value) =>{
        setFormValue(value);
        props.data.setValue(value);
    }

    const onValueChanged = (e) => {
        clearTimeout(timer.current);

        const input = e.value;
        setValue(input);

        timer.current = setTimeout(() => {
            asyncCall(input);
        }, waitTime);
    };

    return(
        <div>
            <NumberBox 
                id="DataGridAsyncBox"
                valueChangeEvent="keyup"
                value={formValue}
                onValueChanged={onValueChanged}
                onFocusIn={() => {
                    formFocusOut.current = false;
                    asyncCall(formValue);
                }}
                onFocusOut={() => {
                    formFocusOut.current = true;
                    //If the data is invalid then clear the form value
                    if((showError || notVerified.current) && props.invalidClearValue !== false){
                        if(!utils.isNullOrEmpty(props.clearValueReplace)){
                            setValue(props.clearValueReplace);
                        }
                        else if(!utils.isNullOrEmpty(replaceValue.current)){
                            setValue(replaceValue.current)
                        }
                        else{
                            setValue(0);
                        }
                        setShowError(false);
                    }

                }}
            >
                
            </NumberBox>

            <Popover
                target={"#DataGridAsyncBox"}
                position="top"
                width={300}
                visible={showError}
            >
                <span className="customized-datagrid-error-message">{message}</span>
            </Popover>
        </div>
        
    );
}