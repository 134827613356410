import React from "react";
import { useEffect, useRef, useState } from "react";
import { DxReportDesigner } from "devexpress-reporting/dx-reportdesigner";
import * as ko from "knockout";
import { reportFunctionDisplay } from "devexpress-reporting/dx-reportdesigner";
import { addTemplate } from "@devexpress/analytics-core/analytics-widgets";
import { ReportUrlEditor } from "devexpress-reporting/dx-reportdesigner";
import DataSource from "devextreme/data/data_source";
import $ from "jquery";
import { Popup } from "devextreme-react/popup";
import SelectBox from "devextreme-react/select-box";
import { Button } from "devextreme-react/button";
import { Toast } from "devextreme-react/toast";
import { LoadPanel } from "devextreme-react/load-panel";
import util from "../../utils/common";
import baseapi from "../../api/baseapi";

export default function ReportDesigner() {
  var subReports = [];
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const reportUrl = ko.observable(urlParams.get("report"));
  const designerRef = useRef();
  const [isReportRendered, setIsReportRendered] = useState(false);
  const [isManagement, setIsManagement] = useState(false);
  const [openReportDisplay, setOpenReportDisplay] = useState(false);
  const [displayLoadPanel, setDisplayLoadPanel] = useState(false);
  const [availableReports, setAvailableReports] = useState([]);
  const [openReportURL, setOpenReportURL] = useState("");
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "info",
    message: "",
  });
  const currentToken = localStorage.getItem("Authorization").substring(7);
  const path = "/api/Dashboard";
  const authPath = "/api/Login";

  const requestOptions = {
    host: util.ReportURL + "/",
    getDesignerModelAction: "api/ReportDesigner/GetReportDesignerModel",
  };

  const addJavaScriptFile = () => {
    var script = document.createElement("script");
    script.type = "text/jsx";
    script.src = "/js/report-designer.js";
    script.async = true;
    document.body.appendChild(script);
  };

  const openReport = () => {
    if (openReportURL.length !== 0) {
      window.open(window.location.origin + openReportURL);
    }
  };

  class CustomReportSourceUrl extends ReportUrlEditor {
    constructor(info, level, parentDisabled, textToSearch) {
      super(info, level, parentDisabled, textToSearch);
    }
    getValues(urls, tab) {
      if (!this.dataSource) {
        this._initUrls(urls, tab);
        this._disposables.push(
          (this.dataSource = ko.computed(() => {
            var dataSource = new DataSource({
              store: this.urls(),
              paginate: true,
              filter: (url) => {
                // console.log("key: ", url.Key)
                return (
                  subReports.find(
                    (element) => element.ReportName === url.Key
                  ) !== undefined
                );
              },
              pageSize: 100,
            });
            return dataSource;
          }))
        );
      }
      return this.dataSource;
    }
  }

  useEffect(() => {
    if (currentToken) {
      // console.log(currentToken);

      baseapi
        .httppost(
          util.ReportURL +
            path +
            "/ReportVerification?token=" +
            urlParams.get("token")
        )
        // .then(response => response.text())
        .then((result) => {
          var isPubliReport = result.data.isPublicReport;
          var isDeleted = result.data.isDeleted;
          var allowAccess = result.data.accessAllowed;
          var isManagement = result.data.isManagement;
          setIsManagement(result.data.isManagement);

          //Display Report
          if (!isReportRendered) {
            //Set report rendered before
            setIsReportRendered(true);

            //Add JavaScript file
            addJavaScriptFile();

            baseapi
              .httpget(
                util.ReportURL +
                  "/api/Report/GetAllSubReport?accessToken=" +
                  currentToken
              )
              .then((result) => {
                subReports = result.data;

                const model = {
                  reportUrl: reportUrl,
                  requestOptions: requestOptions,
                  callbacks: {
                    BeforeRender: function (s, e) {
                      addTemplate(
                        "grouped-report-source-url-editor",
                        `
                          <div data-bind="dxSelectBox: { dataSource: $data.getValues($root.reportUrls, $root.navigateByReports.currentTab), onOpened: function() { $data.updateUrls(); }, searchEnabled: true, value: value, disabled: disabled, valueExpr: 'Key', displayExpr: 'Value', dropDownOptions: { container: $root.getPopupContainer($element) }, useItemTextAsTitle: true  }"></div>
                      `
                      );
                      // console.log(e.reportPreviewModel.reportPreview)
                      e.reportPreviewModel.reportPreview.showMultipagePreview(
                        true
                      );

                      var info = s.GetPropertyInfo(
                        "XRSubreport",
                        "ReportSourceUrl"
                      );
                      // console.log(info)
                      info.editor = $.extend({}, info.editor, {
                        editorType: CustomReportSourceUrl,
                        header: "grouped-report-source-url-editor",
                      });

                      baseapi
                        .httpget(
                          util.ReportURL +
                            "/api/CustomFunction/GetCustomFunctionDetails"
                        )
                        .then((result) => {
                          result = result.data;
                          const customFuncArr = [];

                          result.map((functionDetails) => {
                            customFuncArr.push({
                              paramCount: functionDetails.MaxOperandCount,
                              text: functionDetails.Name + "({0})",
                              displayName: functionDetails.Name,
                              description: functionDetails.Description,
                            });
                          });

                          reportFunctionDisplay.push({
                            display: "Custom Functions",
                            items: {
                              CustomFormatFunction: customFuncArr,
                            },
                          });
                        });
                    },
                    CustomizeToolbox: function (s, e) {
                      //Hide sub report option from menu if the user is not management
                      if (!isManagement) {
                        var subreportInfo =
                          e.ControlsFactory.getControlInfo("XRSubreport");
                        subreportInfo.isToolboxItem = false;
                      }
                    },
                    CustomizeSaveAsDialog: function (s, e) {
                      e.Popup.onSaving = (saving) => {
                        var reportURL = saving.url;
                        var data = new Object();
                        data.reportName = saving.url;
                        const params = "?reportName=" + reportURL;
                        //Pause the saving process
                        saving.cancel = true;
                        setDisplayLoadPanel(true);

                        baseapi
                          .httppost(
                            util.ReportURL +
                              path +
                              "/ReportSavingValidation" +
                              params
                          )
                          .then((result) => {
                            result = result.data;
                            setDisplayLoadPanel(false);

                            //Cancel saving
                            if (result === "Invalid") {
                              if (
                                window.confirm(
                                  "The name " +
                                    reportURL +
                                    " has been used by other report, do you want to overwrite it?"
                                ) === true
                              ) {
                                s.SaveNewReport(reportURL);

                                //Close save as dialog
                                var closeBtn = document.querySelectorAll(
                                  '[data-bind="dxButton: options"]'
                                );
                                for (var i = 0; i < closeBtn.length; i++) {
                                  if (closeBtn[i].innerText === "Cancel") {
                                    closeBtn[i].click();
                                  }
                                }

                                //Display alert
                                setToastConfig({
                                  type: "warning",
                                  isVisible: true,
                                  message:
                                    "The report is successfully overwritten!",
                                });
                              } else {
                                //Display alert
                                setToastConfig({
                                  type: "warning",
                                  isVisible: true,
                                  message:
                                    "Please choose a new name for your report!",
                                });
                              }
                            } else if (result === "used by Public Report") {
                              alert(
                                "This report name is already used by a public report! please choose a different one"
                              );
                            } else {
                              s.SaveNewReport(reportURL);

                              //Close save as dialog
                              var closeBtn = document.querySelectorAll(
                                '[data-bind="dxButton: options"]'
                              );
                              for (var i = 0; i < closeBtn.length; i++) {
                                if (closeBtn[i].innerText === "Cancel") {
                                  closeBtn[i].click();
                                }
                              }

                              setToastConfig({
                                type: "success",
                                isVisible: true,
                                message:
                                  "The report has been successfully saved!",
                              });
                            }
                          })
                          .catch((error) => console.log("error", error));
                      };
                    },
                    CustomizeMenuActions: function (s, e) {
                      //Customized open report function
                      e.Actions[11].clickAction = () => {
                        const params = `?accessToken=${currentToken}&refreshToken=${false}`;
                        setDisplayLoadPanel(true);

                        baseapi
                          .httpget(
                            util.ReportURL + "/api/Report" + "/GET" + params
                          )
                          .then((result) => {
                            result = result.data;
                            const reportArr = [];

                            result.map((report) => {
                              reportArr.push({
                                value: report.DesignerAccessURL,
                                label: report.ReportName,
                              });
                            });

                            setDisplayLoadPanel(false);
                            setAvailableReports(reportArr);
                          });

                        setOpenReportDisplay(true);
                      };

                      //Disable add data source
                      e.Actions[17].visible = false;

                      e.Actions[19].clickAction = () => {
                        setDisplayLoadPanel(true);
                        baseapi
                          .httppost(
                            util.ReportURL + path + "/getReportNameByID"
                          )
                          .then((result) => {
                            result = result.data;

                            if (result !== "Invalid Report ID") {
                              s.SaveNewReport(result);
                              setDisplayLoadPanel(false);

                              setToastConfig({
                                type: "success",
                                isVisible: true,
                                message:
                                  "The report has been successfully saved!",
                              });
                            } else {
                              alert("Error during saving report");
                            }
                          })
                          .catch((error) => console.log("error", error));
                      };

                      //Disable save button
                      if (
                        urlParams.get("report") === "blank" ||
                        (isPubliReport && !isManagement)
                      ) {
                        e.Actions[19].visible = false;
                      } else {
                        e.Actions[19].visible = true;
                      }

                      //Disable save buttons if acessed denied
                      if (isDeleted || !allowAccess) {
                        e.Actions[19].visible = false;
                        e.Actions[20].visible = false;
                      }
                    },
                    CustomizeLocalization: function (s, e) {
                      s.UpdateLocalization({
                        "The report has been successfully saved.": "",
                      });
                    },
                    CustomizeFieldListActions: function (s, e) {
                      //Remove delete resource button
                      if (e.Actions.length >= 5) {
                        // console.log("Visible" + e.Actions[4].visible)
                        e.Actions[e.Actions.length - 1].visible = false;
                      }
                    },
                    CustomizeWizard: function (s, e) {
                      //Disable custom SQL
                      if (!isManagement) {
                        if (e.Type === "SingleQueryDataSourceWizard") {
                          //console.log(e.Wizard._wizardOptions.disableCustomSql)
                          e.Wizard._wizardOptions.disableCustomSql = true;
                        }
                      }
                    },
                  },
                  designerModelSettings: {
                    reportPreviewSettings: {
                      exportSettings: {
                        useSameTab: false,
                      },
                      progressBarSettings: {
                        position: "TopRight",
                      },
                    },
                    wizardSettings: {
                      useFullscreenWizard: false,
                      enableSqlDataSource: false,
                    },
                    dataSourceSettings: {
                      allowRemoveDataSource: false,
                      allowAddDataSource: false,
                      allowEditDataSource: false,
                    },
                  },
                };

                const designer = new DxReportDesigner(
                  designerRef.current,
                  /*{ reportUrl, requestOptions }*/ model
                );
                designer.render();

                return () => designer.dispose();
              });
          }
        })
        .catch((error) => console.log("error", error));
    }
  });

  return (
    <div style={{ width: "100%", height: "calc(100vh - 56px)" }}>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={displayLoadPanel}
        showIndicator={true}
        shading={true}
        showPane={true}
        hideOnOutsideClick={false}
      />

      <div
        ref={designerRef}
        id={isManagement ? "" : "report-designer-container"}
      ></div>

      <Popup
        visible={openReportDisplay}
        onHiding={(e) => setOpenReportDisplay(false)}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={true}
        showTitle={true}
        title="Export Report(s)"
        container=".dx-viewport"
        height={"auto"}
        width={800}
      >
        <SelectBox
          displayExpr={"label"}
          valueExpr={"value"}
          items={availableReports}
          searchEnabled={true}
          onSelectionChanged={(e) => {
            setOpenReportURL(e.selectedItem.value);
          }}
        />

        <div className="popup-footer">
          <Button
            width={120}
            text="Close"
            type="normal"
            stylingMode="contained"
            id="close-btn"
            onClick={() => setOpenReportDisplay(false)}
          />

          <Button
            width={120}
            text="Open"
            type="default"
            stylingMode="contained"
            disabled={openReportURL === ""}
            onClick={openReport}
          />
        </div>
      </Popup>

      <Toast
        visible={toastConfig.isVisible}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={(e) => {
          setToastConfig({
            ...toastConfig,
            isVisible: false,
          });
        }}
        displayTime={3000}
      />
    </div>
  );
}

// const ReportDesigner = () => {
//     const queryString = window.location.search;
//     const urlParams = new URLSearchParams(queryString);
//     const reportUrl = ko.observable(urlParams.get('report'));
//   // const reportUrl = ko.observable("TestReport");
//   const designerRef = useRef();

//   const requestOptions = {
//     host: util.ReportURL + "/",
//     getDesignerModelAction: "api/ReportDesigner/GetReportDesignerModel"
//   };
//   useEffect(() => {
//     const designer = new DxReportDesigner(designerRef.current, { reportUrl, requestOptions });
//     designer.render();
//     return () => designer.dispose();
//   })
//   return (<div ref={designerRef}></div>);
// }

// function App() {
//   return (<div style={{ width: "100%", height: "1000px" }}>
//       <ReportDesigner />
//   </div>);
//   }

// export default App;
