import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import Validator, { RequiredRule, PatternRule, CompareRule } from 'devextreme-react/validator';
import { Popup } from 'devextreme-react/popup';
import '../login-form/LoginForm.scss';
import swal from 'sweetalert2';
import baseapi from '../../api/baseapi';
import { loading, closeLoading } from "../../utils/common";
import { useNavigate } from 'react-router-dom';
import VerifyEmailSuccess from './VerifyEmailSuccess';
const VerifyEmail = () => {


    const navigate = useNavigate();
    const passwordValidatorRef = useRef(null);
    const confirmPasswordValidatorRef = useRef(null);
    const [showVerifyEmailSuccess, setShowVerifyEmailSuccess] = useState(false);
    const popupWidth = useRef('45%'); // Default width
    const formData = useRef({ newPassword: '', confirmPassword: '' });
    const [passwordMode, setPasswordMode] = useState('password');
    const [confirmPasswordMode, setConfirmPasswordMode] = useState('password');
    const [visibile, setVisible] = useState(true);
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const tacCode = urlParams.get('t');

    const showErrorAlert = () => {
        swal.fire({
            title: 'Error',
            text: 'Unauthorized User or Expired Link. Please try again.',
            icon: 'error',
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/login');
            }
        });
    };
    const checkWindowSize = useCallback(() => {
        if (window.innerWidth < 870) {
            popupWidth.current = '100%';
        } else {
            popupWidth.current = '45%';
        }
    }, []);

    useEffect(() => {
        const verifyCode = async () => {
            try {
                const response = await baseapi.httppost('/oauth/VerifyCode', { email, tacCode });
                if (!response.data.status) {
                    showErrorAlert();
                } else {
                    const verifyResponse = await baseapi.httppost('/api/register/verify', { email, tacCode });
                    if (verifyResponse.data.status) {
                        setShowVerifyEmailSuccess(true);
                    } else {
                        showErrorAlert();
                    }
                }

              
            } catch (error) {
                console.error('Error occurred while verifying code:', error);
                showErrorAlert();
            }
        };

        if (!email || !tacCode) {
            showErrorAlert();
        } else {
            verifyCode();
        }

    
    }, [navigate]);
    const handleClose = useCallback(() => {
        setVisible(false)
    }, []);


   

    return (
       <>

            <VerifyEmailSuccess visible={showVerifyEmailSuccess} onClose={handleClose} email={email}/>


        </>
    );
};

export default VerifyEmail;
