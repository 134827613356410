import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import baseapi from '../../api/baseapi';
import utils, { done, processing } from '../../utils/common';
import '../../components/login-form/LoginForm.scss';
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import Validator from 'devextreme-react/validator';

export default function Test() {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ email: '', name: '', companyName: '' });
  const [focus, setFocus] = useState({ email: false, password: false });
  const emailValidatorRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onFocus = useCallback((field) => {
    setFocus((prevFocus) => ({ ...prevFocus, [field]: true }));
  }, []);

  const onBlur = useCallback((field) => {
    if (!formData.current[field]) {
      setFocus((prevFocus) => ({ ...prevFocus, [field]: false }));
    }
  }, []);

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();

    const emailValidation = emailValidatorRef.current.instance.validate();

    if (!emailValidation.isValid ) {
      return;
    }

    const { email, name, companyName } = formData.current;
    setLoading(true);

    const result = await baseapi.httppost('/api/register/unittest', { Email: email, CompanyName:companyName, Name:name });
    console.log(result.data, "result");
    if (!result.data.status) {
      console.log(result, "result");
      notify(result.data.message, 'error', 2000);
    } else {
      done('Register successfully. Please continue to login').then(() => { navigate('/login') });
    }
    setLoading(false);
  }, []);

  return (
    <>
        <img src={"/images/nav-logo.png"} alt={"Greenplus Accounting"} width={110} id='nav-logo' style={{ paddingTop: '15px' }} />

        <div className="auth-container">
          <div className="description">
            <span className='title'>
              Greenplus
              <p className='subtitle'>
                Streamlining Efficiency, Perfecting Precision.
              </p>
            </span>
          </div>

          <div className='auth-section-container'>
            <div className="auth-content">
              <div className='auth-form'>
                <span className="authcontent-title">Register Test</span>
                <br />
                <p className="authcontent-subtitle" style={{ marginTop: '15px',marginBottom: '30px' }}>
                {windowWidth > 500 ? 'And start using Greenplus Accounting to boost your business!' : 'Use Greenplus Accounting To boost your business!'}
                </p>

                <form onSubmit={onSubmit}>
                  <div className={`auth-input-div one ${focus.email ? 'focus' : ''}`}>
                    <div className="auth-i">
                      <i className="fas fa-user"></i>
                    </div>
                    <div className="auth-div">
                      <TextBox
                        value={formData.current.email}
                        onValueChanged={(e) => {
                          formData.current.email = e.value;
                          setFocus((prevFocus) => ({ ...prevFocus, email: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('email')}
                        onFocusOut={() => onBlur('email')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Email"
                        labelMode="outside"
                      >
                        <Validator ref={emailValidatorRef}>
                          <RequiredRule message="Email is required" />
                          <EmailRule message="Email is invalid" />
                        </Validator>
                      </TextBox>
                    </div>
                  </div>

                  <div className={`auth-input-div one ${focus.companyName ? 'focus' : ''}`}>
                    <div className="auth-i">
                      <i className="fas fa-user"></i>
                    </div>
                    <div className="auth-div">
                      <TextBox
                        value={formData.current.companyName}
                        onValueChanged={(e) => {
                          formData.current.companyName = e.value;
                          setFocus((prevFocus) => ({ ...prevFocus, companyName: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('companyName')}
                        onFocusOut={() => onBlur('companyName')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Company Name"
                        labelMode="outside"
                      >
                        <Validator>
                          <RequiredRule message="Company Name is required" />
                        </Validator>
                      </TextBox>
                    </div>
                  </div>

                  <div className={`auth-input-div one ${focus.name ? 'focus' : ''}`}>
                    <div className="auth-i">
                      <i className="fas fa-user"></i>
                    </div>
                    <div className="auth-div">
                      <TextBox
                        value={formData.current.name}
                        onValueChanged={(e) => {
                          formData.current.name = e.value;
                          setFocus((prevFocus) => ({ ...prevFocus, name: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('name')}
                        onFocusOut={() => onBlur('name')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Full Name"
                        labelMode="outside"
                      >
                        <Validator>
                          <RequiredRule message="Full Name is required" />
                        </Validator>
                      </TextBox>
                    </div>
                  </div>

                  {!loading && <input type="submit" className="auth-button" value="Register" />}
                  {loading && (
                    <div className="loading-indicator-container">
                    <div class="loader"></div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>

        <img className="wave" src="images/wave.svg" />
      </>
  );
}

const companyCodeEditorOptions = { stylingMode: 'filled', placeholder: 'Company Code', mode: 'text' };
const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
const rememberMeEditorOptions = { text: 'Remember me', elementAttr: { class: 'form-text' } };
