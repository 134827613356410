import React from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';
import { Column, Editing, Popup, Form, Item, RequiredRule, Button as ButtonColumn, AsyncRule } from 'devextreme-react/data-grid';
import utils from '../../utils/common';

class Salesmen extends React.Component {

    constructor(props) {
        super();
        this.listingRef = React.createRef();
        this.ButtonColumnWidth = utils.getColumnWidth("buttons", "SettingSalesmenListing") === null ? 80 : utils.getColumnWidth("buttons", "SettingSalesmenListing");
        this.CodeColumnWidth = utils.getColumnWidth("Code", "SettingSalesmenListing") === null ? undefined : utils.getColumnWidth("Code", "SettingSalesmenListing");
        this.NameColumnWidth = utils.getColumnWidth("Name", "SettingSalesmenListing") === null ? undefined : utils.getColumnWidth("Name", "SettingSalesmenListing");
        this.EmailColumnWidth = utils.getColumnWidth("Email", "SettingSalesmenListing") === null ? undefined : utils.getColumnWidth("Email", "SettingSalesmenListing");
        this.PhoneColumnWidth = utils.getColumnWidth("Phone", "SettingSalesmenListing") === null ? undefined : utils.getColumnWidth("Phone", "SettingSalesmenListing");
        this.CreatedDateColumnWidth = utils.getColumnWidth("CreatedDate", "SettingSalesmenListing") === null ? undefined : utils.getColumnWidth("CreatedDate", "SettingSalesmenListing");

        this.ButtonVisibleIndex = utils.getColumnVisibleIndex("buttons", "SettingSalesmenListing") === null ? 0 : utils.getColumnVisibleIndex("buttons", "SettingSalesmenListing");
        this.CodeVisibleIndex = utils.getColumnVisibleIndex("Code", "SettingSalesmenListing") === null ? 1 : utils.getColumnVisibleIndex("Code", "SettingSalesmenListing");
        this.NameVisibleIndex = utils.getColumnVisibleIndex("Name", "SettingSalesmenListing") === null ? 2 : utils.getColumnVisibleIndex("Name", "SettingSalesmenListing");
        this.EmailVisibleIndex = utils.getColumnVisibleIndex("Email", "SettingSalesmenListing") === null ? 3 : utils.getColumnVisibleIndex("Email", "SettingSalesmenListing");
        this.PhoneVisibleIndex = utils.getColumnVisibleIndex("Phone", "SettingSalesmenListing") === null ? 4 : utils.getColumnVisibleIndex("Phone", "SettingSalesmenListing");
        this.CreatedDateVisibleIndex = utils.getColumnVisibleIndex("CreatedDate", "SettingSalesmenListing") === null ? 5 : utils.getColumnVisibleIndex("CreatedDate", "SettingSalesmenListing");
    }

    onInitNewRow(e) {
        e.data.Active = true;
    }

    render() {
        return <Container>
            <div id='normal-listing'>
                <SettingListing
                    ref={this.listingRef}
                    listingTitle="Salesman"
                    apiURL="/api/Salesman"
                    listingURL="/api/Salesman/list"
                    sortColumn={"Code"}
                    dateFilter={false}
                    storageName={"SettingSalesmenListing"}
                    customizedPopup={true}
                    onInitNewRow={this.onInitNewRow}
                >
                    <Column type="buttons" width={this.ButtonColumnWidth} visibleIndex={this.ButtonVisibleIndex} fixed={true}>
                        <ButtonColumn name="edit" />
                        <ButtonColumn name="delete" />
                    </Column>
                    <Column dataField="Code" dataType="string" setCellValue={utils.GridCellValueToUpper} width={this.CodeColumnWidth} visibleIndex={this.CodeVisibleIndex} />
                    <Column dataField="Name" dataType="string" width={this.NameColumnWidth} visibleIndex={this.NameVisibleIndex} />
                    <Column dataField="Email" dataType="string" width={this.EmailColumnWidth} visibleIndex={this.EmailVisibleIndex} />
                    <Column dataField="Phone" dataType="string" width={this.PhoneColumnWidth} visibleIndex={this.PhoneVisibleIndex} />
                    <Column dataField="CreatedDate" format="dd/MM/yyyy" dataType={"date"} width={this.CreatedDateColumnWidth} visibleIndex={this.CreatedDateVisibleIndex} />
                    <Column visible={false} dataField="Active" dataType="string" />
                    <Editing useIcons={true}
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        mode="popup"
                    >
                        <Popup title="Salesman" showTitle={true} width="auto" height="auto" dragEnabled={false} />
                        <Form>
                            <Item dataField="Code">
                                <RequiredRule message={"Code is Required!"} />
                                <AsyncRule
                                    message={"This code is already taken!"}
                                    validationCallback={(e) => utils.codeAsyncPropsValidation("/api/Salesman", { code: e.value, id: this.listingRef.current?.getEditingID() })}
                                />
                            </Item>
                            <Item dataField="Name" >
                                <RequiredRule message={"Name is Required!"} />
                            </Item>
                            <Item dataField="Email">
                                {/* <RequiredRule message={"Email is Required!"}/> */}
                            </Item>
                            <Item dataField="Phone">
                                {/* <RequiredRule message={"Phone is Required!"}/> */}
                            </Item>
                            <Item dataField="Active" editorType="dxCheckBox" defaultValue={true} enableThreeStateBehavior={false} />
                        </Form>
                    </Editing>
                </SettingListing>
            </div>
        </Container>
    }
}

export default Salesmen;