import { Popup, Button, TextBox } from "devextreme-react";
import { useEffect, useState, useRef } from "react";
import "./StockNegativeValueForm.scss";
import baseapi from "../../api/baseapi";
import ErrorPopUpForm from "../../components/popup-form/ErrorPopupForm";
import utils from "../../utils/common";
import Validator, { RequiredRule } from "devextreme-react/validator";
import DataGrid, { Column, Editing, Selection, Scrolling, Lookup, KeyboardNavigation, ColumnChooser, Summary, TotalItem, Toolbar, Item as ToolbarItem } from "devextreme-react/data-grid";
export default function StockNegativeValueForm(props) {
	const [visible, setVisible] = useState(props.errorVisible);
	const popupMessageRef = useRef(null);
	const dataSource = props.dataSource;
	useEffect(() => {
		if (props.onValueChanged !== undefined) {
			props.onValueChanged({ value: visible });
		}
	}, [visible]);

	function onClose() {
		setVisible(false);
	}

	function handleSubmit() {
		props.ReturnSaveResult({ canSave: true });
		onClose();

	}



	return (
		<div>
			<Popup id='stocknegativeMessagePopUp' visible={visible} width={1200} showTitle={false} hideOnOutsideClick={true} height={"auto"} shading={true} onHiding={() => onClose()}>
				<div className='stocknegativeMessage-popup-items'>
					<div className='stocknegativeMessage-popup-header'>
						<div className='stocknegativeMessage-popup-cross'>
							<Button icon='close' stylingMode='text' onClick={() => onClose()} />
						</div>
					</div>
				</div>
				<div className='stocknegativeMessage-popup-body'>
					<div className='stocknegativeMessage-popup-content'>
						<div>

							The quantity of stock item(s) listed below are in <b>negative</b> value, do you still want to save it?
						</div>
						<div className='stocknegativeMessage-popup-limits' style={{ marginTop: 20 }}>
							<div className='stocknegative-border-bottom' style={{ padding: "0 0" }}>
								<DataGrid
									className={"gl-listing-datagrid StockValue"}
									showRowLines={true}
									dataSource={dataSource}
									listingTitle={"Stock Selling Price"}
									storageName={"GLStockValueListing"}
									sorting={false}

								>

									<Column dataField='StockCode' caption={"Stock Code"} />
									<Column dataField='Description' caption={"Description"} width={"300px"} />
									<Column dataField='LocationCode' caption={"Location"} />
									<Column dataField='ShelfCode' caption={"Bin / Shelf No"} />
									<Column dataField='QtyOnHand' caption={"Qty On Hand"} />
									<Column dataField='InOutQty' caption={"In / Out Qty"} />
									<Column dataField='BalQty' caption={"Bal. Qty"} />
									<Column dataField='MinLevel' caption={"Min Qty"} />

									<Editing allowUpdating={false} mode='cell' />
								</DataGrid>
							</div>

						</div>

						<div className='stocknegativeMessage-popup-footer'>
							<Button
								type='success'
								stylingMode='contained'
								text='Yes'
								onClick={() => handleSubmit()}
								className='button-custom'
							/>
							<Button
								type='success'
								stylingMode='outlined'
								text='No'
								onClick={() => onClose()}
								className='button-custom'
							/>
						</div>
					</div>
				</div>
			</Popup>

			<ErrorPopUpForm ref={popupMessageRef} />
		</div>
	);
}
