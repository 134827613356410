import React, { useEffect, useState, useRef } from "react";
import baseapi from "../../../api/baseapi";
import Container from "../../../components/container";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { DateBox } from "devextreme-react/date-box";
import utils from "../../../utils/common";
import TreeList, { Column, Lookup, Scrolling } from "devextreme-react/tree-list";
import CustomizedLookup from "../../../components/lookup/Lookup";
import { customerOptions, workshopMechanic, workshopRegistration } from "../../../utils/lookupstore";
import { saveToLS, getFromLS } from "../../../utils/localstorage";
import CustomStore from "devextreme/data/custom_store";
import { useNavigate } from "react-router-dom";
import WorkshopInvoiceForm from "../Invoice/form";
import PathIndicator from "../../../components/path-indicator/PathIndicator";

export default function VehicleServiceRecordListing() {
    const [listingDataSource, setListingDataSource] = useState(null);
    const [registrationList, setRegistrationList] = useState([]);
    const [currentEditID, setCurrentEditID] = useState(null);
    const [regNoValue, setRegNoValue] = useState(null);
    const treelistRef = useRef(null);
    const searchBoxRef = useRef(null);
    const fromDate = useRef(null);
    const toDate = useRef(null);
    const regNoLookupRef = useRef(null);
	const [expandedRowKeys, setExpandedRowKeys] = useState([]);
	const refresh = () => {
        if (regNoValue !== null) {
          
            setListingDataSource({
                store: new CustomStore({
                    key: "ID",
                    load: () => {
                        return baseapi
                            .httpget(`api/WKInvoice/GetInvoiceByRegID`, {
                                regID: regNoValue,
                                fromDate: fromDate.current,
                                toDate: toDate.current
                            })
                            .then((response) => {
                                const { data } = response;
                                if (data !== null) {
                                    let completeArray = [];
                                    let invoiceMainArray = [];
                                    let invoiceDetailsArray = [];
                                    for (let i = 0; i < data.length; i++) {
                                        invoiceMainArray.push(data[i].InvoiceMain);
                                        invoiceDetailsArray = invoiceDetailsArray.concat(data[i].InvoiceDetail);
                                    }
                                    completeArray = invoiceMainArray.concat(invoiceDetailsArray);
                                    return completeArray;
                                } else {
                                    return [];
                                }
                            })
                            .catch(() => {
                                throw "Network error";
                            });
                    }
                })
            });
        }
    };

    useEffect(() => {
        refresh();
    }, [regNoValue]);

	// const filterData = (data) => {
	// 	if (!Array.isArray(data)) return []; // Ensure data is an array
	
	// 	let from = fromDate.current ? new Date(fromDate.current) : null;
	// 	let to = toDate.current ? new Date(toDate.current) : null;
	
	// 	if (from) {
	// 		from.setHours(0, 0, 0, 0); // Set 'from' to the start of the day
	// 	}
	// 	if (to) {
	// 		to.setHours(23, 59, 59, 999); // Set 'to' to the end of the day
	// 	}
	
	// 	if (!from && !to) {
	// 		return data; // Return original data if both dates are empty
	// 	}
	
	// 	return data.filter((transaction) => {
	// 		if (!transaction.DocumentDate) return false; // Skip if DocumentDate is undefined or null
			
	// 		const transactionDate = new Date(transaction.DocumentDate.split('T')[0]);
	// 		return (!from || transactionDate >= from) && (!to || transactionDate <= to);
	// 	});
	// };

    // const refresh = () => {
    //     if (listingDataSource && listingDataSource.store) {
    //         listingDataSource.store.load().then((data) => {
    //             const filteredData = filterData(data);
    //             setListingDataSource({
    //                 ...listingDataSource,
    //                 store: new CustomStore({
    //                     key: "ID",
    //                     load: () => Promise.resolve(filteredData)
    //                 })
    //             });
    //         });
    //     }
    // };

    return (
        <Container>
            <div className='listing-page-title-container'>
                <span className='datagrid-customized-title'>
                    <PathIndicator />
                </span>
            </div>

            <div className='listing-datebox-container'>
                <div className='listing-left-side'>
                    <div style={{ marginRight: '4px' }}>
                        <span>Reg No: </span>
                        <CustomizedLookup
                            ref={regNoLookupRef}
                            className={"listing-page-searching-lookup"}
                            dataSource={registrationList}
                            displayExpr={"code"}
                            valueExpr={"id"}
                            height={"36px"}
                            onSelectionChanged={(e) => setRegNoValue(e.value)}
                            dataSourceURL={"api/Utilities/GetWKRegistration"}
                            onDataSourceChanged={setRegistrationList}
                        >
                            <Column dataField='code'></Column>
                            <Column dataField='modelCode' caption='Model'></Column>
                            <Column dataField='customerName' caption='Customer'></Column>
                            <Column dataField='mileage'></Column>
                        </CustomizedLookup>
                    </div>
                    <div style={{ marginTop: '18px' }}>
                        <span></span>
                        <TextBox
                            width={"450px"}
                            height={"36px"}
                            ref={searchBoxRef}
                            className='listing-page-search-box'
                            placeholder='Search'
                            valueChangeEvent='keyup'
                            onValueChanged={(e) => {
                                treelistRef.current.instance.searchByText(e.value);
                            }}
                        />
                    </div>
                </div>

                <div className='listing-right-side'>
                    <div className='listing-date-from-container'>
                        <span>From: </span>
                        <DateBox
                            displayFormat='dd/MM/yyyy'
                            dateSerializationFormat='yyyy-MM-dd'
                            useMaskBehavior={true}
                            type='date'
                            showClearButton={true}
                            onValueChanged={(e) => {
                                fromDate.current = e.value;
                                refresh();
                            }}
                        />
                    </div>

                    <div className='listing-date-to-container'>
                        <span>To: </span>
                        <DateBox
                            displayFormat='dd/MM/yyyy'
                            dateSerializationFormat='yyyy-MM-dd'
                            useMaskBehavior={true}
                            type='date'
                            showClearButton={true}
                            onValueChanged={(e) => {
                                toDate.current = e.value;
                                refresh();
                            }}
                        />
                    </div>
                </div>
            </div>

			<TreeList
				id='vehicle-access-treelist '
				ref={treelistRef}
				className='listing-page-treelist margin-top34'
				dataSource={listingDataSource}
				allowColumnReordering={true}
				allowColumnResizing={true}
				columnAutoWidth={false}
				columnResizingMode='nextColumn'
				showRowLines={true}
				showBorders={false}
				autoExpandAll={true}
				filterMode={'fullBranch'}
				height={"65vh"}
				width={"100%"}
				keyExpr='ID'
				parentIdExpr='TreeListDocNoRef'
				rootValue={0}
			>

				<Column
					dataField='DocumentNo'
					caption={"Doc No"}
					width={130}
					cellRender={(e) => {
						const data = e.data;
						const id = e.key;

						if (data.DocumentNo !== undefined) {
							return (
								<span
									className='clickable-span-tag'
									onClick={() => {
										setCurrentEditID(id);
									}}>
									{e.displayValue}
								</span>
							);
						} else {
							return e.displayValue;
						}
					}}
				/>
				<Column
					dataField='DocumentDate'
					caption={"Date"}
					width={95}
					cellRender={(e) => {
						const data = e.data;
						if (data.DocumentNo !== undefined) {
							return <span className='highlight-span-tag'>{utils.dateFormatter(e.displayValue)}</span>;
						}
					}}
				/>
				<Column
					dataField='Mileage'
					width={95}
					cellRender={(e) => {
						const data = e.data;
						if (data.DocumentNo !== undefined) {
							return <span className='highlight-span-tag'>{e.displayValue}</span>;
						}
					}}
				/>
				<Column
					dataField='Description'
					dataType={"string"}
					caption={"Part/Description"}
					width={390}
					cellRender={(e) => {
						if (utils.isNumberString(e.value)) {
							return <span className='highlight-span-tag'>{"Grand Total: " + utils.numberToCurrency(e.value)}</span>;
						} else {
							return e.value;
						}
					}}
				/>
				<Column dataField='Quantity' caption={"Qty"} width={60} />
				<Column dataField='UnitPrice' caption={"U/Price"} width={105} format={{ type: "fixedPoint", precision: 2 }} />
				<Column
					dataField='TaxInclusive'
					visible={false}
					caption={"SST"}
					dataType={"string"}
					width={95}
					customizeText={(v) => {
						if (v.value === true) {
							return "Inclusive";
						} else if (v.value === false) {
							return "Exclusive";
						} else {
							return "";
						}
					}}
				/>
				<Column dataField='ForexTaxable' caption={"Net/Price"} width={105} format={{ type: "fixedPoint", precision: 2 }} />
				<Column dataField='ItemDiscount' caption={"Discount"} width={135} format={{ type: "fixedPoint", precision: 2 }} />
				<Column dataField='NettAmount' caption={"Amount"} width={135} format={{ type: "fixedPoint", precision: 2 }} />
				<Column dataField='MechanicName' caption={"MechanicName"} width={186} />

				<Scrolling columnRenderingMode='standard' showScrollbar={"onHover"} useNative={true} />
			</TreeList>

			<WorkshopInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} title={"Invoice Entry"} />
        </Container>
    );
}
