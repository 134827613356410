import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import Validator, { RequiredRule, PatternRule, CompareRule } from 'devextreme-react/validator';
import { Popup } from 'devextreme-react/popup';
import ResetPasswordSend from './ResetPasswordSend';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import baseapi from '../../api/baseapi';

import notify from 'devextreme/ui/notify';
const ResetPasswordSetNew = ({ visible, onClose, email, tacCode, onSuccess}) => {

    const passwordValidatorRef = useRef(null);
    const confirmPasswordValidatorRef = useRef(null);
    const latestEmail = useRef(email);
    const [showResetPasswordSuccess, setShowResetPasswordSuccess] = useState(false);
    const popupWidth = useRef('45%'); // Default width
    const formData = useRef({ newPassword: '', confirmPassword: '' });
    const [passwordMode, setPasswordMode] = useState('password');
    const [confirmPasswordMode, setConfirmPasswordMode] = useState('password');
    const latestTacCode = useRef(tacCode);
    const [loading, setLoading] = useState(false);
    const checkWindowSize = useCallback(() => {
        if (window.innerWidth < 870) {
            popupWidth.current = '100%';
        } else {
            popupWidth.current = '45%';
        }
    }, []);
    useEffect(() => {
        latestTacCode.current = tacCode;
        latestEmail.current = email;
    }, [tacCode, email]);


    useEffect(() => {
        // Check window size initially
        checkWindowSize();

        // Add resize listener to check window size dynamically
        window.addEventListener('resize', checkWindowSize);

        return () => {
            // Clean up resize listener on component unmount
            window.removeEventListener('resize', checkWindowSize);
        };
    }, [checkWindowSize]);

    const onSubmit = useCallback(async () => {

        const passwordValidation = passwordValidatorRef.current.instance.validate();
        const confirmPasswordValidation = confirmPasswordValidatorRef.current.instance.validate();

        if (!passwordValidation.isValid || !confirmPasswordValidation.isValid) {
            return;
        }
        const { newPassword, confirmPassword } = formData.current;
        const tacCode = latestTacCode.current;
        const email = latestEmail.current;
        try {
            setLoading(true);
            const response = await baseapi.httppost('/oauth/ResetPassword', { Email: email, Password: newPassword, ConfirmPassword: confirmPassword, TacCode: tacCode, isNewAccount: false });
            setLoading(false);
            if (response.data.status) {
                onSuccess();
            } else {
                notify(response.data.message, 'error', 5000);
            }
        } catch (error) {
            console.error('Error occurred while resetting password:', error.message);
        }
    }, []);

    const handleClose = useCallback(() => {
        setShowResetPasswordSuccess(false);
        onClose();
    }, [onClose]);

    const passwordButton = useMemo(
        () => ({
            icon: 'images/eye-regular.svg',
            onClick: () => {
                setPasswordMode((prevPasswordMode) => (prevPasswordMode === 'text' ? 'password' : 'text'));
            },
        }),
        [setPasswordMode],
    );

    const confirmPasswordButton = useMemo(
        () => ({
            icon: 'images/eye-regular.svg',
            onClick: () => {
                setConfirmPasswordMode((prevPasswordMode) => (prevPasswordMode === 'text' ? 'password' : 'text'));
            },
        }),
        [setConfirmPasswordMode],
    );

    const passwordComparison = useCallback(() => formData.current.newPassword, []);
    return (
        <>
            <Popup
                width={popupWidth.current}
                height={'64%'}
                visible={visible}
                onHiding={handleClose}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={false}
            >
                <div className="auth-popup-content">
                    <div className="icon-flex">
                        <div className="icon-container">
                            <i className="fas fa-lock" aria-hidden="true"></i>
                        </div>
                    </div>
                    <p className="auth-popup-title">Set New Password</p>
                    <p className="auth-popup-subtitle">Must be at least 8 characters long, contain at least one number and one letter.</p>

                    <TextBox
                        onValueChanged={(e) => {
                            formData.current.newPassword = e.value;
                        }}
                        className="auth-pop-up-input"
                        stylingMode="outlined"
                        placeholder='New Password'
                        mode={passwordMode}
                    >
                        <TextBoxButton
                            name="password"
                            location="after"
                            options={passwordButton}
                        />
                        <Validator ref={passwordValidatorRef}>
                            <RequiredRule message="New Password is required" />
                            <PatternRule
                                message="Must contain at least 8 characters, one number, and one letter"
                                pattern={/^(?=.*[a-zA-Z])(?=.*\d).{8,}$/}
                            />
                        </Validator>
                    </TextBox>

                    <br />

                    <TextBox
                        onValueChanged={(e) => {
                            formData.current.confirmPassword = e.value;
                        }}
                        className="auth-pop-up-input"
                        stylingMode="outlined"
                        placeholder='Confirm New Password'
                        mode={confirmPasswordMode}
                    >
                        <TextBoxButton
                            name="password"
                            location="after"
                            options={confirmPasswordButton}
                        />
                        <Validator ref={confirmPasswordValidatorRef}>
                            <RequiredRule message="Confirm Password is required" />
                            <CompareRule
                                message="Password and Confirm Password do not match"
                                comparisonTarget={passwordComparison}
                            />
                        </Validator>
                    </TextBox>

                    <div className="auth-buttons">

                        {!loading && <button className="auth-button" onClick={onSubmit}>Reset Password</button>}
                        {loading && (
                            <div className="loading-indicator-container">
                                <div class="loader"></div>
                            </div>
                        )}

                        <a href="#" onClick={handleClose} className="auth-link">&lt;&lt; Back to Login</a>
                    </div>
                </div>
            </Popup>


        </>
    );
};

export default ResetPasswordSetNew;
