import React from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';

class StatementTypes extends React.Component {
    // render() {
    //     return <CommonTable title="Statement Type" url="/api/StatementTypes" hasCode sidx={"Code"} sord={true}/>;
    // }

    render() {
        return <Container>
            <div id='normal-listing'>
                <SettingListing
                    listingTitle="Statement Type"
                    apiURL="/api/StatementTypes"
                    listingURL="/api/StatementTypes/list"
                    sortColumn={"Code"}
                    defaultSettingTemplate={true}
                    dateFilter={false}
                    storageName={"SettingStatementTypesListing"}
                />
            </div>
        </Container>
    }
}

export default StatementTypes;