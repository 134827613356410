import { useEffect, useRef } from "react";
import { DxReportViewer } from "devexpress-reporting/dx-webdocumentviewer";
import { setSharedState } from "../../utils/localstorage";
import * as ko from "knockout";
import $ from "jquery";
import util from "../../utils/common";
import '../../styles/designer.scss';

export default function DocumentViewer() {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const reportUrl = urlParams.get("report");
	// const reportUrl = ko.observable("Tax Invoice (Full)");
	const viewerRef = useRef();
	const portNumber = 51561;
	const path = "/api/Dashboard";
	const authPath = "/api/Login";

	const requestOptions = {
		host: util.ReportURL + "/",
		invokeAction: "api/WebDocumentViewer",
	};

	useEffect(() => {
		const model = {
			reportUrl: reportUrl,
			requestOptions: requestOptions,
			callbacks: {
				BeforeRender: function (s, e) {
					// console.log(e.reportPreview.showMultipagePreview)
					e.reportPreview.showMultipagePreview(true);
				},
				CustomizeMenuActions: function (s, e) {
					// console.log(s.GetPreviewModel)

					// console.log(e)
					// console.log(s.previewModel)

					// for(var i = 0; i < e.Actions.length; i++){
					//   console.log(e.Actions[i]);
					// }
					// e.Actions[5].clickAction = () => {
					//     var zoom = reportUrl._zoom();
					//     reportUrl.showMultipagePreview(true);
					//     reportUrl.zoom(zoom);
					// }
					e.Actions[11].clickAction = () => {
						const style = document.createElement("style");
						style.innerHTML = `@page {size: auto!important}`;
						style.id = "page-orientation";
						document.head.appendChild(style);
						document.getElementsByTagName("body")[0].style = "";
						s.Print();
					};
					//console.log(e.Actions[11].clickAction);
				},
				// CustomizeParameterEditors: function (s, e) {
				//     if (e.parameter.type === "System.DateTime") {
				//         console.log("I AM HERE")
				//         e.info.editor = $.extend({}, e.info.editor);
				//         e.info.editor.extendedOptions =
				//             $.extend(e.info.editor.extendedOptions || {},
				//                 { type: 'date' }, { displayFormat: 'dd-MMM-yyyy' });
				//         var validationRule = {
				//             type: "range",
				//             min: new Date(2000, 0, 1),
				//             message: "We do not retain data prior to the year 2000."
				//         };
				//         e.info.validationRules=[validationRule];
				//     }
				//     console.log(e)
				// }
			},
		};

		//Display Report
		const viewer = new DxReportViewer(viewerRef.current, model);
		viewer.render();

		//Change Shared State
		setSharedState({previewLoading: false});

		return () => viewer.dispose();
	});

	return (
		<div
			ref={viewerRef}
			style={{ width: "100%", height: "calc(100vh - 56px)" }}
		></div>
	);
}

// const ReportViewer = () => {
// 	const reportUrl = ko.observable("TestReport");
// 	const viewerRef = useRef();
// 	const requestOptions = {
// 		host: "https://localhost:7136/",
// 		invokeAction: "api/WebDocumentViewer",
// 	};
// 	useEffect(() => {
// 		const model = {
// 			reportUrl: reportUrl,
// 			requestOptions: requestOptions,
// 			callbacks: {
// 				BeforeRender: function (s, e) {
// 					// console.log(e.reportPreview.showMultipagePreview)
// 					e.reportPreview.showMultipagePreview(true);
// 				},
// 				CustomizeMenuActions: function (s, e) {
// 					// console.log(s.GetPreviewModel)

// 					// console.log(e)
// 					// console.log(s.previewModel)

// 					// for(var i = 0; i < e.Actions.length; i++){
// 					//   console.log(e.Actions[i]);
// 					// }
// 					// e.Actions[5].clickAction = () => {
// 					//     var zoom = reportUrl._zoom();
// 					//     reportUrl.showMultipagePreview(true);
// 					//     reportUrl.zoom(zoom);
// 					// }
// 					e.Actions[11].clickAction = () => {
// 						const style = document.createElement("style");
// 						style.innerHTML = `@page {size: auto!important}`;
// 						style.id = "page-orientation";
// 						document.head.appendChild(style);
// 						document.getElementsByTagName("body")[0].style = "";
// 						s.Print();
// 					};
// 					//console.log(e.Actions[11].clickAction);
// 				},
// 				// CustomizeParameterEditors: function (s, e) {
// 				//     if (e.parameter.type === "System.DateTime") {
// 				//         console.log("I AM HERE")
// 				//         e.info.editor = $.extend({}, e.info.editor);
// 				//         e.info.editor.extendedOptions =
// 				//             $.extend(e.info.editor.extendedOptions || {},
// 				//                 { type: 'date' }, { displayFormat: 'dd-MMM-yyyy' });
// 				//         var validationRule = {
// 				//             type: "range",
// 				//             min: new Date(2000, 0, 1),
// 				//             message: "We do not retain data prior to the year 2000."
// 				//         };
// 				//         e.info.validationRules=[validationRule];
// 				//     }
// 				//     console.log(e)
// 				// }
// 			},
// 		};

// 		//Display Report
// 		const viewer = new DxReportViewer(viewerRef.current, model);
// 		viewer.render();

// 		return () => viewer.dispose();
// 	});
// 	return <div ref={viewerRef}></div>;
// };

// function App() {
// 	return (
// 		<div style={{ width: "100%", height: "1000px" }}>
// 			<ReportViewer />
// 		</div>
// 	);
// }

// export default App;
