import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import ResetPasswordSetNew from './ResetPasswordSetNew';
import notify from 'devextreme/ui/notify';
import baseapi from '../../api/baseapi';
const ResetPasswordOTP = ({ visible, onClose, onSendAgain, email, onOtpSubmit, loading, setCountdown }) => {
    const emailValidatorRef = useRef(null);
    const passwordValidatorRef = useRef(null);
    const confirmPasswordValidatorRef = useRef(null);
    const [showResetPasswordSetNew, setShowResetPasswordSetNew] = useState(false);
    const [otpFilled, setOtpFilled] = useState(false); // Track if OTP is completely filled
    const popupWidth = useRef('45%'); // Default width
    const formData = useRef({ newPassword: '', confirmPassword: '' });
    const [passwordMode, setPasswordMode] = useState('password');
    const [confirmPasswordMode, setConfirmPasswordMode] = useState('password');
    const otpInputsRef = useRef([]);
    const latestEmail = useRef(email);
    const [countdown, localSetCountdown] = useState(0); // Countdown state
    const checkWindowSize = useCallback(() => {
        if (window.innerWidth < 870) {
            popupWidth.current = '100%';
        } else {
            popupWidth.current = '45%';
        }


    }, []);
   
    useEffect(() => {
        // Check window size initially
        checkWindowSize();

        // Add resize listener to check window size dynamically
        window.addEventListener('resize', checkWindowSize);

        return () => {
            // Clean up resize listener on component unmount
            window.removeEventListener('resize', checkWindowSize);
        };


    }, [checkWindowSize]);

    const handleOtpPaste = useCallback((event) => {
        const paste = event.clipboardData.getData('text');
        const pasteArray = paste.split('').slice(0, 6);

        pasteArray.forEach((char, index) => {
            if (otpInputsRef.current[index]) {
                otpInputsRef.current[index].value = char;
            }
        });

        checkIfOtpFilled(pasteArray.length); // Check if OTP is filled after paste
    }, []);

    const handleOtpInput = useCallback((index, event) => {
        let digit = event.target.value;
        digit = digit.replace(/\D/g, ''); // Remove non-digit characters
        if (digit.length > 1) {
            digit = digit.slice(0, 1); // Ensure only the first digit is used
        }
        event.target.value = digit;

        if (digit !== '' && otpInputsRef.current[index + 1]) {
            otpInputsRef.current[index + 1].focus();
        }

        const filled = otpInputsRef.current.every((input) => input.value.length === 1);
        setOtpFilled(filled);
    }, []);

    const checkIfOtpFilled = useCallback((length) => {
        setOtpFilled(length === 6); // Check if all 6 inputs are filled
    }, []);

    const onSubmit = useCallback(async () => {
        const email = latestEmail.current;

        // Check if all OTP inputs are filled with exactly 6 digits
        const filledCorrectly = otpInputsRef.current.every(input => input.value.length === 1);
        if (!filledCorrectly) {
            notify("Please enter all OTP digits.", 'error', 2000);
            return;
        }

        let otp = "";
        otpInputsRef.current.forEach((input) => {
            otp += input.value;
            input.disabled = true;
            input.classList.add("disabled");
        });
        try {

            const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

            // Clear the inputs with a delay
            setTimeout(() => {
                otpInputsRef.current.forEach((input) => {
                    input.value = ""; // Clear the input
                    input.disabled = false;
                    input.classList.remove("disabled");
                });
            }, 500);

            await delay(500);
            const response = await baseapi.httppost('/oauth/VerifyCode', { TacCode: otp, Email: email });

            if (response.data.status) {
                onOtpSubmit(otp);
                setCookie(`attempts_${email}`, '', -1); // Set expiry date in the past to delete the cookie
            } else {
                notify(response.data.message, 'error', 5000);

                  // Update the email attempts count
                  let attempts = parseInt(getCookie(`attempts_${email}`), 10) || 0;
                  attempts += 1;
  
                  if (attempts > 5) {
                      notify("Your account has been locked. Please contact Greenplus Accounting Assistant Team at 03-62633933 for assistance in unlocking your account", 'error', 5000);
                  } else {
                      setCookie(`attempts_${email}`, attempts, 1); // 1 day expiration
                  }
            }
        } catch (error) {
            console.error('Error occurred while resetting password:', error.message);
        }






    }, []);

    const handleClose = useCallback(() => {
        setShowResetPasswordSetNew(false);
        onClose();
    }, [onClose]);

    useEffect(() => {
        if (otpFilled) {
            onSubmit();
        }
    }, [otpFilled, onSubmit]);

    useEffect(() => {
        latestEmail.current = email;
    }, [email]);

  

    const handleSendAgain = useCallback(() => {
        localSetCountdown(59); // Update local countdown state
        onSendAgain();
    }, [onSendAgain]);

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                localSetCountdown(countdown - 1);
                setCountdown(countdown - 1); 
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [countdown, setCountdown]);

    const setCookie = (name, value, days) => {
        const expires = new Date(Date.now() + days * 864e5).toUTCString();
        document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=/';
    };

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    };

    return (
        <>
            <Popup
                width={popupWidth.current}
                height={'66%'}
                visible={visible}
                onHiding={onClose}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={false}
            >
                <div className="auth-popup-content">
                    <div className="icon-flex">
                        <div className="icon-container">
                            <i className="fas fa-envelope-open"></i>
                        </div>
                    </div>
                    <p className="auth-popup-title">Password Recovery</p>
                    <p className="auth-popup-subtitle">We have sent a code to {latestEmail.current}</p>
                    <div className="otp-field" onPaste={handleOtpPaste}>
                        {Array(6).fill().map((_, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength="1"
                                ref={(el) => otpInputsRef.current[index] = el}
                                onInput={(event) => handleOtpInput(index, event)}
                                onFocus={(event) => event.target.select()} // Select text on focus
                            />
                        ))}
                    </div>

                    <div className="auth-buttons">
                        {!loading && <button className="auth-button" onClick={onSubmit}>Continue</button>}
                        {loading && (
                            <div className="loading-indicator-container">
                                <div class="loader"></div>
                            </div>
                        )}

                        <p className="auth-popup-subtitle" style={{ color: '#949494' }}>
                            {countdown > 0
                                ? <>You can resend the OTP after <strong style={{ color: '#6F6F6F', fontSize: '1em' }}>{countdown}</strong> second(s)</>
                                : <>
                                    Don’t receive the email? &nbsp;
                                    <a href="#" onClick={handleSendAgain} className="auth-link-inline">Click To Send Again</a>
                                </>}
                        </p>
                        <a href="#" onClick={onClose} className="auth-link">&lt;&lt; Back to Login</a>
                    </div>
                </div>
            </Popup>


        </>
    );
};

export default ResetPasswordOTP;
