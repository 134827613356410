import { Popup, Button } from "devextreme-react";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { useEffect, useState, useRef, useMemo, forwardRef, useImperativeHandle } from "react";
import "./CreditTermForm.scss";
import baseapi from "../../api/baseapi";
import ErrorPopUpForm from "../../components/popup-form/ErrorPopupForm";
import utils from "../../utils/common";

export default forwardRef(function CreditTermForm(props, ref) {
	const popupRef = useRef(null);
	const [userType, setUserType] = useState(null);
	const [isCustomer, setIsCustomer] = useState(null);
	const [creditTerm, setCreditTerm] = useState(null);
	const [totalOutAmt, setTotalOutAmt] = useState(null);
	const [overdueDays, setOverdueDays] = useState(null);
	const [creditLimit, setCreditLimit] = useState(null);
	const [outstandingAmt, setOutstandingAmt] = useState(null);
	const [isBoth, setIsBoth] = useState(null);
	const [isTerm, setIsterm] = useState(null);
	const [password, setPassword] = useState("");
	const popupMessageRef = useRef(null);
	const [passwordMode, setPasswordMode] = useState("password");

	useImperativeHandle(ref, () => ({
		displayMessage(details){
			if(!utils.isNullOrEmpty(details.userType)){
				setUserType(details.userType);
			}
	
			if(!utils.isNullOrEmpty(details.isCustomer)){
				setIsCustomer(details.isCustomer);
			}
	
			if(!utils.isNullOrEmpty(details.creditTerm)){
				setCreditTerm(details.creditTerm);
			}
	
			if(!utils.isNullOrEmpty(details.totalOutAmt)){
				setTotalOutAmt(details.totalOutAmt);
			}
	
			if(!utils.isNullOrEmpty(details.overdueDays)){
				setOverdueDays(details.overdueDays);
			}
	
			if(!utils.isNullOrEmpty(details.creditLimit)){
				setCreditLimit(details.creditLimit);
			}
	
			if(!utils.isNullOrEmpty(details.outstandingAmt)){
				setOutstandingAmt(details.outstandingAmt);
			}
	
			if(!utils.isNullOrEmpty(details.isBoth)){
				setIsBoth(details.isBoth);
			}
	
			if(!utils.isNullOrEmpty(details.isTerm)){
				setIsterm(details.isTerm);
			}

			if(!utils.isNullOrEmpty(details.visible)){
				if(!utils.isNullOrEmpty(popupRef.current)){
					if(details.visible){
						popupRef.current.instance.show();			
					}	
				}
			}
		},
	}));

	// useEffect(() => {
	// 	popupRef.current.instance.show();		
	// }, [])

	function onClose(savedSuccessful = false) {
		setPassword("");
		// console.log(savedSuccessful)
		popupRef.current.instance.hide();
		if(!savedSuccessful) {
			utils.displayPopupMessage(popupMessageRef, {
				visible: true,
				message: "This account had violated the credit term / credit limit. You are not allowed to save",
				type: "Danger"
			});
			props.ReturnSaveResult({ canSave: false });
		}
	}

	const passwordButton = useMemo(
		() => ({
			icon: "images/eye-regular.svg",
			onClick: () => {
				setPasswordMode((prevPasswordMode) => (prevPasswordMode === "text" ? "password" : "text"));
			}
		}),
		[setPasswordMode]
	);

	function handleSubmit() {
		if (password.length > 0) {
			const email = JSON.parse(localStorage.getItem("data")).LoginID;
			const companyID = JSON.parse(localStorage.getItem("data")).CompanyID;
			baseapi.httppost(`api/UserProfile/CheckPassword?companyID=${companyID}&email=${email}&password=${password}`).then((res) => {
				const { data } = res;
				// console.log(data);
				if (data.status) {
					props.ReturnSaveResult({ canSave: data.status });
					onClose(data.status);
				} else {
					utils.displayPopupMessage(popupMessageRef, {
						visible: true,
						message: data.message,
						type: "Danger"
					});
				}
			});
		} else {
			utils.displayPopupMessage(popupMessageRef, {
				visible: true,
				message: `Please enter password to proceed`,
				type: "Danger"
			});
		}
	}

	function valueOnChange(e, receivedDataField = null) {
		try {
			if (e.value) {
				// console.log(e.value);
				const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
				if (dataField === "Password") {
					setPassword(e.value);
				}
			}
		} catch (err) {
			console.log(err);
		}
	}

	function renderData (isBoth = false, isTerm = false) {
		if(isBoth) {
			return (
				<div className='outstandingMessage-popup-limits' style={{ marginTop: 10 }}>
					<div className='outstandingMessage-popup-limit-item'>
						<div>
							<div>Credit Limit: </div>
						</div>
					</div>

					<div className='outstandingMessage-popup-limit-item'>
						<div>
							<div>{utils.numberToCurrency(creditLimit)}</div>
						</div>
					</div>
					<div className='outstandingMessage-popup-limit-item'>
						<div>
							<div>Outstanding Amount: </div>
						</div>
					</div>

					<div className='outstandingMessage-popup-limit-item'>
						<div>
							<div style={{ color: "red" }}>{utils.numberToCurrency(outstandingAmt)}</div>
						</div>
					</div>
					<div className='outstandingMessage-popup-limit-item'>
						<div>
							<div>Credit Term: </div>
						</div>
					</div>

					<div className='outstandingMessage-popup-limit-item'>
						<div>
							<div>{creditTerm} Day(s)</div>
						</div>
					</div>
					<div className='outstandingMessage-popup-limit-item'>
						<div>
							<div>Overdue Days: </div>
						</div>
					</div>

					<div className='outstandingMessage-popup-limit-item'>
						<div>
							<div style={{ color: "red" }}>{overdueDays}</div>
						</div>
					</div>
					<div className='outstandingMessage-popup-limit-item'>
						<div>
							<div>Overdue Credit: </div>
						</div>
					</div>

					<div className='outstandingMessage-popup-limit-item'>
						<div>
							<div style={{ color: "red" }}>{utils.numberToCurrency(totalOutAmt)}</div>
						</div>
					</div>
				</div>)
		} else {
			if (isTerm) {
				return(
					<div className='outstandingMessage-popup-limits' style={{ marginTop: 10 }}>
						<div className='outstandingMessage-popup-limit-item'>
							<div>
								<div>Credit Term: </div>
							</div>
						</div>

						<div className='outstandingMessage-popup-limit-item'>
							<div>
								<div>{creditTerm} Day(s)</div>
							</div>
						</div>
						<div className='outstandingMessage-popup-limit-item'>
							<div>
								<div>Overdue Days: </div>
							</div>
						</div>

						<div className='outstandingMessage-popup-limit-item'>
							<div>
								<div style={{ color: "red" }}>{overdueDays}</div>
							</div>
						</div>
						<div className='outstandingMessage-popup-limit-item'>
							<div>
								<div>Overdue Credit: </div>
							</div>
						</div>

						<div className='outstandingMessage-popup-limit-item'>
							<div>
								<div style={{ color: "red" }}>{utils.numberToCurrency(totalOutAmt)}</div>
							</div>
						</div>
					</div>
				)
			} else {
				return(
					<div className='outstandingMessage-popup-limits' style={{ marginTop: 10 }}>
						<div className='outstandingMessage-popup-limit-item'>
							<div>
								<div>Credit Limit: </div>
							</div>
						</div>

						<div className='outstandingMessage-popup-limit-item'>
							<div>
								<div>{utils.numberToCurrency(creditLimit)}</div>
							</div>
						</div>
						<div className='outstandingMessage-popup-limit-item'>
							<div>
								<div>Outstanding Amount: </div>
							</div>
						</div>
						<div className='outstandingMessage-popup-limit-item'>
							<div>
								<div style={{ color: "red" }}>{utils.numberToCurrency(outstandingAmt)}</div>
							</div>
						</div>
					</div>
				)
			}
		}
	}

	return (
		<div>
			<Popup ref={popupRef} id='OutstandingMessagePopUp' width={450} showTitle={false} hideOnOutsideClick={true} height={"auto"} shading={false}>
				<div className='outstandingMessage-popup-items'>
					<div className='outstandingMessage-popup-header'>
						<div className='outstandingMessage-popup-cross'>
							<Button
								icon='close'
								stylingMode='text'
								onClick={() => {
									onClose(false);
								}}
							/>
						</div>
					</div>
				</div>
				<div className='outstandingMessage-popup-body'>
					<div className='outstandingMessage-popup-content'>
						<div>
							{isCustomer ? `Customer ${userType} ` : `Supplier ${userType} `}
							<b>has exceeded the {isBoth ? "credit term and credit limit." : (isTerm ? "credit term." : "credit limit.")}</b>

							{renderData(isBoth, isTerm)}
						</div>
						<div style={{ marginBottom: 10, marginTop: 10 }}>Please refer to authorize personal.</div>
						<div style={{ marginBottom: 5 }}>Enter the password to continue: </div>
						<TextBox
							mode={passwordMode}
							showClearButton={true}
							value={password}
							elementAttr={{ dataField: "Password" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}>
							<TextBoxButton name='password' location='after' options={passwordButton} />
						</TextBox>

						<div className='outstandingMessage-popup-footer'>
							<Button type='success' stylingMode='contained' text='Confirm' onClick={() => handleSubmit()} />
							<Button
								type='success'
								stylingMode='outlined'
								text='Cancel'
								onClick={() => {
									onClose(false);
								}}
							/>
						</div>
					</div>
				</div>
			</Popup>

			<ErrorPopUpForm ref={popupMessageRef} />
		</div>
	);
})