import { Popup } from "devextreme-react";
import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import "./ErrorPopupForm.scss";
import utils from "../../utils/common";

export default forwardRef(function ErrorPopUpForm(props, ref) {
	const popupRef = useRef(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [messageType, setMessageType] = useState(null);
	const styleType = useRef(null);
	const styleName = ["errorMessage-popup-container", "warningMessage-popup-container", "successMessage-popup-container"];

	useImperativeHandle(ref, () => ({
		displayMessage(details){
			if(!utils.isNullOrEmpty(details.message)){
				setErrorMessage(details.message);
			}
	
			if(!utils.isNullOrEmpty(details.type)){
				styleType.current = details.type
				setMessageType(details.type);
			}

			if(!utils.isNullOrEmpty(details.visible)){
				if(!utils.isNullOrEmpty(popupRef.current)){
					if(details.visible){
						popupRef.current.instance.show();

						//If it is success remove it in 3 seconds
						if(details.type === "Success"){
							setTimeout(() => {
								if(!utils.isNullOrEmpty(popupRef.current))
									popupRef.current.instance.hide();
							}, utils.isNullOrEmpty(props.displayTime) ? 3000 : props.displayTime);
						}				
					}
				}
			}
		},
	}));

	return (
		<div>
			<Popup
				ref={popupRef}
				id='ErrorMessagePopUp'
				onShowing={(e) => {
					if(!utils.isNullOrEmpty(styleType.current)){
						if (styleType.current === "Danger") {
							//Remove the unused style to avoid conflict
							e.component.content().parentElement.classList.remove(...styleName);
							e.component.content().parentElement.classList.add(styleName[0]);
						} else if (styleType.current === "Warning") {
							//Remove the unused style to avoid conflict
							e.component.content().parentElement.classList.remove(...styleName);
							e.component.content().parentElement.classList.add(styleName[1]);
						} else if (styleType.current === "Success") {
							//Remove the unused style to avoid conflict
							e.component.content().parentElement.classList.remove(...styleName);
							e.component.content().parentElement.classList.add(styleName[2]);
						}
					}
				}}
				width={375}
				showTitle={false}
				height={"auto"}
				shading={false}
				hideOnOutsideClick={true}
			>
				<div className='errorMessage-popup-body'>
					{messageType === "Danger" && (
						<div className='errorMessage-popup-items'>
							<i className='dx-icon-clear dx-icon-custom-styleError'></i>
						</div>
					)}
					{messageType === "Warning" && (
						<div className='errorMessage-popup-items'>
							<i className='dx-icon-warning dx-icon-custom-styleError'></i>
						</div>
					)}
					{messageType === "Success" && (
						<div className='errorMessage-popup-items'>
							<i className='dx-icon-todo dx-icon-custom-styleError'></i>
						</div>
					)}
					<div className='errorMessage-popup-items'>
						<div className='errorMessage-popup-header'>{messageType === "Danger" ? "Error" : messageType === "Warning" ? "Warning" : "Successful"}</div>
						<span className='errorMessage-popup-content'>{errorMessage}</span>
					</div>
				</div>
			</Popup>
		</div>
	);
})