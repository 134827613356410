import { Popup, Button, TextBox } from "devextreme-react";
import { useEffect, useState, useRef } from "react";
import baseapi from "../../../api/baseapi";
import ErrorPopUpForm from "../../../components/popup-form/ErrorPopupForm";
import utils from "../../../utils/common";
import Validator, { RequiredRule } from "devextreme-react/validator";
import DataGrid, { Column, Editing, Selection, Scrolling, Lookup, KeyboardNavigation, ColumnChooser, Summary, TotalItem, Toolbar, Item as ToolbarItem } from "devextreme-react/data-grid";
import { ScrollView } from 'devextreme-react/scroll-view';
export default function MoreInfor(props) {
    const [visible, setVisible] = useState(props.visible);
    const [formValue, setFormValue] = useState({});

    useEffect(() => {
        if (props.onValueChanged !== undefined) {
            props.onValueChanged({ value: visible });
        }
    }, [visible]);

    function onClose() {
        setVisible(false);
    }

    function handleSubmit() {
        props.ReturnSaveResult({ canSave: true });
        onClose();
    }

    function valueOnChange(e) {
        setFormValue(prevFormValue => ({
            ...prevFormValue,
            [e.elementAttr.dataField]: e.value
        }));
    }

    return (
        <div>
            <Popup id='stockMoreInforMessagePopUp' visible={visible} fullScreen={false}
                width={"45%"}
                height={"60%"} showTitle={false} hideOnOutsideClick={true} shading={true} onHiding={() => onClose()}>
                <div className='stockMoreInforMessage-popup-items'>
                    <div className='stockMoreInforMessage-popup-header'>
                        <div className='stockMoreInforMessage-popup-cross'>
                            <Button icon='close' stylingMode='text' onClick={() => onClose()} />
                        </div>
                    </div>
                </div>
                <div className='stockMoreInforMessage-popup-body'>
                    
                    <div className='stockMoreInforMessage-popup-content'>
                        <div className='stockMoreInforMessage-popup' style={{ marginTop: 34 }}>
                            <div className='popup-form-main-content'>
                                <div className='popup-form-item-container2' style={{ marginTop: '5px' }}>
                                    <div className='popup-group-form-item-stock-detail'>
                                        <div className='popup-group-form-label'>Average Sales: </div>
                                        <div className='popup-group-form-input'>
                                            <TextBox
                                                value={props.formValue["AverageSales"]}
                                                alignment='left'
                                                width={"auto"}
                                            >
                                            </TextBox>
                                        </div>
                                    </div>
                                    <div className='popup-group-form-item-stock-detail'>
                                        <div className='popup-group-form-label'>12 Mth Sales: </div>
                                        <div className='popup-group-form-input'>
                                            <TextBox
                                                value={props.formValue["TwelveMonthSales"]}
                                              
                                                alignment='left'
                                                width={"auto"}
                                            >
                                            </TextBox>
                                        </div>
                                    </div>
                                </div>
                                <div className='popup-form-item-container2' style={{ marginTop: '5px' }}>
                                    <div className='popup-group-form-item-stock-detail'>
                                        <div className='popup-group-form-label'>Item Type </div>
                                        <div className='popup-group-form-input'>
                                            <TextBox
                                                value={props.formValue["ItemTypeCode"]}
                                                alignment='left'
                                                width={"auto"}
                                            >
                                            </TextBox>
                                        </div>
                                    </div>
                                    <div className='popup-group-form-item-stock-detail'>
                                        <div className='popup-group-form-label'>Category Code: </div>
                                        <div className='popup-group-form-input'>
                                            <TextBox
                                                value={props.formValue["CategoryCode"]}
                                                alignment='left'
                                                width={"auto"}
                                            >
                                            </TextBox>
                                        </div>
                                    </div>
                                </div>

                                <div className='popup-form-item-container2' style={{ marginTop: '5px' }}>
                                    <div className='popup-group-form-item-stock-detail'>
                                        <div className='popup-group-form-label'>Brand Code </div>
                                        <div className='popup-group-form-input'>
                                            <TextBox
                                                value={props.formValue["BrandCode"]}
                                                alignment='left'
                                                width={"auto"}
                                            >
                                            </TextBox>
                                        </div>
                                    </div>
                                    <div className='popup-group-form-item-stock-detail'>
                                        <div className='popup-group-form-label'>Model Code: </div>
                                        <div className='popup-group-form-input'>
                                            <TextBox
                                                value={props.formValue["ModelCode"]}
                                                alignment='left'
                                                width={"auto"}
                                            >
                                            </TextBox>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginTop: '40px' }}>

                                <div className='popup-group-form-item'>
                                    <div className='inventory-module-border-bottom' style={{ padding: "0 0" }}>
                                        <DataGrid
                                            dataSource={props.listingStockAlternateSource}
                                            sorting={false}
                                            showBorders={true}
                                        >
                                            
                                            <Column dataField='AlternateCode' editorOptions={{ disabled: true }} />

                                            <Column dataField='Description' editorOptions={{ disabled: true }} />
                                            <Editing allowUpdating={true} allowDeleting={false} mode='cell' />
                                        </DataGrid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>

          
        </div>
    );
}
