import React, { useState, useEffect, useRef, useCallback } from "react";
import PopupForm from "../../components/popup-form/PopupFormV2";
import { useNavigate } from "react-router-dom";
import baseapi from "../../api/baseapi";
import utils from "../../utils/common";
import DataGrid, { Column, Editing, Selection, Scrolling, Lookup, KeyboardNavigation, ColumnChooser, Summary, TotalItem, Toolbar, Item as ToolbarItem } from "devextreme-react/data-grid";
import { saveToLS, getFromLS } from "../../utils/localstorage";
import { NumberBox } from "devextreme-react/number-box";
import { SelectBox } from "devextreme-react/select-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { TextBox } from "devextreme-react/text-box";
import { Button } from "devextreme-react/button";
import { loading, closeLoading } from "../../utils/common";
import CustomizedLookup from "../../components/lookup/Lookup";
import CustomizedGridLookup from "../../components/lookup/GridLookup";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import AsyncTextBox from "../../components/async-text-box/AsyncTextBox";
import { apdebitNoteReportNames } from "../../utils/lookupstore";
import ErrorPopUpForm from "../../components/popup-form/ErrorPopupForm";
import ChildrenDatagrid from "../../components/children-datagrid/ChildrenDatagrid";
import { moduleCalculation, ParentValueCalculation } from "../../utils/module-calculation";
import Tabs from 'devextreme-react/tabs';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { Gallery } from 'devextreme-react/gallery';
import TreeList from "devextreme-react/tree-list";
import Form, { SimpleItem, Label, GroupItem } from "devextreme-react/form";
import TextArea from "devextreme-react/text-area";
import RadioGroup from "devextreme-react/radio-group";
import CustomStore from "devextreme/data/custom_store";
import { debounce } from "lodash";
export default function UserGroupsForm(props) {

	const formID = useRef(null);
	const formRead = useRef(false);
	const formRef = useRef(null);

	const [isEditing, setIsEditing] = useState(false);
	const [formValue, setFormValue] = useState({});
	const itemTypeLookupRef = useRef(null);
	const [displayError, setDisplayError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [messageType, setMessageType] = useState("");
	const userRoleLookupRef = useRef(null);
	const [userRoleList, setUserRoleList] = useState([]);
	const [listingDataSource, setListingDataSource] = useState(null);
	const treelistRef = useRef(null);
	const tempForm = useRef(null);
	const [startValidation, setStartValidation] = useState(0);
	const [checkboxValues, setCheckboxValues] = useState({});
	const [directAccessClicked, setDirectAccessClicked] = useState(false);
	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}
	}, [props.formID]);
	const handleCheckboxChange = useCallback((rowId, column, value) => {
		setCheckboxValues(prevValues => {
			const newValues = { ...prevValues };

			const updateRow = (id, columns, val) => {
				const updates = columns.reduce((acc, col) => {
					acc[col] = val;
					return acc;
				}, {});

				newValues[id] = {
					...newValues[id],
					...updates
				};
			};

			const updateChildren = (id, columns, val) => {
				const children = Object.keys(newValues).filter(key => newValues[key].ParentID === id);
				if (children.length > 0) {
					children.forEach(childId => {
						updateRow(childId, columns, val);
						updateChildren(childId, columns, val);
					});
				}
			};
			const updateParents = (id, columns, val) => {
				const parentId = newValues[id].ParentID;
				if (parentId) {
					const parent = newValues[parentId];
					updateRow(parentId, ['DirectAccess'], val);
					updateRow(parentId, columns, val);
					updateParents(parentId, columns, val);
				}
			};
			const relatedColumns = column === 'DirectAccess'
				? ['DirectAccess', 'Add', 'Edit', 'DeleteAccess', 'Report']
				: [column];

			if (newValues[rowId][column] !== value) {
				updateRow(rowId, relatedColumns, value);

				if (['Add', 'Edit', 'DeleteAccess', 'Report'].includes(column) && value) {
					updateRow(rowId, ['DirectAccess'], true);
					updateChildren(rowId, ['DirectAccess'], true);
				}
				const hasChildren = Object.keys(newValues).some(key => newValues[key].ParentID === rowId);
				if (hasChildren) {
					updateChildren(rowId, relatedColumns, value);
				}
				if (value) {
					updateParents(rowId, relatedColumns, value);
				}
			}
			if (column === 'DirectAccess') {
				//change this to false
				setDirectAccessClicked(true);
				// Set a timeout to re-enable after a certain period
				setTimeout(() => setDirectAccessClicked(true), 1000); 

			}
		
			return newValues;
		});
	}, [setCheckboxValues]);


	useEffect(() => {
		console.log(checkboxValues, "checkboxValues");
	}, [checkboxValues]);

	function valueOnChange(e, receivedDataField = null) {

		
		try {
			if (e.value !== undefined) {
				const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
				const copied = { ...tempForm.current };
				copied[dataField] = e.value;
				tempForm.current = copied;
				setFormValue(tempForm.current);
			}
		} catch (err) {
			console.log(err);
		}
	}

	function handleSubmit({ stay }) {
		loading("Saving User Group...");

		const submittedForm = {
			Parent: formValue,
			CheckboxValues: Object.values(checkboxValues)
		};

		console.log(submittedForm);
		baseapi.httppost(
			utils.extendUrlVar("/api/UserGroup/save", {
				id: formID.current || "",
				del: false,
			}),
			submittedForm
		)
			.then((response) => {
				const { data } = response;

				console.log("response", data);

				utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Warning",
					action: data.action
				});

				if (data.status) {
					setMessageType("Success");
					clearForm();
				} else {
					setMessageType("Warning");
				}
				setDisplayError(true);
				setErrorMessage(data.message);
			});
	}

	function onInitialized(e) {

		if (formID.current === "new") loading("Loading New User Group...");
		else loading("Loading Existing User Group...");

		baseapi.httpget("/api/UserGroup/Get?id=" + (formID.current || "new")).then((response) => {
			const { data } = response;
			if (formID.current === "new") {
				tempForm.current = data.model.Parent;
				if (tempForm.current.IdleTime === 0) {
					tempForm.current.IdleTime = 7200000; 
				}
				setFormValue(tempForm.current);
			} else {

				setFormValue(data.model.Parent);
				tempForm.current = data.model.Parent;
			}

			closeLoading();
			formRead.current = true;
		});

		baseapi
			.httpget(`/api/UserGroup/UserRightList`, { id: (formID.current || "new") })
			.then((response) => {
				const { data } = response;

				console.log("list", data);
				setListingDataSource(data);

				const initialValues = data.reduce((acc, item) => {
					acc[item.id] = {
						ParentID: item.ParentID,
						MenuID: item.MenuID,
						Add: item.Add,
						Edit: item.Edit,
						DeleteAccess: item.DeleteAccess,
						Report: item.Report,
						View: item.View,
						DirectAccess: item.DirectAccess
					};
					return acc;
				}, {});
				setCheckboxValues(initialValues);
				closeLoading();
			})
			.catch((error) => {
				console.log(error);
				closeLoading();
			});
	}

	const clearForm = () => {
		props.closePopup(null);
		formRead.current = false;
		tempForm.current = {};
		setFormValue(tempForm.current);
		setCheckboxValues({});
		setListingDataSource(null);
	};

	const idleTimeOptions = [
		{ display: "1 hour", value: 3600000 },
		{ display: "2 hours", value: 7200000 },
		{ display: "3 hours", value: 10800000 },
		{ display: "4 hours", value: 14400000 },
		{ display: "5 hours", value: 18000000 },
		{ display: "6 hours", value: 21600000 },
		{ display: "7 hours", value: 25200000 },
		{ display: "8 hours", value: 28800000 },
		{ display: "9 hours", value: 32400000 },
		{ display: "10 hours", value: 36000000 },
	];

	return (
		<div>
			<PopupForm
				ref={formRef}
				onClosing={clearForm}
				fullScreen={false}
				width={"95%"}
				height={"90%"}
				disableButtons={props.lockedForm === true}
				headerClassName={"maintainance-module-form-title-bg popup-form-title"}
				creditChecking={false}
				title={"New User Group"}
				onShown={onInitialized}
				onSuccessfulSubmit={({ stay }) => { if (stay) onInitialized(); }}
				onPasteForm={(e) => { tempForm.current = e.Parent; setFormValue(e.Parent); }}
				copyStorage={"UserGroupCopiedData"}
				formData={formValue}
				isEditing={isEditing}
				onValidating={setStartValidation}
				startValidation={startValidation}
				onSaving={handleSubmit}
				cancelDocumentCustomization={
					<span className='popup-form-header-cancel'>
						<CheckBox
							value={formValue["Active"]}
							elementAttr={{ dataField: "Active" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"25px"}
							height={"50px"}
							iconSize={"18px"}
							disabled={props.lockedForm === true}
							className='popup-header-cancel-input'
						/>
						<span className='popup-header-cancel-text' style={{ color: '#808080' }}>Active</span>
					</span>
				}
			>
				<hr className='maintenance-popup-form-hr' />
				<div className='popup-form-main-content'>
					<div className='maintenance-module-parent-section'>
						<div className='popup-form-item-container2'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label next-doc-label'>User Group ID: </div>
								<div className='popup-group-form-input'>
									<AsyncTextBox
										value={formValue["Code"]}
										elementAttr={{ dataField: "Code" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										disabled={formID.current !== "new" || props.lockedForm === true}
										startValidation={startValidation !== 0}
										asyncURL={"api/UserGroup/CheckDuplicateUserGroup"}
										requiredMessage={"User Group ID is required!"}
										asyncMessage={"This User Group ID is already taken!"}
									/>
								</div>
							</div>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label next-doc-label'>IDLE TIME: </div>
								<div className='popup-group-form-input'>
									<SelectBox
										dataSource={idleTimeOptions}
										displayExpr="display"
										valueExpr="value"
										elementAttr={{ dataField: "IdleTime" }}
										value={formValue["IdleTime"]}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										placeholder="Select idle time"

									/>
								</div>
							</div>
						</div>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Description: </div>
							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["Name"]}
									elementAttr={{ dataField: "Name" }}
									onValueChanged={(e) => valueOnChange(e)}
									alignment='left'
									width={"auto"}
								>
									<Validator>{startValidation !== 0 && <ValidatorRequired message={"Description is required!"} />}</Validator>
								</TextBox>
							</div>
						</div>
					</div>
					<TreeList
						id='user-access-treelist'
						className='listing-page-treelist treelist-margin-left'
						dataSource={listingDataSource}
						allowColumnReordering={true}
						allowColumnResizing={true}
						columnAutoWidth={false}
						columnResizingMode='nextColumn'
						showRowLines={true}
						showBorders={false}
						autoExpandAll={true}
						height={"100%"}
						width={"100%"}
						keyExpr='id'
						parentIdExpr='ParentID'
						rootValue={"t2B0SsAZelE%3d"}
						loadPanel={{ enabled: true }}
						hoverStateEnabled={true}
					>
						<Column dataField={"Name"} caption={"Name"} minWidth={150} />

						<Column
							dataField={"DirectAccess"}
							width={120}
							alignment={"center"}
							cellRender={data => (
								<CheckBox
									value={checkboxValues[data.data.id]?.DirectAccess || false}
									//disabled={tempForm.current?.isSystemDefined}
									onValueChanged={e => {
										handleCheckboxChange(data.data.id, 'DirectAccess', e.value);
										//handleDirectAccessChange(data.data.id, e.value);
									}}
								/>
							)}
						/>

						<Column
							dataField={"Add"}
							width={120}
							alignment={"center"}
							cellRender={data => (
								<CheckBox
									value={checkboxValues[data.data.id]?.Add || false}
									//disabled={tempForm.current?.isSystemDefined}
									onValueChanged={//directAccessClicked ? undefined : 
										(e) => handleCheckboxChange(data.data.id, 'Add', e.value)
									}
								/>
							)}
						/>

						<Column
							dataField={"Edit"}
							width={120}
							alignment={"center"}
							cellRender={data => (
								<CheckBox
									value={checkboxValues[data.data.id]?.Edit || false}
									//disabled={tempForm.current?.isSystemDefined}
									onValueChanged={//directAccessClicked ? undefined : 
										(e) => handleCheckboxChange(data.data.id, 'Edit', e.value)}
								/>
							)}
						/>

						<Column
							dataField={"DeleteAccess"}
							width={120}
							caption={"Delete"}
							alignment={"center"}
							cellRender={data => (
								<CheckBox
									value={checkboxValues[data.data.id]?.DeleteAccess || false}
									//disabled={tempForm.current?.isSystemDefined}
									onValueChanged={//directAccessClicked ? undefined : 
										(e) => handleCheckboxChange(data.data.id, 'DeleteAccess', e.value)}
								/>
							)}
						/>

						<Column
							dataField={"Report"}
							width={120}
							alignment={"center"}
							cellRender={data => (
								<CheckBox
									value={checkboxValues[data.data.id]?.Report || false}
									//disabled={tempForm.current?.isSystemDefined}
									onValueChanged={//directAccessClicked ? undefined : 
										(e) => handleCheckboxChange(data.data.id, 'Report', e.value)}

								/>
							)}
						/>

						<Scrolling columnRenderingMode='standard' showScrollbar={"onHover"} useNative={true} />
					</TreeList>
				</div>
			</PopupForm>
		</div>
	);
}
