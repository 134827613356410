import { useEffect, useRef } from "react";
import { DxReportViewer } from "devexpress-reporting/dx-webdocumentviewer";
import { setSharedState } from "../../utils/localstorage";
import util from "../../utils/common";
import '../../styles/designer.scss';

export default function PDFViewer() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const reportUrl = urlParams.get("report");
    const viewerRef = useRef();

    const requestOptions = {
        host: util.ReportURL + "/",
        invokeAction: "api/WebDocumentViewer",
    };

    useEffect(() => {
        const model = {
            reportUrl: reportUrl,
            requestOptions: requestOptions,
            callbacks: {
                BeforeRender: function (s, e) {
                    e.reportPreview.showMultipagePreview(true);
                },
                CustomizeMenuActions: function (s, e) {
                    e.Actions[11].clickAction = () => {
                        const style = document.createElement("style");
                        style.innerHTML = `@page {size: auto!important}`;
                        style.id = "page-orientation";
                        document.head.appendChild(style);
                        document.getElementsByTagName("body")[0].style = "";
                        s.Print();
                    };
                },
            },
        };

        // Display Report
        const viewer = new DxReportViewer(viewerRef.current, model);
        viewer.render();

        // Change Shared State
        setSharedState({ previewLoading: false });

        // Inject CSS to hide specific elements
        const injectCSS = () => {
            const style = document.createElement("style");
            style.innerHTML = `
                .dxrd-right-tabs,
                .dxd-side-panel-tabs-back-color,
                .dxd-back-contrast,
                .dxrd-tab-panel-right {
                    display: none !important;
                }
            `;
            document.head.appendChild(style);
        };

        injectCSS();

        return () => {
            viewer.dispose();
            // Optionally, remove the injected styles on component unmount
            const style = document.getElementById("page-orientation");
            if (style) style.remove();
        };
    }, [reportUrl]);

    return (
        <div
            ref={viewerRef}
            style={{ width: "100%", height: "calc(100vh - 56px)" }}
        ></div>
    );
}
