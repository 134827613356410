import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import baseapi from "../../api/baseapi";
import { useParams } from "react-router-dom";
import utils, { done, processing } from "../../utils/common";
import Form, { Item, GroupItem, Label, ButtonItem, RequiredRule, PatternRule } from "devextreme-react/form";
import { taxCodeOptions, projectOptions, glChartAccountOptions, gstTranTypeOptions, taxTypeOptions } from "../../utils/lookupstore";
import Container from "../../components/container";
import { saveToLS, getFromLS } from "../../utils/localstorage";

export default function GSTTaxEdit() {
	const { id } = useParams();
	const navigate = useNavigate();

	const [form, setForm] = useState({
		model: null
	});

	const [dropDownList, setDropDownList] = useState({
		taxCodeList: [],
		projectList: [],
		chartTypeList: [],
		gstTaxCodeList: []
	});

	const badDepts = [
		{
			id: "0",
			name: "None"
		},
		{
			id: "1",
			name: "AP Bad Debts Relief"
		},
		{
			id: "2",
			name: "AR Bad Debts Relief"
		}
	];

	const getDisplayExpr = (option) => {
		return option ? `${option.code} - ${option.name}` : "";
	};

	const [taxCodeEditorOptions, setTaxCodeEditorOptions] = useState(utils.getSelectBoxOption(dropDownList.taxCodeList));

	const newTaxCode = (args) => {
		// console.log(args)

		if (!args.text) {
			args.customItem = null;
			return;
		}

		const newItem = {
			code: args.text,
			name: args.text
		};

		args.customItem = newItem;
		taxCodeEditorOptions.dataSource.push(newItem);
	};

	const newTaxType = (args) => {
		// console.log(args)

		if (!args.text) {
			args.customItem = null;
			return;
		}

		const newItem = {
			code: args.text,
			name: args.text
		};

		args.customItem = newItem;
		taxTypeCodeEditorOptions.dataSource.push(newItem);
	};

	const [govtTaxCodeEditorOptions, setGovtTaxCodeEditorOptions] = useState();

	const [projectCodeEditorOptions, setProjectCodeEditorOptions] = useState(utils.getSelectBoxOption(dropDownList.projectList));

	const [chartAccountCodeEditorOptions, setChartAccountCodeEditorOptions] = useState(utils.getSelectBoxOption(dropDownList.chartTypeList));

	const [taxTypeCodeEditorOptions, setTaxTypeCodeEditorOptions] = useState();

	const [gstTranTypeCodeEditorOptions, setGSTTranTypeCodeEditorOptions] = useState();

	const [badDeptsCodeEditorOptions, setBadDeptsCodeEditorOptions] = useState(utils.getSelectBoxOption(badDepts));

	const [description, setDescription] = useState({
		disabled: false,
		value: ""
	});

	const [taxRate, setTaxRate] = useState({
		disabled: false,
		value: 0.0
	});

	const submitButtonOptions = {
		text: "Save",
		type: "success",
		useSubmitBehavior: false,
		onClick: handleSubmit
	};

	const cancelButtonOptions = {
		text: "Cancel",
		type: "normal",
		useSubmitBehavior: false,
		onClick: handleCancel
	};

	useEffect(() => {
		Promise.allSettled([taxCodeOptions.store.load(), projectOptions.store.load(), glChartAccountOptions.store.load(), gstTranTypeOptions.store.load(), taxTypeOptions.store.load()]).then((lists) => {
			setDropDownList({
				taxCodeList: lists[0].value.data,
				projectList: lists[1].value.data,
				chartTypeList: lists[2].value.data,
				gstTranTypeList: lists[3].value.data,
				taxTypeList: lists[4].value.data
			});
			setTaxCodeEditorOptions(utils.getSelectBoxOption(lists[0].value.data));
			setProjectCodeEditorOptions(utils.getSelectBoxOption(lists[1].value.data));
			setChartAccountCodeEditorOptions(utils.getSelectBoxOption(lists[2].value.data));

			const govtTaxCodeSettings = utils.getSelectBoxOption(lists[0].value.data);
			govtTaxCodeSettings["displayExpr"] = getDisplayExpr;
			govtTaxCodeSettings["valueExpr"] = "code";
			setGovtTaxCodeEditorOptions(govtTaxCodeSettings);

			const gstTranTypeSettings = utils.getSelectBoxOption(lists[3].value.data);
			gstTranTypeSettings["displayExpr"] = getDisplayExpr;
			setGSTTranTypeCodeEditorOptions(gstTranTypeSettings);

			const taxTypeSettings = utils.getSelectBoxOption(lists[4].value.data);
			taxTypeSettings["displayExpr"] = getDisplayExpr;
			setTaxTypeCodeEditorOptions(taxTypeSettings);
		});

		window.addEventListener("resize", () => {
			saveToLS("windowSize", window.innerWidth);
		});
	}, []);

	function handleSubmit(e) {
		if (e.validationGroup.validate().isValid) {
			processing();
			// console.log(form);
			baseapi.httppost(utils.extendUrlVar("/api/GSTTaxCode/save", { id: id || "", del: false }), form).then((response) => {
				// const { id } = response;
				done().then(() => {
					onInitialized();
				});
			});
		}
	}

	function onFieldDataChanged(e) {
		if (e.dataField === "Code") {
			var gstTaxCodeID = 0;
			baseapi.httpget("/api/GSTTaxCode/GetGSTTaxByCode", { code: e.value }).then((response) => {
				gstTaxCodeID = response.data;

				if (gstTaxCodeID !== null) {
					baseapi.httpget("/api/GSTTaxCode/Get", { id: gstTaxCodeID }).then((response2) => {
						const { data } = response2;
						const list = form;

						const govtTaxCodeList = { ...govtTaxCodeEditorOptions };
						const projectCodeList = { ...projectCodeEditorOptions };
						const chartAccountCodeList = { ...chartAccountCodeEditorOptions };
						const taxTypeCodeList = { ...taxTypeCodeEditorOptions };
						const gstTranTypeCodeList = { ...gstTranTypeCodeEditorOptions };
						const badDeptsCodeList = { ...badDeptsCodeEditorOptions };

						govtTaxCodeList["value"] = data.model.GovtTaxCode;
						projectCodeList["value"] = data.model.ProjectID;
						chartAccountCodeList["value"] = data.model.ChartAccountID;
						taxTypeCodeList["value"] = data.model.TaxType;
						gstTranTypeCodeList["value"] = data.model.TranType;
						badDeptsCodeList["value"] = data.model.BadDebtsOption;

						setGovtTaxCodeEditorOptions(govtTaxCodeList);
						setProjectCodeEditorOptions(projectCodeList);
						setChartAccountCodeEditorOptions(chartAccountCodeList);
						setTaxTypeCodeEditorOptions(taxTypeCodeList);
						setGSTTranTypeCodeEditorOptions(gstTranTypeCodeList);
						setBadDeptsCodeEditorOptions(badDeptsCodeList);

						setDescription({
							disabled: false,
							value: data.model.Name
						});

						setTaxRate({
							disabled: false,
							value: data.model.TaxRate
						});

						// console.log(list)
						setForm(list);
					});
				}
			});
		}
	}

	function onInitialized(e) {
		// handlePromptOpen()
		baseapi.httpget("/api/GSTTaxCode/Get", { id: id || "" }).then((response) => {
			const { data } = response;
			if (id === "new") {
				// console.log("New")
				setForm({
					model: {
						Code: data.model.Code,
						Name: data.model.Name,
						TaxType: data.model.TaxType,
						TaxRate: data.model.TaxRate,
						ChartAccountID: data.model.ChartAccountID,
						ProjectID: data.model.ProjectID,
						LineNo: data.model.LineNo,
						FCode: data.model.FCode,
						Sign: data.model.Sign,
						IRR: data.model.IRR,
						Display: data.model.Display,
						Active: data.model.Active,
						GovtTaxCode: data.model.GovtTaxCode,
						BadDebtsOption: data.model.BadDebtsOption
					}
				});
			} else {
				// console.log("Existing")
				// console.log(data)
				setForm({ model: data.model });
			}
		});
	}

	function handleCancel() {
		navigate("/GSTTaxCode");
	}

	function testing(e) {
		// console.log("LOL", e)
	}

	return (
		<Container title=''>
			<Form id='form' formData={form.model} onInitialized={onInitialized} onFieldDataChanged={onFieldDataChanged}>
				<GroupItem colCount={2} caption='GST Tax Code'>
					<Item
						dataField='Code'
						editorType='dxSelectBox'
						editorOptions={{
							dataSource: taxCodeEditorOptions.dataSource,
							displayExpr: getDisplayExpr,
							valueExpr: "code",
							searchEnabled: true,
							searchExpr: "code",
							acceptCustomValue: true,
							onCustomItemCreating: newTaxCode,
							onSelectionChanged: testing
						}}
						disabled={id !== "new" ? true : false}>
						<Label text='Tax Code' />
					</Item>
					<Item dataField='GSTTranTypeID' editorType='dxSelectBox' editorOptions={gstTranTypeCodeEditorOptions}>
						<Label text='Type' />
					</Item>
					<Item editorType='dxSelectBox' editorOptions={taxTypeCodeEditorOptions} dataField='TaxTypeID'>
						<Label text='Tax Type' />
					</Item>
					<Item dataField='Name' editorOptions={description}>
						<Label text='Description' />
					</Item>
					<Item dataField='TaxRate' editorOptions={taxRate}>
						<Label text='GST Rate' />
						<PatternRule message='Must be in numerical form. Decimal is optional. Ex: 3.12 or 3' pattern={/^[0-9]+(?:\.[0-9]{1,2})?$/} />
					</Item>
					<Item editorType='dxSelectBox' editorOptions={govtTaxCodeEditorOptions} dataField='GovtTaxCode'>
						<Label text='Govt. Tax Code' />
					</Item>
					<Item editorType='dxSelectBox' editorOptions={projectCodeEditorOptions} dataField='ProjectID'>
						<Label text='Dept Code' />
					</Item>
					<Item editorType='dxSelectBox' editorOptions={chartAccountCodeEditorOptions} dataField='ChartAccountID'>
						<Label text='Chart Code' />
					</Item>
					<Item editorType='dxSelectBox' editorOptions={badDeptsCodeEditorOptions} dataField='BadDebtsOption'>
						<Label text='Bad Debts' />
					</Item>
					{/* <Item colSpan={2}
                    dataField="SalesmanID"
                    editorType="dxSelectBox"
                    editorOptions={salesmanIDEditorOptions}
                ><Label text="Salesman" />
                    <RequiredRule />
                </Item> */}
				</GroupItem>
				<GroupItem colCount={2}>
					{/* <ColCountByScreen xs={8} sm={8} md={8} lg={8} /> */}
					<ButtonItem colSpan={1} horizontalAlignment='right' buttonOptions={submitButtonOptions} />
					<ButtonItem colSpan={1} horizontalAlignment='left' buttonOptions={cancelButtonOptions} />
				</GroupItem>
			</Form>
		</Container>
	);
}
