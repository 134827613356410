import Listing from "../../../components/listing/newListing";
import { Column } from "devextreme-react/data-grid";
import Container from "../../../components/container";
import CashBookEntryForm from "./form";
import { useState } from "react";
import { Button } from "devextreme-react";
import ARReceiptForm from "../../AR-Module/receipt/form";
import APPaymentForm from "../../AP-Module/payment/form";
import ARRefundForm from "../../AR-Module/refund/form";
import APRefundForm from "../../AP-Module/refund/form";

export default function CashBookEntryListing() {
	const apiURL = `/api/GLCashBook`;
	const [currentEditID, setCurrentEditID] = useState(null);
	const [formType, setFormType] = useState(null);
	const [documentType, setDocumentType] = useState("");

	function handleEdit(e) {
		const id = e.row.key;
		const data = e.row.data;
		// console.log("Data", data);
		// console.log("ID", id);
		// console.log("DocType", data.DocumentType);
		// if (documentType === "OR") {
		// } else {
		// }
		if (data.DocumentType === "GL_PV") {
			setFormType("Payment Voucher");
			setDocumentType("PV");
		} else if (data.DocumentType === "GL_OR") {
			setFormType("Official Receipt");
			setDocumentType("OR");
		} else if (data.DocumentType === "AR_RR") {
			setFormType("AR Receipt");
		} else if (data.DocumentType === "AP_PV") {
			setFormType("AP Payment");
		} else if (data.DocumentType === "AR_RF") {
			setFormType("AR Refund");
		} else if (data.DocumentType === "AP_RF") {
			setFormType("AP Refund");
		}
		setCurrentEditID(id);
	}

	return (
		<Container>
			<div id='normal-listing'>
				<Listing
					apiURL={apiURL}
					listingURL={`/api/GLCashBook/list`}
					className={"gl-listing-datagrid"}
					sortColumn={"DocumentNo"}
					listingTitle={"Cash Book Entry"}
					storageName={"GLCashBookListing"}
					onAddClick={setCurrentEditID}
					customizedAddButton={[
						<Button
							icon='plus'
							text='New PV'
							className='listing-page-add-btn'
							id='gl-listing-btn'
							height={"36px"}
							onClick={() => {
								setCurrentEditID("new");
								setFormType("Payment Voucher");
								setDocumentType("PV");
							}}
						/>,
						<Button
							icon='plus'
							text='New OR'
							className='listing-page-add-btn'
							id='gl-listing-btn'
							style={{ marginLeft: "5px" }}
							onClick={() => {
								setCurrentEditID("new");
								setFormType("Official Receipt");
								setDocumentType("OR");
							}}
						/>
					]}
					handleEdit={handleEdit}>
					<Column dataField={"DocumentNo"} caption={"Journal No"} />
					<Column dataField={"DocumentDate"} format='dd/MM/yyyy' dataType={"date"} />
					<Column dataField={"PayTo"} />
					<Column dataField={"Description"} />
					<Column dataField={"PaymentMethod"} />
					<Column dataField={"ChequeNo"} />
					<Column dataField={"ProjectCode"} caption={"Project"} />
					<Column dataField={"ForexCode"} caption={"Forex"} />
					<Column dataField={"LocalDebit"} format={{ type: "fixedPoint", precision: 2 }} />
					<Column dataField={"LocalCredit"} format={{ type: "fixedPoint", precision: 2 }} />
					<Column dataField={"TotalLocalDebitIncTax"} caption={"Debit Inc Tax"} format={{ type: "fixedPoint", precision: 2 }} />
					<Column dataField={"TotalForexCreditIncTax"} caption={"Credit Inc Tax"} format={{ type: "fixedPoint", precision: 2 }} />
				</Listing>
			</div>

			{(formType == "Official Receipt" || formType == "Payment Voucher") && <CashBookEntryForm documentType={documentType} formType={formType} formID={currentEditID} closePopup={setCurrentEditID} apiURL={apiURL} />}

			{formType == "AR Receipt" && <ARReceiptForm formType={formType} formID={currentEditID} closePopup={setCurrentEditID} apiURL={`/api/ARReceipt`} title={"AR Receipt"} />}

			{formType == "AP Payment" && <APPaymentForm formType={formType} formID={currentEditID} closePopup={setCurrentEditID} title={"AP Payment"} apiURL={`/api/APPayment`} />}

			{formType == "AP Refund" && <APRefundForm formType={formType} formID={currentEditID} closePopup={setCurrentEditID} title={"AP Refund"} apiURL={`/api/APRefund`} />}

			{formType == "AR Refund" && <ARRefundForm formType={formType} formID={currentEditID} closePopup={setCurrentEditID} title={"AR Refund"} apiURL={`/api/ARRefund`} />}
		</Container>
	);
}
