import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import './HeaderV2.scss';
import { Button } from "devextreme-react";
import UserPanel from "../user-panel/UserPanelV2";
import baseapi from "../../api/baseapi";
import { useAuth } from '../../contexts/auth';
export default function Header(props) {
    const [companyName, setCompanyName] = useState(null);
    const [menuOpen, setMenuOpen] = useState(props.menuOpen);
    const { user } = useAuth();
    const navigate = useNavigate();

    const onInitialized = (e) => {
        const userID = JSON.parse(localStorage.getItem('data'));
        baseapi.httpget(`api/Company/Get`).then(response2 => {
            const { data } = response2;
            if (data.Name !== undefined) {
                setCompanyName(data.Name);
            }
        });
    }

    useEffect(() => {
        if (companyName === null) {
            onInitialized();
        }
    }, [companyName]);

    useEffect(() => {
        if (props.menuOpen !== undefined) {
            setMenuOpen(props.menuOpen);
        }
    }, [props.menuOpen]);
    const expiredMessage = user?.IsSubscriptionExpired
        ? "Your subscription plan has ended, and all documents have been set to read-only."
        : user?.IsFreeTrialExpired
            ? "Your free trial has ended, and all documents have been set to read-only."
            : null;

    const expiredSoonMessage = user?.IsSubscriptionExpiringSoon
        ? user?.DayLeft === 0
            ? `Your subscription plan will end in ${user?.TimeLeft === 0 ? 1 : user?.TimeLeft} hours.`
            : `Your subscription plan will end in ${user?.DayLeft} days.`
        : user?.IsFreeTrialExpiringSoon
            ? user?.DayLeft === 0
                ? `Your free trial plan will end in ${user?.TimeLeft === 0 ? 1 : user?.TimeLeft} hours.`
                : `Your free trial plan will end in ${user?.DayLeft} days.`
            : null;
    return (
        <div className="nav-header-container">
            <div className={`header-logo-container logo-container-white-${!menuOpen}`}>
                <div className="header-nav-button-container">
                    <Button
                        icon="menu"
                        stylingMode="text"
                        className="header-nav-button"
                        onClick={() => { props.onMenuButtonClick(!menuOpen); setMenuOpen(!menuOpen); }}
                    />
                </div>

                <div style={props.logoStyling} onClick={() => navigate("/home")}>
                    <img src={"/images/nav-logo.png"} alt={"Greenplus Accounting"} width={110} id='nav-logo' />
                </div>
            </div>

            <>
                {expiredSoonMessage && (
                    <div className="subscription-alert-background">
                        <div className="subscription-alert">
                            <img src="/images/warning-message.svg" alt="Warning" className="subscription-alert-icon" />
                            <p className="subscription-alert-message">
                                {expiredSoonMessage} To continue enjoying all features, please make the payment to reactivate your account through the <a href="/subscribe">Subscription Plan</a>.
                            </p>
                        </div>
                    </div>
                )}
            </>
            <>
                {expiredMessage && (
                    <div className="subscription-alert-background">
                        <div className="subscription-expired-alert">
                            <img src="/images/errorwarning-message.svg" alt="Warning" className="subscription-expired-alert-icon" />
                            <p className="subscription-expired-alert-message">
                                {expiredMessage} To continue enjoying all features, please make the payment to reactivate your account through the <a href="/subscribe">Subscription Plan</a>.
                            </p>
                        </div>
                    </div>
                )}
            </>

            <div className="nav-header-right-section">
                <UserPanel
                    companyName={companyName}
                    isMobile={props.isMobile}
                />
            </div>
        </div>
    );
}
