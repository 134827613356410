import React from "react";
// import "./home.scss";
import { useNavigate } from "react-router-dom";
import DataGrid, { Column, Grouping, GroupPanel, Pager, Paging, SearchPanel, Scrolling } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { Popup } from "devextreme-react/popup";
import DropDownButton from "devextreme-react/drop-down-button";
import { CheckBox } from "devextreme-react/check-box";
import { useState, useEffect, useRef } from "react";
import { Toast } from "devextreme-react/toast";
import SelectBox from "devextreme-react/select-box";
import { NumberBox, TagBox, TextBox } from "devextreme-react";
import DateBox from "devextreme-react/date-box";
import { LoadPanel } from "devextreme-react/load-panel";
import { Button } from "devextreme-react/button";
import PieChart, { Series, Label, Connector, Size, Export } from "devextreme-react/pie-chart";
import PopupForm from "../../components/popup-form/PopupForm";
import ValidationSummary from "devextreme-react/validation-summary";
import { Validator, RequiredRule } from "devextreme-react/validator";
import { areas, ChartDataSource, architectureSources, sharingStatisticsInfo, seriesTypeLabel, populationByRegions, grossProductData, facebookUsers, energySources, countriesInfo } from "./dummy-data";
import {
	Chart,
	Series as ChartSeries,
	ArgumentAxis,
	CommonSeriesSettings,
	CommonAxisSettings,
	Grid,
	Export as ChartExport,
	Legend,
	Margin,
	Tooltip,
	Label as ChartLabel,
	Format,
	SeriesTemplate,
	Title,
	Crosshair,
	Subtitle,
	Font,
	Point
} from "devextreme-react/chart";
import ScrollView from "devextreme-react/scroll-view";
import utils from "../../utils/common";
import baseapi from "../../api/baseapi";

export default function Home() {
	const path = "/api/Dashboard";
	const authPath = "/api/Login";
	const [displayLoadPanel, setDisplayLoadPanel] = useState(false);

	const [chartType, setChartType] = useState({ type: "spline" });
	const [chartOption1, setChartOption1] = useState("Standard Bar");
	const [chartOption2, setChartOption2] = useState("Doughnut");

	// useEffect (() => {
	//   const userID = JSON.parse(localStorage.getItem('data'));
	//   baseapi.httpget(utils.ReportURL +"/api/User/GetUserDetailByID?id=" + userID?.userID).then(result2 => {
	//     const { data } = result2;
	//     // console.log(data);

	//     localStorage.setItem('Authorization', "Bearer " + data.Token);
	//     setCurrentToken(() => data.Token);
	//     if(currentToken !== data.Token) {
	//       navigate(0);
	//     }
	//   });
	// }, [currentToken]);

	function pointClickHandler(e) {
		toggleVisibility(e.target);
	}

	function legendClickHandler(e) {
		const arg = e.target;
		const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];

		toggleVisibility(item);
	}

	function toggleVisibility(item) {
		item.isVisible() ? item.hide() : item.show();
	}

	const chartTooltip = (arg) => {
		// console.log("Args", arg)
		return { text: `${arg.valueText} - ${(arg.percent * 100).toFixed(2)}%` };
	};

	return (
		<React.Fragment>
			<LoadPanel shadingColor='rgba(0,0,0,0.4)' visible={displayLoadPanel} showIndicator={true} shading={true} showPane={true} hideOnOutsideClick={false} />

			{/* <div className={'content-block dashboard-chart-container kpi-chart-container'}>
          <div className="dx-card responsive-paddings dasboard-card">
            <div className="dashboard-kpi-container">
              <span className="dashboard-kpi-icon"><i className="dx-icon-money"></i></span>
              <div className="dashboard-kpi-data">
                <div className="dashboard-kpi-number">RM 120,000</div>
                <div className="dashboard-kpi-indicator">Revenue</div>
              </div>
            </div>
          </div>

          <div className="dx-card responsive-paddings dasboard-card">
            <div className="dashboard-kpi-container">
              <span className="dashboard-kpi-icon"><i className="dx-icon-cart"></i></span>
              <div className="dashboard-kpi-data">
                <div className="dashboard-kpi-number">RM 450,000</div>
                <div className="dashboard-kpi-indicator">Total Sales</div>
              </div>
            </div>
          </div>

          <div className="dx-card responsive-paddings dasboard-card">
            <div className="dashboard-kpi-container">
              <span className="dashboard-kpi-icon"><i className="dx-icon-doc"></i></span>
              <div className="dashboard-kpi-data">
                <div className="dashboard-kpi-number">1,250</div>
                <div className="dashboard-kpi-indicator">Total Orders</div>
              </div>
            </div>
          </div>

          <div className="dx-card responsive-paddings dasboard-card">
            <div className="dashboard-kpi-container">
              <span className="dashboard-kpi-icon"><i className="dx-icon-group"></i></span>
              <div className="dashboard-kpi-data">
                <div className="dashboard-kpi-number">5,000</div>
                <div className="dashboard-kpi-indicator">Clients</div>
              </div>
            </div>
          </div>
        </div> */}

			<div className={"content-block dashboard-chart-container chart-container-full"}>
				<div className='dx-card responsive-paddings dashboard-chart-content'>
					<Chart id='chart' dataSource={countriesInfo} height={350} width={"auto"}>
						<CommonSeriesSettings type='bar' argumentField='salesman' color={"#ffaa66"}>
							<Point hoverMode='allArgumentPoints' />
						</CommonSeriesSettings>
						{energySources.map((item) => (
							<Series key={item.value} valueField={item.value} name={item.name} />
						))}
						{/* <ArgumentAxis
                valueMarginsEnabled={false}
                discreteAxisDivisionMode="crossLabels"
              >
                <Grid visible={true} />
              </ArgumentAxis> */}
						<Crosshair enabled={true} color='#949494' width={3} dashStyle='dot'>
							<Label visible={true} backgroundColor='#949494'>
								<Font color='#fff' size={12} />
							</Label>
						</Crosshair>
						<Legend verticalAlignment='bottom' horizontalAlignment='center' itemTextPosition='bottom' equalColumnWidth={true} visible={false} />
						<Title text='Sales'>{/* <Subtitle text="Sales closed by each salesperson" /> */}</Title>
						<Export enabled={true} />
						<Tooltip enabled={true} />
					</Chart>
				</div>
			</div>

			<div className={"content-block dashboard-chart-container"}>
				<div className='dx-card responsive-paddings dashboard-chart-content'>
					{/* <div className="chart-options-container">
              Options:
              <SelectBox 
                dataSource={["Doughnut", "Side-by-Side Bar", "Color Each Bar Differently"]}
                onValueChanged={(e) => setChartOption2(e.value)}
                value={chartOption2}
              />
            </div> */}

					{chartOption2 === "Doughnut" && (
						<PieChart
							id='pie'
							height={350}
							type='doughnut'
							title='Sales & Collection'
							palette='Soft Pastel'
							dataSource={populationByRegions}
							customizePoint={(e) => {
								if (e.argument === "Payment") {
									return { color: "#02762e", hoverStyle: { color: "#02762e" } };
								} else {
									return { color: "#ec7c30", hoverStyle: { color: "#ec7c30" } };
								}
							}}>
							<Series argumentField='region'>
								<Label
									visible={true}
									format={{
										type: "currency", // the format of absolute values
										precision: 2, // the precision of absolute values (123.456 --> 123.45)
										currency: "MYR"
									}}>
									<Connector visible={true} />
								</Label>
							</Series>
							<Export enabled={true} />
							<Legend margin={0} horizontalAlignment='left' verticalAlignment='top' />

							<Tooltip
								enabled={true}
								customizeTooltip={(arg) => {
									return {
										text: `${arg.valueText} - ${(arg.percent * 100).toFixed(2)}%`
									};
								}}>
								<Format type='currency' currency={"MYR"} />
							</Tooltip>
						</PieChart>
					)}

					{chartOption2 === "Side-by-Side Bar" && (
						<Chart
							id='chart'
							height={280}
							title='Gross State Product within the Great Lakes Region'
							dataSource={grossProductData}
							onPointClick={(e) => {
								e.target.select();
							}}>
							<CommonSeriesSettings argumentField='state' type='bar' hoverMode='allArgumentPoints' selectionMode='allArgumentPoints'>
								<ChartLabel visible={true}>
									<Format type='fixedPoint' precision={0} />
								</ChartLabel>
							</CommonSeriesSettings>
							<Series argumentField='state' valueField='year2018' name='2018' />
							<Series valueField='year2017' name='2017' />
							<Series valueField='year2016' name='2016' />
							<Legend verticalAlignment='bottom' horizontalAlignment='center'></Legend>
							<ChartExport enabled={true} />
						</Chart>
					)}

					{chartOption2 === "Color Each Bar Differently" && (
						<Chart id='chart' height={280} palette='Soft' dataSource={facebookUsers}>
							<CommonSeriesSettings argumentField='age' valueField='number' type='bar' ignoreEmptyPoints={true} />
							<SeriesTemplate nameField='age' />
							<Title text='Age Breakdown of Facebook Users in the U.S.' subtitle='as of January 2017' />
						</Chart>
					)}
				</div>

				<div className='dx-card responsive-paddings dashboard-chart-content'>
					{/* <div className="chart-options-container">
              Options:
              <SelectBox 
                dataSource={["Standard Bar", "Pie", "Spline"]}
                onValueChanged={(e) => setChartOption1(e.value)}
                value={chartOption1}
              />
            </div> */}

					{chartOption1 === "Pie" && (
						<PieChart id='pie' height={280} dataSource={areas} palette='Bright' title='Area of Countries' onPointClick={pointClickHandler} onLegendClick={legendClickHandler} width={"auto"}>
							<Series argumentField='country' valueField='area'>
								<Label visible={true}>
									<Connector visible={true} width={1} />
								</Label>
							</Series>

							<Size width={"auto"} />
							<Export enabled={true} />
						</PieChart>
					)}

					{chartOption1 === "Standard Bar" && (
						<Chart
							id='chart'
							height={350}
							dataSource={ChartDataSource}
							width={"auto"}
							rotated={true}
							title={"Salesman Collection"}
							legend={{
								visible: false
							}}
							export={{ enabled: true }}>
							<ChartSeries valueField='oranges' argumentField='day' name='My oranges' type='bar' color='#02762e' />

							<Tooltip
								enabled={true}
								customizeTooltip={(arg) => {
									return {
										text: `${arg.valueText}`
									};
								}}>
								<Format type='currency' currency={"MYR"} />
							</Tooltip>
						</Chart>
					)}

					{chartOption1 === "Spline" && (
						<div>
							<Chart height={280} palette='Violet' dataSource={sharingStatisticsInfo} title='Architecture Share Over Time (Count)'>
								<CommonSeriesSettings argumentField='year' type={chartType.type} />
								<CommonAxisSettings>
									<Grid visible={true} />
								</CommonAxisSettings>
								{architectureSources.map((item) => (
									<ChartSeries key={item.value} valueField={item.value} name={item.name} />
								))}
								<Margin bottom={20} />
								<ArgumentAxis allowDecimals={false} axisDivisionFactor={60}>
									<ChartLabel>
										<Format type='decimal' />
									</ChartLabel>
								</ArgumentAxis>
								<Legend verticalAlignment='top' horizontalAlignment='right' />
								<ChartExport enabled={true} />
								<Tooltip enabled={true} />
							</Chart>

							<div className='options'>
								<div className='caption'>Options</div>
								<div className='option'>
									<span>Series Type </span>
									<SelectBox
										dataSource={["spline", "stackedspline", "fullstackedspline"]}
										inputAttr={seriesTypeLabel}
										value={chartType.type}
										onValueChanged={(e) => {
											setChartType({ type: e.value });
										}}
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</React.Fragment>
	);
}
