import React, { useEffect, useState, useRef } from "react";
import baseapi from "../../api/baseapi";
import { TextBox } from "devextreme-react/text-box";
import utils from "../../utils/common";
import Button from "devextreme-react/button";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import TextArea from "devextreme-react/text-area";
import { loading, closeLoading } from "../../utils/common";
import CustomizedLookup from "../../components/lookup/Lookup";
import { Column } from "devextreme-react/data-grid";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import PopupForm from "../../components/popup-form/PopupFormV2";
import { data } from "jquery";

export default function CustomerForm(props) {
	const formID = useRef(null);
	const formRef = useRef(null);
	const [isEditing, setIsEditing] = useState(false);
	const [startValidation, setStartValidation] = useState(0);
	const [forexList, setForexList] = useState([]);
	const [creditTermList, setCreditTermList] = useState([]);
	const [areaList, setAreaList] = useState([]);
	const [salesmanList, setSalesmanList] = useState([]);
	const [statementTypeList, setStatementTypeList] = useState([]);
	const [projectList, setProjectList] = useState([]);
	const [chartAccountList, setChartAccountList] = useState([]);
	const [hasTransaction, setHasTransaction] = useState(false);
	const formRead = useRef(false);
	const tempForm = useRef(null);
	const [formValue, setFormValue] = useState({});
	const chartAccountLookupRef = useRef(null);
	const areaLookupRef = useRef(null);
	const salesmanLookupRef = useRef(null);

	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}
	}, [props.formID]);

	function valueOnChange(e, receivedDataField = null) {
		// console.log("I am in value changed", e, "data field", e.element.getAttribute("dataField"))
		try {
			const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
			const copied = { ...tempForm.current };
			copied[dataField] = e.value;
			tempForm.current = copied;
			setFormValue(tempForm.current);

			if (!utils.isNullOrEmpty(e.value))
				if (dataField === "Name" && formID.current === "new") {
					let stringName = e.value;
					if (!utils.isNullOrEmpty(stringName)) {
						baseapi.httpget("/api/Customer/GetCustomerRunningNumber", { name: e.value }).then((response) => {
							const { data } = response;
							const copied = { ...tempForm.current };
							copied["Code"] = data;
							copied["Name"] = utils.toUpperCase(e.value);

							// copied[dataField] = e.value;
							tempForm.current = copied;
							setFormValue(tempForm.current);
						});
					}
				} else if (dataField === "CustomerAddress" && formRead) {
					const lines = e.value.split(/\r?\n|\\n/);
					// console.log(e.value);
					// const copied = {...tempForm.current};
					copied["CustomerAddress"] = utils.toUpperCase(e.value);
					copied["Address1"] = utils.toUpperCase(lines[0]);
					copied["Address2"] = utils.toUpperCase(lines[1]);
					copied["Address3"] = utils.toUpperCase(lines[2]);

					tempForm.current = copied;
					setFormValue(tempForm.current);
				} else if (dataField === "CustomerDeliveryAddress" && formRead) {
					const lines = e.value.split(/\r?\n|\\n/);
					// const copied = {...tempForm.current};
					copied["CustomerDeliveryAddress"] = utils.toUpperCase(e.value);
					copied["DeliveryAddress1"] = utils.toUpperCase(lines[0]);
					copied["DeliveryAddress2"] = utils.toUpperCase(lines[1]);
					copied["DeliveryAddress3"] = utils.toUpperCase(lines[2]);

					tempForm.current = copied;
					setFormValue(tempForm.current);
				} else if(dataField === "Code" && formRead.current){
					copied["Code"] = utils.toUpperCase(e.value);

					tempForm.current = copied;
					setFormValue(tempForm.current);
				} 
				else if (dataField === "Person" && formRead) {
					copied["Person"] = utils.toUpperCase(e.value);

					tempForm.current = copied;
					setFormValue(tempForm.current);
				} else if (dataField === "RegisterNo" && formRead) {
					copied["RegisterNo"] = utils.toUpperCase(e.value);

					tempForm.current = copied;
					setFormValue(tempForm.current);
				} else if (dataField === "RegisterNoOld" && formRead) {
					copied["RegisterNoOld"] = utils.toUpperCase(e.value);

					tempForm.current = copied;
					setFormValue(tempForm.current);
				} else if (dataField === "Remarks" && formRead) {
					copied["Remarks"] = utils.toUpperCase(e.value);

					tempForm.current = copied;
					setFormValue(tempForm.current);
				} else if (dataField === "GSTRegNo" && formRead) {
					copied["GSTRegNo"] = utils.toUpperCase(e.value);

					tempForm.current = copied;
					setFormValue(tempForm.current);
				} else if (dataField === "SalesTaxNo" && formRead) {
					copied["SalesTaxNo"] = utils.toUpperCase(e.value);

					tempForm.current = copied;
					setFormValue(tempForm.current);
				} else if (dataField === "ServiceTaxNo" && formRead) {
					copied["ServiceTaxNo"] = utils.toUpperCase(e.value);

					tempForm.current = copied;
					setFormValue(tempForm.current);
				} else if (dataField === "SSTExemptionNo" && formRead) {
					copied["SSTExemptionNo"] = utils.toUpperCase(e.value);

					tempForm.current = copied;
					setFormValue(tempForm.current);
				}

			if (dataField === "GSTRegDate" && formRead) {
				if (e.value === null) {
					copied["GSTRegDate"] = new Date();
				} else {
					copied["GSTRegDate"] = e.value;
				}
				tempForm.current = copied;
				setFormValue(tempForm.current);
			} else if (dataField === "SSTExemptionDate" && formRead) {
				if (e.value === null) {
					copied["SSTExemptionDate"] = new Date(1900, 0, 1);
				} else {
					copied["SSTExemptionDate"] = e.value;
				}
				tempForm.current = copied;
				setFormValue(tempForm.current);
			}
		} catch (err) {
			console.log(err);
		}
	}

	function handleCopyAddress() {
		const copiedForm = { ...formValue };

		if (copiedForm["CustomerAddress"] !== "") {
			copiedForm["DeliveryAddress1"] = copiedForm["Address1"];
			copiedForm["DeliveryAddress2"] = copiedForm["Address2"];
			copiedForm["DeliveryAddress3"] = copiedForm["Address3"];
			copiedForm["CustomerDeliveryAddress"] = copiedForm["CustomerAddress"];
		}

		setFormValue(copiedForm);
	}

	function handleSubmit({ stay }) {
		loading("Saving Customer...");
		const submittedForm = formValue;
		baseapi.httppost(utils.extendUrlVar("/api/Customer/save", { id: formID.current || "", del: false }), submittedForm).then((response) => {
			const { data } = response;
			utils.popupFormSuccessfulSubmit(formRef, data);
			utils.popupFormSetErrorForm(formRef, {
				visible: true,
				message: data.message,
				type: data.status ? "Success" : "Danger",
				action: data.action
			});

			//call refresh  on lookup
			if (data.status) {
				if (props.onRefresh) {
					props.onRefresh();
				}
			}
		});
	}

	const clearForm = () => {
		if (props.closePopup) {
			props.closePopup(null);
		}
		formRead.current = false;
		tempForm.current = {};
		setFormValue(tempForm.current);
	};

	function onInitialized(e) {
		if (formID.current === "new") loading("Loading New Customer...");
		else loading("Loading Existing Customer...");

		baseapi.httpget("/api/Customer/Get?id=" + (formID.current || "new")).then((response) => {
			const { data } = response;
			// console.log("data", data)
			if (!utils.isNullOrEmpty(data.hasTransaction)) {
				setHasTransaction(data.hasTransaction);
			} else {
				setHasTransaction(false);
			}

			tempForm.current = data.model;
			setFormValue(tempForm.current);
			closeLoading();
			formRead.current = true;
		});
	}

	return (
		<div>
			<PopupForm
				ref={formRef}
				onClosing={clearForm}
				width={"95%"}
				height={"90%"}
				headerClassName={"ar-module-form-title-bg popup-form-title"}
				title={props.title}
				fullScreen={false}
				onSuccessfulSubmit={({ stay }) => {
					if (stay) onInitialized();
				}}
				onValidating={setStartValidation}
				startValidation={startValidation}
				directSaveAndClose={props.directSaveAndClose}
				copyFields={{
					Parent: formValue,
				}}
				creditChecking={false}
				disabledCopyColumns={{ Parent : ['OutstandingAmount', 'OutstandingBalance'] }}
				onPasteForm={(e) => {
					tempForm.current = e.Parent;
					setFormValue(e.Parent);
				}}
				copyStorage={"ARCustomerCopiedData"}
				apiURL={`/api/Customer`}
				formData={formValue}
				isEditing={isEditing}
				lookupValidateRef={[chartAccountLookupRef]}
				onSaving={handleSubmit}
				onShown={onInitialized}>
				<div className='popup-from-group-container'>
					<div className='popup-form-group-name'>Profile</div>
					<div className={"popup-form-item-container1"}>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Company Name: </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["Name"]}
									elementAttr={{ dataField: "Name" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									// disabled={formID.current !== "new"}
								>
									<Validator>{startValidation !== 0 && <ValidatorRequired message={"Company Name is required!"} />}</Validator>
								</TextBox>
							</div>
						</div>
					</div>

					<div className={"popup-group-item-container-flex"}>
						{/* <div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Project: </div>

						<div className='popup-group-form-input'>
							<CustomizedLookup
								ref={projectLookupRef}
								className={"ar-listing-datagrid"}
								dataSource={projectList}
								displayExpr={"code"}
								valueExpr={"id"}
								value={formValue["ProjectID"]}
								onSelectionChanged={(e) => {
									valueOnChange(e, "ProjectID");
								}}
								startValidation={startValidation !== 0}
								required={true}
								requiredMessage={"Project is required!"}
								onDataSourceChanged={setProjectList}
								displayText={formValue["ProjectCode"]}
								dataSourceURL={"api/Utilities/GetProjects"}>
								<Column dataField='code'></Column>
								<Column dataField='name'></Column>
							</CustomizedLookup>
						</div>
					</div> */}

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Chart Account: </div>

							<div className='popup-group-form-input'>
								<CustomizedLookup
									ref={chartAccountLookupRef}
									className={"ar-listing-datagrid"}
									dataSource={chartAccountList}
									displayExpr={"code"}
									valueExpr={"id"}
									value={formValue["ChartAccountID"]}
									onSelectionChanged={(e) => {
										valueOnChange(e, "ChartAccountID");
									}}
									startValidation={startValidation !== 0}
									required={true}
									requiredMessage={"Chart Account is required!"}
									onDataSourceChanged={setChartAccountList}
									displayText={formValue["ChartAccountCode"]}
									dataSourceURL={"api/Utilities/GetGLDebtorAccount"}>
									<Column dataField='code'></Column>
									<Column dataField='name'></Column>
								</CustomizedLookup>
							</div>
						</div>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Code: </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["Code"]}
									elementAttr={{ dataField: "Code" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									disabled={false}>
									<Validator>{startValidation !== 0 && <ValidatorRequired message={"Code is required!"} />}</Validator>
								</TextBox>
							</div>
						</div>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label' style={{ width: "60px" }}>
								Active:{" "}
							</div>

							<div className='popup-group-form-input' style={{ paddingBottom: "7px" }}>
								<CheckBox
									value={formValue["Cancelled"]}
									elementAttr={{ dataField: "Cancelled" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}>
									{/* <Validator
										>
											<ValidatorRequired message={"Active is required!"} />
										</Validator> */}
								</CheckBox>
							</div>
						</div>
					</div>
				</div>

				<div className='popup-from-group-container'>
					<div className='popup-form-group-name'>General</div>
					<div className={"popup-form-item-container2"}>
						<div className='popup-group-form-item group-form-item-align-top'>
							<div className='popup-group-form-label'>Billing Address: </div>

							<div className='popup-group-form-input'>
								<TextArea
									value={formValue["CustomerAddress"]}
									elementAttr={{ dataField: "CustomerAddress" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									height={100}></TextArea>
							</div>
						</div>

						<div className='popup-group-form-item group-form-item-align-top'>
							<div className='popup-group-form-label'>
								Delivery Address:
								<Button icon='copy' text='Copy Billing' onClick={handleCopyAddress} style={{ marginTop: "5px" }} id='copy-address-btn' />
							</div>

							<div className='popup-group-form-input'>
								<TextArea
									value={formValue["CustomerDeliveryAddress"]}
									elementAttr={{ dataField: "CustomerDeliveryAddress" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									height={100}></TextArea>
							</div>
						</div>
					</div>

					<div className={"popup-form-item-container2"}>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Phone: </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["Telephone"]}
									elementAttr={{ dataField: "Telephone" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}></TextBox>
							</div>
						</div>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Fax: </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["Fax"]}
									elementAttr={{ dataField: "Fax" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}></TextBox>
							</div>
						</div>
					</div>

					<div className={"popup-form-item-container2"}>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Contact: </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["Person"]}
									elementAttr={{ dataField: "Person" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'></TextBox>
							</div>
						</div>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Email: </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["Email"]}
									elementAttr={{ dataField: "Email" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'></TextBox>
							</div>
						</div>
					</div>

					{/* <div className={"popup-form-item-container2"}>
							<div className="popup-group-form-item">
								<div className='popup-group-form-label'>Email: </div>

								<div className="popup-group-form-input">
									<TextBox
										value={formValue["Email"]}
										elementAttr={{dataField : "Email"}}
										onValueChanged={(e) => {valueOnChange(e)}}
										alignment="left"
									>
									</TextBox>
								</div>
							</div>
						</div> */}

					<div className={"popup-form-item-container2"}>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Registration No(New): </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["RegisterNo"]}
									elementAttr={{ dataField: "RegisterNo" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}></TextBox>
							</div>
						</div>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Registration No(Old): </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["RegisterNoOld"]}
									elementAttr={{ dataField: "RegisterNoOld" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}></TextBox>
							</div>
						</div>
					</div>
				</div>

				<div className='popup-from-group-container'>
					<div className='popup-form-group-name'>Others</div>

					<div className={"popup-form-item-container2"}>
						<div className={"popup-form-item-container1"}>
							<div className={"popup-form-item-container2"}>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Area: </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={areaLookupRef}
											className={"ar-listing-datagrid"}
											dataSource={areaList}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["AreaID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "AreaID");
											}}
											onDataSourceChanged={setAreaList}
											displayText={formValue["AreaCode"]}
											dataSourceURL={"api/Utilities/GetAreas"}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Area is required!"}>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Forex: </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											className={"ar-listing-datagrid"}
											dataSource={forexList}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["ForexID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "ForexID");
											}}
											onDataSourceChanged={setForexList}
											displayText={formValue["ForexCode"]}
											dataSourceURL={"api/Utilities/GetForexs"}
											disabled={hasTransaction}>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>
							</div>
							
							<div className={"popup-form-item-container1"}>
								<div className='popup-group-form-item group-form-item-align-top'>
									<div className='popup-group-form-label'>Remarks: </div>
									<div className='popup-group-form-input'>
										<TextArea
											value={formValue["Remarks"]}
											elementAttr={{ dataField: "Remarks" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											height={80}></TextArea>
									</div>
								</div>
							</div>
						</div>
						
						<div className={"popup-form-item-container1"}>
							<div className={"popup-form-item-container2"}>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Credit Term: </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											className={"ar-listing-datagrid"}
											dataSource={creditTermList}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["CreditTermID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "CreditTermID");
											}}
											onDataSourceChanged={setCreditTermList}
											displayText={formValue["CreditTermCode"]}
											dataSourceURL={"api/Utilities/GetCreditTerms"}>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Credit Limit: </div>

									<div className='popup-group-form-input'>
										<NumberBox
											value={formValue["CreditLimit"]}
											elementAttr={{ dataField: "CreditLimit" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											format='#,##0.00'></NumberBox>
									</div>
								</div>
							</div>

							<div className={"popup-form-item-container1"}>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Bank Acc: </div>

									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["BankAccount"]}
											elementAttr={{ dataField: "BankAccount" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
										/>
									</div>
								</div>
							</div>

							<div className={"popup-form-item-container2"}>					
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Statement Type: </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											className={"ar-listing-datagrid"}
											dataSource={statementTypeList}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["StatementTypeID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "StatementTypeID");
											}}
											onDataSourceChanged={setStatementTypeList}
											displayText={formValue["StatementTypeCode"]}
											dataSourceURL={"api/Utilities/GetStatementTypes"}>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Salesman: </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={salesmanLookupRef}
											className={"ar-listing-datagrid"}
											dataSource={salesmanList}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["SalesmanID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "SalesmanID");
											}}
											onDataSourceChanged={setSalesmanList}
											displayText={formValue["SalesmanCode"]}
											dataSourceURL={"api/Utilities/GetSalesmans"}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Salesman is required!"}>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='popup-from-group-container'>
					<div className='popup-form-group-name'>Tax</div>

					<div className={"popup-form-item-container2"}>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>GST Reg No: </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["GSTRegNo"]}
									elementAttr={{ dataField: "GSTRegNo" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}></TextBox>
							</div>
						</div>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>GST Reg Date: </div>

							<div className='popup-group-form-input'>
								<DateBox
									value={formValue["GSTRegDate"]}
									elementAttr={{ dataField: "GSTRegDate" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									displayFormat='dd/MM/yyyy'
									dateSerializationFormat='yyyy-MM-dd'></DateBox>
							</div>
						</div>
					</div>

					<div className={"popup-form-item-container2"}>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Sales Tax No: </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["SalesTaxNo"]}
									elementAttr={{ dataField: "SalesTaxNo" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}></TextBox>
							</div>
						</div>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Service Tax No: </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["ServiceTaxNo"]}
									elementAttr={{ dataField: "ServiceTaxNo" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}></TextBox>
							</div>
						</div>
					</div>

					<div className={"popup-form-item-container2"}>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Sales Tax Exemption No: </div>

							<div className='popup-group-form-input'>
								<TextBox
									value={formValue["SSTExemptionNo"]}
									elementAttr={{ dataField: "SSTExemptionNo" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}></TextBox>
							</div>
						</div>
					</div>

					<div className={"popup-form-item-container2"}>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Expired Date: </div>

							<div className='popup-group-form-input'>
								<DateBox
									value={formValue["SSTExemptionDate"]}
									elementAttr={{ dataField: "SSTExemptionDate" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									displayFormat='dd/MM/yyyy'
									dateSerializationFormat='yyyy-MM-dd'></DateBox>
							</div>
						</div>
					</div>
				</div>
			</PopupForm>
		</div>
	);
}
