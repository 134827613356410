import React from 'react';
import SettingListing from '../../components/listing/SettingListing';
import Container from '../../components/container';

class TransactionTypes extends React.Component {
    // render() {
    //     return <CommonTable title="Transaction Type" url="/api/TransactionType" hasCode sidx={"Code"} sord={true}/>;
    // }

    render() {
        return <Container>
            <div id='normal-listing'>
                <SettingListing
                    listingTitle="Transaction Type"
                    apiURL="/api/TransactionType"
                    listingURL="/api/TransactionType/list"
                    sortColumn={"Code"}
                    defaultSettingTemplate={true}
                    dateFilter={false}
                    storageName={"SettingTransactionTypeListing"}
                />
            </div>
        </Container>
    }
}

export default TransactionTypes;